import React, { useContext, useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

import { AuthContext } from "contexts/auth"
import api from "../../../config/api"

// Import Images
import avatar1 from "../../../assets/images/users/avatar-1.jpg"

function CardUser(props) {
  const { user } = useContext(AuthContext)
  const [settingsMenu, setSettingsMenu] = useState(false)
  const [captacao, setCaptacao] = useState()
  const [grupos, setGrupos] = useState()
  const [hunters, setHunters] = useState()
  const [totalUsers, setTotalUsers] = useState(0) 

  useEffect(() => {
    async function getData() {
      try {
        const response = await api.get("/users/all", {
          headers: {
            Authorization: `Bearer ${user.token}`
          }
        });
  
        let cap = [];
        let gru = [];
        let hun = [];
  
        response.data.forEach((element) => {
          if (element.type_user.type === "Captação") {
            cap.push(element);
          } else if (element.type_user.type === "Grupos") {
            gru.push(element);
          } else if (element.type_user.type === "Hunter") {
            hun.push(element);
          }
        });
  
        setCaptacao(cap.length);
        setGrupos(gru.length);
        setHunters(hun.length);
  
        const totalColaboradores = cap.length + gru.length + hun.length;
        setTotalUsers(totalColaboradores);
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
    }
  
    getData();
  }, []);
  

  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Row>
                <Col lg="6">
                  <div className="d-flex">
                    <div className="me-3">
                      <img
                        src={user.photo}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5 className="mb-1">{user.fullname}</h5>
                        <p className="mb-0 text-dark">Setor [{user.typeName?.type}]</p>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col lg="6" className="align-self-center">
                  <div className="text-lg-center mt-4 mt-lg-0">
                    <Row>
                      <Col xs="3">
                        <div>
                          <p className="text-muted text-truncate mb-2">
                            Colaboradores
                          </p>
                          <h5 className="mb-0">{totalUsers}</h5>
                        </div>
                      </Col>
                      <Col xs="3">
                        <div>
                          <p className="text-muted text-truncate mb-2">
                            Closers
                          </p>
                          <h5 className="mb-0">{captacao}</h5>
                        </div>
                      </Col>
                      <Col xs="3">
                        <div>
                          <p className="text-muted text-truncate mb-2">
                            Grupos
                          </p>
                          <h5 className="mb-0">{grupos}</h5>
                        </div>
                      </Col>
                      <Col xs="3">
                        <div>
                          <p className="text-muted text-truncate mb-2">
                            Hunters
                          </p>
                          <h5 className="mb-0">{hunters}</h5>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default CardUser
