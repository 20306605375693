import React, { useContext, useState, useEffect } from "react";
import { Modal, Button, Form, FormGroup, Label, Input, ModalFooter } from "reactstrap";
import { AuthContext } from "contexts/auth";
import Toastr from "../../../components/Ui/Notifications";
import api from "config/api";

const AddPaymentModal = ({ id, observation, controlModal, toggle, onChangeValueObservation, advisor }) => {
  const [newObservation, setNewObservation] = useState("");
  const { user } = useContext(AuthContext);

  useEffect(() => {
    setNewObservation(observation);
  }, [observation]);

  const handleAddPayment = async () => {
    const success = await postObservation(newObservation, id);
    if (success) {
      onChangeValueObservation(newObservation);
      toggle();
    }
  };

  async function postObservation(value, id) {
    try {
      await api.put(
        "assessor/update/meta",
        {
          id: id,
          meta: value,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      Toastr("success", "Meta atualizada com sucesso!");
      return true;
    } catch (error) {
      console.error(error);
      Toastr("error", "Algo errado aconteceu!");
      return false;
    }
  }

  return (
    <Modal isOpen={controlModal} toggle={toggle}>
      <div className="modal-header">
        <h5 className="modal-title">Editar Meta de {advisor}</h5>
        <button type="button" className="btn-close" onClick={toggle}></button>
      </div>
      <div className="modal-body">
        <Form>
          <FormGroup>
            <Label for="paymentValue">Defina uma nova meta para o Assessor</Label>
            <Input
              type="text"
              id="paymentValue"
              placeholder="Digite o valor semanal"
              value={newObservation}
              onChange={(e) => setNewObservation(e.target.value)}
            />
          </FormGroup>
        </Form>
      </div>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Fechar
        </Button>
        <Button color="primary" onClick={handleAddPayment}>
          Salvar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddPaymentModal;
