import React, { useEffect, useState, useContext } from 'react';
import { Row, Col, Card, CardBody } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../../components/Common/ChartsDynamicColor";
import { useParams } from "react-router-dom";
import { AuthContext } from "contexts/auth";

const SalesAnalytics = ({ dataColors, dashboard, primeiraAbordagem, conversaIniciada, convitesWhatsapp, whatsappCaptados, whatsappRespondidos, followUp, semSucesso, fechados }) => {
  const apexsalesAnalyticsChartColors = getChartColorsArray(dataColors);
  const { user } = useContext(AuthContext);
  const { id } = useParams();
  const [colaborador, setColaborador] = useState('');

  // Calculate total and percentages
  const total = primeiraAbordagem + conversaIniciada + convitesWhatsapp + whatsappCaptados + whatsappRespondidos + followUp + semSucesso + fechados;
  const percentages = total > 0 
    ? [
        (primeiraAbordagem / total) * 100,
        (conversaIniciada / total) * 100,
        (convitesWhatsapp / total) * 100,
        (whatsappCaptados / total) * 100,
        (whatsappRespondidos / total) * 100,
        (followUp / total) * 100,
        (semSucesso / total) * 100,
        (fechados / total) * 100,
      ]
    : Array(8).fill(0); // fallback in case total is 0

  const options = {
    chart: {
      type: 'radialBar',
      height: 400,
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 225,
        hollow: {
          size: '60%',
          background: 'transparent',
        },
        track: {
          background: '#e7e7e7',
          strokeWidth: '97%',
          margin: 5,
        },
        dataLabels: {
          name: {
            offsetY: -10,
            fontSize: '16px',
            color: '#333',
            fontWeight: 'bold',
          },
          value: {
            fontSize: '14px',
            color: '#777',
            fontWeight: '400',
            formatter: (val) => `${Math.round(val)}%`,
          },
          total: {
            show: true,
            label: 'Total',
            fontSize: '18px',
            fontWeight: 700,
            color: '#333',
            formatter: () => `${total}`,
          },
        },
      },
    },
    colors: apexsalesAnalyticsChartColors,
    labels: [
      "Primeira abordagem", 
      "Conversa iniciada", 
      "Convites de WhatsApp", 
      "WhatsApp captado", 
      "WhatsApp respondidos", 
      "Follow Up", 
      "Sem sucesso", 
      "Fechados"
    ],
    responsive: [
      {
        breakpoint: 576,
        options: {
          chart: {
            height: 250,
          },
          plotOptions: {
            radialBar: {
              hollow: {
                size: '50%',
              },
            },
          },
        },
      },
    ],
  };

  const labelItems = [
    { label: "Primeira abordagem", value: primeiraAbordagem, color: "info" },
    { label: "Conversa iniciada", value: conversaIniciada, color: "primary" },
    { label: "Convites de WhatsApp", value: convitesWhatsapp, color: "warning" },
    { label: "WhatsApp captado", value: whatsappCaptados, color: "light" },
    { label: "WhatsApp respondidos", value: whatsappRespondidos, color: "purple" },
    { label: "Follow Up", value: followUp, color: "pink" },
    { label: "Sem sucesso", value: semSucesso, color: "danger" },
    { label: "Fechado", value: fechados, color: "success" },
  ];

  // Split labels into two columns
  const half = Math.ceil(labelItems.length / 2);
  const firstColumn = labelItems.slice(0, half);
  const secondColumn = labelItems.slice(half);

  return (
    <React.Fragment>
      {dashboard ? (
        <Col xs="12">
          <Card className="shadow-sm border-0 rounded">
            <CardBody>
              <h4 className="card-title mb-4 text-center text-primary">Status total dos Captadores</h4>
              <div className="d-flex flex-row align-items-center">
                <Col xs="5">
                  <ReactApexChart
                    options={options}
                    series={percentages}
                    type="radialBar"
                    height={400}
                    className="apex-charts"
                  />
                </Col>
                <Col xs="7" className="text-center text-muted mt-4">
                  <Row className="g-2">
                    <Col xs="6">
                      {firstColumn.map((item, index) => (
                        <Row key={index} className="mb-2">
                          <Col className="d-flex align-items-center">
                            <i className={`mdi mdi-circle text-${item.color} me-2`} />
                            <span>{item.label}: <strong>{item.value}</strong></span>
                          </Col>
                        </Row>
                      ))}
                    </Col>
                    <Col xs="6">
                      {secondColumn.map((item, index) => (
                        <Row key={index} className="mb-2">
                          <Col className="d-flex align-items-center">
                            <i className={`mdi mdi-circle text-${item.color} me-2`} />
                            <span>{item.label}: <strong>{item.value}</strong></span>
                          </Col>
                        </Row>
                      ))}
                    </Col>
                  </Row>
                </Col>
              </div>
            </CardBody>
          </Card>
        </Col>
      ) : (
        <Col xl="7"></Col>
      )}
    </React.Fragment>
  );
};

export default SalesAnalytics;
