import React, { useContext, useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

import { AuthContext } from "contexts/auth"
import api from "../../../config/api"; 

function CardUser({total, ativos, archiveds }) {
  const { user } = useContext(AuthContext)
  const [settingsMenu, setSettingsMenu] = useState(false)



  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Row>
                <Col lg="4">
                  <div className="d-flex">
                    <div className="me-3">
                      <img
                        src={user.photo}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                        style={{borderRadius: '50%', minWidth: '75px', maxWidth: '75px', minHeight: '75px', maxHeight: '75px'}}
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <p className="mb-2 text-dark">Bem-vindo ao Painel Viraw </p>
                        <h5 className="mb-1">{user.fullname}</h5>
                        <p className="mb-0 text-dark">Setor: {user.typeName?.type}</p>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col lg="8" className="align-self-center">
                  <div className="text-lg-center mt-4 mt-lg-0">
                    <Row>
                      <Col xs="4">
                        <div>
                          <p className="text-muted text-truncate mb-2">
                            Total
                          </p>
                          <h5 className="mb-0">{total}</h5>
                        </div>
                      </Col>
                      <Col xs="4">
                        <div>
                          <p className="text-muted text-truncate mb-2">
                            Ativos
                          </p>
                          <h5 className="mb-0">{ativos}</h5>
                        </div>
                      </Col>
                      <Col xs="4">
                        <div>
                          <p className="text-muted text-truncate mb-2">
                            Desativados
                          </p>
                          <h5 className="mb-0">{archiveds}</h5>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>

         
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default CardUser
