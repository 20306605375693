import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChart = () => {
  const barData = {
    labels: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
    datasets: [
      {
        label: "Entrada",
        data: [120, 150, 180, 200, 230, 210, 250, 220, 240, 260, 20, 140],
        backgroundColor: "blue",
      },
      {
        label: "Saída",
        data: [80, 100, 120, 140, 150, 170, 190, 160, 180, 200, 210, 230],
        backgroundColor: "red",
      },
    ],
  };

  const barOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "right",
        align: "center",
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Mês",
        },
      },
      y: {
        title: {
          display: true,
          text: "Valor",
        },
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <div style={{ height: '350px', width: '100%' }}>
      <Bar data={barData} options={barOptions} />
    </div>
  );
};

export default BarChart;
