import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "contexts/auth";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Input,
} from "reactstrap";
import api from "config/api";
import Spinners from "components/Common/Spinner";
import Breadcrumbs from "components/Common/Breadcrumb";
import List from "./List";

const CollaboratorsList = () => {
  const [isLoading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [colaboradoresGrupo, setColaboradoresGrupo] = useState([]);
  const [listPayments, setListPayments] = useState([]);
  const [listPaymentsOriginal, setListPaymentsOriginal] = useState([]);
  const [totalFTDs, setTotalFTDs] = useState(0); 
  const { user } = useContext(AuthContext);

  const getCurrentPeriod = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1; 
    return { period: `${year}-${month}`, monthName: now.toLocaleString('default', { month: 'long' }), year };
  };


  const getSaques = async () => {
    try {
      const response = await api.get("/tracking/superbet/geral/all", {
        headers: {
          Authorization: `Bearer ${user.token}`, 
        },
      });

      setListPayments(response.data);
      setListPaymentsOriginal(response.data);

      const captadorId = user?.userId;

      if (!captadorId) {
        console.error("Captador ID not found in user context");
        return;
      }

      const filteredData = response.data.filter(item => item.captador?.id === captadorId);
      setListPayments(filteredData);
      setColaboradoresGrupo(filteredData);

    
    } catch (error) {
      console.error("Error fetching influencers:", error);
    }
  };

  useEffect(() => {
    getSaques();
  }, [user.token]);

  const handleSearchCollaborator = value => {
    setSearchValue(value);
    const lowerCaseValue = value.toLowerCase();

    const filteredCollaborators = listPaymentsOriginal.filter(collaborator => {
      const fullname = collaborator.influencer?.toLowerCase() || "";
      const username = collaborator.username?.toLowerCase() || "";

      const isUserCaptador = collaborator.captador?.id === user.userId;

      return (
        isUserCaptador &&
        (fullname.includes(lowerCaseValue) || username.includes(lowerCaseValue))
      );
    });

    setListPayments(filteredCollaborators);

    if (value.trim() === "") {
      const filteredByUser = listPaymentsOriginal.filter(
        item => item.captador?.id === user.userId
      );
      setListPayments(filteredByUser);
    }

  };

  const { monthName, year } = getCurrentPeriod();
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Influencers"
            breadcrumbItem="Acompanhamento Mensal"
          />
          <Row>
            <Col lg={12}>
              <Card className="collaborator-filter">
                <CardBody>
                  <form action="#">
                    <Row className="g-3">
                      <Col xxl={4} lg={4}>
                        <div className="position-relative">
                          <Input
                            type="text"
                            id="searchCollaborator"
                            autoComplete="off"
                            placeholder="Buscar Influenciador"
                            onChange={e => handleSearchCollaborator(e.target.value)}
                            value={searchValue}
                          />
                        </div>
                        <div style={{marginTop: '10px'}}>
                        <h4>FTDs de {monthName}</h4>
                        </div>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
        
          </Row>
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <List listData={listPayments} />
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CollaboratorsList;
