import React, { useState, useEffect, useContext, useRef } from "react";
import { Card, CardBody, Col, Row, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { AuthContext } from "contexts/auth";
import UpdateReativarInfluencer from "components/Modals/UpdateReativarInfluencer";

const List = ({ listData, selectedFilterOption }) => {
  const [influencers, setInfluencers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalDetails, setShowModalDetails] = useState(false);
  const [observation, setObservation] = useState("");
  const [idInfluencer, setIdInfluencer] = useState("");
  const [page, setPage] = useState(1);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const { user } = useContext(AuthContext);
  const observer = useRef();

  const loadMore = () => {
    setIsLoadingMore(true); 
    setTimeout(() => { 
      const nextPage = page + 1;
      const start = (nextPage - 1) * 20;
      const end = start + 20;
      const moreInfluencers = listData.slice(start, end);
      setInfluencers(prev => [...prev, ...moreInfluencers]);
      setPage(nextPage);
      setIsLoadingMore(false);
    }, 1000);
  };

  const lastElementRef = useRef();

  useEffect(() => {
    if (listData.length > 0) {
      setInfluencers(listData.slice(0, 20));
    }
  }, [listData]);

  useEffect(() => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        loadMore();
      }
    });
    if (lastElementRef.current) {
      observer.current.observe(lastElementRef.current);
    }
  }, [influencers]);

  useEffect(() => {
    if (selectedFilterOption) {
      const filteredCollaborators = listData.filter(collaborator => {
        if (selectedFilterOption.value === 'Outros...') {
          return collaborator.observation !== 'Valores.' && collaborator.observation !== 'Não divulga casa de apostas.' && collaborator.observation !== 'Não respondeu.';
        } else {
          return collaborator.observation === selectedFilterOption.value;
        }
      });
      setInfluencers(filteredCollaborators.slice(0, 20));
    } else {
      setInfluencers(listData.slice(0, 20));
    }
  }, [listData, selectedFilterOption]);

  const activeBtn = (id) => {
    setIdInfluencer(id);
    setShowModal(true);
  };

  const handleCloseModalDetails = () => {
    setShowModalDetails(false);
  };

  const handleShowDetails = (observation) => {
    setObservation(observation);
    setShowModalDetails(true);
  };

  return (
    <React.Fragment>
      <Row>
        {influencers.map((influencer, key) => (
          <Col xl={3} key={key}>
            <Card>
              <CardBody>
                <div className="d-flex align-start mb-3">
                  <div className="flex-grow-1">
                    <span className="badge badge-soft-info">
                      {influencer.captacao_influencer.fullname ? influencer.captacao_influencer.fullname : "Nome indisponível"}
                    </span>
                  </div>
                  <button
                    type="button"
                    className="btn btn-light btn-sm like-btn"
                    onClick={(e) => activeBtn(influencer?.id)}
                  >
                    <i
                      className="mdi mdi-account-reactivate-outline"
                      style={{ fontSize: "14px" }}
                    ></i>
                  </button>
                </div>
                <div className="text-center mb-3">
                  {influencer.photo && (
                    <img
                      src={influencer.photo}
                      alt=""
                      className="avatar-sm rounded-circle"
                    />
                  )}
                  <h6 className="font-size-15 mt-3 mb-1">
                    @{influencer.username ? influencer.username : 'Nome indisponível'}
                  </h6>
                </div>
                <div className="mt-4 pt-1 d-flex justify-content-center">
                  <button
                    className="btn btn-soft-primary"
                    onClick={() => handleShowDetails(influencer.observation)}
                  >
                    Ver Detalhes
                  </button>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
        <div ref={lastElementRef} />
      </Row>
      {isLoadingMore && (
        <div className="text-center mt-3 mb-3">
          <p>Carregando...</p>
        </div>
      )}
      {showModal && (
        <UpdateReativarInfluencer
          influencerId={idInfluencer}
          controlModal={showModal}
          toggle={() => setShowModal(!showModal)}
        />
      )}
      <Modal isOpen={showModalDetails} toggle={handleCloseModalDetails}>
        <ModalHeader toggle={handleCloseModalDetails}>Detalhes do Influenciador</ModalHeader>
        <ModalBody>
          <p>{observation ? observation : "Motivo não informado"}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleCloseModalDetails}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default List;
