import React, { useEffect, useState, useMemo, useContext } from "react"
import "./styles.css"
import { Link, useParams } from "react-router-dom"
import { map } from "lodash"
import api from "config/api"
import { AuthContext } from "contexts/auth"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
  Tooltip,
  Button,
} from "reactstrap"
import { verificarImagem } from "utils/verificationImage"
import {
  Badge,
  ImageBadge,
  Username,
  PostTitle,
  GroupInfo,
  Info,
  LinkBadge,
  Buttons,
  ButtonsTxt,
} from "./styles"
import Loading from "components/Ui/LoadingCircle"

// TableContainer

import { Pdate, Ddate, Name, Idno, Budget } from "./CryptoCol"

import TableContainer from "../../../../components/Common/TableContainer"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import mini card widgets
import MiniCards from "./mini-card"

//Import Images
import profile1 from "assets/images/profile-img.png"
import EditarColaborador from "components/Modals/EditarModal/index"

// import charts
import ApexRevenue from "../ApexRevenue"
import { StateContext } from "contexts/states"

const ContactsProfile = () => {
  const { user } = useContext(AuthContext)
  const { id } = useParams()

  const [colaborador, setColaborador] = useState([])
  const [colaboradores, setColaboradores] = useState([])
  const [colaboradorPhoto, setColaboradorPhoto] = useState()
  const [loading, setLoading] = useState(true)
  const [values, setValues] = useState()
  const [valuesMes, setValuesMes] = useState(0)
  const [valuesGroup, setValuesGroup] = useState()
  const [valuesMesGroup, setValuesMesGroup] = useState(0)
  const [errorMessage, setErrorMessage] = useState(null)
  const [ttop, setttop] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const { editmodal, setEditModal } = useContext(StateContext)
  const [selectedStatus, setSelectedStatus] = useState("Todos") // Estado para controlar o status selecionado
  const [indexInit, setIndexInit] = useState()

  const [graphicsCap, setGraphicsCap] = useState()
  const [graphicsGru, setGraphicsGru] = useState()

  function handleEditClick() {
    setEditModal(true)
  }
  const filterByStatus = status => {
    setSelectedStatus(status)
  }

  useEffect(() => {
    setLoading(true)
    async function getColaborador() {
      try {
        await api
          .get(`/users/${id}`, {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          })
          .then(async response => {
            const influencerData = response.data
            const type = response.data.type

            setColaborador(influencerData)

            verificarImagem(influencerData.photo)
              .then(isValid => {
                if (isValid) {
                  setColaboradorPhoto(influencerData.photo)
                } else {
                  setColaboradorPhoto(
                    "https://i.ibb.co/y6nkQKW/461085513-1715142105981321-1128849984098454117-n.jpg"
                  )
                }
              })
              .catch(error => {
                console.error("Ocorreu um erro:", error)
              })

            if (type === 2) {
              await api
                .get(`/graphics/captacao/${influencerData.id}`, {
                  headers: {
                    Authorization: `Bearer ${user.token}`,
                  },
                })
                .then(response => {
                  setGraphicsCap(response.data)

                  setLoading(false)
                })
                .catch(err => {
                  console.error(err)
                })
            } else if (type === 3) {
              await api
                .get(`/graphics/grupos/${influencerData.id}`, {
                  headers: {
                    Authorization: `Bearer ${user.token}`,
                  },
                })
                .then(response => {
                  setGraphicsGru(response.data)
                })
                .catch(err => {
                  console.error(err)
                })
            }
            setLoading(false)
          })
      } catch (error) {
        console.error("Erro ao carregar o influenciador:", error)
        setErrorMessage("Erro ao carregar o influenciador.")
      }
    }

    getColaborador()
  }, [user, id])

  useEffect(() => {
    async function getColaboradores() {
      await api
        .get(`/users/all`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        })
        .then(response => {
          let temp = []
          response.data.forEach(element => {
            if (element.type_user?.type !== "Administrador") {
              temp.push(element)
            }
          })

          temp.map((element, index) => {
            if (element.id === id) {
              setIndexInit(index)
            }
          })

          setColaboradores(temp)
        })
    }

    getColaboradores()
  }, [])

  const experiences = [
    {
      id: 1,
      iconClass: "bx-briefcase",
      designation: `${
        colaborador.type === 2 ? "Closer" : colaborador.type_user?.type
      }`,
      timeDuration: "2024 - Presente",
    },
  ]

  const getStatusBackgroundColor = status => {
    switch (status) {
      case "Fechado":
        return "bg-success"
      case "Em contato":
        return "bg-warning"
      case "Negociando":
        return "bg-primary"
      case "Sem sucesso":
        return "bg-danger"
      default:
        return "bg-warning"
    }
  }
  const renderInfluenciadores = () => {
    if (colaborador.type_user.type === "Captação") {
      return colaborador.influencerCaptacao
        .filter(
          element =>
            element.status_influencer?.status === selectedStatus ||
            selectedStatus === "Todos"
        )
        .map((element, index) => {
          const badgeId = `badge-cards-${index}`

          return (
            <LinkBadge
              className={`badge-cards ${getStatusBackgroundColor(
                element.status_influencer?.status
              )}`}
              key={index}
              id={badgeId}
              href={
                element.status_influencer.status === "Fechado"
                  ? `/influencer/${element.plug_chat?.id}`
                  : "#"
              }
              onMouseEnter={() => setttop(badgeId)}
              onMouseLeave={() => setttop(null)}
            >
              <Badge>
                <ImageBadge src={element.photo} alt="" />
              </Badge>
              {ttop === badgeId && (
                <Tooltip placement="top" isOpen={true} target={badgeId}>
                  @{element.username}
                </Tooltip>
              )}
            </LinkBadge>
          )
        })
    } else {
      return colaborador?.plug_chat.map((element, index) => {
        const badgeId = `badge-cards-${index}`

        return element.influencer_rent?.archiving === false &&
          element.influencer_rent.posts.filter(post => {
            const postDate = new Date(post.start)
            return postDate.toDateString() === new Date().toDateString()
          }).length > 0 &&
          (element.status_influencer?.status === selectedStatus ||
            selectedStatus === "Todos") ? (
          <LinkBadge
            className={`badge-cards ${getStatusBackgroundColor(
              element.status_influencer?.status
            )}`}
            key={index}
            id={badgeId}
            href={
              element.status_influencer.status === "Fechado"
                ? `/influencer/${element.plug_chat?.id}`
                : "#"
            }
            onMouseEnter={() => setttop(badgeId)}
            onMouseLeave={() => setttop(null)}
          >
            <Badge>
              <ImageBadge src={element.influencer_rent?.photo} alt="" />
            </Badge>
            {ttop === badgeId && (
              <Tooltip placement="top" isOpen={true} target={badgeId}>
                @{element.influencer_rent?.username}
              </Tooltip>
            )}
          </LinkBadge>
        ) : null
      })
    }
  }

  const handleNextClick = () => {
    if (indexInit === colaboradores.length - 1) {
      setIndexInit(0)
    } else {
      setIndexInit(prevIndex => prevIndex + 1)
    }
  }

  const handlePreviousClick = () => {
    if (indexInit === 0) {
      setIndexInit(colaboradores.length - 1)
    } else {
      setIndexInit(prevIndex => prevIndex - 1)
    }
  }

  const next = option => {
    if (option === "next") {
      return colaboradores[indexInit + 1]?.id
    } else {
      return colaboradores[indexInit - 1]?.id
    }
  }

  return (
    <React.Fragment>
      {!loading ? (
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Colaboradores" breadcrumbItem="Perfil" />
            <div className="text-sm-end mb-3">
              <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2"
                onClick={handleEditClick}
              >
                <i className="bx bx bx-edit-alt" />
                Editar Colaborador
              </Button>
            </div>

            <Buttons className="bg-light">
              <Link
                to={`/colaboradores/${
                  indexInit <= 0
                    ? colaboradores[colaboradores.length - 1]?.id
                    : next("anterior")
                }`}
                onClick={handlePreviousClick}
              >
                <ButtonsTxt className="text-muted">🠔 Anterior</ButtonsTxt>
              </Link>

              <Link
                to={`/colaboradores/${
                  indexInit === colaboradores.length - 1
                    ? colaboradores[0]?.id
                    : next("next")
                }`}
                onClick={handleNextClick}
              >
                <ButtonsTxt className="text-muted">Próximo 🠖</ButtonsTxt>
              </Link>
            </Buttons>

            {editmodal && (
              <EditarColaborador
                isOpen={editmodal}
                toggle={() => setEditModal(!editmodal)}
                id={id} // Passando o id para o modal
                email={colaborador.email}
                fullname={colaborador.fullname}
                photo={colaboradorPhoto}
              />
            )}

            {colaborador ? (
              <Row>
                <Col xl="4">
                  <Card className="overflow-hidden">
                    <div className="bg-primary-subtle">
                      <Row>
                        <Col xs="7">
                          <div className="text-primary p-3" />
                        </Col>
                      </Row>
                    </div>
                    <CardBody className="pt-0">
                      <Row>
                        <Col sm="6">
                          <div className="avatar-md profile-user-wid mb-4">
                            <img
                              src={colaboradorPhoto}
                              alt=""
                              className="photo-profile-colaborador"
                            />
                          </div>
                          <h5 className="font-size-15 text-truncate">
                            {colaborador.fullname}
                          </h5>
                          <p className="text-muted text-truncate">
                            {colaborador.email}
                          </p>
                        </Col>

                        {colaborador.type_user.type === "Captação" ? (
                          <Col sm={6}>
                            <div className="pt-4">
                              <Row>
                                <Col xs="12">
                                  <h5 className="font-size-15">
                                    {graphicsCap &&
                                      graphicsCap.graphic1?.fechados}
                                  </h5>
                                  <p className="text-muted mb-0">
                                    Total de influencers fechados
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        ) : (
                          <Col sm={6}>
                            <div className="pt-4">
                              <Row>
                                <Col xs="12">
                                  <h5 className="font-size-15">
                                    {graphicsGru &&
                                      graphicsGru.graphic1?.ativos}
                                  </h5>
                                  <p className="text-muted mb-0">
                                    Total de grupos administrados
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        )}
                      </Row>
                    </CardBody>
                  </Card>

                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4 pt-1">
                        Informações pessoais
                      </CardTitle>
                      <div className="table-responsive">
                        <Table className="table-nowrap mb-0">
                          <tbody>
                            <tr>
                              <th scope="row">Nome Completo: </th>
                              <td>{colaborador.fullname}</td>
                            </tr>
                            <tr>
                              <th scope="row">Telefone: </th>
                              <td>{colaborador.phone || "Não cadastrado"}</td>
                            </tr>
                            <tr>
                              <th scope="row">E-mail: </th>
                              <td>{colaborador.email}</td>
                            </tr>
                            <tr>
                              <th scope="row">Localização: </th>
                              <td>Petrolina - PE</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>

                  <Card>
                    <CardBody>
                      <CardTitle className="mb-5">
                        Experiencia Profissional{" "}
                      </CardTitle>

                      <div>
                        <ul className="verti-timeline list-unstyled">
                          {map(experiences, (experience, i) => (
                            <li
                              className={
                                experience.id === 1
                                  ? "event-list active"
                                  : "event-list"
                              }
                              key={"_exp_" + i}
                            >
                              <div className="event-timeline-dot">
                                <i
                                  className={
                                    experience.id === 1
                                      ? "bx bx-right-arrow-circle bx-fade-right"
                                      : "bx bx-right-arrow-circle"
                                  }
                                />
                              </div>
                              <div className="d-flex">
                                <div className="me-3">
                                  <i
                                    className={
                                      "bx " +
                                      experience.iconClass +
                                      " h4 text-primary"
                                    }
                                  />
                                </div>

                                <div className="flex-grow-1">
                                  <div>
                                    <h5 className="font-size-15">
                                      <Link
                                        to={experience.link}
                                        className="text-dark"
                                      >
                                        {experience.designation}
                                      </Link>
                                    </h5>
                                    <span className="text-primary">
                                      {experience.timeDuration}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </CardBody>
                  </Card>

                  {colaborador.type_user.type === "Captação" ? (
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4">
                          Status do Captador
                        </CardTitle>
                        <div className="table-responsive">
                          <Table className="table-nowrap mb-0">
                            <tbody>
                              <tr>
                                <th scope="row">Em contato: </th>
                                <td>
                                  {graphicsCap && graphicsCap.graphic4?.contato}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Remarketing: </th>
                                <td>
                                  {graphicsCap &&
                                    graphicsCap.graphic4?.remarketing}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Negociando: </th>
                                <td>
                                  {graphicsCap &&
                                    graphicsCap.graphic4?.negociando}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Fechados: </th>
                                <td>
                                  {graphicsCap &&
                                    graphicsCap.graphic4?.fechados}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Fechados Hoje: </th>
                                <td>
                                  {graphicsCap &&
                                    graphicsCap.graphic2?.fechadosHoje}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Fechados Essa Semana: </th>
                                <td>
                                  {graphicsCap &&
                                    graphicsCap.graphic2?.fechadosSemana}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Fechados Esse Mês: </th>
                                <td>
                                  {graphicsCap &&
                                    graphicsCap.graphic2?.fechadosMes}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Sem Sucesso: </th>
                                <td>
                                  {graphicsCap &&
                                    graphicsCap.graphic4?.semSucesso}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </CardBody>
                    </Card>
                  ) : null}
                </Col>

                <Col xl="8">
                  <Row>
                    {colaborador.type_user.type === "Captação" ? (
                      <>
                        <h4>Influencers Fechados</h4>
                        <MiniCards
                          title={"Hoje"}
                          text={`${
                            graphicsCap && graphicsCap.graphic2?.fechadosHoje
                          }`}
                          iconClass={"bx-check-circle"}
                          key={"_card_" + 1}
                        />
                        <MiniCards
                          title={"Na semana"}
                          text={`${
                            graphicsCap && graphicsCap.graphic2?.fechadosSemana
                          }`}
                          iconClass={"bx-check-circle"}
                          key={"_card_" + 2}
                        />
                        <MiniCards
                          title={"No mês"}
                          text={`${
                            graphicsCap && graphicsCap.graphic2?.fechadosMes
                          }`}
                          iconClass={"bx-check-circle"}
                          key={"_card_" + 3}
                        />
                      </>
                    ) : (
                      <>
                        <h4>Influencers Atribuidos</h4>
                        <MiniCards
                          title={"Hoje"}
                          text={`${graphicsGru && graphicsGru.graphic2?.hoje}`}
                          iconClass={"bx-check-circle"}
                          key={"_card_" + 4}
                        />
                        <MiniCards
                          title={"Na semana"}
                          text={`${
                            graphicsGru && graphicsGru.graphic2?.semana
                          }`}
                          iconClass={"bx-check-circle"}
                          key={"_card_" + 5}
                        />
                        <MiniCards
                          title={"No mês"}
                          text={`${graphicsGru && graphicsGru.graphic2?.mes}`}
                          iconClass={"bx-check-circle"}
                          key={"_card_" + 6}
                        />
                      </>
                    )}
                  </Row>

                  <Card>
                    <CardBody>
                      <CardTitle className="card-title">Evolução</CardTitle>
                      <div id="revenue-chart">
                        {colaborador.type_user.type === "Captação" ? (
                          <ApexRevenue
                            dataColors='["--bs-primary"]'
                            data={[
                              graphicsCap && graphicsCap.graphic3?.jan,
                              graphicsCap && graphicsCap.graphic3?.fev,
                              graphicsCap && graphicsCap.graphic3?.mar,
                              graphicsCap && graphicsCap.graphic3?.abr,
                              graphicsCap && graphicsCap.graphic3?.mai,
                              graphicsCap && graphicsCap.graphic3?.jun,
                              graphicsCap && graphicsCap.graphic3?.jul,
                              graphicsCap && graphicsCap.graphic3?.ago,
                              graphicsCap && graphicsCap.graphic3?.set,
                              graphicsCap && graphicsCap.graphic3?.out,
                              graphicsCap && graphicsCap.graphic3?.nov,
                              graphicsCap && graphicsCap.graphic3?.dez,
                            ]}
                          />
                        ) : (
                          <ApexRevenue
                            dataColors='["--bs-primary"]'
                            data={[
                              graphicsGru && graphicsGru.graphic3?.jan,
                              graphicsGru && graphicsGru.graphic3?.fev,
                              graphicsGru && graphicsGru.graphic3?.mar,
                              graphicsGru && graphicsGru.graphic3?.abr,
                              graphicsGru && graphicsGru.graphic3?.mai,
                              graphicsGru && graphicsGru.graphic3?.jun,
                              graphicsGru && graphicsGru.graphic3?.jul,
                              graphicsGru && graphicsGru.graphic3?.ago,
                              graphicsGru && graphicsGru.graphic3?.set,
                              graphicsGru && graphicsGru.graphic3?.out,
                              graphicsGru && graphicsGru.graphic3?.nov,
                              graphicsGru && graphicsGru.graphic3?.dez,
                            ]}
                          />
                        )}
                      </div>
                    </CardBody>
                  </Card>

                  <Card>
                    <CardTitle className="mb-4">
                      {colaborador.type_user.type === "Captação"
                        ? "Meus Influencers"
                        : "Registros diários"}
                    </CardTitle>

                    {colaborador.type_user.type === "Grupos" ? (
                      <GroupInfo>
                        <Info key={55} className="bg-success">
                          Postou
                        </Info>
                        <Info key={56} className="bg-danger">
                          Não Postou
                        </Info>
                        <Info key={57} className="bg-warning">
                          Aguardando
                        </Info>
                      </GroupInfo>
                    ) : (
                      <Card>
                        <CardBody>
                          <div className="text-center">
                            <Row className="align-items-center justify-content-center">
                              <Button
                                color={
                                  selectedStatus === "Em contato"
                                    ? "warning"
                                    : "light"
                                }
                                className="mx-2 mb-2"
                                onClick={() => filterByStatus("Em contato")}
                              >
                                Em contato
                              </Button>

                              <Button
                                color={
                                  selectedStatus === "Negociando"
                                    ? "primary"
                                    : "light"
                                }
                                className="mx-2 mb-2"
                                onClick={() => filterByStatus("Negociando")}
                              >
                                Negociando
                              </Button>
                              <Button
                                color={
                                  selectedStatus === "Sem sucesso"
                                    ? "danger"
                                    : "light"
                                }
                                className="mx-2 mb-2"
                                onClick={() => filterByStatus("Sem sucesso")}
                              >
                                Sem Sucesso
                              </Button>
                              <Button
                                color={
                                  selectedStatus === "Fechado"
                                    ? "success"
                                    : "light"
                                }
                                className="mx-2 mb-2"
                                onClick={() => filterByStatus("Fechado")}
                              >
                                Fechados
                              </Button>
                              <Button
                                color={
                                  selectedStatus === "Todos" ? "info" : "light"
                                }
                                className="mx-2 mb-2"
                                onClick={() => filterByStatus("Todos")}
                              >
                                Todos
                              </Button>
                            </Row>
                          </div>
                          <div className="d-flex flex-wrap justify-content-center mt-3">
                            {renderInfluenciadores()}
                          </div>
                        </CardBody>
                      </Card>
                    )}

                    <CardBody className="card-body-badge">
                      {colaborador.type_user.type === "Captação"
                        ? null
                        : colaborador?.plug_chat.map((element, index) =>
                            element.influencer_rent?.archiving === false ? (
                              <>
                                {element.influencer_rent.posts.filter(post => {
                                  const postDate = new Date(post.start)
                                  return (
                                    postDate.toDateString() ===
                                    new Date().toDateString()
                                  )
                                }).length > 0
                                  ? element.influencer_rent.posts.map(
                                      (post, postIndex) => {
                                        const isSameDay =
                                          new Date(
                                            post.start
                                          ).toDateString() ===
                                          new Date().toDateString()

                                        const badgeId = `badge-cards-${index}-${postIndex}`

                                        return isSameDay ? (
                                          post.waiting === false ? (
                                            <>
                                              <LinkBadge
                                                className={`badge-cards bg-${
                                                  post.posted
                                                    ? "success"
                                                    : "danger"
                                                }`}
                                                key={postIndex}
                                                href={`/influencer/${element.id}`}
                                                id={badgeId}
                                              >
                                                <Badge>
                                                  <ImageBadge
                                                    src={
                                                      element.influencer_rent
                                                        ?.photo
                                                    }
                                                    alt=""
                                                  />
                                                </Badge>
                                                <Tooltip
                                                  placement="top"
                                                  isOpen={ttop === badgeId}
                                                  target={badgeId}
                                                  toggle={() =>
                                                    setttop(badgeId)
                                                  }
                                                >
                                                  @
                                                  {
                                                    element.influencer_rent
                                                      ?.username
                                                  }
                                                </Tooltip>
                                              </LinkBadge>
                                            </>
                                          ) : (
                                            <>
                                              <LinkBadge
                                                className="bg-warning"
                                                key={index}
                                                id={`badge-cards-${index}`}
                                                href={`/influencer/${element.id}`}
                                              >
                                                <Badge>
                                                  <ImageBadge
                                                    src={
                                                      element.influencer_rent
                                                        ?.photo
                                                    }
                                                    alt=""
                                                  />
                                                </Badge>
                                                <Tooltip
                                                  placement="top"
                                                  isOpen={
                                                    ttop ===
                                                    `badge-cards-${index}`
                                                  }
                                                  target={`badge-cards-${index}`}
                                                  toggle={() =>
                                                    setttop(
                                                      `badge-cards-${index}`
                                                    )
                                                  }
                                                >
                                                  @
                                                  {
                                                    element.influencer_rent
                                                      ?.username
                                                  }
                                                </Tooltip>
                                              </LinkBadge>
                                            </>
                                          )
                                        ) : null
                                      }
                                    )
                                  : null}
                              </>
                            ) : null
                          )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            ) : (
              <h6>Carregando...</h6>
            )}
          </Container>
        </div>
      ) : (
        <Loading />
      )}
    </React.Fragment>
  )
}

export default ContactsProfile
