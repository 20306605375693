import React from "react";
import PropTypes from "prop-types";
import { Col, Card, CardBody, Input } from "reactstrap";

const MiniWidgetAlternar = ({ report, onToggle }) => {
    return (
        <Col sm="4">

            <Card>
                <CardBody>

                    <div className="d-flex align-items-center mb-3">
                        <div className="avatar-xs me-3">
                            <span className="avatar-title rounded-circle bg-primary-subtle text-primary font-size-18">
                                <i className={report.icon} />
                            </span>
                        </div>

                        <h5 className="font-size-14 mb-0" style={{ cursor: 'pointer' }}>
                            {report.title}
                            <i className="mdi mdi-sync ms-2" style={{ color: '#007bff' }} onClick={onToggle} />
                        </h5>
                    </div>

                    <div className="text-muted mt-4">
                        <h4>{report.value}</h4>
                        <div className="d-flex">
                            <span className={"badge badge-soft-" + report.color + " font-size-12"}>
                                {report.badgeValue}
                            </span>
                            <span className="ms-2 text-truncate">{report.desc}</span>
                        </div>
                    </div>

                </CardBody>
            </Card>
        </Col>
    );
};

MiniWidgetAlternar.propTypes = {
    report: PropTypes.object.isRequired,
    onToggle: PropTypes.func.isRequired,
};

export default MiniWidgetAlternar;