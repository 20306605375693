import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, Form, FormGroup, Label, Input } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../../components/Common/ChartsDynamicColor";
import api from "config/api"
import Loading from "components/Ui/LoadingCircle"

const SalesAnalytics = ({ data, dataColors, dataMeses }) => {
  const apexsalesAnalyticsChartColors = getChartColorsArray(dataColors);
  const [loading, setLoading] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState("Geral");

  const sumAllMonthsData = (dataMeses) => {
    return {
      Visitors: dataMeses.reduce((acc, curr) => acc + curr.Visitors, 0),
      Registrations: dataMeses.reduce((acc, curr) => acc + curr.Registrations, 0),
      Leads: dataMeses.reduce((acc, curr) => acc + curr.Leads, 0),
      UniqueVisitors: dataMeses.reduce((acc, curr) => acc + curr.UniqueVisitors, 0)
    };
  };

  const filteredData = selectedMonth === "Geral" ? sumAllMonthsData(dataMeses) : dataMeses.find(item => item.Month === selectedMonth);
  const options = {
    labels: ["Visitas", "Registros", "Leads", "UniqueVisitors"],
    colors: apexsalesAnalyticsChartColors,
    legend: { show: !1 },
    plotOptions: {
      pie: {
        donut: {
          size: "80%",
        },
      },
    },
  };
  const monthNames = [
    "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
    "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
  ];

  const getMonthName = (monthStr) => {
    const [month, year] = monthStr.split('/');
    const monthIndex = parseInt(month) - 1; 
    if (monthIndex >= 0 && monthIndex < 12) {
      return `${monthNames[monthIndex]} de ${year}`;
    } else {
      return "Mês inválido";
    }
  };
  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  return (
    <React.Fragment>
      <Col lg={4}>
        <Card>
          <CardBody>
            <Form>
              <FormGroup>
                <Label for="monthSelect">Selecione o mês:</Label>
                <Input type="select" name="month" id="monthSelect" value={selectedMonth} onChange={handleMonthChange}>
                  <option value="Geral">Total</option> 
                  {dataMeses.map(item => (
                    <option key={item.Month} value={item.Month}>{getMonthName(item.Month)}</option>
                  ))}
                </Input>
              </FormGroup>
            </Form>
            {filteredData ? (
              <div>
                <div id="donut-chart">
                  <ReactApexChart
                    options={options}
                    series={[filteredData.Visitors, filteredData.Registrations, filteredData.Leads, filteredData.UniqueVisitors]}
                    type="donut"
                    height={270}
                    className="apex-charts"
                  />
                </div>
                <div className="text-center text-muted">
                  <Row>
                    <Col xs="6">
                      <div className="mt-4">
                        <p className="mb-2 text-truncate text-dark">
                          <i className="mdi mdi-circle text-primary me-1" />Visitas:
                        </p>
                        <h6>{filteredData.Visitors.toLocaleString('pt-BR')}</h6>
                      </div>
                    </Col>
                    <Col xs="6">
                      <div className="mt-4">
                        <p className="mb-2 text-truncate text-dark">
                          <i className="mdi mdi-circle text-success me-1" />Registros:
                        </p>
                        <h6>{filteredData.Registrations.toLocaleString('pt-BR')}</h6>
                      </div>
                    </Col>
                    <Col xs="6">
                      <div className="mt-4">
                        <p className="mb-2 text-truncate text-dark">
                          <i className="mdi mdi-circle text-danger me-1" />Leads:
                        </p>
                        <h6>{filteredData.Leads.toLocaleString('pt-BR')}</h6>
                      </div>
                    </Col>
                    <Col xs="6">
                      <div className="mt-4">
                        <p className="mb-2 text-truncate text-dark">
                          <i className="mdi mdi-circle text-warning me-1" />UniqueVisitors:
                        </p>
                        <h6>{filteredData.UniqueVisitors.toLocaleString('pt-BR')}</h6>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            ) : (
              <p>Nenhum dado disponível para o mês selecionado.</p>
            )}
          </CardBody>
        </Card>
      </Col>
      {loading && <Loading />}
    </React.Fragment>
  );
};

export default SalesAnalytics;
