import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../../components/Common/ChartsDynamicColor";
import Loading from "components/Ui/LoadingCircle";

const SalesAnalytics = ({ dataColors, dashboard, values, postou, naopostou, aguardando}) => {
  const apexsalesAnalyticsChartColors = getChartColorsArray(dataColors);
  
  const options = {
    labels: ["Postou", "Não postou", "Em andamento"],
    colors: apexsalesAnalyticsChartColors,
    legend: { show: !1 },
    plotOptions: {
      pie: {
        donut: {
          size: "80%",
        },
      },
    },
  };

  return (
    <React.Fragment>
      {
        values ? (
      <Col xl="5">
        <Card>
          <CardBody>
            <h4 className="card-title mb-4">Posts Diário</h4>

            <div>
              <div id="donut-chart">
                <ReactApexChart
                  options={options}
                  series={values}
                  type="donut"
                  height={260}
                  className="apex-charts"
                />
              </div>
            </div>

            <div className="text-center text-muted">
              <Row>
                <Col xs="4">
                  <div className="mt-4" >
                    <p className="mb-2 text-truncate text-muted">
                      <i className="mdi mdi-circle text-success me-1" />Postou
                    </p>
                    <h5>{postou}</h5>
                  </div>
                </Col>
                <Col xs="4">
                  <div className="mt-4">
                    <p className="mb-2 text-truncate text-muted">
                      <i className="mdi mdi-circle text-danger me-1" />Não Postou
                    </p>
                    <h5>{naopostou}</h5>
                  </div>
                </Col>

                <Col xs="4">
                  <div className="mt-4">
                    <p className="mb-2 text-truncate text-muted">
                      <i className="mdi mdi-circle text-warning me-1" />Aguardando
                    </p>
                    <h5>{aguardando}</h5>
                  </div>
                </Col>

              </Row>
            </div>
          </CardBody>
        </Card>
      </Col> 
        ): (<Loading />)
      }
        
    </React.Fragment>
  );
};

export default SalesAnalytics;
