import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "contexts/auth";
import { Card, CardBody, Col, Container, Row, Input } from "reactstrap";
import Select from "react-select";
import api from "config/api";
import Spinners from "components/Common/Spinner";
import Breadcrumbs from "components/Common/Breadcrumb";
import List from "./List";

const CollaboratorsList = () => {
  document.title = "Influencers | Viraw Influencers";

  const [collaborators, setCollaborators] = useState([]);
  const [originalCollaborators, setOriginalCollaborators] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedFilterOption, setSelectedFilterOption] = useState(null);
  const [selectCampaignType, setSelectCampaignType] = useState(null);
  const [selectPaymentType, setSelectPaymentType] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const { user } = useContext(AuthContext);

  const filterOptions = [
    { label: "Todos", value: "all" },
    { label: "Aguardando Hoje", value: "today" },
    { label: "Geral Hoje", value: "todayGeral" },
  ];

  const filterOptionsCampanha = [
    { label: "Todos", value: null },
    { label: "SuperBet", value: "SuperBet" },
    { label: "Betano", value: "Betano" },
    { label: "Betano e SuperBet", value: "Betano e SuperBet" },
  ];

  const filterTypePayments = [
    { label: "Todos", value: null },
    { label: "Diário", value: "isDailyPayment" },
    { label: "Antecipado", value: "isAdvanceValue" },
  ];

  const handleFilterChangeCampanha = (selectedOption) => {
    setSelectCampaignType(selectedOption);

    if (!selectedOption || selectedOption.value === null) {
      setCollaborators(originalCollaborators);
    } else {
      const filteredCollaborators = originalCollaborators.filter(collaborator =>
        collaborator.influencer_rent.campaigns_influencer?.name === selectedOption.value
      );
      setCollaborators(filteredCollaborators);
    }
  };

  const handleFilterChangePayment = (selectedOption) => {
    setSelectPaymentType(selectedOption);

    if (!selectedOption || selectedOption.value === null) {
      setCollaborators(originalCollaborators);
    } else {
      let filteredCollaborators = [];

      if (selectedOption.value === "isDailyPayment") {
        filteredCollaborators = originalCollaborators.filter(collaborator =>
          collaborator.influencer_rent?.influencer_meta?.[0]?.isDailyPayment === true
        );
      } else if (selectedOption.value === "isAdvanceValue") {
        filteredCollaborators = originalCollaborators.filter(collaborator =>
          collaborator.influencer_rent?.influencer_meta?.[0]?.isAdvanceValue === true
        );
      }
      

      setCollaborators(filteredCollaborators);
    }
  };
  const filterPostsByStartDate = (collaborators) => {
    const startDate = new Date();
    return collaborators.filter((collaborator) => {
        if (collaborator.influencer_rent.posts) {
            const hasMatchingPost = collaborator.influencer_rent.posts.some((post) => {
                const postStartDate = new Date(post.start);
                return (
                    postStartDate.toDateString() === startDate.toDateString() &&
                    post.waiting === true
                );
            });

            return hasMatchingPost;
        }
        return false;
    });
};

const filterPostsByGeral = (collaborators) => {
  const startDate = new Date();
  return collaborators.filter((collaborator) => {
      if (collaborator.influencer_rent.posts) {
          const hasMatchingPost = collaborator.influencer_rent.posts.some((post) => {
              const postStartDate = new Date(post.start);
              return (
                  postStartDate.toDateString() === startDate.toDateString()
              );
          });
          return hasMatchingPost;
      }
      return false;
  });
};
  const handleFilterChange = (selectedOption) => {
    setSelectedFilterOption(selectedOption);

    if (selectedOption) {
      if (selectedOption.value === "today") {
        const todayPosts = filterPostsByStartDate(originalCollaborators);
        setCollaborators(todayPosts);
      } else if (selectedOption.value === "todayGeral") {
        const todayPosts = filterPostsByGeral(originalCollaborators);
        setCollaborators(todayPosts);
      } else if (selectedOption.value === "all") {
        setCollaborators(originalCollaborators);
      }
    } else {
      setCollaborators(originalCollaborators);
    }
  };

  const getCollaborators = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/plugchat/grupos/${user.userId}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      let myInfluencers = [];
      response.data.forEach(element => {
        if (element.responsavel === user.userId) {
          myInfluencers.push(element);
        }
      });

      if (selectedFilterOption) {
        if (selectedFilterOption.value === "today") {
          const todayPosts = filterPostsByStartDate(myInfluencers);
          setCollaborators(todayPosts);
        } else if (selectedFilterOption.value === "all") {
          setCollaborators(myInfluencers);
        }
      } else {
        setCollaborators(myInfluencers);
      }

      setOriginalCollaborators(myInfluencers);
    } catch (error) {
      console.error("Error fetching collaborators:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getCollaborators();
  }, [user.token, selectedFilterOption]);

  const handleSearchCollaborator = (value) => {
    setSearchValue(value);
    const lowerCaseValue = value.toLowerCase();

    const filteredCollaborators = originalCollaborators.filter(collaborator => {
      const fullname = collaborator.influencer_rent?.fullname?.toLowerCase() || "";
      const username = collaborator.influencer_rent?.username?.toLowerCase() || "";
      return fullname.includes(lowerCaseValue) || username.includes(lowerCaseValue);
    });

    setCollaborators(filteredCollaborators);

    if (value.trim() === "") {
      setSelectedOption(null);
      setCollaborators(originalCollaborators);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Influencers" breadcrumbItem="Influencers" />
          <Row>
            <Col lg={12}>
              <Card className="collaborator-filter">
                <CardBody>
                  <form action="#">
                    <Row className="g-3">
                      <Col xxl={4} lg={4}>
                        <div className="position-relative">
                          <Input
                            type="text"
                            id="searchCollaborator"
                            autoComplete="off"
                            placeholder="Buscar Influenciador"
                            onChange={e => handleSearchCollaborator(e.target.value)}
                            value={searchValue}
                          />
                        </div>
                      </Col>

                      <Col xxl={2} lg={2}>
                        <div className="position-relative">
                          <Select
                            className="select2"
                            onChange={handleFilterChange}
                            value={selectedFilterOption}
                            options={filterOptions}
                            placeholder="Filtrar Posts"
                            isClearable
                          />
                        </div>
                      </Col>

                      <Col xxl={2} lg={3}>
                        <div className="position-relative">
                          <Select
                            className="select2"
                            onChange={handleFilterChangeCampanha}
                            value={selectCampaignType}
                            options={filterOptionsCampanha}
                            placeholder="Filtrar Campanha"
                            isClearable
                          />
                        </div>
                      </Col>

                      <Col xxl={2} lg={3}>
                        <div className="position-relative">
                          <Select
                            className="select2"
                            onChange={handleFilterChangePayment}
                            value={selectPaymentType}
                            options={filterTypePayments}
                            placeholder="Filtrar Pagamento"
                            isClearable
                          />
                        </div>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <List listData={collaborators} />
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CollaboratorsList;
