import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../../components/Common/ChartsDynamicColor";
import Loading from "components/Ui/LoadingCircle";

const SalesAnalytics = ({ dataColors, dashboard, values }) => {
  const apexsalesAnalyticsChartColors = getChartColorsArray(dataColors);
  
  const options = {
    labels: ["Hoje", "Na semana", "Mês"],
    colors: apexsalesAnalyticsChartColors,
    legend: { show: !1 },
    plotOptions: {
      pie: {
        donut: {
          size: "80%",
        },
      },
    },
  };

  return (
    <React.Fragment>
      {
        values ? (
      <Col xl="12">
        <Card>
          <CardBody>
            <h4 className="card-title mb-4">Posts Geral (Influenciadores que postaram)</h4>

            <div>
              <div id="donut-chart">
                <ReactApexChart
                  options={options}
                  series={values}
                  type="donut"
                  height={260}
                  className="apex-charts"
                />
              </div>
            </div>

            <div className="text-center text-muted">
              <Row>
                <Col xs="4">
                  <div className="mt-4" >
                    <p className="mb-2 text-truncate text-muted">
                      <i className="mdi mdi-circle text-warning me-1" />Hoje
                      
                    </p>
                    <h5>{values[0]}</h5>
                  </div>
                </Col>
                <Col xs="4">
                  <div className="mt-4">
                    <p className="mb-2 text-truncate text-muted">
                      <i className="mdi mdi-circle text-info me-1" />Semana
                    </p>
                    <h5>{values[1]}</h5>
                  </div>
                </Col>

                <Col xs="4">
                  <div className="mt-4">
                    <p className="mb-2 text-truncate text-muted" >
                      <i className="mdi mdi-circle text-success me-1" />Mês
                    </p>
                    <h5>{values[2]}</h5>
                  </div>
                </Col>

              </Row>
            </div>
          </CardBody>
        </Card>
      </Col> 
        ): (<Loading />)
      }
        
    </React.Fragment>
  );
};

export default SalesAnalytics;
