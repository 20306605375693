import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { Card, CardBody, Col, Container, InputGroupText, Label, Row, Line } from "reactstrap";
import withRouter from "components/Common/withRouter";
import "assets/scss/datatables.scss";

import { AuthContext } from "contexts/auth";

import Breadcrumbs from "components/Common/Breadcrumb";
import Profile from "./profile";
import GraficoAcompanhamento from "./graficoAcompanhamento"
import TabelaTrack from "./graficoTracking"
import DatePicker from "react-datepicker";
import api from "config/api";

const Tracking = () => {
  const { id } = useParams();
  const {user} = useContext(AuthContext);
  const [isLoading, setLoading] = useState(true);

  const [advisorDetails, setAdvisorDetails] = useState({});;
  const [depositos, setDepositos] = useState();
  const [firstDeposits, setFirstDeposits] = useState();
  const [rev, setRev] = useState();
  const [cpa, setCpa] = useState();
  const [commission, setCommission] = useState();
  const [cliquesTotais, setCliquesTotais] = useState();
  const [registrosTotais, setRegistrosTotais] = useState();
  const [totalDepositado, setTotalDepositado] = useState()
  const [primeiroDeposito, setPrimeiroDeposito] = useState()
  const [primeiroDepositoReal, setPrimeiroDepositoReal] = useState()
  const [comissaoCPA, setComissaoCPA] = useState()
  const [comissaoREV, setComissaoREV] = useState()
  const [revcpa, setRevcpa] = useState()
  
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  
  const [influencerTrackingAll, setInfluencerTrackingAll] = useState([]);
  const [filteredReports, setFilteredReports] = useState([])
  
  const [labels, setLabels] = useState([]);
  const [registros, setRegistros] = useState([]);
  const [visitors, setVisitors] = useState([]);
  const [ftd, setFTD] = useState([]);
  const [influencerTracking, setInfluencerTracking] = useState("");
  const [influencerData, setInfluencerData] = useState("");

  const [trackingTela, setTrackingTela] = useState("")

  const filterDataByDate = () => {
    if (!startDate || !endDate) {
      setCliquesTotais(influencerData.Clicks);
      setRegistrosTotais(influencerData.Registrations);
      setTotalDepositado(influencerData.Deposits);
      setPrimeiroDeposito(influencerData.FirstDepositCount);
      setPrimeiroDepositoReal(influencerData.NewAccPurchases);
      setComissaoCPA(influencerData.CPACommission);
      setComissaoREV(influencerData.PercentCommission);
      setRevcpa(influencerData.TotalCommission);
      return;
    }
  
    const filteredData = influencerTrackingAll.filter((item) => {
      const itemDate = new Date(item.Period);
      const start = new Date(Date.UTC(startDate.getUTCFullYear(), startDate.getUTCMonth(), startDate.getUTCDate()));
      const end = new Date(Date.UTC(endDate.getUTCFullYear(), endDate.getUTCMonth(), endDate.getUTCDate(), 23, 59, 59));
  
      return itemDate >= start && itemDate <= end;
    });
  
    let cliquesTotais = 0;
    let registrosTotais = 0;
    let totalDepositado = 0;
    let primeiroDeposito = 0;
    let primeiroDepositoReal = 0;
    let comissaoCPA = 0;
    let comissaoREV = 0;
    let revcpa = 0;
  
    filteredData.forEach((item) => {
      cliquesTotais += item.Clicks || 0;
      registrosTotais += item.Registrations || 0;
      totalDepositado += item.Deposits || 0;
      primeiroDeposito += item.FirstDepositCount || 0;
      primeiroDepositoReal += item.NewAccPurchases || 0;
      comissaoCPA += item.CPACommission || 0;
      comissaoREV += item.PercentCommission || 0;
      revcpa += item.TotalComission || 0;
    });
  
    setCliquesTotais(cliquesTotais);
    setRegistrosTotais(registrosTotais);
    setTotalDepositado(totalDepositado);
    setPrimeiroDeposito(primeiroDeposito);
    setPrimeiroDepositoReal(primeiroDepositoReal);
    setComissaoCPA(comissaoCPA);
    setComissaoREV(comissaoREV);
    setRevcpa(revcpa);
  };

  useEffect(() => {
    const fetchAdvisorDetails = async () => {
      try {
        const response = await api.get(`/tracking/superbet/fixed/orlandinho`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${user.token}`,
          }
        })

        const data = response.data;
        
        setAdvisorDetails(data);

        const resultTrackings = response.data?.Trackings;

        setCliquesTotais(data.Geral?.Clicks)
        setRegistrosTotais(data.Geral?.Registrations)
        setTotalDepositado(data.Geral?.Deposits)
        setPrimeiroDeposito(data.Geral?.FirstDepositCount)
        setPrimeiroDepositoReal(data.Geral?.NewAccPurchases)
        setComissaoCPA(data.Geral?.CPACommission)
        setComissaoREV(data.Geral?.PercentCommission)
        setRevcpa(data.Geral?.TotalCommission)
        setTrackingTela(data.Geral?.Trackings)

        if (response.data && Array.isArray(response.data.Trackings)) {
          setFilteredReports(response.data.Trackins)
        } else {
          console.error(
            "Trackings não encontrados ou em formato incorreto."
          )
        }
        const dataMap = {}

        resultTrackings?.forEach((element) => {
          const date = element.Period

          if(!dataMap[date]) {
            dataMap[date] = {
              Date: date,
              Registrations: 0,
              Visitors: 0,
              FTD: 0,
            }
          }
          dataMap[date].Registrations += element.Registrations
          dataMap[date].Visitors += element.Visitors
          dataMap[date].FTD += element.FTD
        })

        const sortedTrackings = Object.values(dataMap)
        const sortedTrackingsAll = Object.values(resultTrackings)

        sortedTrackings.sort((a, b) => {
          const dateA = new Date(a.Date);
          const dateB = new Date(b.Date);
          return dateA - dateB
        })

        const dates = sortedTrackings.map((element) => element.Date)
        const registrtions = sortedTrackings.map(
          (element) => element.Registrtions
        )
        const visitors = sortedTrackings.map(
          (element) => element.Visitors
        )
        const ftd = sortedTrackings.map((element) => element.FTD)

        setLabels(dates)
        setRegistros(registrtions)
        setVisitors(visitors)
        setFTD(ftd)
        setInfluencerTracking(sortedTrackings)
        setInfluencerData(response.data.Geral);
        setLoading(false)
        setTrackingTela(advisorDetails.Trackings)

        sortedTrackingsAll.sort((a, b) => {
          const dateA = new Date(a.Date)
          const dateB = new Date(b.Date)
          return dateA - dateB
        })
        setInfluencerTrackingAll(sortedTrackingsAll)


      } catch (error) {
        console.error("Erro ao buscar os detalhes do assessor:", error);
      } finally {
        setLoading(false);
      } 
    };
    
    fetchAdvisorDetails();
  }, [id, user.token]);

  useEffect(() => {
    filterDataByDate();
  }, [startDate, endDate, influencerTrackingAll])

  const wallet = {
    series: [
      { type: "area", name: "Depósitos", data: depositos },
      { type: "area", name: "FTDs", data: firstDeposits },
      { type: "area", name: "Commission Rev", data: rev },
      { type: "line", name: "Commission CPA", data: cpa },
      { type: "line", name: "Commission Total", data: commission },
    ],
    options: {
      chart: { toolbar: { show: false } },
      dataLabels: { enabled: false },
      stroke: { curve: "smooth", width: 2, dashArray: [0, 0, 3] },
      fill: { type: "solid", opacity: [0.15, 0.05, 1] },
      xaxis: {
        categories: [
          "Ago", "Set", "Out", "Nov", "Dez", "Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul"
        ],
      },
      colors: ["#f1b44c", "#3452e1", "#187623", "#50a5f1"],
    },
  };

  const trackingAll = advisorDetails.Trackings || 0;
  const trackings = advisorDetails.Trackings || 0;

  return (
    <React.Fragment>
      <div className="page-content">
        {isLoading ? (
          <Row>
            <Col xl="12">
              <div className="text-center my-3">
                <div className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                  Carregando...
                </div>
              </div>
            </Col>
          </Row>
        ) : (
          <Container fluid>
            <Breadcrumbs title="Estatísticas" breadcrumbItem="Assessor" />

            <Row>
              <Col xl="12">
                <Profile data={advisorDetails} />
              </Col>
            </Row>
  
            <Row>
              <Col xl="12">
              <Row>
                <Label for="dateRange" sm={1}>
                  Filtrar por Período:
                </Label>
                <Col sm={4}>
                                <div className="d-flex">
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        selectsStart
                                        startDate={startDate}
                                        endDate={endDate}
                                        dateFormat="dd/MM/yyyy"
                                        className="form-control me-2"
                                    />
                                    <InputGroupText className="me-0">
                                        até
                                    </InputGroupText>
                                    <DatePicker
                                        selected={endDate}
                                        onChange={(date) => setEndDate(date)}
                                        selectsEnd
                                        startDate={startDate}
                                        endDate={endDate}
                                        dateFormat="dd/MM/yyyy"
                                        className="form-control"
                                    />
                                </div>
                            </Col>
                            </Row>
                            
                <Row>
                <Col sm="4">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="me-3 align-self-center">
                            <i className="mdi mdi-cursor-default-click-outline text-primary h1" />
                          </div>
                          <div className="flex-grow-1">
                            <p className="text-muted mb-2">Cliques Totais</p>
                            <h5 className="mb-0">
                              {cliquesTotais ? cliquesTotais.toLocaleString() : 0}
                            </h5>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col sm="4">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="me-3 align-self-center">
                            <i className="mdi mdi-account-plus text-primary h1" />
                          </div>
                          <div className="flex-grow-1">
                            <p className="text-muted mb-2">Registros</p>
                            <h5 className="mb-0">
                              {registrosTotais ? registrosTotais.toLocaleString() : 0}
                            </h5>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col sm="4">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="me-3 align-self-center">
                            <i className="bx bxs-dollar-circle h2 text-primary mb-0" />
                          </div>
                          <div className="flex-grow-1">
                            <p className="text-muted mb-2">
                              Total Depositado
                            </p>
                            <h5 className="mb-0">
                              R$ {totalDepositado ? totalDepositado.toLocaleString() : 0}
                            </h5>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <Row>
                <Col sm="4">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="me-3 align-self-center">
                            <i className="mdi mdi-bank-transfer-in text-primary h1" />
                          </div>
                          <div className="flex-grow-1">
                            <p className="text-muted mb-2">Primeiro Depósito</p>
                            <h5 className="mb-0">
                            {primeiroDeposito ? primeiroDeposito.toLocaleString() : 0}
                            </h5>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col sm="4">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="me-3 align-self-center">
                            <i className="mdi mdi-bank-transfer-in text-primary h1" />
                          </div>
                          <div className="flex-grow-1">
                            <p className="text-muted mb-2">Total Primeiro Depósito</p>
                            <h5 className="mb-0">
                              R$ {primeiroDepositoReal ? primeiroDepositoReal.toLocaleString() : 0}
                            </h5>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col sm="4">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="me-3 align-self-center">
                            <i className="mdi mdi-account-plus text-primary h1" />
                          </div>
                          <div className="flex-grow-1">
                            <p className="text-muted mb-2">Comissão CPA</p>
                            <h5 className="mb-0">
                              R$ {comissaoCPA ? comissaoCPA.toLocaleString() : 0}
                            </h5>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
  
                <Row>
                <Col sm="6">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="me-3 align-self-center">
                            <i className="mdi mdi-bank-transfer-in text-primary h1" />
                          </div>
                          <div className="flex-grow-1">
                            <p className="text-muted mb-2">Comissão Revenue Share</p>
                            <h5 className="mb-0">
                              R$ {comissaoREV ? comissaoREV.toLocaleString() : 0}
                            </h5>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
  
                  <Col sm="6">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="me-3 align-self-center">
                            <i className="mdi mdi-bank-transfer-in text-primary h1" />
                          </div>
                          <div className="flex-grow-1">
                            <p className="text-muted mb-2">CPA + Revenue Share</p>
                            <h5 className="mb-0">
                              R$ {revcpa ? revcpa.toLocaleString() : (comissaoCPA + comissaoREV).toLocaleString()}
                            </h5>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
  
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <div className="d-sm-flex flex-wrap">
                      <h4 className="card-title mb-4">
                        Gráfico de acompanhamento
                      </h4>
                      <div className="ms-auto"></div>
                    </div>
  
                    <GraficoAcompanhamento
                      dataColors='["--bs-primary", "--bs-warning", "--bs-success"]'
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
  
            <Row>
              <Col xl="12">
                <TabelaTrack trackingAll={trackings} />
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </React.Fragment>
  );
};

export default withRouter(Tracking);
