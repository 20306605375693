import React, { useContext } from "react";
import { Modal, Button, ModalFooter } from "reactstrap";
import { AuthContext } from "contexts/auth";
import Toastr from "../../Ui/Notifications";
import api from "config/api";

const ModalDeletePagamento = ({ isOpen, toggle, paymentId }) => {
  const { user } = useContext(AuthContext);

  const handleSubmit = async () => {
    try {
      await api.delete(`/payments/assessor/delete/${paymentId}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      toggle();
      Toastr("success", "Pagamento deletado com sucesso.");
    } catch (error) {
      Toastr("error", "Erro ao deletar pagamento.");
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <div className="modal-header">
        <h5 className="modal-title">Deseja realmente Deletar Pagamento</h5>
        <button type="button" className="btn-close" onClick={toggle}></button>
      </div>
      <div className="">
        <p></p>
      </div>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Cancelar</Button>
        <Button color="primary" onClick={handleSubmit}>Deletar</Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalDeletePagamento;
