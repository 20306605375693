import React, { useEffect, useState, useMemo, useContext } from "react"

import Loading from "../../../components/Ui/LoadingCircle"

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Input,
} from "reactstrap"

import ApexRevenue from "./ApexRevenue"

// Importar Breadcrumbs (Trilha de navegação)
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// Importar Componentes
import CardUser from "./card-user"
import CardWelcome from "./card-welcome"
import MiniWidget from "./mini-widget"
import Earning from "./earning"
import SalesAnalytics from "./sales-analytics"
import TotalSellingProduct from "./total-selling-product"
import Tasks from "./tasks"
import ChatBox from "./chat-box"
import MiniCards from "./mini-card"

import { AuthContext } from "contexts/auth"
import api from "config/api"

import { Fechados, FechadosCards, Title } from "./styles"
import {
  CounterBox,
  CounterTitle,
  CounterValue,
  FormContainer,
  FormLabel,
  FormInput,
  FormButton,
} from "./styles1"

const DashboardSaas = props => {
  const { user } = useContext(AuthContext)
  const [loading, setLoading] = useState(true)
  const [grafico1, setGrafico1] = useState({})
  const [grafico2, setGrafico2] = useState({})
  const [grafico3, setGrafico3] = useState({})
  const [grafico4, setGrafico4] = useState({})

  const [errorMessage, setErrorMessage] = useState(null)
  const [dataInicial, setDataInicial] = useState(getFirstDayOfMonth());
  const [dataFinal, setDataFinal] = useState(getTodayDate());
  const [cadastros, setCadastros] = useState(0)
  const [ftds, setFtds] = useState(0)
  const [ftds1, setFtds1] = useState(0)
  const [ftds2, setFtds2] = useState(0)
  const [ftds3, setFtds3] = useState(0)
  const [mediaFtds, setMediaFtds] = useState(0)
  const [loadingFilter, setLoadingFilter] = useState(false)
  useEffect(() => {
    setLoading(true)
    if (user.token && user.userId) {
      api
        .get(`graphics/captacao/${user.userId}`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        })
        .then(response => {
          const data = response.data
          setGrafico1(data.graphic1)
          setGrafico2(data.graphic2)
          setGrafico3(data.graphic3)
          setGrafico4(data.graphic4)
          setLoading(false)
        })
        .catch(error => {
          console.error("Erro ao carregar os Dados:", error)
          setErrorMessage("Erro ao carregar os Dados.")
          setLoading(false)
        })
    }
  }, [user.token, user.userId])

  const handleDataInicialChange = e => {
    setDataInicial(e.target.value)
  }

  const handleDataFinalChange = e => {
    setDataFinal(e.target.value)
  }

  const handleSubmit = e => {
    e.preventDefault()
    getSearchMetricsCollaborator()
  }
  
  function getTodayDate() {
    const today = new Date()
    const year = today.getFullYear()
    let month = today.getMonth() + 1
    let day = today.getDate()

    month = month < 10 ? "0" + month : month
    day = day < 10 ? "0" + day : day

    return year + "-" + month + "-" + day
  }
  
  function getFirstDayOfMonth() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;

    month = month < 10 ? "0" + month : month;

    return `${year}-${month}-01`;
  }

  async function getSearchMetricsCollaborator() {
    setLoadingFilter(true)
    const response = await api.get(
      `/users/collaborator/metrics/${dataInicial}/${dataFinal}/${user.userId}`
    )
    setCadastros(response.data.totals.Cadastros)
    setFtds(response.data.totals.FTDs)
    setFtds1(response.data.totals.FTDs1)
    setFtds2(response.data.totals.FTDs2)
    setFtds3(response.data.totals.FTDs3)
    setMediaFtds(response.data.totals.mediaFTDs)
    setLoadingFilter(false)

  }
  const formatCurrency = (value, locale = "pt-BR", currency = "BRL") => {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currency,
    }).format(value)
  }
  
  document.title = "Painel | Plataforma Viraw ©"
  return (
    <React.Fragment>
      {!loading ? (
        <div className="page-content">
          <Container fluid>
            {/* Renderizar Trilha de navegação */}
            <Breadcrumbs title="Dashboard" breadcrumbItem="Painel" />

            {/* Cartão do Usuário */}
            <CardUser
              total={grafico1.total}
              remarketing={grafico4.remarketing}
              fechados={grafico1.fechados}
              semSucesso={grafico1.semSucesso}
            />

            <Row>
              {/* Cartão de Boas-Vindas */}
              <CardWelcome />

              <Col xl="8">
                <Row>
                  <Fechados>
                    {/* <Title>Fechados</Title>
                    <FechadosCards>
                      <MiniCards
                        title={"Hoje"}
                        text={`${grafico2.fechadosHoje}`}
                        iconClass={"bx-check-circle"}
                        key={"_card_" + 1}
                      />
                      <MiniCards
                        title={"Na semana"}
                        text={`${grafico2.fechadosSemana}`}
                        iconClass={"bx-check-circle"}
                        key={"_card_" + 2}
                      />
                      <MiniCards
                        title={"No mês"}
                        text={`${grafico2.fechadosMes}`}
                        iconClass={"bx-check-circle"}
                        key={"_card_" + 3}
                      />
                    </FechadosCards> */}
                    <Col sm={12} md={12}>
                      <FormContainer onSubmit={handleSubmit}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "15px",
                            width: "100%",
                          }}
                        >
                          <FormLabel>
                            <span
                              style={{
                                height: "30px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              Data Inicial:
                            </span>
                            <Input
                              type="date"
                              value={dataInicial}
                              onChange={handleDataInicialChange}
                            />
                          </FormLabel>

                          <FormLabel>
                            <span
                              style={{
                                height: "30px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              Data Final:
                            </span>

                            <Input
                              type="date"
                              value={dataFinal}
                              onChange={handleDataFinalChange}
                            />
                          </FormLabel>
                        </div>

                        <FormButton
                          className="btn btn-soft-primary d-block"
                          type="submit"
                        >
                          {loadingFilter ? "Carregando..." : "Filtrar"}
                        </FormButton>
                      </FormContainer>

                      <FechadosCards>
                        <MiniCards
                          title={"Cadastros"}
                          text={`${cadastros}`}
                          iconClass={"bx-check-circle"}
                          key={"_card_" + 1}
                        />
                        <MiniCards
                          title={"FTDs"}
                          text={`${(ftds)}`}
                          iconClass={"bx-check-circle"}
                          key={"_card_" + 2}
                        />
                        <MiniCards
                          title={"Ganho Potencial"}
                          text={`${formatCurrency((ftds1 * 3) + (ftds2 * 2) + (ftds3 * 1.5))}`}
                          iconClass={"bx-check-circle"}
                          key={"_card_" + 3}
                        />
                      </FechadosCards>
                    </Col>
                  </Fechados>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <Card>
                  <CardBody>
                    <CardTitle className="card-title">Evolução</CardTitle>
                    <div id="revenue-chart">
                      <ApexRevenue
                        dataColors='["--bs-primary"]'
                        data={[
                          grafico3?.jan || 0,
                          grafico3?.fev || 0,
                          grafico3?.mar || 0,
                          grafico3?.abr || 0,
                          grafico3?.mai || 0,
                          grafico3?.jun || 0,
                          grafico3?.jul || 0,
                          grafico3?.ago || 0,
                          grafico3?.set || 0,
                          grafico3?.out || 0,
                          grafico3?.nov || 0,
                          grafico3?.dez || 0,
                        ]}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <SalesAnalytics
            
                dataColors='["--bs-info", "--bs-primary", "#fd7e14","#B0E0E6", "#6f42c1", "#e83e8c", "#FF0000", "--bs-success"]'
                dashboard={true}
                values={[
                  grafico4 && grafico4.primeiraAbordagem,
                  grafico4 && grafico4.conversaIniciada,
                  grafico4 && grafico4.convitesWhatsapp,
                  grafico4 && grafico4.whatsappCaptados,
                  grafico4 && grafico4.whatsappRespondidos,
                  grafico4 && grafico4.followUp,
                  grafico4 && grafico4.semSucesso,
                  grafico4 && grafico4.fechados,
                ]}
              />

            </Row>
          </Container>
        </div>
      ) : (
        <Loading />
      )}
    </React.Fragment>
  )
}

export default DashboardSaas
