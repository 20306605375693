import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from 'contexts/auth';
import { Card, CardBody, Col, Container, Row, Collapse, Input } from 'reactstrap';
import Select from 'react-select';
import api from 'config/api';
import Spinners from 'components/Common/Spinner';
import Breadcrumbs from 'components/Common/Breadcrumb';
import List from './List';

const CollaboratorsList = () => {
    document.title = 'Influencers | Viraw Influencers';

    const [collaborators, setCollaborators] = useState([]);
    const [originalCollaborators, setOriginalCollaborators] = useState([]); 
    const [isLoading, setLoading] = useState(true); 
    const [isOpen, setIsOpen] = useState(false); 
    const [selectedOption, setSelectedOption] = useState(null); 
    const { user } = useContext(AuthContext);
    const [searchValue, setSearchValue] = useState(''); 
    const [colaboradoresGrupo, setColaboradoresGrupo] = useState([]); 

    const getSaques = async () => {
        try {
            const response = await api.get('/payments/request', {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });

            const influenciadoresSaquesSolicitados = response.data.filter(
                influenciador => !influenciador.isPaymentSuccess && !influenciador.isRejected
            );

            setOriginalCollaborators(influenciadoresSaquesSolicitados); 
            setCollaborators(influenciadoresSaquesSolicitados); 
            setLoading(false); 
        } catch (error) {
            console.error('Error fetching influencers:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        getSaques();
    }, [user.token]);

    const handleSectorChange = (selectedOption) => {
        setSelectedOption(selectedOption);

        if (selectedOption) {
            const selectedSectorValue = selectedOption.value;

            const filteredBySector = originalCollaborators.filter(collaborator =>
                collaborator.responsavel_rent?.fullname === selectedSectorValue
            );
            setCollaborators(filteredBySector);
        } else {
            setCollaborators(originalCollaborators);
        }
    };

    const handleSearchCollaborator = (value) => {
        setSearchValue(value);

        if (value.trim() === '') {
            setCollaborators(originalCollaborators); 
            return;
        }

        const lowerCaseValue = value.toLowerCase();

        const filteredCollaborators = originalCollaborators.filter(collaborator => {
            const fullname = collaborator.influencer_requestPayment?.fullname?.toLowerCase() || '';
            const username = collaborator.influencer_requestPayment?.username?.toLowerCase() || '';
            return fullname.includes(lowerCaseValue) || username.includes(lowerCaseValue);
        });

        setCollaborators(filteredCollaborators); 
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Influencers" breadcrumbItem="pendencias" />
                    <Row>
                        <Col lg={12}>
                            <Card className="collaborator-filter">
                                <CardBody>
                                    <form action="#">
                                        <Row className="g-3">
                                            <Col xxl={4} lg={4}>
                                                <div className="position-relative">
                                                    <Input
                                                        type="text"
                                                        id="searchCollaborator"
                                                        autoComplete="off"
                                                        placeholder="Buscar Influenciador"
                                                        onChange={(e) => handleSearchCollaborator(e.target.value)}
                                                        value={searchValue}
                                                    />
                                                </div>
                                            </Col>
                                            <Col xxl={2} lg={4}>
                                                <div className="position-relative">
                                                    <Select
                                                        className="select2"
                                                        onChange={handleSectorChange}
                                                        options={colaboradoresGrupo.map(colaborador => ({
                                                            value: colaborador,
                                                            label: colaborador
                                                        }))}
                                                        value={selectedOption}
                                                        placeholder="Filtrar por responsável"
                                                        isClearable
                                                    />
                                                </div>
                                            </Col>

                                          
                                        </Row>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {isLoading ? (
                        <Spinners setLoading={setLoading} />
                    ) : (
                        <List listData={collaborators} />
                    )}
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CollaboratorsList;
