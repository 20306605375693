import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { map } from "lodash";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";

// TableContainer

import { Pdate, Ddate, Name, Idno, Budget } from "./CryptoCol";

import TableContainer from "../../../../components/Common/TableContainer";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//Import mini card widgets
import MiniCards from "./mini-card";

//Import Images
import profile1 from "assets/images/profile-img.png";

// import charts
import ApexRevenue from "../ApexRevenue";

const ContactsProfile = () => {
  // Estado local para armazenar os dados do perfil do usuário
  const [userProfile, setUserProfile] = useState({
    img: "https://scontent.cdninstagram.com/v/t51.2885-19/385638855_1448096705983046_252952359200501924_n.jpg?stp=dst-jpg_s100x100&_nc_cat=102&ccb=1-7&_nc_sid=c4dd86&_nc_ohc=Qwy7Z_utgS8AX9HDkvT&_nc_ht=scontent.cdninstagram.com&oh=00_AfC_TmXx__iO5RdhCp8LDU08fjrZVw1CCu22aFZJiOYucg&oe=657DF968",
    name: "Luan Sampaio",
    designation: "Frontend Developer",
    projectCount: 125,
    revenue: "$36,524",
    personalDetail: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    phone: "+1234567890",
    email: "john@example.com",
    location: "New York, USA",
    experiences: [
      {
        id: 1,
        iconClass: "bx-briefcase",
        designation: "Frontend Developer",
        timeDuration: "2018 - Present",
        link: "/experience/1",
      },
      // Outras experiências...
    ],
    projects: [
      {
        id: 1,
        name: "Project A",
        startDate: "2022-01-01",
        deadline: "2022-02-01",
        budget: "$10,000",
      },
      // Outros projetos...
    ],
  });

  const [miniCards, setMiniCards] = useState([
    {
      title: "Projects",
      iconClass: "bx-check-circle",
      text: "100",
    },
    { title: "Pending Projects", iconClass: "bx-hourglass", text: "12" },
    { title: "Total Revenue", iconClass: "bx-package", text: "$36,524" },
  ]);

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "id",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return <Idno {...cellProps} />;
        },
      },
      {
        Header: "Project",
        accessor: "name",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Start Date",
        accessor: "startDate",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return <Pdate {...cellProps} />;
        },
      },
      {
        Header: "Deadline",
        accessor: "deadline",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return <Ddate {...cellProps} />;
        },
      },
      {
        Header: "Budget",
        accessor: "budget",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return <Budget {...cellProps} />;
        },
      },
    ],
    []
  );

  // Simula a obtenção dos dados do perfil do usuário
  useEffect(() => {
    // Simule a obtenção dos dados do perfil do usuário de uma API ou de outra fonte de dados.
    const fakeUserProfile = {
      img: "https://scontent.cdninstagram.com/v/t51.2885-19/403976491_1522151151877074_5560874435162702793_n.jpg?stp=dst-jpg_s100x100&_nc_cat=1&ccb=1-7&_nc_sid=c4dd86&_nc_ohc=wLNlp2F8LTkAX8l6YUD&_nc_ht=scontent.cdninstagram.com&oh=00_AfC1L71KTXgbcfGGwg1DWWyLtBMV4qOV_VvEm3cpliBFOg&oe=6584D856",
      name: "John Doe",
      designation: "Frontend Developer",
      projectCount: 125,
      revenue: "$36,524",
      personalDetail: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      phone: "+1234567890",
      email: "john@example.com",
      location: "New York, USA",
      experiences: [
        {
          id: 1,
          iconClass: "bx-briefcase",
          designation: "Frontend Developer",
          timeDuration: "2018 - Present",
          link: "/experience/1",
        },
 
      ],
      projects: [
        {
          id: 1,
          name: "Project A",
          startDate: "2022-01-01",
          deadline: "2022-02-01",
          budget: "$10,000",
        },
   
      ],
    };
    setUserProfile(fakeUserProfile);
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Contacts" breadcrumbItem="Profile" />

          <Row>
            <Col xl="4">
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col xs="7">
                      <div className="text-primary p-3">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>It will seem like simplified</p>
                      </div>
                    </Col>
                    <Col xs="5" className="align-self-end">
                      <img src={userProfile.img} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <Row>
                    <Col sm="4">
                      <div className="avatar-md profile-user-wid mb-4">
                        <img
                          src={userProfile.img}
                          alt=""
                          className="img-thumbnail rounded-circle"
                        />
                      </div>
                      <h5 className="font-size-15 text-truncate">
                        {userProfile.name}
                      </h5>
                      <p className="text-muted mb-0 text-truncate">
                        {userProfile.designation}
                      </p>
                    </Col>

                    <Col sm={8}>
                      <div className="pt-4">
                        <Row>
                          <Col xs="6">
                            <h5 className="font-size-15">
                              {userProfile.projectCount}
                            </h5>
                            <p className="text-muted mb-0">Projects</p>
                          </Col>
                          <Col xs="6">
                            <h5 className="font-size-15">
                              {userProfile.revenue}
                            </h5>
                            <p className="text-muted mb-0">Revenue</p>
                          </Col>
                        </Row>
                        <div className="mt-4">
                          <Link to="" className="btn btn-primary  btn-sm">
                            View Profile{" "}
                            <i className="mdi mdi-arrow-right ms-1" />
                          </Link>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Personal Information</CardTitle>
                  <p className="text-muted mb-4">
                    {userProfile.personalDetail}
                  </p>
                  <div className="table-responsive">
                    <Table className="table-nowrap mb-0">
                      <tbody>
                        <tr>
                          <th scope="row">Full Name :</th>
                          <td>{userProfile.name}</td>
                        </tr>
                        <tr>
                          <th scope="row">Mobile :</th>
                          <td>{userProfile.phone}</td>
                        </tr>
                        <tr>
                          <th scope="row">E-mail :</th>
                          <td>{userProfile.email}</td>
                        </tr>
                        <tr>
                          <th scope="row">Location :</th>
                          <td>{userProfile.location}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <CardTitle className="mb-5">Experience</CardTitle>
                  <div>
                    <ul className="verti-timeline list-unstyled">
                      {map(userProfile.experiences, (experience, i) => (
                        <li
                          className={
                            experience.id === 1
                              ? "event-list active"
                              : "event-list"
                          }
                          key={"_exp_" + i}
                        >
                          <div className="event-timeline-dot">
                            <i
                              className={
                                experience.id === 1
                                  ? "bx bx-right-arrow-circle bx-fade-right"
                                  : "bx bx-right-arrow-circle"
                              }
                            />
                          </div>
                          <div className="d-flex">
                            <div className="me-3">
                              <i
                                className={
                                  "bx " +
                                  experience.iconClass +
                                  " h4 text-primary"
                                }
                              />
                            </div>
                            <div className="flex-grow-1">
                              <div>
                                <h5 className="font-size-15">
                                  <Link
                                    to={experience.link}
                                    className="text-dark"
                                  >
                                    {experience.designation}
                                  </Link>
                                </h5>
                                <span className="text-primary">
                                  {experience.timeDuration}
                                </span>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl="8">
              <Row>
                {map(miniCards, (card, key) => (
                  <MiniCards
                    title={card.title}
                    text={card.text}
                    iconClass={card.iconClass}
                    key={"_card_" + key}
                  />
                ))}
              </Row>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Revenue</CardTitle>
                  <div id="revenue-chart">
                    <ApexRevenue dataColors='["--bs-primary"]' />
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">My Projects</CardTitle>

                  <TableContainer
                    columns={columns}
                    data={userProfile.projects || []}
                    isGlobalFilter={false}
                    customPageSize={7}
                    customPageSizeOptions={true}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ContactsProfile;
