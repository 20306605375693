import React, { useEffect, useState, useContext } from "react";
import { laneStyle, boardStyle } from './styles';
import Board from "react-trello";
import "./styles.css";
import Card from "./card.js";
import { AuthContext } from "contexts/auth";
import api from "config/api";
import { Col } from "reactstrap";
import Botao from "./button/button";

const Kanban = () => {
  const { user } = useContext(AuthContext);
  const [lanes, setLanes] = useState([]);
  const [status, setStatus] = useState([]);
  const [influencers, setInfluencers] = useState([]);
  const [filter, setFilter] = useState("semana");

  useEffect(() => {
    async function getStatus() {
      try {
        const response = await api.get("/status", {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });

        const filteredStatus = response.data.filter((status) => status.id !== 9);
        setStatus(filteredStatus);
      } catch (error) {
        console.error("Erro ao obter status:", error);
      }
    }

    getStatus();
  }, [user.token]);

  useEffect(() => {
    async function fetchInfluencers() {
      try {
        const response = await api.get(`/influencers/captador/${user.userId}`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });

        setInfluencers(response.data);
      } catch (error) {
        console.error("Erro ao obter influenciadores:", error);
      }
    }

    fetchInfluencers();
  }, [user.token]);

  useEffect(() => {
    if (status.length > 0 && influencers.length > 0) {
      const laneData = status.map((statusItem) => {
        if (!statusItem.id) {
          console.warn("Status sem ID encontrado:", statusItem);
          return null;
        }

        const filteredInfluencers = filterInfluencers(influencers, filter).filter(
          (inf) => inf.status === statusItem.id
        );

        return {
          id: statusItem.id?.toString() || "unknown",
          title: statusItem.status,
          cards: filteredInfluencers.map((inf) => ({
            id: inf.id.toString(),
            data: inf,
          })),
        };
      });

      setLanes(laneData.filter((lane) => lane !== null));
    }
  }, [status, influencers, filter]);

  const filterInfluencers = (influencers, filter) => {
    const today = new Date();
    return influencers.filter((inf) => {
      const createdAt = new Date(inf.created_at);
      switch (filter) {
        case "dia":
          return createdAt.toDateString() === today.toDateString();
        case "semana":
          const sevenDaysAgo = new Date(today);
          sevenDaysAgo.setDate(today.getDate() - 6);
          return createdAt >= sevenDaysAgo && createdAt <= today;
        case "mes":
          return createdAt.getMonth() === today.getMonth() && createdAt.getFullYear() === today.getFullYear();
        case "todos":
        default:
          return true;
      }
    });
  };

  const handleCardMove = async (cardId, targetLaneId) => {
    try {
      await api.put(
        `/influencers/update/status`,
        {
          id: cardId,
          status: targetLaneId,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      console.log(`Status do influenciador ${cardId} atualizado para o status ${targetLaneId}`);
    } catch (error) {
      console.error("Erro ao atualizar status do influenciador:", error);
    }
  };

  const customCard = ({ data }) => <Card {...data} />;

  return (
    <div className="container">
      <Col>
        <Col>
          <div className="filtro">
            <label>Filtrar por:</label>
            <select
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            >
              <option value="dia">Dia</option>
              <option value="semana">Semana</option>
              <option value="mes">Mês</option>
              <option value="todos">Todos</option>
            </select>
          </div>
        </Col>
  
        <Col>
          {lanes.length > 0 ? (
            <div className="react-trello-board">
              <Board
                data={{ lanes }}
                laneStyle={laneStyle}
                style={boardStyle}
                customCardLayout
                components={{ Card: customCard }}
                onCardMoveAcrossLanes={(fromLaneId, toLaneId, cardId) => {
                  console.log(
                    `Movendo card ID ${cardId} de ${fromLaneId} para ${toLaneId}`
                  );
                  handleCardMove(cardId, toLaneId);
                }}
              />
            </div>
          ) : (
            <p>Carregando...</p>
          )}
        </Col>
      </Col>
  
      <Botao />
    </div>
  );
};

export default Kanban;
