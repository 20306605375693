import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody } from "reactstrap";
import ReactApexChart from "react-apexcharts";

const WalletOverView = ({ wallet }) => {
  const formatCurrency = (value) => {
    return value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2
    });
  };

  const optionsWithFormattedTooltips = {
    ...wallet.options,
    tooltip: {
      y: {
        formatter: (value) => formatCurrency(value)
      }
    },
    yaxis: {
      labels: {
        formatter: (value) => formatCurrency(value)
      }
    }
  };

  return (
    <Card>
      <CardBody>
        <h4 className="card-title mb-5">Movimentação</h4>
        <div>
          <div id="overview-chart" dir="ltr">
            <ReactApexChart
              series={wallet.series}
              options={optionsWithFormattedTooltips}
              type="line"
              height={210}
              className="apex-charts"
            />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

WalletOverView.propTypes = {
  wallet: PropTypes.any,
};

export default WalletOverView;
