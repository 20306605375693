import React, { useEffect, useState, useContext } from "react"
import { AuthContext } from "contexts/auth"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Collapse,
  Input,
} from "reactstrap"
import Select from "react-select"
import api from "config/api"
import Spinners from "components/Common/Spinner"
import Breadcrumbs from "components/Common/Breadcrumb"
import List from "./List"

const CollaboratorsList = () => {
  document.title = "Influencers | Viraw Influencers"

  const [collaborators, setCollaborators] = useState([])
  const [originalCollaborators, setOriginalCollaborators] = useState([]) // Estado para manter a lista original
  const [isLoading, setLoading] = useState(true)
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)
  const { user } = useContext(AuthContext)
  const [searchValue, setSearchValue] = useState("")
  const [colaboradoresGrupo, setColaboradoresGrupo] = useState([])

  // Função para buscar colaboradores
  const getCollaborators = async () => {
    setLoading(true)
    try {
      const response = await api.get(`/influencers/captador/${user.userId}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })

      let influencers = []
      response.data.forEach(element => {
        if (element.status === 8 && element.archiving === true) {
          influencers.push(element)
        }
      })

      setCollaborators(influencers)
      setOriginalCollaborators(influencers)
    } catch (error) {
      console.error("Error fetching collaborators:", error)
    }
    setLoading(false)
  }

  useEffect(() => {
    getCollaborators()
  }, [user.token])

  const handleSectorChange = selectedOption => {
    setSelectedOption(selectedOption)
    if (selectedOption) {
      const selectedSectorValue = selectedOption.value

      const filteredBySector = originalCollaborators.filter(
        collaborator =>
          collaborator.responsavel_rent.fullname === selectedSectorValue
      )
      setCollaborators(filteredBySector)
    } else {
      setCollaborators(originalCollaborators)
    }
  }

  const handleSearchCollaborator = value => {
    setSearchValue(value)
    const lowerCaseValue = value.toLowerCase()

    // Filtra imediatamente com base no valor de entrada atual
    const filteredCollaborators = originalCollaborators.filter(collaborator => {
      const fullname = collaborator.fullname?.toLowerCase() || ""
      const username = collaborator?.username?.toLowerCase() || ""
      return (
        fullname.includes(lowerCaseValue) || username.includes(lowerCaseValue)
      )
    })
    setCollaborators(filteredCollaborators)

    if (value.trim() === "") {
      setSelectedOption(null)
      setCollaborators(originalCollaborators)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Influencers" breadcrumbItem="Influencers" />
          <Row>
            <Col lg={12}>
              <Card className="collaborator-filter">
                <CardBody>
                  <form action="#">
                    <Row className="g-3">
                      <Col xxl={4} lg={4}>
                        <div className="position-relative">
                          <Input
                            type="text"
                            id="searchCollaborator"
                            autoComplete="off"
                            placeholder="Buscar Influenciador"
                            onChange={e =>
                              handleSearchCollaborator(e.target.value)
                            }
                            value={searchValue}
                          />
                        </div>
                      </Col>
                      <Col xxl={2} lg={4}>
                        <div className="position-relative">
                          {/* <Select
                                                        className="select2"
                                                        onChange={handleSectorChange}
                                                        options={colaboradoresGrupo.map(colaborador => ({
                                                            value: colaborador,
                                                            label: colaborador
                                                        }))}
                                                        value={selectedOption}
                                                        placeholder="Filtrar por responsável"
                                                        isClearable
                                                    /> */}
                        </div>
                      </Col>

                      <Collapse isOpen={isOpen} id="collapseExample">
                        <div>
                          {/* Additional filtering options can be added here */}
                        </div>
                      </Collapse>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <List listData={collaborators} />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CollaboratorsList
