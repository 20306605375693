import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";

const ModalObservation = ({ isOpen, toggle, influencer }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader>Detalhes do Remarketing</ModalHeader>
      <ModalBody>
        {influencer && (
          <>
            <p><strong>Nome:</strong> {influencer.fullname}</p>
            <p><strong>Detalhes do Remarketing:</strong> {influencer.remarketing_reason}</p>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Fechar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalObservation;