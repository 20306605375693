import api from "config/api"
import { AuthContext } from "contexts/auth"
import React, { useState, useEffect, useContext } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Row,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap"
import Toastr from "../../../components/Ui/Notifications"

const List = ({ listData, selectedFilterOption }) => {
  const [influencers, setInfluencers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [observation, setObservation] = useState('');
  const [idInfluencer, setIdInfluencer] = useState('');
  const { user } = useContext(AuthContext);

  const activeBtn = (id) => {
    setIdInfluencer(id);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowDetails = (observation) => {
    setObservation(observation);
    setShowModal(true);
  };

  const handleArchiveConfirm = async () => {
    await api.put("/influencers/update/archiving/", {
      id: idInfluencer,
      request_archiving: false,
      archiving: false
    },{
      headers:{
        Authorization: `Bearer ${user.token}`
      }
    }).then(() => {
      Toastr("success", "Influencer reativado!")
      setRequestArchiving(true)
    }).catch((err) => {
      console.error("Erro" + err);
    })
    setShowModal(false);
  };

  useEffect(() => {
    if (selectedFilterOption) {
        const filteredCollaborators = listData.filter(collaborator => {
            if (selectedFilterOption.value === 'Outros...') {
                return collaborator.observation !== 'Valores.' && collaborator.observation !== 'Não divulga casa de apostas.' && collaborator.observation !== 'Não respondeu.';
            } else {
                return collaborator.observation === selectedFilterOption.value;
            }
        });
        setInfluencers(filteredCollaborators);
    } else {
        setInfluencers(listData);
    }
}, [listData, selectedFilterOption]);

return (
  <React.Fragment>
    <Row>
      {influencers.map((influencer, key) => (
        <Col xl={3} key={key}>
          <Card>
            <CardBody>
              <div className="d-flex align-start mb-3">
                <div className="flex-grow-1">
                  <span
                    className={
                      influencer.responsavel_rent &&
                      influencer.type_user
                        ? sectorBadgeClasses(
                            influencer.type_user.type
                          )
                        : "badge badge-soft-danger"
                    }
                  >
                    {influencer.captacao_influencer.fullname
                        ? influencer.captacao_influencer.fullname
                        : "Nome indisponível"}
                  </span>
                </div>
              </div>
              <div className="text-center mb-3">
                {influencer.photo && influencer.photo && (
                  <img
                    src={influencer.photo}
                    alt=""
                    className="avatar-sm rounded-circle"
                  />
                )}
                <h6 className="font-size-15 mt-3 mb-1">
                  @{influencer.username ? influencer.username : 'Nome indisponível'}
                </h6>
                <p></p>
                <p className="mb-0 text-muted">
                </p>
              </div>
              <div className="mt-4 pt-1 d-flex justify-content-center">
                <button
                  className="btn btn-soft-primary"
                  onClick={() => handleShowDetails(influencer.observation)}
                >
                  Ver Detalhes
                </button>
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}
    </Row>

    {/* Modal */}
    <Modal isOpen={showModal} toggle={handleCloseModal}>
        <ModalHeader toggle={handleCloseModal}>Detalhes do Influenciador</ModalHeader>
        <ModalBody>
          <p>{observation ? observation : "Motivo não informado"}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleCloseModal}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
);
}

export default List
