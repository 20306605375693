import React from "react"
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "../../../components/Common/ChartsDynamicColor";

const ApexRevenue = ({dataColors, data}) => {
  var apexRevenuechartColors =  getChartColorsArray(dataColors); 
  const options = {
    chart: {
      height: 300,
      type: "bar",
      toolbar: {
        show: !1,
      },
    },
    plotOptions: {
      bar: {
        horizontal: !1,
        columnWidth: "14%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: !1,
    },
    stroke: {
      show: !0,
      width: 2,
      colors: ["transparent"],
    },
    series: [
      {
        name: "Quantidade",
        data: data
      },
    ],
    xaxis: {
      categories: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],
    },
    yaxis: {
      title: {
        text: "Influencers Atribuidos",
      },
    },
    fill: {
      opacity: 1,
    },
    colors: apexRevenuechartColors,
  }
  const series = [
    {
      name: "Quantidade",
      data: data,
    },
  ]

  return (
    <ReactApexChart options={options} series={series} type="bar" height="330" className="apex-charts" />
  )
}

export default ApexRevenue
