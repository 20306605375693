import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import withRouter from "../../../components/Common/withRouter";
import {
    Card,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    InputGroupText,
    Col,
    Pagination
} from "reactstrap";
import TableContainer from "../../../components/Common/TableContainer";
import moment from "moment";
import DatePicker from "react-datepicker";

const LatestTransaction = ({ trackingAll }) => {
    const [filterMonth, setFilterMonth] = useState((moment().month() + 1).toString());
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const meses = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
    ];

    const filteredData = useMemo(() => {
        let filtered = trackingAll || [];

        if (filterMonth) {
            filtered = filtered.filter((item) => {
                const month = moment(item.Period).month() + 1;
                return month === parseInt(filterMonth);
            });
        }

        if (startDate && endDate) {
            filtered = filtered.filter((item) => {
                const itemDate = moment(item.Period);
                return itemDate.isBetween(startDate, endDate, null, "[]");
            });
        }

        filtered.sort((a, b) => moment(a.Period).diff(moment(b.Period)));

        return filtered;
    }, [trackingAll, filterMonth, startDate, endDate]);

    const columns = useMemo(
        () => [
            {
                Header: "Campanha",
                accessor: "Campaign",
                Cell: (cellProps) => cellProps.value,
            },
            {
                Header: "Dia",
                accessor: "Period",
                Cell: (cellProps) => moment(cellProps.value).format("DD/MM/YYYY"),
            },
            {
                Header: "Trackings",
                accessor: "ACID",
                Cell: (cellProps) => (
                    <div style={{ maxWidth: "220px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                        {cellProps.value}
                    </div>
                ),
            },
            {
                Header: "FTD",
                accessor: "FirstDepositCount",
                Cell: (cellProps) => cellProps.value || 0,
            },
            {
                Header: "Cadastros",
                accessor: "Registrations",
                Cell: (cellProps) => cellProps.value || 0,
            },
            {
                Header: "Clicks",
                accessor: "Clicks",
                Cell: (cellProps) => cellProps.value || 0,
            },
        ],
        []
    );

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <div className="mb-4 h4 card-title">
                        Clicks são atualizados 3 vezes ao dia
                    </div>
                    <Form>
                        <FormGroup row>
                            <Label for="filterMonth" sm={1}>
                                Filtrar por Mês:
                            </Label>
                            <Col sm={2}>
                                <Input
                                    type="select"
                                    name="filterMonth"
                                    id="filterMonth"
                                    value={filterMonth}
                                    onChange={(e) => setFilterMonth(e.target.value)}
                                >
                                    <option value="">Selecione o mês</option>
                                    {meses.map((month, index) => (
                                        <option key={index} value={index + 1}>
                                            {month}
                                        </option>
                                    ))}
                                </Input>
                            </Col>

                            <Label for="dateRange" sm={1}>
                                Filtrar por Data:
                            </Label>
                            <Col sm={3}>
                                <div className="d-flex">
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        selectsStart
                                        startDate={startDate}
                                        endDate={endDate}
                                        dateFormat="dd/MM/yyyy"
                                        className="form-control me-2"
                                    />
                                    <InputGroupText className="me-0">
                                        até
                                    </InputGroupText>
                                    <DatePicker
                                        selected={endDate}
                                        onChange={(date) => setEndDate(date)}
                                        selectsEnd
                                        startDate={startDate}
                                        endDate={endDate}
                                        dateFormat="dd/MM/yyyy"
                                        className="form-control"
                                    />
                                </div>
                            </Col>
                        </FormGroup>
                    </Form>

                    <TableContainer
                        columns={columns}
                        data={filteredData}
                        isGlobalFilter={true}
                        isAddOptions={false}
                        customPageSize={10}
                        isPagination={true}
                        pagination="pagination pagination-rounded justify-content-end mb-2"
                        filterable={false}
                        iscustomPageSizeOptions={true}
                        tableClass="align-middle table-nowrap table-check"
                        theadClass="table-light"
                    />
                    <Pagination />
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

LatestTransaction.propTypes = {
    trackingAll: PropTypes.array,
};

export default withRouter(LatestTransaction);