import React, {useContext, useState} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from 'reactstrap';
import api from 'config/api';
import { AuthContext } from 'contexts/auth';
import Toastr from "../../../components/Ui/Notifications";

function CadastrarCampanha({ isOpen, toggle, userToken }) {
    const [plugchatLink, setPlugchatLink] = useState('');
    const [campaignName, setCampaignName] = useState('');
    const { user } = useContext(AuthContext)

    const handleLinkChange = (e) => {
      setCampaignName(e.target.value);
    };
  
    async function handleSubmit (){
      await api.post("/campaigns",{
          name: campaignName,
      },{
          headers:{
              Authorization: `Bearer ${user.token}`
          }
      }).then((response) =>{
          Toastr("success", "Campanha adicionada com sucesso!")
          toggle(); 
      }).catch((error) =>{
          console.error(error);
          Toastr("error", "Algo errado aconteceu!")
      });
  }
  
    return (
      <div>
        <Modal isOpen={isOpen} toggle={toggle}>
          <ModalHeader toggle={toggle}>Crie uma nova Campanha</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="plugchatLink">Nome da campanha</Label>
              <Input
                type="text"
                name="name"
                id="name"
                placeholder="Insira o nome da sua Campanha..."
                value={campaignName}
                onChange={handleLinkChange}
              />
            </FormGroup>
          </ModalBody>
          
          <ModalFooter>
          <Button color="primary" onClick={handleSubmit}>Salvar</Button>{' '}
            <Button color="secondary" onClick={toggle}>Cancelar</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
  
  export default CadastrarCampanha;