import React, { useEffect, useState, useContext } from "react"
import { AuthContext } from "contexts/auth"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Collapse,
  Input,
  Button,
} from "reactstrap"
import Select from "react-select"
import api from "config/api"
import Spinners from "components/Common/Spinner"
import Breadcrumbs from "components/Common/Breadcrumb"
import List from "./List"
import {
  CounterBox,
  CounterTitle,
  CounterValue,
  FormContainer,
  FormLabel,
  FormInput,
  FormButton,
} from "./styles"
import { StateContext } from "contexts/states"
import CadastrarInfluencerManual from "components/Modals/CadastrarInfluencerManual"

const CollaboratorsList = () => {
  const { user } = useContext(AuthContext)
  const [collaborators, setCollaborators] = useState([])
  const [originalCollaborators, setOriginalCollaborators] = useState([])
  const [isLoading, setLoading] = useState(true)
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)
  const [searchValue, setSearchValue] = useState("")
  const [dataInicial, setDataInicial] = useState("2024-01-01")
  const [dataFinal, setDataFinal] = useState(getTodayDate())
  const [selectedFilterOption, setSelectedFilterOption] = useState(null)
  const { modal, setModal } = useContext(StateContext)

  const filterOptions = [
    { value: "todos", label: "Todos" },
    { value: "ativos", label: "Ativos" },
    { value: "arquivados", label: "Arquivados" },
  ]

  const handleFilterChange = selectedOption => {
    setSelectedFilterOption(selectedOption)
    switch (selectedOption.value) {
      case "ativos":
        setCollaborators(
          originalCollaborators.filter(
            collaborator => collaborator.influencer_rent.archiving === false
          )
        )
        break
      case "arquivados":
        setCollaborators(
          originalCollaborators.filter(
            collaborator => collaborator.influencer_rent.archiving === true
          )
        )
        break
      default:
        setCollaborators(originalCollaborators)
        break
    }
  }

  function getTodayDate() {
    const today = new Date()
    const year = today.getFullYear()
    let month = today.getMonth() + 1
    let day = today.getDate()

    month = month < 10 ? "0" + month : month
    day = day < 10 ? "0" + day : day

    return year + "-" + month + "-" + day
  }

  const handleDataInicialChange = e => {
    setDataInicial(e.target.value)
  }

  const handleDataFinalChange = e => {
    setDataFinal(e.target.value)
  }

  const handleSubmit = e => {
    e.preventDefault()
    getPlugInterval()
  }

  useEffect(() => {
    getPlugInterval()
  }, [])

  async function getPlugInterval() {
    setLoading(true)
    try {
      const response = await api.post(
        "/plugchat/all/interval",
        {
          gte: `${dataInicial}T00:00:00.000Z`,
          lte: `${dataFinal}T23:59:59.000Z`,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      setCollaborators(response.data)
      setOriginalCollaborators(response.data)
      setSelectedFilterOption(null)
      setLoading(false)
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  const handleSearchCollaborator = value => {
    setSearchValue(value)
    const lowerCaseValue = value.toLowerCase()

    const filteredCollaborators = originalCollaborators.filter(collaborator => {
      const fullname =
        collaborator.influencer_rent?.fullname?.toLowerCase() || ""
      const username =
        collaborator.influencer_rent?.username?.toLowerCase() || ""
      return (
        fullname.includes(lowerCaseValue) || username.includes(lowerCaseValue)
      )
    })
    setCollaborators(filteredCollaborators)

    if (value.trim() === "") {
      setCollaborators(originalCollaborators)
    }
  }
  function handleOpenCollaboratorModal() {
    setModal(true)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Influencers" breadcrumbItem="Influencers" />
          <Row>
            <Col lg={12}>
              <Card className="collaborator-filter">
                <Row className="d-flex justify-content-center">
                  <Col sm={6} md={3}>
                    <CounterBox className="bg-muted">
                      <CounterTitle className="text-muted">
                        Total selecionado
                      </CounterTitle>
                      <CounterValue className="text-muted">
                        {collaborators.length}
                      </CounterValue>
                    </CounterBox>
                  </Col>

                  <Col sm={6} md={9}>
                    <FormContainer onSubmit={handleSubmit}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "15px",
                          width: "80%",
                        }}
                      >
                        <FormLabel>
                          <span
                            style={{
                              height: "30px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            Data Inicial:
                          </span>
                          <Input
                            type="date"
                            value={dataInicial}
                            onChange={handleDataInicialChange}
                          />
                        </FormLabel>

                        <FormLabel>
                          <span
                            style={{
                              height: "30px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            Data Final:
                          </span>

                          <Input
                            type="date"
                            value={dataFinal}
                            onChange={handleDataFinalChange}
                          />
                        </FormLabel>
                      </div>

                      <FormButton
                        className="btn btn-soft-primary d-block"
                        type="submit"
                      >
                        Filtrar
                      </FormButton>
                    </FormContainer>
                  </Col>
                </Row>
                <CardBody>
  <form action="#">
    <Row className="g-3">
      <Col xxl={4} lg={4}>
        <div className="position-relative">
          <Input
            type="text"
            id="searchCollaborator"
            autoComplete="off"
            placeholder="Buscar Influenciador"
            onChange={e => handleSearchCollaborator(e.target.value)}
            value={searchValue}
          />
        </div>
      </Col>
      <Col xxl={4} lg={4}>
        <div className="position-relative">
          <Select
            value={selectedFilterOption}
            onChange={handleFilterChange}
            options={filterOptions}
            placeholder="Selecione o filtro"
          />
        </div>
      </Col>
      <Col xxl={4} lg={4}>
        {(user.userId === "d7054962-4115-4cec-9ba2-18c665352c1f" ||
          user.userId === "1ff92548-c820-44fd-8fa5-c5695b0fa460") && (
          <Button
            type="button"
            color="primary"
            className="btn mb-2 w-100"
            onClick={handleOpenCollaboratorModal}
          >
            <i className="mdi mdi-plus-circle-outline me-1" />
            Cadastrar Influenciador
          </Button>
        )}
      </Col>
    </Row>
  </form>
</CardBody>

              </Card>
            </Col>
          </Row>
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <List listData={collaborators} />
          )}
          <CadastrarInfluencerManual />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CollaboratorsList
