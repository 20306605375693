import React, { useEffect, useState, useContext } from "react"
import { AuthContext } from "contexts/auth"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Collapse,
  Input,
  Button,
} from "reactstrap"
import Select from "react-select"
import api from "config/api"
import Spinners from "components/Common/Spinner"
import Breadcrumbs from "components/Common/Breadcrumb"
import List from "./List"
import Toastr from "../../../components/Ui/Notifications"

const CollaboratorsList = () => {
  const [isLoading, setLoading] = useState(true)
  const [selectedOption, setSelectedOption] = useState(null)
  const { user } = useContext(AuthContext)
  const [searchValue, setSearchValue] = useState("")
  const [colaboradoresGrupo, setColaboradoresGrupo] = useState([])
  const [listPayments, setListPayments] = useState([])
  const [listPaymentsOriginal, setListPaymentsOriginal] = useState([])
  const [antecipados, setAntecipados] = useState("")




  const getSaques = async () => {
    try {
      const response = await api.get("/influencers/generetepayments/advance", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      setListPaymentsOriginal(response.data.payments); 
      setListPayments(response.data.payments); 
      setAntecipados(response.data)
    } catch (error) {
      console.error("Error fetching influencers:", error)
    }
  }
  useEffect(() => {
    getSaques()
  }, [user.token])
  
  const handleSectorChange = selectedOption => {
    setSelectedOption(selectedOption)
    if (selectedOption) {
      const selectedSectorValue = selectedOption.value
      const filteredBySector = listPaymentsOriginal.filter(
        collaborator => collaborator.responsavel === selectedSectorValue
      )
      setListPayments(filteredBySector)
    } else {
      setListPayments(listPaymentsOriginal)
    }
  }

const handleSearchCollaborator = value => {
  setSearchValue(value);

  const lowerCaseValue = value.toLowerCase();

  const filteredCollaborators = listPaymentsOriginal.filter(collaborator => {

    const fullname = collaborator.influencer?.toLowerCase() || "";
    const username = collaborator.username?.toLowerCase() || "";

    const matches = fullname.includes(lowerCaseValue) || username.includes(lowerCaseValue);

    return matches;
  });

  setListPayments(filteredCollaborators);

  if (value.trim() === "") {
    setSelectedOption(null);
    setListPayments(listPaymentsOriginal);
  }
}

  
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Influencers"
            breadcrumbItem="Pagamentos da semana"
          />
          <Row>
            <Col lg={12}>
              <Card className="collaborator-filter">
                <CardBody>
                  <form action="#">
                    <Row className="g-3">
                      <Col xxl={4} lg={4}>
                        <div className="position-relative">
                          <Input
                            type="text"
                            id="searchCollaborator"
                            autoComplete="off"
                            placeholder="Buscar Influenciador"
                            onChange={e =>
                              handleSearchCollaborator(e.target.value)
                            }
                            value={searchValue}
                          />
                        </div>
                      </Col>

                      <Col xxl={2} lg={3}>
                        <div className="position-relative">
                          <Select
                            className="select2"
                            onChange={handleSectorChange}
                            options={colaboradoresGrupo.map(colaborador => ({
                              value: colaborador,
                              label: colaborador,
                            }))}
                            value={selectedOption}
                            placeholder="Responsável"
                            isClearable
                          />
                        </div>
                      </Col>
                      <Col xxl={6} lg={5} style={{textAlign: 'center', display: 'flex', justifyItems: 'center', alignItems: 'center', padding: ''}}>
                            <h2 style={{width: '100%', height: '100%', display: 'flex', justifyItems: 'center', alignItems: 'center'}}>Total antecipado: {antecipados?.TotalAntecipado?.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                        minimumFractionDigits: 2,
                      })}
                      </h2>
                      </Col>
                  
               
{/* 
                      <Col xxl={3} lg={3}>
                        <div className="position-relative">
                          <Button
                            color="primary"
                            onClick={() => fetchPayments()}
                            disabled={!isMonday}
                          >
                            {loadingRequests
                              ? "Aguarde..."
                              : "Solicitar pagamentos"}
                          </Button>
                        </div>
                      </Col> */}
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <List setLoading={setLoading} listData={listPayments}  />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CollaboratorsList
