import styled from "styled-components";

export const BadgeDiv = styled.div `

`;
export const Badge = styled.div `
 display: flex;
 justify-content: center;
 align-items: center;
 flex-direction: column;
`;

export const ImageBadge = styled.img`
width: 50px;
height: 50px;
border-radius: 50%;
`;

export const LinkBadge = styled.a`
display: flex;
justify-content: center;
align-items: center;
width: 60px;
height: 60px;
 border-radius: 50%;
`

export const Username = styled.h6``;
export const PostTitle = styled.h6`
border: 2px solid #fff;
border-radius: 20px;
padding: 5px 5px;
`;

export const HeaderInfo = styled.div`
display: flex;
flex-direction: row;
background-color: green;
align-items: center;
`
export const GroupInfo = styled.div`
display: flex;
flex-direction: row;
margin: 5px 20px;
height: 30px;
`

export const Info = styled.div`
margin: 0px 5px;
padding: 5px;
border-radius: 10px;
color: #fff;
font-size: 12px;
`

export const Buttons = styled.div`
display: flex;
justify-content: space-between;
align-items:center;
padding: 10px 20px;
margin: 10px 0;
`;
export const ButtonsTxt = styled.span`
    font-size: 16px;
    transition: font-size 0.3s ease;
    &:hover{
        font-size: 17px;
        cursor: pointer;
    }
`;

