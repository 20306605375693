import api from "config/api"
import { AuthContext } from "contexts/auth"
import React, { useState, useEffect, useContext } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
import Toastr from "../../../components/Ui/Notifications"
import Loading from "components/Ui/LoadingCircle"
import { Link } from "react-router-dom"

const List = ({ listData, selectDay }) => {
  const sectorBadgeClasses = sectorType => {
    switch (sectorType) {
      case "Francisco":
        return "badge badge-soft-success"
      case "João Victor":
        return "badge badge-soft-info"
      case "Karol":
        return "badge badge-soft-danger"
      default:
        return "badge badge-soft-warning"
    }
  }

  const sectorBadgeCampaigns = option => {
    switch (option) {
      case "SuperBet":
        return "badge badge-soft-info"
      case "Betano e SuperBet":
        return "badge badge-soft-success"
      case "Betano":
        return "badge badge-soft-danger"
      default:
        return "badge badge-soft-warning"
    }
  }
  return (
    <React.Fragment>
      <Row>
        {listData.map((influencer, key) => (
          <Col xl={3} key={key}>
            <Card>
              <CardBody>
                <div className="d-flex align-start mb-3">
                  <div className="flex-grow-1">
                    <span
                      className={
                        influencer.responsavel
                          ? sectorBadgeClasses(influencer.responsavel)
                          : "badge badge-soft-success"
                      }
                    >
                      {influencer.responsavel
                        ? influencer.responsavel
                        : "Nome indisponível"}
                    </span>

                    <span
                      style={{ marginLeft: "10px" }}
                      className={
                        influencer && influencer.campaign
                          ? sectorBadgeCampaigns(influencer.campaign)
                          : "badge badge-soft-success"
                      }
                    >
                      {influencer ? influencer.campaign : "Nome indisponível"}
                    </span>
                  </div>
                </div>
                <div className="text-center mb-3">
                  <img
                    src="https://i.ibb.co/y6nkQKW/461085513-1715142105981321-1128849984098454117-n.jpg"
                    alt="Logo da viraw"
                    className="avatar-sm rounded-circle"
                  />
                  <h6 className="font-size-15 mt-3 mb-1 text-truncate">
                    {influencer?.influencer}
                  </h6>
                  <p className="mb-0 text-muted font-size-12">
                    @{influencer?.username}
                  </p>
                  <p className="mb-0 text-muted font-size-12">
                    <strong>FTDs: </strong>
                    {influencer?.FTDsPorDia[selectDay]?.toLocaleString("pt-BR")}
                  </p>
                  <p className="mb-0 text-muted font-size-12">
                    <strong>Ganho: </strong>
                    {influencer?.valorPorDia[selectDay]?.toLocaleString(
                      "pt-BR",
                      {
                        style: "currency",
                        currency: "BRL",
                        minimumFractionDigits: 2,
                      }
                    )}
                  </p>
                </div>

                <Row>
                  <Col md="12">
                    <Link to={`/influencer/${influencer?.plugchatId}`}>
                      <Button
                        style={{ width: "100%" }}
                        className="btn btn-soft-primary d-block"
                      >
                        Perfil
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </React.Fragment>
  )
}

export default List
