import { StateContext } from "contexts/states";
import React, { useContext, useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Table } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import api from "config/api";
import { AuthContext } from "contexts/auth";

const PaymentDetailsModal = ({ payment, handleClose, influencerId  }) => {
  const { detailsValue, setDetailsValue } = useContext(StateContext);
  const { user } = useContext(AuthContext);

  const [comprovantes, setComprovantes] = useState([]);
  const [totalGasto, setTotalGasto] = useState(0);

  useEffect(() => {
    async function getComprovantes() {
      await api.get("/payments", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((response) => {
        setComprovantes(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
    }

    getComprovantes();
  }, [user.token]);
  function formatValue(value) {
    // Ensure the value is a number
    const numericValue = typeof value === 'number' ? value : parseFloat(value);
  
    // Check if the value is NaN or not a number
    if (isNaN(numericValue)) {
      return ''; // Return an empty string for invalid values
    }
  
    // Format the value as currency with two decimal places
    const formattedValue = numericValue.toFixed(2);
  
    // Use Intl.NumberFormat to add thousands separator and format as currency
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(formattedValue);
  }
  
  useEffect(() => {
    // Filtra os comprovantes para obter apenas os do influenciador específico
    const comprovantesDoInfluenciador = comprovantes.filter((comprovante) => {
      return comprovante.influencer === influencerId;
    });
  
    // Calcula o total gasto somando os valores dos comprovantes do influenciador
    const total = comprovantesDoInfluenciador.reduce((acc, comprovante) => {
      return acc + comprovante.value;
    }, 0);
  
    setTotalGasto(total);
  }, [comprovantes, influencerId]);

  const toggle = () => {
    setDetailsValue(!detailsValue);
  };

  // Função para abrir uma nova guia com o link (se disponível)
  const openNewTab = (link) => {
    if (link) {
      window.open(link, "_blank");
    }
  };

  function formatarData(dataString) {
    // Cria um objeto Date com a string de data
    const data = new Date(dataString);
  
    // Extrai os componentes da data (dia, mês, ano)
    const dia = data.getDate().toString().padStart(2, '0');
    const mes = (data.getMonth() + 1).toString().padStart(2, '0'); // Mês é baseado em zero
    const ano = data.getFullYear();
  
    // Retorna a data formatada
    return `${dia}/${mes}/${ano}`;
  }

  return (
    <Modal isOpen={detailsValue} toggle={toggle} size="md">
      <ModalHeader toggle={toggle}>Detalhes do Pagamento</ModalHeader>
      <ModalBody>
        <Table bordered>
          <tbody>
            {comprovantes.map((element, index) => (
             element.influencer === influencerId ? (
              <tr key={index}>
                <td>{formatarData(element.date)}</td>
                <td>{formatValue(element.value)}</td>
                <td style={{ verticalAlign: "middle", textAlign: "center" }}>
                  {element.link ? (
                    <Button
                      onClick={() => openNewTab(element.link)}
                      className="btn btn-soft-success"
                    >
                      <FontAwesomeIcon icon={faFile} style={{ fontSize: "16px" }} />
                    </Button>
                  ) : (
                    <span>Sem Link</span>
                  )}
                </td>
              </tr>
             ): null
            ))}
          </tbody>
        </Table>
        <div className="total-gasto">
          <strong>Total Gasto:</strong> {formatValue(totalGasto)}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Fechar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default PaymentDetailsModal;
