import Spinners from "components/Common/Spinner"
import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Row } from "reactstrap"

const List = ({ listData }) => {
  const [advisors, setAdvisors] = useState([])
  const [isLoading, setLoading] = useState(true)

  // Foto padronizada para todos os assessores
  const defaultPhoto =
    "https://i.ibb.co/y6nkQKW/461085513-1715142105981321-1128849984098454117-n.jpg"

  useEffect(() => {
    const advisorsWithPhotos = listData.map(advisor => ({
      ...advisor,
      photo: defaultPhoto,
    }))
    setAdvisors(advisorsWithPhotos)
    setLoading(false)
  }, [listData])

  const activeBtn = ele => {
    if (ele.closest("button").classList.contains("active")) {
      ele.closest("button").classList.remove("active")
    } else {
      ele.closest("button").classList.add("active")
    }
  }
  return (
    <React.Fragment>
      <Row>
        {advisors.map((advisor, key) => (
          <Col xl={3} key={key}>
            <Card>
              <CardBody>
                <div className="d-flex align-start mb-3">
                  <div className="flex-grow-1">
                    <span
                      style={{ marginRight: "5px" }}
                      className={
                        advisor?.active
                          ? "badge badge-soft-success"
                          : "badge badge-soft-danger"
                      }
                    >
                      {advisor?.active ? "Ativo" : "Inativo"}
                    </span>

                    <span
                      style={{ marginRight: "5px" }}
                      className={
                        advisor?.isMeta
                          ? "badge badge-soft-info"
                          : "badge badge-soft-danger"
                      }
                    >
                      {advisor?.isMeta ? "Com Meta" : "Sem Meta"}
                    </span>
                  </div>
                </div>
                <div className="text-center mb-3">
                  <img
                    src={advisor.photo}
                    alt="Assessor"
                    className="avatar-sm rounded-circle"
                  />
                  <h6 className="font-size-15 mt-3 mb-1 text-truncate">
                    {advisor.name}
                  </h6>
                  <h6 className="font-size-12 mb-0 text-muted">
                    {advisor.email}
                  </h6>
                </div>
                <div className="mt-4 pt-1">
                  <Link
                    to={`/assessor/${advisor.id}`}
                    className="btn btn-soft-primary d-block"
                  >
                    Ver Detalhes
                  </Link>
                </div>
                <div className="mt-1 pt-1">
                  <Link
                    to={`/assessor/relatorios/${advisor.id}`}
                    className="btn btn-soft-primary d-block"
                  >
                    Relatórios
                  </Link>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
      {isLoading && <Spinners setLoading={setLoading} />}
    </React.Fragment>
  )
}

export default List
