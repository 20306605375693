import React, { useContext, useState, useEffect } from "react";
import { Modal, Button, Form, FormGroup, Label, Input, ModalFooter } from "reactstrap";
import { AuthContext } from "contexts/auth";
import Toastr from "../../Ui/Notifications";
import api from "config/api";

const EditBankDataModal = ({ id, name, cpf, pix, type, bankData, controlModal, toggle, onChangeBankData }) => {

  const [newName, setNewName] = useState(bankData.name || "");
  const [newCpf, setNewCpf] = useState(bankData.cpf || "");
  const [newAccountType, setNewAccountType] = useState(bankData.type || "Selecione");
  const [newPix, setNewPix] = useState(bankData.pix || "");
  const { user } = useContext(AuthContext);

  const handleAddBankData = () => {
    handleSaveChanges(id, newName, newCpf, newPix, newAccountType);
    toggle();
  }

  useEffect(() => {
    setNewName(name);
    setNewCpf(cpf);
    setNewAccountType(type);
    setNewPix(pix);
  },[])

  async function handleSaveChanges(id, newName, newCpf, newPix, newAccountType) {
    await api.put(`/bankdata/update/`, 
      {
        id, 
        name: newName,
        cpf: newCpf,
        ...(newAccountType !== "Selecione" && { type: newAccountType }),
        pix: newPix,
      },
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    )
    .then((response) => {
      Toastr("success", "Dados bancários atualizados com sucesso!");
      onChangeBankData({
        id,
        name: newName,
        cpf: newCpf,
        pix: newPix,
        type: newAccountType !== "Selecione" ? newAccountType : undefined,
      });
    })
    .catch((error) => {
      console.error(error);
      Toastr("error", "Algo deu errado ao tentar atualizar! Detalhes: " + error.message);
    });
  }

  const typeOptions = [
    { value: "", label: "Selecione" },
    { value: "CPF", label: "CPF" },
    { value: "CNPJ", label: "CNPJ" },
    { value: "Telefone", label: "Telefone" },
    { value: "Email", label: "E-mail" },
    { value: "Aleatoria", label: "Aleatória" }
  ];

  return (
    <Modal isOpen={controlModal} toggle={toggle}>
      <div className="modal-header">
        <h5 className="modal-title">Editar Dados Bancários</h5>
        <button type="button" className="btn-close" onClick={toggle}></button>
      </div>
      <div className="modal-body">
        <Form>
          <FormGroup>
            <Label for="name">Nome Completo</Label>
            <Input
              type="text"
              id="name"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="cpf">CPF</Label>
            <Input
              type="text"
              id="cpf"
              value={newCpf}
              onChange={(e) => setNewCpf(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="accountType">Tipo de Conta</Label>
            <Input
              type="select"
              id="accountType"
              value={newAccountType}
              onChange={(e) => setNewAccountType(e.target.value)}
            >
              {typeOptions.map((option) => (
                <option key={option.value} value={option.value}>{option.label}</option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="pix">Chave PIX</Label>
            <Input
              type="text"
              id="pix"
              value={newPix}
              onChange={(e) => setNewPix(e.target.value)}
            />
          </FormGroup>
        </Form>
      </div>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Fechar</Button>
        <Button color="primary" onClick={handleAddBankData}>Salvar</Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditBankDataModal;