import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = () => {
  const pieData = {
    labels: ["Custo", "Despesa", "Investimento"],
    datasets: [
      {
        label: "Distribuição de Saída de Caixa",
        data: [300, 150, 100],
        backgroundColor: ["#A9A9A9", "#FF0000", "#FFFF00"],
        hoverBackgroundColor: ["#808080", "#D90000", "#FFD700"],
        borderWidth: 1,
      },
    ],
  };

  const pieOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
    },
  };

  return (
    <div style={{ width: '330px', height: '326px', marginTop: '10px' }}>
      <Pie data={pieData} options={pieOptions} />
    </div>
  );
};

export default PieChart;
