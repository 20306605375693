import React, { useContext, useEffect, useState } from "react";
import { FaInstagram } from "react-icons/fa";
import {
  CardContainer,
  Background,
  Image,
  Content,
  Title,
  SocialIcons,
  RankLabel,
  TitleContainer,
  PageContainer,
  Subtitle,
} from "./styles";
import api from "config/api";
import { AuthContext } from "contexts/auth";

const Card = () => {
  const [topInfluencers, setTopInfluencers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tema, setTema] = useState(""); 
  const { user } = useContext(AuthContext);

  const getTemaFromStorage = () => {
    return localStorage.getItem("tema") || "";
  };

  useEffect(() => {
    setTema(getTemaFromStorage());

    const intervalId = setInterval(() => {
      const currentTema = getTemaFromStorage();
      if (currentTema !== tema) {
        setTema(currentTema);
      }
    }, 100); 

    return () => clearInterval(intervalId);
  }, [tema]); 

  useEffect(() => {
    async function getTopInfluencers() {
      try {
        const response = await api.get("/tracking/superbet/geral/all", {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });

        const data = response.data;
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        const formattedYesterday = `${yesterday.getFullYear()}-${yesterday.getMonth() + 1}-${yesterday.getDate()}`;

        const influencers = data
          .filter(item => item.Diario && Array.isArray(item.Diario))
          .map(influencer => {
            const periodData = influencer.Diario.find(
              period => period.Period === formattedYesterday
            );
            return {
              ...influencer,
              FirstDepositCount: periodData ? periodData.FirstDepositCount : 0,
            };
          })
          .filter(influencer => influencer.FirstDepositCount > 0)
          .sort((a, b) => b.FirstDepositCount - a.FirstDepositCount)
          .slice(0, 3);

        if (influencers.length === 3) {
          const [top1, top2, ...rest] = influencers;
          setTopInfluencers([top2, top1, ...rest]);
        } else {
          setTopInfluencers(influencers);
        }
      } catch (err) {
        console.error("Erro ao buscar influenciadores:", err);
      } finally {
        setLoading(false);
      }
    }
    getTopInfluencers();
  }, [user.token]);

  const handleInstagramClick = handle => {
    window.open(`https://instagram.com/${handle}`, "_blank");
  };

  return (
    <PageContainer tema={tema}>
      <TitleContainer>
        TOP 3 DIÁRIOS
        <Subtitle>{getFormattedDateYesterday()}</Subtitle>
      </TitleContainer>
      
      <div className="card-list">
        {topInfluencers.map((influencer, index) => (
          <CardContainer key={index} isTop1={index === 1}>
            <RankLabel>{`#${index === 1 ? 1 : index === 0 ? 2 : 3}`}</RankLabel>
            <Background />
            <Image src={influencer?.photo} alt={influencer?.fullname} />
            <Content className="content">
              <Title>
                {influencer?.fullname} <br />
                <span>{influencer?.company}</span>
              </Title>
              <div className="ftds">
                <strong style={{ color: "white" }}>
                  FTDs: {influencer?.FirstDepositCount || 0}
                </strong>
              </div>
              <SocialIcons className="sci">
                <li>
                  <a
                    href="#"
                    onClick={() =>
                      handleInstagramClick(influencer?.username.replace("@", ""))
                    }
                  >
                    <FaInstagram />
                  </a>
                </li>
              </SocialIcons>
            </Content>
          </CardContainer>
        ))}
      </div>
    </PageContainer>
  );
};

function getFormattedDateYesterday() {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const day = yesterday.getDate(); 
  const month = yesterday.getMonth() + 1; 
  const year = yesterday.getFullYear();
  return `${year}-${month}-${day}`;
}

export default Card;
