import styled from 'styled-components';

export const CounterBox = styled.div`
    
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
    }
`;

export const CounterTitle = styled.p`
    font-size: 16px;
  
    margin-bottom: 10px;
`;

export const CounterValue = styled.p`
    font-size: 24px;
  
    font-weight: bold;
`;

export const FormContainer = styled.form`

display: flex;
justify-content: center;
flex-direction: column;
padding: 5px;
align-items: center;
box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.4);

`;

export const FormLabel = styled.label`
display: flex;
flex-direction: column;
justify-content: center;
width: 80%;
`;
export const FormInput = styled.input`
height: 30px;
border-bottom-left-radius: 3px;
border-top-right-radius: 3px;
width: 100%;

`;
export const FormButton = styled.button`
height: 30px;
width: 80%;

`;