//INSERIR TODAS AS ROTAS DO USUÁRIO QUE NÃO PRECISAM ESTAR LOGADO

import React from 'react';
import { Routes, Route } from "react-router-dom";

import Login from "../pages/Authentication/Login2";
import ForgetPassword from "../pages/Authentication/ForgetPassword";

export default function AppRoutes() {
  return (
    <Routes>
      <Route index path="/" element={<Login />}  />
      <Route index path="/esqueceu-senha" element={<ForgetPassword />}  />
      <Route path="*" element={<Login />} />
    </Routes>
  );
}
