import React, { useState, useEffect } from "react";
import { useMemo } from "react";
import { Card, CardBody, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import TableContainer from "../../../components/Common/TableContainer";
import Spinners from "components/Common/Spinner";

const WalletActivities = ({ Trackings,influencer, isLoading, setLoading,  }) => {
  const [activeTab, setActiveTab] = useState("all");
const track = influencer.username


  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "Period",
        filterable: true,
        isSort: true,
      },
      {
        Header: "TrackingCode",
        accessor: "ACID",
        filterable: true,
        isSort: false,
      },
      {
        Header: "Registrations",
        accessor: "Registrations",
        filterable: true,
        isSort: true,
      },
      {
        Header: "Deposits",
        accessor: "Deposits",
        filterable: true,
        isSort: true,
      },
      {
        Header: "FTD",
        accessor: "FirstDepositCount",
        filterable: true,
        isSort: true,
      },  
      {
        Header: "CPACommission",
        accessor: "CPACommission",
        filterable: true,
        isSort: false,
      },
      {
        Header: "RevCommission",
        accessor: "PercentCommission",
        filterable: true,
        isSort: true,
      },
      {
        Header: "Commission",
        accessor: "TotalCommission",
        filterable: true,
        isSort: true,
      },

      {
        Header: "CPA",
        accessor: "CPACount",
        filterable: true,
        isSort: true,
      },
      {
        Header: "Visitors",
        accessor: "Clicks",
        filterable: true,
        isSort: true,
      }, 
    ],
    []
  );

  return (
    <Card>
      <CardBody>
        <h4 className="card-title mb-4">Activities</h4>
        {isLoading ? (
          <Spinners setLoading={setLoading} />
        ) : (
          <>
            <ul className="nav nav-tabs nav-tabs-custom">
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "all",
                  })}
                  onClick={() => {
                    toggleTab("all");
                  }}
                >
                  All
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "Buy",
                  })}
                  onClick={() => {
                    toggleTab("Buy");
                  }}
                >
                  Buy
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "Sell",
                  })}
                  onClick={() => {
                    toggleTab("Sell");
                  }}
                >
                  Sell
                </NavLink>
              </NavItem>
            </ul>
            <div className="mt-4">
              <TableContainer
                tableClass="table-hover dt-responsive nowrap dataTable no-footer dtr-inline"
                columns={columns}
                data={Trackings}
                track={track}
                // data={activeTab !== "all" ? products.filter(product => product.type === activeTab) : products}
                isGlobalFilter={true}
                customPageSize={10}
                isPagination={true}
                isShowingPageLength={true}
                iscustomPageSizeOptions={true}
                paginationDiv="col-sm-12 col-md-7"
                pagination="pagination justify-content-end pagination-rounded"
              />
            </div>
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default WalletActivities;
