import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import withRouter from "components/Common/withRouter";
import "assets/scss/datatables.scss";
import Spinners from "components/Common/Spinner"
import { FaPen } from "react-icons/fa";
import ModalMeta from "../../../components/Modals/updateMetaAdvisor"

import { AuthContext } from "contexts/auth";

import Breadcrumbs from "components/Common/Breadcrumb";
import Profile from "./profile";
import Pagamento from "./pagamento";
import GraficoAcompanhamento from "./graficoAcompanhamento"
import TabelaTrack from "./graficoTracking"
import api from "config/api";

const Tracking = () => {
  const { id } = useParams();
  const {user} = useContext(AuthContext);
  const [isLoading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [advisorDetails, setAdvisorDetails] = useState({});
  const [trackingGeral, setTrackingGeral] = useState([]);
  const [depositos, setDepositos] = useState();
  const [firstDeposits, setFirstDeposits] = useState();
  const [rev, setRev] = useState();
  const [cpa, setCpa] = useState();
  const [commission, setCommission] = useState();

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleMetaUpdate = (newMeta) => {
    setAdvisorDetails(prevState => ({
      ...prevState,
      meta: newMeta,
    }));
  };
  
  useEffect(() => {
    const fetchAdvisorDetails = async () => {
      try {
        const response = await api.get(`/assessor/${id}`, {
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          }
        })
        const data = response.data;
        
        setAdvisorDetails(data);
        setDepositos(data.depositos);
        setFirstDeposits(data.firstDeposits);
        setRev(data.rev);
        setCpa(data.cpa);
        setCommission(data.commission);
      } catch (error) {
        console.error("Erro ao buscar os detalhes do assessor:", error);
      } finally {
        setLoading(false);
      }
    };
    
    fetchAdvisorDetails();
  }, [id, user.token]);

  const wallet = {
    series: [
      { type: "area", name: "Depósitos", data: depositos },
      { type: "area", name: "FTDs", data: firstDeposits },
      { type: "area", name: "Commission Rev", data: rev },
      { type: "line", name: "Commission CPA", data: cpa },
      { type: "line", name: "Commission Total", data: commission },
    ],
    options: {
      chart: { toolbar: { show: false } },
      dataLabels: { enabled: false },
      stroke: { curve: "smooth", width: 2, dashArray: [0, 0, 3] },
      fill: { type: "solid", opacity: [0.15, 0.05, 1] },
      xaxis: {
        categories: [
          "Ago", "Set", "Out", "Nov", "Dez", "Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul"
        ],
      },
      colors: ["#f1b44c", "#3452e1", "#187623", "#50a5f1"],
    },
  };

  const totalFirstDepositCount = advisorDetails.trackingsAll?.total?.totalFirstDepositCount || 0;
  const totalClicks = advisorDetails.trackingsAll?.total?.totalClicks || 0;
  const totalRegistrations = advisorDetails.trackingsAll?.total?.totalRegistrations || 0;
  const trackingAll = advisorDetails.trackingsAll?.daily || 0;
  const trackings = advisorDetails.allTrackings || 0;

  

  return (
    <React.Fragment>
      <div className="page-content">
      {isLoading ? (
          <Row>
            <Col xl="12">
              <div className="text-center my-3">
                <div className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                  Carregando...
                </div>
              </div>
            </Col>
          </Row>
        ) : (
        <Container fluid>
          <Breadcrumbs title="Estatísticas" breadcrumbItem="Influencer" />

            <Row>
              <Col xl="6">
               <Profile
                  data={advisorDetails}
                /> 
              </Col>
              <Col xl="6">

            
                <Row>
                  <Col sm="6">
                  <Card className="mini-stats-wid">
        <CardBody>
          <div className="d-flex justify-content-between">
            <div className="me-3 align-self-center">
              <i className="bx bxs-dollar-circle h2 text-primary mb-0" />
            </div>
            <div className="flex-grow-1">
              <p className="text-muted mb-2">Meta {advisorDetails.meta} FTD's</p>
              <h5 className="mb-0">
                {advisorDetails.meta
                  ? `${((totalFirstDepositCount / advisorDetails.meta) * 100).toFixed(2)}%`
                  : "Sem meta definida"}
              </h5>
            </div>
            <div className="align-self-center">
              <FaPen
                onClick={toggleModal}
                style={{ cursor: "pointer" }}
                className="text-muted"
              />
            </div>
          </div>
        </CardBody>
      </Card>

      <ModalMeta
        id={advisorDetails.id}
        observation={advisorDetails.meta}
        controlModal={isModalOpen}
        toggle={toggleModal}
        onChangeValueObservation={handleMetaUpdate}
        advisor={advisorDetails.name}
      />
                  </Col>
                  <Col sm="6">            
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="me-3 align-self-center">
                          <i className="mdi mdi-cursor-default-click-outline text-primary h1" />
                          </div>
                          <div className="flex-grow-1">
                            <p className="text-muted mb-2">Cliques Totais</p>
                            <h5 className="mb-0">
                            {totalClicks.toLocaleString()}                      
                            </h5>
                          </div>
                        </div>                      
                      </CardBody>
                    </Card>  
                  </Col>

                    
                </Row>

                  <Row>
                    <Col sm="6">            
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="me-3 align-self-center">
                          <i className="mdi mdi-account-plus text-primary h1" />
                          </div>
                          <div className="flex-grow-1">
                            <p className="text-muted mb-2">Registros</p>
                            <h5 className="mb-0">
                            {totalRegistrations.toLocaleString()}                      
                            </h5>
                          </div>
                        </div>                      
                      </CardBody>
                    </Card>  
                  </Col>

                  <Col sm="6">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="me-3 align-self-center">
                          <i className="mdi mdi-bank-transfer-in text-primary h1" />
                          </div>
                          <div className="flex-grow-1">
                            <p className="text-muted mb-2">Primeiro Depósito</p>
                            <h5 className="mb-0">
                            {totalFirstDepositCount.toLocaleString()}
                            </h5>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>

                  <Row>
                    <Col xl="12">
                    <Pagamento data={advisorDetails}/>
                    </Col>
                  </Row>


            <Col xl="12">
            <Card>
              <CardBody>
                <div className="d-sm-flex flex-wrap">
                  <h4 className="card-title mb-4">
                    Gráfico de acompanhamento
                  </h4>
                  <div className="ms-auto">
                      </div>
                      </div>

                  <GraficoAcompanhamento
                  advisorDetails={trackingAll}
                  dataColors='["--bs-primary", "--bs-warning", "--bs-success"]'
                  />
                </CardBody>
              </Card>
            </Col>

            <Col xl="12">
              <TabelaTrack trackingAll={trackings}/>
            </Col>

        </Container>
      )}
      </div>
    </React.Fragment>
  );
};

export default withRouter(Tracking);
