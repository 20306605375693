import React, { useState, useContext } from "react";
import {
  Label,
  Input,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
} from "reactstrap";
import Select from "react-select";
import { AuthContext } from "contexts/auth";
import api from "config/api"

const ModalCreateInfluencer = ({ modal, toggle }) => {
  const [valor, setValor] = useState('');
  const { user } = useContext(AuthContext);

  const handleValorChange = (e) => {
    let value = e.target.value.replace(/\D/g, "");
    if (value) {
      const formattedValue = (parseFloat(value) / 100).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
      setValor(formattedValue);
    } else {
      setValor("");
    }
  };

  const classificacaoOptions = [
    { value: 'entrada', label: 'Entrada' },
    { value: 'saida', label: 'Saída' }
  ];

  const tipoOptions = [
    { value: 'custos', label: 'Custos' },
    { value: 'despesas', label: 'Despesas' }
  ];

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle} tag="h4">
        Adicionar Informações
      </ModalHeader>
      <ModalBody>
        <Form>
          <Row>
            <Col xs={6}>
              <div className="mb-3">
                <Label className="form-label">Valor</Label>
                <Input
                  type="text"
                  name="valor"
                  placeholder="Digite o valor"
                  value={valor}
                  onChange={handleValorChange}
                />
              </div>
            </Col>

            <Col xs={6}>
              <div className="mb-3">
                <Label className="form-label">Setor</Label>
                <Input
                  name="setor"
                  type="text"
                  placeholder="Descreva o setor"
                />
              </div>
            </Col>

            <Col xs={6}>
              <div className="mb-3">
                <Label className="form-label">Data</Label>
                <Input
                  name="data"
                  type="date"
                />
              </div>
            </Col>

            <Col xs={6}>
              <div className="mb-3">
                <Label className="form-label">Responsável</Label>
                <Input
                  name="responsavel"
                  type="text"
                  placeholder="Escreva seu Nome"
                  value={user.fullname}
                />
              </div>
            </Col>

            <Col xs={12}>
              <div className="mb-3">
                <Label className="form-label">Detalhes</Label>
                <Input
                  name="detalhes"
                  type="text"
                  placeholder="Informação detalhada"
                />
              </div>
            </Col>

            <Col xs={6}>
              <div className="mb-3">
                <Label className="form-label">Classificação</Label>
                <Select
                  className="select2"
                  placeholder="Selecione..."
                  options={classificacaoOptions}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                />
              </div>
            </Col>

            <Col xs={6}>
              <div className="mb-3">
                <Label className="form-label">Tipo</Label>
                <Select
                  className="select2"
                  placeholder="Selecione o tipo"
                  options={tipoOptions}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="text-end">
                <button
                  type="submit"
                  className="btn btn-info save-user"
                  style={{ marginTop: "5px" }}
                >
                  Adicionar
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ModalCreateInfluencer;
