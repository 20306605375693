import React, { useContext, useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap"

import { AuthContext } from "contexts/auth"
import api from "../../../config/api"

// Import Images
import avatar1 from "../../../assets/images/users/avatar-1.jpg"

function CardUser(props) {
  const { user } = useContext(AuthContext)
  const [captacao, setCaptacao] = useState(0);
  const [grupos, setGrupos] = useState(0);
  const [hunters, setHunters] = useState(0);
  const [diretoria, setDiretoria] = useState(0);

  useEffect(() => {
    async function getData() {
      try {
        const response = await api.get("/users/all", {
          headers: {
            Authorization: `Bearer ${user.token}`
          }
        });
  
        let cap = [];
        let gru = [];
        let hun = [];
        let diret = [];
  
        response.data.forEach((element) => {
          if (element.active) { 
            if (element.type_user.type === "Captação") {
              cap.push(element);
            } else if (element.type_user.type === "Grupos") {
              gru.push(element);
            } else if (element.type_user.type === "Hunter") {
              hun.push(element);
            } else if (element.type_user.type === "Diretoria") {
              diret.push(element);
            }
          }
        });
  
        setCaptacao(cap.length);
        setGrupos(gru.length);
        setHunters(hun.length);
        setDiretoria(diret.length);
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
    }
  
    getData();
  }, []);
    

  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default CardUser
