import api from "config/api"
import Toastr from "../../../../components/Ui/Notifications"
import axios from "axios"

export async function getUserGrupos({ token, actionUserGrupos }) {
  await api
    .get("/users/api/3", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(response => {
      actionUserGrupos(response.data)
    })
    .catch(error => {
      console.error(error)
    })
}

export async function onSubmitInfluencers(
  phone,
  email,
  username,
  status,
  captacao,
  methodContact,
  Register
) {
  try {
    await axios
      .post("https://hook.us1.make.com/79fv3nry9ifo1552od9q2s2monmt45od", {
        username: username,
        phone: phone ? phone : null,
        email: email,
        method_contact: methodContact,
        status: parseInt(status),
        captacao: captacao,
      })
      .then(response => {
        Register()
      })
      .catch(error => {
        Toastr("error", "Algo errado na requisição...")
        console.error(error)
      })
  } catch (error) {
    console.error("Error", error)
  }
}

export async function onSubmitInfluencersEdit(
  token,
  id,
  phone,
  email,
  fullname,
  photo,
  username,
  following,
  followers,
  status,
  Updated,
  methodContact,
  finalObservation
) {
  await api
    .put(
      "/influencers/update",
      {
        id: id,
        phone: phone ? phone : null,
        email: email,
        fullname: fullname,
        photo: `${photo}`,
        username: username,
        following: `${following}`,
        followers: `${followers}`,
        method_contact: methodContact,
        status: status,
        observation: finalObservation,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(response => {
      Updated()
    })
    .catch(error => {
      console.error("Error", error)
      Toastr("error", "Influencer já existe!")
    })
}

export async function handleDeleteUser(id, token, handledelete) {
  try {
    await api
      .delete(`/influencers/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        Toastr("success", "Influencer deletado!")
        setTimeout(() => {}, 3000)
      })
      .catch(error => {
        console.error("Erro ao excluir influenciador:", error)
      })
  } catch (error) {
    console.error("Erro ao excluir influenciador:", error)
  }
}

export async function fetchInfluencerData(username, fetchInfluencer) {
  try {
    await api
      .get(`/influencer/${username}`)
      .then(response => {
        const influencerData = response.data
        fetchInfluencer(influencerData)
      })
      .catch(error => {
        Toastr("error", "Influenciador não encontrado")
      })
  } catch (error) {
    console.error("Erro ao buscar dados do influenciador:", error)
  }
}

export async function postAlterStatus(id_influencer, token, status) {
  try {
    await api.put(
      "/influencers/update/status",
      {
        id: id_influencer,
        status: status,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
  } catch (error) {
    console.error("Erro:", error)
    throw error
  }
}

export async function postbankData(
  id_influencer,
  token,
  name,
  cpf,
  type,
  pix,
  value,
  influencer
) {
  try {
    await api.post(
      "/bankdata",
      {
        id: id_influencer,
        name: name,
        cpf: cpf,
        type: type,
        pix: pix,
        value: value,
        influencer,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
  } catch (error) {
    if (error.response.status === 409) {
      Toastr(
        "error",
        "Dados bancários",
        "Este Pix já está cadastrado no sistema!"
      )
    } else {
      Toastr("error", "Dados bancários", "Algo errado nos dados do Pix...")
      console.error("Error Bank", error.message)
    }
  }
}

export async function postplugchat(
  token,
  link,
  id_influencer,
  responsavel,
  date_init,
  hour_init
) {
  try {
    await api.post(
      "/plugchat",
      {
        linkPlug: link,
        influencer: id_influencer,
        responsavel: responsavel,
        date_init: date_init,
        hour_init: hour_init,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
  } catch (error) {
    Toastr("error", "Algo errado nos dados do plugchat...")
    console.error("Error Plugchat", error.message)
  }
}

export async function FecharInfluencer(
  id_influencer,
  token,
  status,
  name,
  cpf,
  type,
  pix,
  value,
  link,
  responsavel,
  data,
  hora,
  username,
  username_number,
  name_responsavel,
  confirm,
  toogle,
  updateInfluencers,
  campaignOptions,
  descricao
) {
  if (username_number === null || username_number === "") {
    Toastr("error", "Telefone não informado!")
  } else {
    await api
      .post(
        "/bankdata",
        {
          name: name,
          cpf: cpf,
          type: type,
          pix: pix,
          value: value,
          influencer: id_influencer,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(async () => {
        await api
          .post("https://hook.us1.make.com/vub87s04kstjcntoxpmd67z4em1cnnr7", {
            username: username,
            username_number: username_number,
            linkPlug: link,
            influencer: id_influencer,
            reponsavel: responsavel,
            name_responsavel: name_responsavel,
            date_init: data,
            hour_init: hora,
            message: "Um novo influencer foi atribuido a você!",
          })
          .then(async () => {
            await api
              .put(
                "/influencers/update/status/campaign",
                {
                  id: id_influencer,
                  status: status,
                  campaigns: campaignOptions,
                  observation: descricao,
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then(response => {
                confirm()
                toogle()
                updateInfluencers(response.data.id, 8)
                Toastr("success", "Fechado com sucesso!")
              })
          })
          .catch(error => {
            Toastr("error", "Algo errado nos dados do plugchat...")
            console.error("Error Plugchat", error)
          })
      })
      .catch(error => {
        Toastr("error", "Dados bancários", "Algo errado nos dados do Pix...")
        console.error("Error Bank", error.message)
      })
  }
}

export async function FecharInfluencerClone(
  id_influencer,
  token,
  status,
  name,
  cpf,
  type,
  pix,
  value,
  link,
  responsavel,
  data,
  hora,
  username,
  username_number,
  name_responsavel,
  confirm,
  toogle,
  updateInfluencers,
  campaignOptions,
  descricao,
  fixo,
  meta,
  valueFTD,
  valueExcedente,
  dataInicioMeta,
  dataFinalMeta,
  email,
  isDailyPayment,
  isAdvanceValue,
  advanceValue,
  createPlugChat,
  selectAssessor
) {
  if (!username_number) {
    Toastr("error", "Telefone não informado!")
    return
  }

  const apiHeaders = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const bankData = {
    name,
    cpf,
    type,
    pix,
    value,
    influencer: id_influencer,
  }

  const plugchatData = {
    link,
    linkPlug: link,
    influencer: id_influencer,
    responsavel: "4430840d-65cb-4dcf-84c7-4b3f09ee1e19",
    date_init: data,
    hour_init: hora,
  }

  const plugchatDataMaker = {
    username: username,
    username_number: username_number,
    linkPlug: link,
    influencer: id_influencer,
    reponsavel: responsavel,
    name_responsavel: name_responsavel,
    date_init: data,
    hour_init: hora,
    message: "Um novo influencer foi atribuido a você!",
  }

  const statusData = {
    id: id_influencer,
    status,
    campaigns: campaignOptions,
    observation: descricao,
    isPaymentFixed: fixo,
    assessor: selectAssessor === "null" ? null : selectAssessor
  }

  try {
    await api.post("/bankdata", bankData, apiHeaders)
  } catch (error) {
    Toastr("error", "Algo errado nos dados do Pix...", error.message)
    console.error("Error Bank", error.message)
    return
  }
  if (createPlugChat === 'true') {
    try {
      await api.post(
        "https://hook.us1.make.com/vub87s04kstjcntoxpmd67z4em1cnnr7",
        plugchatDataMaker
      )
    } catch (error) {
      Toastr("error", "Algo errado nos dados do plugchat...", error)
      console.error("Error Plugchat", error)
      return
    }
  } else {
    try {
      await api.post("/plugchat", plugchatData, apiHeaders);
    } catch (error) {
      Toastr("error", "Algo errado nos dados do Pix...", error.message);
      console.error("Error Bank", error.message);
      return;
    }
  }


  try {
    const response = await api.put(
      "/influencers/update/status/campaign",
      statusData,
      apiHeaders
    )
    confirm()
    toogle()
    updateInfluencers(response.data.id, 8)
    Toastr("success", "Fechado com sucesso!")
  } catch (error) {
    Toastr("error", "Algo errado na mudança de status...", error)
    console.error("Error Status", error)
    return
  }

  if (!fixo) {
    try {
      await api.post(
        "/profile/influencer",
        { influencer: id_influencer, email },
        apiHeaders
      )

      await api.post(
        "/profile/influencer/meta",
        {
          influencer: id_influencer,
          meta,
          valueMin: valueFTD,
          valueBonus: valueExcedente,
          dateInit: dataInicioMeta,
          dateFinal: dataFinalMeta,
          isDailyPayment: isDailyPayment,
          isAdvanceValue: isAdvanceValue,
          advanceValue: advanceValue,
        },
        apiHeaders
      )

      Toastr("success", "Perfil do Influenciador criado!")
    } catch (error) {
      Toastr("error", "Erro ao criar perfil do influenciador...", error)
      console.error("Error Profile", error)
    }
  } 

}
