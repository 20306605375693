export function sortearComMenorPeso(elementos) {
  // Ordenar os elementos com base no peso (do menor para o maior)

  const elementosOrdenados = elementos.sort((a, b) => a.plug_chat.length - b.plug_chat.length);

  const menorPeso = elementosOrdenados[0].plug_chat.length;

  const elementosComMenorPeso = elementosOrdenados.filter((elemento) => elemento.plug_chat.length === menorPeso);

  const indiceSorteado = Math.floor(Math.random() * elementosComMenorPeso.length);
  // Retornar o elemento sorteado
  return elementosComMenorPeso[indiceSorteado];
}