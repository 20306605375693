import React, { useEffect, useState, useContext } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Card, Col, Container, Row, Input } from "reactstrap"
import { map } from "lodash"
import { AuthContext } from "../../../contexts/auth"
import Breadcrumbs from "components/Common/Breadcrumb"
import CardTracking from "./CardTracking"
import api from "../../../config/api"

const ListTracking = () => {
  const { user } = useContext(AuthContext)
  const [trackings, setTrackings] = useState([])
  const [trackingsOriginal, setTrackingsOriginal] = useState([])
  const [loading, setLoading] = useState(true)
  const [searchUsername, setSearchUsername] = useState("")
  const [sortBy, setSortBy] = useState("")
  const [filteredTrackings, setFilteredTrackings] = useState([])
  const [filteredArchive, setFilteredArchive] = useState(null)
  const [selectedMonth, setSelectedMonth] = useState(null)
  const [selectedMonthFormated, setSelectedMonthFormated] = useState()
  const [sortDirection, setSortDirection] = useState("asc")
  const [selectedDay, setSelectedDay] = useState(null)
  const [selectedDayFormatted, setSelectedDayFormatted] = useState()

  useEffect(() => {
    setLoading(true)
    async function getTrackings() {
      try {
        const response = await api.get("/tracking/superbet/geral/all", {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        })

        const updatedTrackings = await Promise.all(
          response.data.map(async tracking => {
            const photoUrl = await verificarImagem(tracking.photo)
            return { ...tracking, photo: photoUrl }
          })
        )
        setTrackings(updatedTrackings)
        setTrackingsOriginal(updatedTrackings)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }

    getTrackings()
  }, [user.token])

  useEffect(() => {
    let filtered = trackings

    if (searchUsername) {
      filtered = filtered.filter(tracking =>
        tracking.username.toLowerCase().includes(searchUsername.toLowerCase())
      )
    }

    if (filteredArchive !== null) {
      filtered = filtered.filter(
        tracking => tracking.archive === filteredArchive
      )
    }

    if (selectedMonthFormated && selectedMonthFormated !== "undefined/") {
      let temp = []

      for (let index = 0; index < filtered.length; index++) {
        // Filtrando os dados mensais
        filtered[index].Mensal.forEach(element => {
          if (element.Period === selectedMonthFormated) {
            temp.push({
              Total: element,
              archive: filtered[index].archive,
              company: filtered[index].company,
              fullname: filtered[index].fullname,
              photo: filtered[index].photo,
              plugchat: filtered[index].plugchat,
              posts: filtered[index].posts,
              username: filtered[index].username,
              isDailyPayment: filtered[index].isDailyPayment,
              isAdvanceValue: filtered[index].isAdvanceValue,
              assessor: filtered[index].assessor,
            })
          }
        })

        // Filtrando os dados diários
        filtered[index].Diario.forEach(element => {
          if (element.Period === selectedDayFormatted) {
            temp.push({
              Total: element,
              archive: filtered[index].archive,
              company: filtered[index].company,
              fullname: filtered[index].fullname,
              photo: filtered[index].photo,
              plugchat: filtered[index].plugchat,
              posts: filtered[index].posts,
              username: filtered[index].username,
              isDailyPayment: filtered[index].isDailyPayment,
              isAdvanceValue: filtered[index].isAdvanceValue,
              assessor: filtered[index].assessor,
            })
          }
        })
      }

      filtered = temp
    }

    if (sortBy) {
      let sortedTrackings = [...filtered]

      if (sortBy === "ValorInvestido" || sortBy === "FirstDepositCount") {
        sortedTrackings = sortedTrackings.filter(
          tracking =>
            tracking.Total[sortBy] !== undefined &&
            tracking.Total[sortBy] !== null &&
            tracking.Total[sortBy] !== 0
        )

        sortedTrackings.sort((a, b) => {
          const valueA = a.Total[sortBy]
          const valueB = b.Total[sortBy]

          if (sortDirection === "asc") {
            return valueA - valueB
          } else {
            return valueB - valueA
          }
        })
      } else {
        sortedTrackings.sort((a, b) => b.Total[sortBy] - a.Total[sortBy])
      }
      setFilteredTrackings(sortedTrackings)
    } else {
      setFilteredTrackings(filtered)
    }
  }, [
    trackings,
    searchUsername,
    filteredArchive,
    selectedMonthFormated,
    sortBy,
    sortDirection,
    selectedDayFormatted,
  ])
  const handleSortChange = event => {
    setSortBy(event.target.value)
  }

  const handleFilterArchive = event => {
    const selectedValue = event.target.value

    let archiveFilter = null
    let filterFn = tracking => true

    if (selectedValue === "true") {
      archiveFilter = true
      filterFn = tracking => tracking.archive === archiveFilter
    } else if (selectedValue === "false") {
      archiveFilter = false
      filterFn = tracking => tracking.archive === archiveFilter
    } else if (selectedValue === "Diário") {
      filterFn = tracking => tracking.isDailyPayment === true
    } else if (selectedValue === "Antecipado") {
      filterFn = tracking => tracking.isAdvanceValue === true
    } else if (selectedValue === "assessor") {
      filterFn = tracking =>
        tracking.assessor && Object.keys(tracking.assessor).length > 0
    }
    const filtered = trackings.filter(filterFn)
    setFilteredTrackings(filtered)
  }

  const handleMonthChange = event => {
    const selectedDate = event.target.value

    if (!selectedDate) {
      setFilteredTrackings(trackingsOriginal)
      return
    }

    const [year, month] = selectedDate.split("-")
    const formattedMonth = `${year}-${parseInt(month)}`
    setSelectedMonth(event.target.value)
    setSelectedMonthFormated(formattedMonth)
  }

  const handleDayChange = event => {
    const selectedDate = event.target.value

    if (!selectedDate) {
      setFilteredTrackings(trackingsOriginal)
      setSelectedDayFormatted(null)
      setSelectedMonthFormated(null)
      return
    }

    const [year, month, day] = selectedDate.split("-")

    const formattedDay = `${year}-${parseInt(month)}-${parseInt(day)}`
    const formattedMonth = `${parseInt(year)}/${parseInt(month)}`

    setSelectedDay(event.target.value)
    setSelectedDayFormatted(formattedDay)
    setSelectedMonthFormated(formattedMonth)
  }

  const toggleSortDirection = () => {
    const newDirection = sortDirection === "asc" ? "desc" : "asc"
    setSortDirection(newDirection)
  }

  async function verificarImagem(photoUrl) {
    try {
      const response = await fetch(photoUrl, { method: "HEAD" })
      if (response.status === 200) {
        return photoUrl
      } else {
        return "https://i.ibb.co/y6nkQKW/461085513-1715142105981321-1128849984098454117-n.jpg"
      }
    } catch (error) {
      return "https://i.ibb.co/y6nkQKW/461085513-1715142105981321-1128849984098454117-n.jpg"
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Estatísticas" breadcrumbItem="Tracking Codes" />

          <Row>
            <Col xs="3" className="mb-3">
              <Input
                type="text"
                value={searchUsername}
                onChange={e => setSearchUsername(e.target.value)}
                placeholder="Pesquisar por @"
              />
            </Col>
            <Col xs="3" className="mb-3">
              <Input value={sortBy} onChange={handleSortChange} type="select">
                <option value="">Sem ordenação</option>
                <option value="Clicks">Cliques</option>
                <option value="Registrations">Registros</option>
                <option value="Deposits">Depósitos</option>
                <option value="ValorInvestido">Investimento</option>
                <option value="FirstDepositCount">FTD</option>
              </Input>
            </Col>

            {sortBy === "ValorInvestido" || sortBy === "FirstDepositCount" ? (
              <>
                <Col xs="2" className="mb-3">
                  <Input
                    value={sortDirection}
                    onChange={toggleSortDirection}
                    type="select"
                  >
                    <option value="asc">Crescente</option>
                    <option value="desc">Decrescente</option>
                  </Input>
                </Col>
              </>
            ) : null}

            <Col xs="2" className="mb-3">
              <Input
                value={filteredArchive}
                onChange={handleFilterArchive}
                type="select"
              >
                <option value="">Todos</option>
                <option value="false">Ativos</option>
                <option value="true">Arquivados</option>
                <option value="Diário">Diário</option>
                <option value="Antecipado">Antecipado</option>
                <option value="assessor">Assessor</option>
              </Input>
            </Col>
            <Col xs="2" className="mb-3">
              <Input
                value={selectedMonth}
                onChange={handleMonthChange}
                type="month"
              />
            </Col>
            <Col xs="2" className="mb-3">
              <Input
                value={selectedDay}
                onChange={handleDayChange}
                type="date"
              />
            </Col>
          </Row>

          {loading ? (
            <Row>
              <Col xs="12">
                <div className="text-center my-3">
                  <Link to="#" className="text-success">
                    <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                    Carregando...
                  </Link>
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
              {map(filteredTrackings, (tracking, key) => (
                <CardTracking trackings={tracking} key={"_track_" + key} />
              ))}
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ListTracking
