import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Form, Row, Input, Label, FormFeedback } from "reactstrap";

import {AuthContext} from "../../contexts/auth";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// Importar imagens
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";
import CarouselPage from "./CarouselPage";

const Login2 = () => {
  const {login, thema} = useContext(AuthContext);
  
  const [passwordShow, setPasswordShow] = useState(false);

  // Título da página

  // Validação do formulário
  const validation = useFormik({
   
    enableReinitialize: true,

    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Por favor, insira seu email de usuário"),
      password: Yup.string().required("Por favor, insira sua senha"),
    }),
    onSubmit: (values) => {
      const email = values.username.toLowerCase();
      const password = values.password;
      login(email, password);
    }
  });

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />

            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="my-auto">
                      <div>
                    <div className="mb-4 mb-md-5" style={{ marginLeft: '55px' }}>
                      <Link to="/dashboard" className="d-block card-logo">
                        <img
                          src={thema === 'dark' ? logolight : logodark}
                          alt=""
                          height="33"
                          className="logo-dark-element"
                          style={{ marginRight: '20px' }}
                        />
                        <img
                          src={logolight}
                          alt=""
                          height="33"
                          className="logo-light-element"
                        />
                      </Link>
                    </div>
                        <h5 className="text-primary">Bem-vindo de volta!</h5>
                        <p className="text-muted">
                          Faça login para continuar na Viraw.
                        </p>
                      </div>

                      <div className="mt-4">
                        <Form
                          className="form-horizontal"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >
                          <div className="mb-3">
                            <Label className="form-label">Email do Usuário</Label>
                            <Input
                              name="username"
                              className="form-control"
                              placeholder="Insira seu email de usuário"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.username || ""}
                              invalid={
                                validation.touched.username &&
                                validation.errors.username
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.username &&
                            validation.errors.username ? (
                              <FormFeedback type="invalid">
                                {validation.errors.username}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <div className="float-end">
                              <Link
                                to="/esqueceu-senha"
                                className="text-muted"
                              >
                                Esqueceu a senha?
                              </Link>
                            </div>
                            <Label className="form-label">Senha</Label>
                            <div className="input-group auth-pass-inputgroup">
                              <Input
                                name="password"
                                value={validation.values.password || ""}
                                type={passwordShow ? "text" : "password"}
                                placeholder="Insira a senha"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.password &&
                                  validation.errors.password
                                    ? true
                                    : false
                                }
                              />
                              <button onClick={() => setPasswordShow(!passwordShow)} className="btn btn-light " type="button" id="password-addon">
                                <i className="mdi mdi-eye-outline"></i></button>
                              
                            </div>
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="form-check">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="auth-remember-check"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="auth-remember-check"
                            >
                              Lembrar de mim
                            </label>
                          </div>

                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-primary btn-block "
                              type="submit"
                            >
                              Entrar
                            </button>
                          </div>
                        </Form>

             
                      </div>
                    </div>

                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0 text-muted">
                        © {new Date().getFullYear()} Viraw Platform. {" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Login2;