import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { map } from "lodash";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//Import Card invoice
import CardInvoice from "./card-invoice";
import Spinners from "components/Common/Spinner";

export const InvoicesList = () => {
  document.title = "Invoice List | Skote - React Admin & Dashboard Template";

  const dummyInvoices = [
    {
      username: 'carlaborges2',
      company: "Aposta Ganha",
      invoicePrice: 1000,
      date: "2024-04-01",
      photoUrl: "https://fly.metropoles.cloud/upload/q_85,w_600/https://uploads.metropoles.cloud/wp-content/uploads/2023/08/16080507/gettyimages-1246653264-612x612-1.jpeg"
    },
    {
      username: 'emily',
      company: "Aposta Ganha",
      invoicePrice: 1500,
      date: "2024-04-05",
      photoUrl: "https://fly.metropoles.cloud/upload/q_85,w_600/https://uploads.metropoles.cloud/wp-content/uploads/2023/08/16080507/gettyimages-1246653264-612x612-1.jpeg"
    },
  ];

  const [isLoading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    setLoading(true);

    const timer = setTimeout(() => {
      setInvoices(dummyInvoices);
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <React.Fragment>
         <div className="page-content">
              <Container fluid>
                {/* Render Breadcrumb */}
                <Breadcrumbs title="Estatísticas" breadcrumbItem="Tracking Codes" />
                {isLoading ? (
          <Row>
          <Col xs="12">
            <div className="text-center my-3">
              <Link to="#" className="text-success">
                <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                Carregando...
              </Link>
            </div>
          </Col>
        </Row>         
    ) : (
            <>
              {invoices && invoices.length > 0 ? (
                <Row>
                  {map(invoices, (invoice) => (
                    <CardInvoice data={invoice} key={"_invoice_" + invoice.id} />
                  ))}
                </Row>
              ) : (
                <div>No invoices found</div>
              )}
              {/* <Row>
                <Col xs="12">
                  <div className="text-center my-3">
                    <Link to="#" className="text-success">
                      <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                      Carregar mais
                    </Link>
                  </div>
                </Col>
              </Row> */}
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

InvoicesList.propTypes = {
  invoices: PropTypes.array,
  onGetInvoices: PropTypes.func,
};

export default withRouter(InvoicesList);
