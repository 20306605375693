import React from "react"
import AuthProvider from "contexts/auth"
import StateProvider from "contexts/states"

// Import scss
import "./assets/scss/theme.scss"
import Router from "./routes/index"

const App = () => {
  return (
    <AuthProvider>
      <StateProvider>
        <React.Fragment>
          <Router />
        </React.Fragment>
      </StateProvider>
    </AuthProvider>
  )
}

export default App
