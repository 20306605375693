import back1 from './back1.png';

// styles.js
export const laneStyle = {
    backgroundColor: "#32394e",
    overflowY: "auto",
    maxHeight: "70vh",
    padding: "10px",
    borderRadius: "10px",
    color: "#ffffff",
    minWidth: "310px",
  };
  
  export const boardStyle = {
    backgroundImage: `url(${back1})`, 
    backgroundPosition: 'center',
  };