import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../../components/Common/ChartsDynamicColor";

const SalesAnalytics = ({ dataColors, dashboard, values }) => {
  const apexsalesAnalyticsChartColors = getChartColorsArray(dataColors);
/*   labels: ["Em contato", "Negociando", "Sem sucesso", "Fechado"],
 */

  const options = {
    labels: ["Primeira abordagem", "Conversa iniciada", "Convites de WhatsApp", "WhatsApp captado", "WhatsApp respondidos", "Follow Up", "Sem sucesso", "Fechados"],
    colors: apexsalesAnalyticsChartColors,
    legend: { show: !1 },
    plotOptions: {
      pie: {
        donut: {
          size: "80%",
        },
      },
    },
  };

  return (
    <React.Fragment>
     {
      dashboard ? (
        <Col md={6}>
        <Card>
          <CardBody>
            <h4 className="card-title mb-4">Influencers</h4>
            <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">

            <div>
              <div id="donut-chart">
                <ReactApexChart
                  options={options}
                  series={values}
                  type="donut"
                  height={260}
                  className="apex-charts"
                />
              </div>
            </div>

            <div className="text-left text-muted">
           
                <Col xs="12">
                  <div className="mt-4">
                    <h5 className="mb-2 text-truncate text-muted">
                      <i className="mdi mdi-circle text-info me-1" />Primeira abordagem: <strong>{values[0]}</strong>
                    </h5>
                  </div>
                </Col>
                <Col xs="12">
                  <div className="mt-4">
                    <h5 className="mb-2 text-truncate text-muted">
                      <i className="mdi mdi-circle text-primary me-1" />Conversa iniciada: <strong>{values[1]}</strong>                     
                    </h5>
                  </div>
                </Col>
                <Col xs="12">
                  <div className="mt-4">
                    <h5 className="mb-2 text-truncate text-muted">
                      <i style={{color: "#fd7e14"}} className="mdi mdi-circle me-1" />Convites de WhatsApp: <strong>{values[2]}</strong>
                    </h5>
                  </div>
                </Col>
                <Col xs="12">
                  <div className="mt-4">
                    <h5 className="mb-2 text-truncate text-muted">
                      <i style={{color: "#B0E0E6"}} className="mdi mdi-circle me-1" />WhatsApp captado: <strong>{values[3]}</strong>
                    </h5>
                  </div>
                </Col>

                <Col xs="12">
                  <div className="mt-4">
                    <h5 className="mb-2 text-truncate text-muted">
                      <i style={{color: "#6f42c1"}} className="mdi mdi-circle me-1" />WhatsApp respondidos: <strong>{values[4]}</strong>
                    </h5>
                  </div>
                </Col>
                <Col xs="12">
                  <div className="mt-4">
                    <h5 className="mb-2 text-truncate text-muted">
                      <i style={{color: "#e83e8c"}} className="mdi mdi-circle me-1" />Follow Up: <strong>{values[5]}</strong>                     
                    </h5>
                  </div>
                </Col>
                <Col xs="12">
                  <div className="mt-4">
                    <h5 className="mb-2 text-truncate text-muted">
                      <i style={{color: "#FF0000"}} className="mdi mdi-circle me-1" />Sem sucesso: <strong>{values[6]}</strong>
                    </h5>
                  </div>
                </Col>
                <Col xs="12">
                  <div className="mt-4">
                    <h5 className="mb-2 text-truncate text-muted">
                      <i className="mdi mdi-circle text-success me-1" />Fechado: <strong>{values[7]}</strong>
                    </h5>
                  </div>
                </Col>

            </div>


            </div>

          </CardBody>
        </Card>
      </Col>
      ):(
        <Col xl="7">
        <Card>
          <CardBody>
            <h4 className="card-title mb-4">Fechamento Diário</h4>

            <div>
              <div id="donut-chart">
                <ReactApexChart
                  options={options}
                  series={values}
                  type="donut"
                  height={260}
                  className="apex-charts"
                />
              </div>
            </div>

            <div className="text-center text-muted">
              <Row>
                <Col xs="3">
                  <div className="mt-4">
                    <p className="mb-2 text-truncate">
                      <i className="mdi mdi-circle text-primary me-1" /> Potencial
                    </p>
                    <h5>{values[0]}</h5>
                  </div>
                </Col>
                <Col xs="3">
                  <div className="mt-4">
                    <p className="mb-2 text-truncate">
                      <i className="mdi mdi-circle text-danger me-1" />Em contato
                      
                    </p>
                    <h5>{values[1]}</h5>
                  </div>
                </Col>
                <Col xs="3">
                  <div className="mt-4">
                    <p className="mb-2 text-truncate">
                      <i className="mdi mdi-circle text-warning me-1" />Negociando
                    </p>
                    <h5>{values[2]}</h5>
                  </div>
                </Col>
                <Col xs="3">
                  <div className="mt-4">
                    <p className="mb-2 text-truncate">
                      <i className="mdi mdi-circle text-success me-1" />Fechado
                    </p>
                    <h5>{values[3]}</h5>
                  </div>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </Col>
      )
     } 
  
    </React.Fragment>
  );
};

export default SalesAnalytics;
