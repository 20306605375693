import React, {useContext, useState} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from 'reactstrap';
import Toastr from "../../Ui/Notifications";
import api from 'config/api';
import { AuthContext } from 'contexts/auth';

function PlugchatModal({ isOpen, toggle, id }) {
    const {user} = useContext(AuthContext);
    const [plugchatLink, setPlugchatLink] = useState('');
  
    const handleLinkChange = (e) => {
      setPlugchatLink(e.target.value);
    };
  
    const handleSubmit = async() => {
      await api.patch("/plugchat/update/link",{
        id: id,
        link: plugchatLink
      },{
        headers:{
          Authorization: `Bearer ${user.token}`
        }
      }).then((response) => {
        Toastr("success", "Link Atualizado")
      }).catch((error) => {
        console.error(error)
      });
        toggle();
    };

    return (
      <div>
        <Modal isOpen={isOpen} toggle={toggle}>
          <ModalHeader toggle={toggle}>Cadastrar Link do Plugchat</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="plugchatLink">Link do Plugchat</Label>
              <Input
                type="text"
                name="plugchatLink"
                id="plugchatLink"
                placeholder="Insira o link do Plugchat aqui"
                value={plugchatLink}
                onChange={handleLinkChange}
              />
            </FormGroup>
          </ModalBody>
          
          <ModalFooter>
            <Button color="primary" onClick={handleSubmit}>Salvar</Button>{' '}
            <Button color="secondary" onClick={toggle}>Cancelar</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
  
  export default PlugchatModal;