import React, { useState, useContext } from "react";
import api from "config/api";
import { AuthContext } from "../../../contexts/auth";
import { StateContext } from "contexts/states";
import {
  Label,
  Input,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
} from "reactstrap";
import Toastr from "../../../components/Ui/Notifications";

const ModalCreateInfluencer = ({ updateInfluencersList }) => {
  const { user } = useContext(AuthContext);
  const { modal, setModal } = useContext(StateContext);

  const [influencerData, setInfluencerData] = useState({
    email: "",
    fullname: "",
    photo: "",
    type: "",
    pass: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInfluencerData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await api.post("/users", influencerData);
      Toastr("success", "Colaborador cadastrado com sucesso!");
    } catch (error) {
      console.error(error);
      Toastr("error", "Ocorreu um erro");
    } finally {
      setIsSubmitting(false);
      toggle();
    }
  };

  const canCreateAdmin = user.type === 1 && user.type !== 5;

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle} tag="h4">
        Adicionar Colaborador
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xxlxs={6}>
              <div className="mb-3">
                <Label className="form-label">Email</Label>
                <Input
                  name="email"
                  type="email"
                  placeholder="Digite o Email"
                  value={influencerData.email}
                  onChange={handleInputChange}
                />
              </div>

              <div className="mb-3">
                <Label className="form-label">Nome Completo</Label>
                <Input
                  name="fullname"
                  type="text"
                  placeholder="Digite o nome completo"
                  value={influencerData.fullname}
                  onChange={handleInputChange}
                />
              </div>

              <div className="mb-3">
                <Label className="form-label">Foto (Link)</Label>
                <Input
                  name="photo"
                  type="text"
                  placeholder="Cole o link da foto"
                  value={influencerData.photo}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col xs={6}>
              <div className="mb-3">
                <Label className="form-label">Senha</Label>
                <Input
                  name="pass"
                  type="password"
                  placeholder="Digite a senha"
                  value={influencerData.pass}
                  onChange={handleInputChange}
                />
              </div>

              <div className="mb-3">
                <Label className="form-label">Tipo</Label>
                <Input
                  name="type"
                  type="select"
                  value={influencerData.type}
                  onChange={handleInputChange}
                >
                  <option value="">Selecione uma opção</option>
                  {canCreateAdmin && <option value="1">Admin</option>}
                  <option value="6">Diretoria</option>
                  <option value="5">Hunter</option>
                  <option value="7">Comercial</option>
                  <option value="2">Closer</option>
                  <option value="3">Grupos</option>
                  <option value="4">Externo</option>
                </Input>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="text-end">
                <button
                  type="submit"
                  className="btn btn-success save-user"
                  style={{ marginTop: "5px" }}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Enviando..." : "Cadastrar"}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ModalCreateInfluencer;
