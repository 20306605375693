import React, { useEffect, useState, useContext } from 'react';
import { Row, Col, Card, CardBody } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../../components/Common/ChartsDynamicColor";
import { useParams } from "react-router-dom"
import api from "config/api"

import { AuthContext } from "contexts/auth"
import RadialChart from 'pages/Captacao/AllCharts/apex/RadialChart';

const SalesAnalytics = ({ dataColors, dashboard, primeiraAbordagem, conversaIniciada, convitesWhatsapp, whatsappCaptados, whatsappRespondidos, followUp, semSucesso, fechados }) => {
  const apexsalesAnalyticsChartColors = getChartColorsArray(dataColors);
  const { user } = useContext(AuthContext)
  const { id } = useParams()
  const [colaborador, setColaborador] = useState('')
  // const [fechamento, setFechamento] = useState('')
  // const [negociando, setNegociando] = useState('')
  // const [semSucesso, setSemSucesso] = useState('')
  // const [contato, setContato] = useState('')

  const options = {
    labels: ["Primeira abordagem", "Conversa iniciada", "Convites de WhatsApp", "WhatsApp captado", "WhatsApp respondidos", "Follow Up", "Sem sucesso", "Fechados"],
    colors: apexsalesAnalyticsChartColors,
    legend: { show: !1 },
    plotOptions: {
      pie: {
        donut: {
          size: "80%",
        },
      },
    },
  };


  return (
    <React.Fragment>
      {
        dashboard ? (
          <Col>
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">Status total dos Captadores</h4>
              <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
                <Col xs="12" md="6" className="mb-4 mb-md-0">
                  <div id="donut-chart">
                    <ReactApexChart
                      options={options}
                      series={[primeiraAbordagem, conversaIniciada, convitesWhatsapp, whatsappCaptados, whatsappRespondidos, followUp, semSucesso, fechados]}
                      type="donut"
                      height={260}
                      className="apex-charts"
                    />
                  </div>
                </Col>
      
                <Col xs="12" md="6" className="text-left text-muted">
                  <Row>
                    <Col xs="12" className="mt-4">
                      <h6 className="mb-2 text-truncate text-dark">
                        <i className="mdi mdi-circle text-info me-1" />Primeira abordagem: <strong>{primeiraAbordagem}</strong>
                      </h6>
                    </Col>
                    
                    <Col xs="12" className="mt-1">
                      <h6 className="mb-2 text-truncate text-dark">
                        <i className="mdi mdi-circle text-primary me-1" />Conversa iniciada: <strong>{conversaIniciada}</strong>
                      </h6>
                    </Col>
      
                    <Col xs="12" className="mt-1">
                      <h6 className="mb-2 text-truncate text-dark">
                        <i style={{color: '#fd7e14'}} className="mdi mdi-circle me-1" />Convites de WhatsApp: <strong>{convitesWhatsapp}</strong>
                      </h6>
                    </Col>
      
                    <Col xs="12" className="mt-1">
                      <h6 className="mb-2 text-truncate text-dark">
                        <i style={{color: "#B0E0E6"}} className="mdi mdi-circle me-1" />WhatsApp captado: <strong>{whatsappCaptados}</strong>
                      </h6>
                    </Col>
      
                    <Col xs="12" className="mt-1">
                      <h6 className="mb-2 text-truncate text-dark">
                        <i style={{color: '#6f42c1'}} className="mdi mdi-circle me-1" />WhatsApp respondidos: <strong>{whatsappRespondidos}</strong>
                      </h6>
                    </Col>
      
                    <Col xs="12" className="mt-1">
                      <h6 className="mb-2 text-truncate text-dark">
                        <i style={{color: '#e83e8c'}} className="mdi mdi-circle me-1" />Follow Up: <strong>{followUp}</strong>
                      </h6>
                    </Col>
      
                    <Col xs="12" className="mt-1">
                      <h6 className="mb-2 text-truncate text-dark">
                        <i style={{color: '#FF0000'}} className="mdi mdi-circle me-1" />Sem sucesso: <strong>{semSucesso}</strong>
                      </h6>
                    </Col>
      
                    <Col xs="12" className="mt-1">
                      <h6 className="mb-2 text-truncate text-dark">
                        <i className="mdi mdi-circle text-success me-1" />Fechado: <strong>{fechados}</strong>
                      </h6>
                    </Col>
                  </Row>
                </Col>
              </div>
            </CardBody>
          </Card>
        </Col>
        ) : (
          <Col xl="7">
          </Col>
        )
      }

    </React.Fragment>
  );
};

export default SalesAnalytics;
