import React, { useState, useContext } from "react";
import {
  Label,
  Input,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
} from "reactstrap";
import { AuthContext } from "contexts/auth";
import api from "config/api";
import Toastr from "../../Ui/Notifications"

const ModalCreatePayment = ({ isOpen, toggle, advisorId }) => {
  const [valor, setValor] = useState('');
  const [data, setData] = useState('');
  const { user } = useContext(AuthContext);

  const formatarValorEmReal = (valor) => {
    const parteInteira = Math.floor(valor);
    const parteDecimal = Math.round((valor - parteInteira) * 100);

    const parteInteiraFormatada = new Intl.NumberFormat('pt-BR').format(parteInteira);
    return `${parteInteiraFormatada},${parteDecimal.toString().padStart(2, '0')}`;
  };

  const formatarMoeda = (valor) => {
    const numeroLimpo = valor.replace(/[^0-9]/g, '');
    const valorNumerico = parseFloat(numeroLimpo) / 100;
    return formatarValorEmReal(valorNumerico);
  };

  const handleValorChange = (e) => {
    const value = e.target.value;
    const formattedValue = formatarMoeda(value); 
    setValor(formattedValue); 
  };

  const handleDataChange = (e) => {
    setData(e.target.value); 
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); 

    const valueToSend = valor; 
    const now = new Date();
    const formattedDate = `${data || now.toISOString().split('T')[0]} ${now.getHours().toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}:00.000`;

    try {
      const response = await api.post(`/payments/assessor`, {
        value: valueToSend,
        date: formattedDate,
        advisor: advisorId,
      }, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      toggle(); 
      Toastr("success", "Pagamento adicionado com sucesso!")
    } catch (error) {
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle} tag="h4">
        Adicionar Pagamento
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs={6}>
              <div className="mb-3">
                <Label className="form-label">Valor</Label>
                <Input
                  type="text"
                  name="valor"
                  placeholder="Digite o valor"
                  value={valor}
                  onChange={handleValorChange}
                />
              </div>
            </Col>

            <Col xs={6}>
              <div className="mb-3">
                <Label className="form-label">Data</Label>
                <Input
                  name="data"
                  type="date"
                  value={data}
                  onChange={handleDataChange}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="text-end">
                <button
                  type="submit"
                  className="btn btn-info save-user"
                  style={{ marginTop: "5px" }}
                >
                  Adicionar
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ModalCreatePayment;
