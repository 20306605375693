import React, { useContext, useState, useEffect } from "react";
import { Modal, Button, Form, FormGroup, Label, Input, ModalFooter } from "reactstrap";
import { AuthContext } from "contexts/auth";
import { StateContext } from "contexts/states";
import Toastr from "../../../components/Ui/Notifications";
import api from "config/api";

const AddPaymentModal = ({ id, observation, controlModal, toggle, onChangeValueObservation }) => {

  const [newObservation, setNewObservation] = useState("");
  const { user } = useContext(AuthContext);

  const handleAddPayment = () => {
    postObservation(newObservation, id);
    toggle();
  };

  useEffect(() => {
    setNewObservation(observation)
  },[])

  async function postObservation(value, id) {
    await api.put("influencers/update/observation",
        {
          id: id,
          observation: value,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      .then((response) => {
        Toastr("success", "Observação atualizada com sucesso!");
        onChangeValueObservation(value);
      })
      .catch((error) => {
        console.error(error);
        Toastr("error", "Algo errado aconteceu!");
      });
  }

  return (
    <Modal isOpen={controlModal} toggle={toggle}>
      <div className="modal-header">
        <h5 className="modal-title">Editar Observação</h5>
        <button type="button" className="btn-close" onClick={toggle}></button>
      </div>
      <div className="modal-body">
        <Form>
          <FormGroup>
            <Label for="paymentValue">Descreva uma nova observação</Label>
            <Input
              type="text"
              id="paymentValue"
              placeholder="Digite o valor semanal"
              value={newObservation}
              onChange={(e) => setNewObservation(e.target.value)}
            />
          </FormGroup>
        </Form>
      </div>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Fechar
        </Button>
        <Button color="primary" onClick={handleAddPayment}>
          Salvar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddPaymentModal;
