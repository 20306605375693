import React, { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { StateContext } from 'contexts/states';


const StyledButton = styled.button`
  position: fixed;
  bottom: 50px;
  right: 20px;
  background: linear-gradient(144deg, #6f39a5 50%, #955ccb); /* Gradiente ajustado para tons de azul */
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 999;
  display: flex; /* Adicionado para alinhar os itens horizontalmente */
  align-items: center; /* Adicionado para alinhar os itens verticalmente */
`;

const PlusSign = styled.div`
  font-size: 1.5em;
`;

const Text = styled.div`
  font-size: 1.2em;
  font-weight: 500;
  margin-left: 5px; /* Adicionado para criar espaço entre o ícone e o texto */
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')}; /* Adicionado para mostrar ou ocultar o texto */
  transition: opacity 0.3s; /* Adicionado para suavizar a transição de opacidade */
`;

const HoverButton = styled(StyledButton)`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  transition-duration: .3s;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);

  &:hover {
    width: 125px;
    border-radius: 10px;
    transition-duration: .3s;

    ${Text} {
      opacity: 1; /* Mostra o texto quando o botão está sendo hover */
    }
  }
`;

const AddButton = () => {
 const { modal, setModal } = useContext(StateContext)

  return (
    <HoverButton onClick={() => setModal(!modal)}>
      <PlusSign><FontAwesomeIcon icon={faPlus} /></PlusSign>
      <Text isVisible={modal && !modal}>Cadastrar</Text> 
    </HoverButton>
  );
};

export default AddButton;
