import React, { useContext, useState, useEffect } from "react";
import { Modal, Button, Form, FormGroup, Label, Input, ModalFooter } from "reactstrap";
import { AuthContext } from "contexts/auth";
import Toastr from "../../Ui/Notifications";
import api from "config/api";

const EditBankDataModal = ({ isOpen, toggle, influencerId }) => {

  const [email, setEmail] = useState('');
  const { user } = useContext(AuthContext);

  const idInfluencer = influencerId 

  const handleChangeEmail = (e) => {
    setEmail(e.target.value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        const response = await api.post(`/profile/influencer`, {
            influencer: idInfluencer,
            email: email,
        }, {
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        })
        toggle()
        Toastr("sucess", "Acesso criado com sucesso.")
        setTimeout(() => {
          window.location.reload();
        }, 3000);
    } catch (error) {
      Toastr("error", "Falha ao criar acesso. Confira se o influenciador já possui uma meta.")
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <div className="modal-header">
        <h5 className="modal-title">Criar Meta Para o Influenciador</h5>
        <button type="button" className="btn-close" onClick={toggle}></button>
      </div>
      <div className="modal-body">

        <Form>
          <FormGroup>
            <Label for="name">E-mail de Acesso</Label>
            <Input
              type="text"
              id="name"
              placeholder="Escolha um e-mail para o influenciador"
              value={email}
              onChange={handleChangeEmail}
            />
          </FormGroup>
        </Form>
      </div>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Fechar</Button>
        <Button color="primary" onClick={handleSubmit}>Salvar</Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditBankDataModal;