import React, { useContext, useState, useEffect } from "react";
import { Modal, Button, Form, FormGroup, Label, Input, ModalFooter } from "reactstrap";
import Toastr from "../../../components/Ui/Notifications";
import api from "config/api";
import { AuthContext } from "contexts/auth";
import { StateContext } from "contexts/states";

const UpdateCaptador = ({ influencerId, id, captador, controlModal, toggle, onChangeValueObservation }) => {
  const [newCaptadorId, setNewCaptadorId] = useState("");
  const [captadores, setCaptadores] = useState([]);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await api.get("/users/api/2", {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        const captadoresData = response.data.filter(captador => captador.sort).map(captador => ({ id: captador.id, fullname: captador.fullname }));
        const captadoresSortFalse = response.data.filter(captador => captador.id === "60b539fd-9306-4ff7-aba6-fc0b8be92138");
        
        setCaptadores([...captadoresData, ...captadoresSortFalse]);
        
      } catch (error) {
        console.error(error);
        Toastr("error", "Algo errado aconteceu ao carregar os captadores!");
      }
    }
    fetchData();
  }, [user.token]);

  useEffect(() => {
    setNewCaptadorId(captador);
  }, [captador]);

  const handleAddPayment = () => {
    updateCaptador(newCaptadorId, id);
    toggle();
  };

  async function updateCaptador() {
    await api
      .patch(
        "/influencers/update/captacao",
        {
          id: influencerId,
          captacao: newCaptadorId,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      .then(response => {
        Toastr("success", "Captador alterado com sucesso!");
        window.location.reload();
      })
      .catch(error => {
        Toastr("error", "Houve um erro!")
      });
  }

  return (
    <Modal isOpen={controlModal} toggle={toggle}>
      <div className="modal-header">
        <h5 className="modal-title">Editar Captador Responsável</h5>
        <button type="button" className="btn-close" onClick={toggle}></button>
      </div>
      <div className="modal-body">
        <Form>
          <FormGroup>
            <Label for="captadorSelect">Selecione um captador</Label>
            <Input
              type="select"
              name="captador"
              id="captadorSelect"
              value={newCaptadorId}
              onChange={(e) => setNewCaptadorId(e.target.value)}
            >
              <option value="">Selecione...</option>
              {captadores.map((captador, index) => (
                <option key={index} value={captador.id}>{captador.fullname}</option>
              ))}
            </Input>
          </FormGroup>
        </Form>
      </div>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Fechar
        </Button>
        <Button color="primary" onClick={handleAddPayment}>
          Salvar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UpdateCaptador;