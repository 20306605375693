import React from "react"
import PropTypes from "prop-types"
import { Card, CardBody, Col, Row } from "reactstrap"

const Profile = ({ data }) => {
  function formatDate(dateString) {
    const date = new Date(dateString)
    return date.toLocaleDateString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
  }

  const dataFormatada1 = formatDate(data.dateInit)
  const dataFormatada2 = formatDate(data.dateFinal)

  return (
    <Card className="overflow-hidden">
      <div className="bg-primary-subtle">
        <Row>
          <Col xs="10">
            <div className="text-primary p-3">
              <h5 className="text-primary">Dados do Assessor</h5>
              <p className="fs-6 text-break text-muted">
                Acompanhe suas métricas na plataforma da Viraw.
              </p>
            </div>
          </Col>
        </Row>
      </div>
      <CardBody className="pt-0">
        <Row className="align-items-center">
          {" "}
          {/* Alinhamento centralizado */}
          <Col sm="5">
            <div className="avatar-md profile-user-wid mb-2">
              <img
                src="https://i.ibb.co/y6nkQKW/461085513-1715142105981321-1128849984098454117-n.jpg"
                alt=""
                className="img-thumbnail rounded-circle"
              />
            </div>
            <h5 className="font-size-15 text-truncate">{data.Assessor.name}</h5>
            <p className="text-muted fs-6 mb-0">Assessor</p>
          </Col>
          <Col sm="7">
            <div className="d-flex justify-content-between align-items-center pt-4">
              <div>
                {/* Aqui você pode ajustar o conteúdo do lado esquerdo, se precisar */}
              </div>
              <div className="text-end">
                {" "}
                {/* Alinhado à direita */}
                <h5 className="font-size-14">Betano</h5>
                <p className="text-muted mb-0 font-size-14">
                  <strong>Campanha</strong>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

Profile.propTypes = {
  data: PropTypes.any,
  isMenu: PropTypes.bool,
  toggleMenu: PropTypes.func,
}

export default Profile
