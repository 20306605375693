import React, { useContext, useEffect, useState } from 'react';
import { Card, CardBody, Col, Input, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

import { AuthContext } from 'contexts/auth';
import api from 'config/api';
import Loading from 'components/Ui/LoadingCircle';


const JobVacancy = ({data}) => {
    const {user} = useContext(AuthContext);
    const [payments, setPayments] = useState();  
    const [totalPayments, setTotalPayments] = useState();  
    const [loading, setLoading] = useState(true);
    
    const [calculos, setCalculos] = useState({});
    const [calculosOriginais, setCalculosOriginais] = useState({});


    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedMonthFormated, setSelectedMonthFormated] = useState();


    useEffect(()=>{
        async function getPayments() {                
            await api.get("payments",{
                headers:{
                    Authorization: `Bearer ${user.token}`
                }
            }).then((response) =>{

                setPayments(response.data);

                const soma = response.data.reduce((total, obj) => total + obj.value, 0);
                setTotalPayments(soma);

                const CustoPorCadastro = soma / data.Total.Registrations;
                const CustoPorDeposito = soma / data.Total.FTD;
                const TicketMedioFTD = data.Total.FirstDeposits / data.Total.FTD;
                const CustoPorDepositoQualificado = soma / data.Total.QFTD;
                const TaxaConversaoVisitaCadastro = (data.Total.Registrations / data.Total.Visitors) * 100;
                const TaxaConversaoDepositoCadastro = (data.Total.FTD / data.Total.Registrations) * 100;

               setCalculos({
                    CustoPorCadastro,
                    CustoPorDeposito,
                    TicketMedioFTD,
                    CustoPorDepositoQualificado,
                    TaxaConversaoVisitaCadastro,
                    TaxaConversaoDepositoCadastro
                })

                setCalculosOriginais({
                    CustoPorCadastro,
                    CustoPorDeposito,
                    TicketMedioFTD,
                    CustoPorDepositoQualificado,
                    TaxaConversaoVisitaCadastro,
                    TaxaConversaoDepositoCadastro
                })

                setLoading(false);
            })
        }
        getPayments()
    },[user.token])


    useEffect(()=>{
            var partesData = selectedMonth.split("-");
            var ano = parseInt(partesData[0]);
            var mes = parseInt(partesData[1]) - 1; 
            var date = new Date(ano, mes, 1);

            let soma = 0;
            payments && payments.forEach(element => {
                if(new Date(element.date).getMonth() === date.getMonth()){
                    soma += element.value;
                 }
            });
            data.Meses.forEach(element => {
                if(element.Month === selectedMonthFormated){
                    const CustoPorCadastro = soma / element.Registrations;
                    const CustoPorDeposito = soma / element.FTD;
                    const TicketMedioFTD = element.FirstDeposits / element.FTD;
                    const CustoPorDepositoQualificado = soma / element.QFTD;
                    const TaxaConversaoVisitaCadastro = (element.Registrations / element.Visitors) * 100;
                    const TaxaConversaoDepositoCadastro = (element.FTD / element.Registrations) * 100;
                    setCalculos({
                        CustoPorCadastro,
                        CustoPorDeposito,
                        TicketMedioFTD,
                        CustoPorDepositoQualificado,
                        TaxaConversaoVisitaCadastro,
                        TaxaConversaoDepositoCadastro
                    })
                }
            });

    },[selectedMonth])



    const handleMonthChange = (event) => {
        const selectedDate = event.target.value;

        if(!selectedDate){
          setCalculos(calculosOriginais)
        }
    
        const [year, month] = selectedDate.split('-');
        const day = parseInt(month) < 10 ? parseInt(month) : month;
        const formattedMonth = `${day}/${year}`;
        setSelectedMonth(event.target.value);
        setSelectedMonthFormated(formattedMonth);
      };


    return (
        <React.Fragment>
         
        {
            loading ? (<Loading />): (
                <>
                  <Col xs="3" className="mb-3">
              <Input value={selectedMonth} onChange={handleMonthChange} type="month" />
            </Col>

                <Row>

               
                 <Col lg={2}>
                    <Card>
                        <CardBody className="p-3">
                            <div className="text-center mb-3">
                                    <h5 className="mt-4 mb-2 font-size-15">CPL</h5>
                                <h6 className="mb-0 text-muted">Custo por Cadastro</h6>
                            </div>

                            <div className="d-flex">
                                <p className="mb-0 flex-grow-1 text-muted text-center">{calculos.CustoPorCadastro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={2}>
                    <Card>
                        <CardBody className="p-3">
                            <div className="text-center mb-3">
                                    <h5 className="mt-4 mb-2 font-size-15">CPA</h5>
                                <h6 className="mb-0 text-muted">Custo por Deposito</h6>
                            </div>

                            <div className="d-flex">
                                <p className="mb-0 flex-grow-1 text-muted text-center">{calculos.CustoPorDeposito.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
   
                <Col lg={2}>
                    <Card>
                        <CardBody className="p-3">
                            <div className="text-center mb-3">
                                    <h5 className="mt-4 mb-2 font-size-15">CPAQ</h5>
                                <h6 className="mb-0 text-muted">Custo por Deposito Qualificado</h6>
                            </div>

                            <div className="d-flex">
                                <p className="mb-0 flex-grow-1 text-muted text-center">{calculos.CustoPorDepositoQualificado.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={2}>
                    <Card>
                        <CardBody className="p-3">
                            <div className="text-center mb-3">
                                    <h5 className="mt-4 mb-2 font-size-15">TM</h5>
                                <h6 className="mb-0 text-muted">Ticket Médio</h6>
                            </div>

                            <div className="d-flex">
                                <p className="mb-0 flex-grow-1 text-muted text-center">{calculos.TicketMedioFTD.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={2}>
                    <Card>
                        <CardBody className="p-2">
                            <div className="text-center mb-3">
                                    <h5 className="mt-4 mb-2 font-size-15">CTR</h5>
                                <h6 className="mb-0 text-muted">Taxa Conversao Cadastro por Visita </h6>
                            </div>

                            <div className="d-flex">
                                <p className="mb-0 flex-grow-1 text-muted text-center">{calculos.TaxaConversaoVisitaCadastro.toFixed(2) + '%'}</p>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={2}>
                    <Card>
                        <CardBody className="p-2">
                            <div className="text-center mb-3">
                                    <h5 className="mt-4 mb-2 font-size-15">CR</h5>
                                <h6 className="mb-0 text-muted">Taxa Conversao Deposito por Cadastro</h6>
                            </div>

                            <div className="d-flex">
                                <p className="mb-0 flex-grow-1 text-muted text-center">{calculos.TaxaConversaoDepositoCadastro.toFixed(2) + '%'}</p>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                </Row>
                </>
            )
        }
               
        </React.Fragment>
    );
}

export default JobVacancy;