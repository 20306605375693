import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import { Card, Col, Row } from "reactstrap"
import { TbCalendarClock } from "react-icons/tb"

import styled from "styled-components"

const ImgPhoto = styled.img`
  border-radius: 50%;
  width: 50px;
  height: 50px;
`
const ButtonInsta = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  font-weight: 400;
`
const Dot = styled.div`
  margin-top: 5px;
  margin-left: 5px;
  font-weight: 600;
  border-radius: 5px 0px 5px 0px;
`
const DotText = styled.span`
  font-size: 12px;
  padding: 3px;
  color: white;
`

const DotCompany = styled.div`
  border-radius: 5px 0px 5px 0px;
  margin-top: 5px;
  margin-left: 5px;
`
const DotTextCompany = styled.span`
  font-size: 10px;
  font-weight: 600;
  padding: 3px;
  color: white;
`
const CardTracking = props => {
  const { trackings } = props

  useEffect(() => {}, [trackings])

  function formatarParaReal(valor) {
    return valor.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    })
  }

  function formatarData(dataISO) {
    const data = new Date(dataISO)

    const dataFormatada = data.toLocaleDateString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })

    return dataFormatada
  }

  const redirectInst = username => {
    window.open(
      `https://instagram.com/${username}`,
      "_blank",
      "noopener,noreferrer"
    )
  }
  
  return (
    <React.Fragment>
      <Col xl="6" sm="12">
        <Card>
          <Row>
            <Col xs={12} md={1} sm={4}>
              {trackings.archive === true ? (
                <div style={{ display: "flex", width: "100%" }}>
                  <Dot style={{ backgroundColor: "red" }}>
                    <DotText>Arquivado</DotText>
                  </Dot>
                  <DotCompany style={{ backgroundColor: "red" }}>
                    <DotTextCompany>{trackings.company}</DotTextCompany>
                  </DotCompany>
                  {trackings.isDailyPayment && !trackings.isAdvanceValue && (
                    <DotCompany style={{ backgroundColor: "#836FFF" }}>
                      <DotTextCompany>Diário</DotTextCompany>
                    </DotCompany>
                  )}
                  {trackings.isAdvanceValue && !trackings.isDailyPayment && (
                    <DotCompany style={{ backgroundColor: "#F08080" }}>
                      <DotTextCompany>Antecipado</DotTextCompany>
                    </DotCompany>
                  )}
                  {trackings.isDailyPayment && trackings.isAdvanceValue && (
                    <>
                      <DotCompany style={{ backgroundColor: "#836FFF" }}>
                        <DotTextCompany>Diário</DotTextCompany>
                      </DotCompany>
                      <DotCompany style={{ backgroundColor: "#F08080" }}>
                        <DotTextCompany>Antecipado</DotTextCompany>
                      </DotCompany>
                    </>
                  )}
                  {trackings.assessor && trackings.assessor.id && (
                    <DotCompany style={{ backgroundColor: "#FF8C00" }}>
                      <DotTextCompany>Assessor</DotTextCompany>
                    </DotCompany>
                  )}
                </div>
              ) : (
                <div style={{ display: "flex", width: "100%" }}>
                  <Dot style={{ backgroundColor: "green" }}>
                    <DotText>Ativo</DotText>
                  </Dot>
                  <DotCompany style={{ backgroundColor: "red" }}>
                    <DotTextCompany>{trackings.company}</DotTextCompany>
                  </DotCompany>
                  {trackings.isDailyPayment && !trackings.isAdvanceValue && (
                    <DotCompany style={{ backgroundColor: "#836FFF" }}>
                      <DotTextCompany>Diário</DotTextCompany>
                    </DotCompany>
                  )}
                  {trackings.isAdvanceValue && !trackings.isDailyPayment && (
                    <DotCompany style={{ backgroundColor: "#F08080" }}>
                      <DotTextCompany>Antecipado</DotTextCompany>
                    </DotCompany>
                  )}
                  {trackings.isDailyPayment && trackings.isAdvanceValue && (
                    <>
                      <DotCompany style={{ backgroundColor: "#836FFF" }}>
                        <DotTextCompany>Diário</DotTextCompany>
                      </DotCompany>
                      <DotCompany style={{ backgroundColor: "#F08080" }}>
                        <DotTextCompany>Antecipado</DotTextCompany>
                      </DotCompany>
                    </>
                  )}
                  {trackings.assessor && trackings.assessor.id && (
                    <DotCompany style={{ backgroundColor: "#FF8C00" }}>
                      <DotTextCompany>Assessor</DotTextCompany>
                    </DotCompany>
                  )}
                </div>
              )}
            </Col>
          </Row>

          <Row>
            <Col xl="5">
              <div className="text-center p-4 border-end">
                <div className="avatar-sm mx-auto mb-3 mt-1">
                  <ImgPhoto src={trackings.photo} alt="" />
                </div>
                <h5 className="text-truncate pb-1">{trackings.fullname}</h5>

                <ButtonInsta onClick={() => redirectInst(trackings.username)}>
                  <h6 className="text-truncate pb-1">@{trackings.username}</h6>
                </ButtonInsta>
                <Row xl={12}>
                  <Col xs={6}>
                    <div className="mt-2">
                      <Link
                        to={`/tracking-perfil/${trackings.username}`}
                        className="btn btn-light btn-md"
                      >
                        Detalhes
                      </Link>
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="mt-2">
                      <Link
                        to={`/influencer/${trackings.plugchat.id}`}
                        className="btn btn-light btn-md"
                      >
                        Perfil
                      </Link>
                    </div>
                  </Col>
                  <Col xs="12" className="mt-3">
                    <div className="mb-2 text-dark text-truncate text-center">
                      <h5 className="text-dark mb-2 text-truncate text-center font-size-12">
                        Fechado:
                        <i className="bx bx-calendar me-0 text-dark ms-2" />
                        {formatarData(trackings.plugchat.created_at)}
                      </h5>
                      {trackings.assessor && trackings.assessor.name && (
                        <h6 className="text-dark mb-2 text-truncate text-center font-size-12">
                          Assessor: {trackings.assessor.name}
                        </h6>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col xl="7">
              <div className="p-3 text-center text-xl-start">
                <Row>
                  <Col xs="6">
                    <div>
                      <p className="text-muted mb-2 text-truncate text-center font-size-14">
                        Registros
                      </p>
                      <h5 className="text-center font-size-14">
                        {trackings.Total.Registrations}
                      </h5>
                    </div>
                  </Col>
                  <Col xs="6">
                    <div>
                      <p className="text-muted mb-2 text-truncate text-center font-size-14">
                        Depósitos
                      </p>
                      <h5 className="text-center font-size-14">
                        {formatarParaReal(trackings.Total.Deposits)}
                      </h5>
                    </div>
                  </Col>
                </Row>

                <hr />

                <Row>
                  <Col xs="6">
                    <div>
                      <h5 className="text-muted mb-2 text-truncate text-center font-size-14">
                        Clicks
                      </h5>
                      <h5 className="text-center font-size-14">
                        {trackings.Total.Clicks.toLocaleString() || 0}
                      </h5>
                    </div>
                  </Col>

                  <Col xs="6">
                    <div>
                      <h5 className="text-muted mb-2 text-truncate text-center font-size-14">
                        FTD
                      </h5>
                      <h5 className="text-center font-size-14">
                        {trackings.Total.FirstDepositCount.toLocaleString() ||
                          0}
                      </h5>
                    </div>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col xs="6">
                    <div>
                      <h5 className="text-muted mb-2 text-truncate text-center font-size-14">
                        CPA
                      </h5>
                      <h5 className="text-center font-size-14">
                        {trackings.Total.CPACount.toLocaleString() || 0}
                      </h5>
                    </div>
                  </Col>
                  <Col xs="6">
                    <div>
                      <h5 className="text-muted mb-2 text-truncate text-center font-size-14">
                        Investimento
                      </h5>
                      <h5 className="text-center font-size-14">
                        {formatarParaReal(trackings.Total.ValorInvestido || 0)}
                      </h5>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Card>
      </Col>
    </React.Fragment>
  )
}

CardTracking.propTypes = {
  trackings: PropTypes.object,
}

export default CardTracking
