import React, { useContext, useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row, Input } from 'reactstrap';
import { useParams } from 'react-router-dom'; // Importando useParams
import List from './List';
import api from "config/api";
import { AuthContext } from "contexts/auth";

const CandidateList = () => {
    const { id } = useParams();
    const [listData, setListData] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const { user } = useContext(AuthContext);
    const [candidateData, setCandidateData] = useState([]);

    useEffect(() => {
        async function fetchAssessor() {
            try {
                const response = await api.get(`/assessor/${id}`, {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                });

                const filteredInfluencers = response.data.influencers.filter(influencer => influencer.archiving === false);

                setCandidateData(filteredInfluencers);
                setListData(filteredInfluencers);
            } catch (error) {
                console.error('Erro ao buscar influenciadores:', error);
            } finally {
                setLoading(false);
            }
        }

        fetchAssessor();
    }, [id, user.token]);

    const handleSearchJob = (value) => {
        const filtered = candidateData.filter((influencer) => 
            (influencer.username || '').toLowerCase().includes(value.toLowerCase())
        );
        setListData(filtered);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                {isLoading ? (
                    <Row>
                        <Col xl="12">
                            <div className="text-center my-3">
                                <div className="text-success">
                                    <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                                    Carregando...
                                </div>
                            </div>
                        </Col>
                    </Row>
                ) : (
                    <Container fluid>
                        <Row>
                            <Col lg={12}>
                                <Card className="job-filter">
                                    <CardBody>
                                        <form action="#">
                                            <Row className="g-3">
                                                <Col xxl={4} lg={4}>
                                                    <div className="position-relative">
                                                        <Input
                                                            type="text"
                                                            id="searchJob"
                                                            autoComplete="off"
                                                            placeholder="Buscar influenciador"
                                                            onChange={(e) => handleSearchJob(e.target.value)}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <List listData={listData}/>
                    </Container>
                )}
            </div>
        </React.Fragment>
    );
};

export default CandidateList;
