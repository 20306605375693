import styled from 'styled-components';

export const CounterBox = styled.div`
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
    }
`;

export const CounterTitle = styled.p`
    font-size: 16px;
    margin-bottom: 10px;
`;

export const CounterValue = styled.p`
    font-size: 24px;
    font-weight: bold;
`;

export const BadgeRoxo = styled.span`
  background-color: #800080; 
  color: #fff; 
  padding: 0.25em 0.5em;
  border-radius: 0.25rem;
  font-size: 0.6rem;
  font-weight: 600;
  display: inline-block;
`;