import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'reactstrap';
import { AuthContext } from "contexts/auth";
import api from '../../../config/api';
import Toggle from './toggle';
import {
  CardBody,
  Col,
  Row,
  Badge,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Toastr from "../../../components/Ui/Notifications";

const List = ({ listData }) => {
  const { user } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  const [selectedInfluencer, setSelectedInfluencer] = useState(null);
  const [colaboradores, setColaboradores] = useState([]);
  const [sortData, setSortData] = useState({});

  useEffect(() => {
    getCollaborators();
  }, [user.token]);

  const updated = () => {
    getCollaborators();
  };

  async function getCollaborators() {
    await Promise.all([
      api.get("/users/api/2", { headers: { Authorization: `Bearer ${user.token}` } }),
      api.get("/users/api/3", { headers: { Authorization: `Bearer ${user.token}` } }),
    ]).then((responses) => {
      const closers = responses[0].data;
      const grupos = responses[1].data;
      setColaboradores([...closers, ...grupos]);

      const sortMap = {};
      closers.forEach(influencer => {
        sortMap[influencer.id] = influencer.sort;
      });
      grupos.forEach(influencer => {
        sortMap[influencer.id] = influencer.sort;
      });
      setSortData(sortMap);
    }).catch((error) => {
      console.error(error);
    });
  }

  const handleOpenModal = (influencer) => {
    setSelectedInfluencer(influencer);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleArchiveConfirm = () => {
    api.put("/users/archiving/", {
      id: selectedInfluencer.id,
      active: false,
      sort: false
    }, {
      headers: {
        Authorization: `Bearer ${user.token}`
      }
    }).then(response => {
      Toastr("success", "Colaborador desativado com sucesso!");
      setTimeout(() => {
        window.location.reload();  
      }, 3000);
      
      handleCloseModal();
    }).catch(error => {
      console.error("Erro ao desativar colaborador:", error);
    });
  };

  const AlterarStatus = (influencerId) => {
    api.put(`/users/update/sort/${influencerId}`, {}, {
      headers: { 
        Authorization: `Bearer ${user.token}`
      },
    })
      .then(() => {
        setSortData((prev) => ({ ...prev, [influencerId]: !prev[influencerId] }));
        Toastr("success", "Atualizado com sucesso.");
      })
      .catch((error) => {
        console.error("Erro ao atualizar sort:", error);
      });
  };

  const sectorBadgeClasses = (sectorType) => {
    switch (sectorType) {
      case 'Grupos':
        return 'badge badge-soft-success';
      case 'Captação':
        return 'badge badge-soft-info';
      case 'Diretoria':
        return 'badge badge-soft-dark';
      default:
        return 'badge badge-soft-warning';
    }
  };

  return (
    <React.Fragment>
      <Row>
        {listData
          .filter(influencer => influencer.active) 
          .map((influencer, key) => (
            <Col xl={3} key={key}>
              <Card>
                <CardBody>
                  <div className="d-flex align-start mb-3">
                    <div className="flex-grow-1">
                      <span className={sectorBadgeClasses(influencer.type_user.type)}>
                        {influencer.type_user.type === 'Captação' ? 'Closer' : influencer.type_user.type}
                      </span>
                      {influencer.type_user.type === 'Grupos' && (
                        <span className={sectorBadgeClasses(influencer.type_user.type)} style={{ marginLeft: '10px' }}>
                          {influencer.plug_chat.length} ativos
                        </span>
                      )}
                    </div>
                    
                    {influencer.type_user.type === 'Captação' || influencer.type_user.type === 'Grupos' ? (
                      <div className="ml-2">
                      </div>
                    ) : null}

                    {influencer.type_user.type === 'Captação' ? (
                      <button 
                        type="button"
                        className={`btn btn-light btn-sm like-btn${(influencer.type_user.type === 'Diretoria' || influencer.type_user.type === 'Hunter') ? ' disabled-btn' : ''}`}
                        onClick={() => handleOpenModal(influencer)}
                      >
                        <i className="mdi mdi-archive-arrow-down-outline" style={{ fontSize: "14px" }}></i>
                      </button>
                    ) : null}
                  </div>

                  <div className="text-center mb-3">
                    <img src={influencer.photo} alt="" className="avatar-sm rounded-circle" />
                    <h6 className="font-size-15 mt-3 mb-1">{influencer.fullname}</h6>
                    <p className="mb-0 text-muted font-size-12">{influencer.email}</p>
                  </div>

                  <div className="d-flex justify-content-center mb-3">
                    <Toggle
                      checked={sortData[influencer.id] || false}
                      onClick={influencer.type_user.type !== 'Diretoria' ? () => AlterarStatus(influencer.id) : null}
                      id={`toggle-${influencer.id}`}
                      disabled={influencer.type_user.type === 'Diretoria'}
                    />
                  </div>

                  <div className="mt-4 pt-1">
                    {(influencer.type_user.type === 'Diretoria' || influencer.type_user.type === 'Hunter') ? (
                      <button className="btn btn-soft-primary d-block " style={{ width: '100%' }} disabled>Ver Perfil</button>
                    ) : (
                      <Link to={`/colaboradores/${influencer.id}`} className="btn btn-soft-primary d-block">Ver Perfil</Link>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))}
      </Row>

      <Modal isOpen={showModal} toggle={handleCloseModal}>
        <ModalHeader toggle={handleCloseModal}>
          Arquivar Colaborador
        </ModalHeader>
        <ModalBody>
          Você deseja desativar este colaborador?
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button color="primary" onClick={handleArchiveConfirm}>
            Sim
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default List;
