import React, { useState, useContext, useEffect } from "react"
import { AuthContext } from "contexts/auth"
import api from "config/api"
import {
  formatAleatoria,
  formatCnpj,
  formatCpf,
  formatPhoneNumber,
  validateEmail,
  formatarParaReal,
} from "../../../utils/masks"

import {
  FecharInfluencer,
  FecharInfluencerClone,
} from "../../../pages/Captacao/Contacts/ContactList/requests"

import { formatCPF } from "utils/formatCpf"
import { sortearElemento, sortearComMenorPeso } from "utils/sortUserGrupos"

import Toasr from "components/Ui/Notifications"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label,
  Input,
  Form,
  FormFeedback,
  Row,
  Col,
} from "reactstrap"

const ModalFechado = ({
  idInfluencer,
  open,
  toogle,
  confirm,
  updateInfluencers,
  campaing,
}) => {
  const { user } = useContext(AuthContext)
  const type_chave = ["CNPJ", "CPF", "Telefone", "E-mail", "Aleatória"]
  const [nome, setNome] = useState("")
  const [cpf, setCpf] = useState("")
  const [value, setValue] = useState("")
  const [email, setEmail] = useState("")

  const [tipoChavePix, setTipoChavePix] = useState("")
  const [chavePix, setChavePix] = useState("")
  const [validEmail, setValidEmail] = useState(true)
  const [selectedCampaign, setSelectedCampaign] = useState("")
  const [influencersList, setInfluencersList] = useState([])

  //const [fechadoModal, setFechadoModal] = useState(open)
  const [confirmarModal, setConfirmarModal] = useState(false)
  const [confirmarEditModal, setConfirmarEditModal] = useState(false)
  const [fechadoModal, setFechadoModal] = useState(false)
  const [link_plug_chat, setLink_plug_chat] = useState("")
  const [dataFechamento, setDataFechamento] = useState("")
  const [horaFechamento, setHoraFechamento] = useState("")

  const [dataInicioMeta, setDataInicioMeta] = useState("")
  const [dataFinalMeta, setDataFinalMeta] = useState("")
  const [valueFTD, setValueFTD] = useState("")
  const [valueAdiantamento, setValueAdiantamento] = useState("")
  const [meta, setMeta] = useState("")
  const [valueExcedente, setValueExcedente] = useState("")

  const [userGrupos, setUserGrupos] = useState([])
  const [influencer, setInfluencer] = useState()
  const [descricao, setDescricao] = useState("")

  const [tipoPagamento, setTipoPagamento] = useState("")

  const [isAdvanceValue, setIsAdvanceValue] = useState(false)
  const [createPlugChat, setCreatePlugChat] = useState('true')
  const [advanceValue, setAdvanceValue] = useState("R$ 0,00")

  const [assessores, setAssessores] = useState([])
  const [selectAssessor, setSelectAssessor] = useState("null")

  const handleTipoPagamentoChange = event => {
    setTipoPagamento(event.target.value)
  }
  const handleIsAdvanceValueChange = event => {
    setIsAdvanceValue(event.target.value)
  }
  const handleCreatePlugChat = event => {
    setCreatePlugChat(event.target.value)
  }
  const handleSelectAssessor = event => {
    setSelectAssessor(event.target.value)
  }

  useEffect(() => {
    async function getInfluencer() {
      await api
        .get(`/influencers/influencer/${idInfluencer}`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        })
        .then(response => {
          setInfluencer(response.data)
        })
        .catch(error => {
          console.error(error)
        })
    }
    async function getAssessores() {
      await api
        .get(`/assessor`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        })
        .then(response => {
          setAssessores(response.data.results)
        })
        .catch(error => {
          console.error(error)
        })
    }

    getAssessores()
    getInfluencer()
  }, [idInfluencer, user.token])

  // Função para atualizar o estado local com o novo status
  const updateLocalStatus = (influencerId, newStatus) => {
    setInfluencersList(prevList =>
      prevList.map(influencer =>
        influencer.id === influencerId
          ? { ...influencer, status: newStatus }
          : influencer
      )
    )
  }

  const [campaignOptions, setCampaignOptions] = useState([])
  const [campaignOptionsValue, setCampaignOptionsValue] = useState([])

  const handleCampaignChange = event => {
    setCampaignOptionsValue(event.target.value)
  }
  useEffect(() => {
    async function getUserGrupos() {
      await api
        .get("/users/api/3", {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        })
        .then(response => {
          let grupos = []
          let plugs = []

          response.data.forEach(element => {
            if (element.active === true && element.sort === true) {
              element.plug_chat.forEach(plugchat => {
                if (plugchat.influencer_rent.archiving === false) {
                  plugs.push(plugchat)
                }
              })

              grupos.push({
                id: element.id,
                email: element.email,
                fullname: element.fullname,
                phone: element.phone,
                active: element.active,
                plug_chat: plugs,
              })

              plugs = []
            }
          })


          setUserGrupos(grupos)


        })
        .catch(error => {
          console.error(error)
        })
    }
    getUserGrupos()
    async function getCampaign() {
      await api
        .get("/campaigns", {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        })
        .then(response => {
          setCampaignOptions(response.data)
        })
        .catch(error => {
          console.error(error)
        })
    }
    getCampaign()
  }, [user])

  const handleConfirmarAcao = () => {
    toggleConfirmarModal()
    toggleFechadoModal()
  }

  const toggleFechadoModal = () => {
    setNome(""),
      setCpf(""),
      setTipoChavePix(""),
      setChavePix(""),
      setValue(""),
      setLink_plug_chat(""),
      setDataFechamento(""),
      setHoraFechamento("")
    toogle()
  }

  const handleTipoChavePixChange = event => {
    const valorSelecionado = event.target.value
    setTipoChavePix(valorSelecionado)
    setChavePix("")
  }

  const onChangePix = event => {
    if (tipoChavePix === "CPF") {
      const formattedValue = formatCpf(event)
      setChavePix(formattedValue)
    } else if (tipoChavePix === "CNPJ") {
      const formattedValue = formatCnpj(event)
      setChavePix(formattedValue)
    } else if (tipoChavePix === "Telefone") {
      const formattedValue = formatPhoneNumber(event)
      setChavePix(formattedValue)
    } else if (tipoChavePix === "E-mail") {
      setChavePix(event)
      setValidEmail(validateEmail(event))
    } else if (tipoChavePix === "Aleatória") {
      const formattedValue = formatAleatoria(event)
      setChavePix(formattedValue)
    } else {
      setChavePix(event)
    }
  }

  const toggleConfirmarModal = () => {
    setConfirmarModal(!confirmarModal)
  }

  function formatDate(date) {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const day = String(date.getDate()).padStart(2, "0")
    return `${year}-${month}-${day}`
  }

  function getDates() {
    const today = new Date()
    const futureDate = new Date()
    futureDate.setDate(today.getDate() + 30)

    return {
      today: formatDate(today),
      futureDate: formatDate(futureDate),
    }
  }
  


  const handleConfirmClosing = async () => {
    const sort = sortearComMenorPeso(userGrupos)
    const fixo = tipoPagamento === "Fixo" ? true : false
    const diario = tipoPagamento === "Diario" ? true : false
    const dates = getDates()

    if (tipoPagamento === "Variavel") {
      if (
        !(
          nome &&
          tipoChavePix &&
          chavePix &&
          link_plug_chat &&
          dataFechamento &&
          horaFechamento &&
          meta &&
          valueFTD &&
          valueExcedente &&
          dataInicioMeta &&
          dataFinalMeta &&
          email
        ) ||
        !campaignOptionsValue
      ) {
        Toasr("warning", "Preencha todos os campos obrigatórios!")
      } else {
        await FecharInfluencerClone(
          idInfluencer,
          user.token,
          8,
          nome,
          cpf,
          tipoChavePix,
          chavePix,
          value,
          link_plug_chat,
          sort.id,
          dataFechamento,
          horaFechamento,
          influencer.username,
          influencer.phone,
          user.fullname,
          confirm,
          toogle,
          updateInfluencers,
          campaignOptionsValue,
          descricao,
          fixo,
          meta,
          valueFTD,
          valueExcedente,
          dataInicioMeta,
          dataFinalMeta,
          email,
          diario,
          isAdvanceValue,
          advanceValue,
          createPlugChat,
          selectAssessor
        )
        updateLocalStatus(idInfluencer, 8)
      }
    } else if (tipoPagamento === "Diario") {
      if (
        !(
          nome &&
          cpf &&
          tipoChavePix &&
          chavePix &&
          link_plug_chat &&
          dataFechamento &&
          horaFechamento &&
          valueFTD &&
          valueExcedente &&
          email
        ) ||
        !campaignOptionsValue
      ) {
        Toasr("warning", "Preencha todos os campos obrigatórios!")
      } else {
        await FecharInfluencerClone
        (
          idInfluencer,
          user.token,
          8,
          nome,
          cpf,
          tipoChavePix,
          chavePix,
          value,
          link_plug_chat,
          sort.id,
          dataFechamento,
          horaFechamento,
          influencer.username,
          influencer.phone,
          user.fullname,
          confirm,
          toogle,
          updateInfluencers,
          campaignOptionsValue,
          descricao,
          fixo,
          0,
          valueFTD,
          valueExcedente,
          dates.today,
          dates.futureDate,
          email,
          diario,
          false,
          "R$ 0,00",
          createPlugChat,
          selectAssessor
        )
        updateLocalStatus(idInfluencer, 8)
      }
    } else {
      if (
        !(
          nome &&
          cpf &&
          tipoChavePix &&
          chavePix &&
          link_plug_chat &&
          dataFechamento &&
          horaFechamento
        ) ||
        !campaignOptionsValue
      ) {
        Toasr("warning", "Preencha todos os campos obrigatórios!")
      } else {
        await FecharInfluencerClone(
          idInfluencer,
          user.token,
          8,
          nome,
          cpf,
          tipoChavePix,
          chavePix,
          value,
          link_plug_chat,
          sort.id,
          dataFechamento,
          horaFechamento,
          influencer.username,
          influencer.phone,
          user.fullname,
          confirm,
          toogle,
          updateInfluencers,
          campaignOptionsValue,
          descricao,
          fixo,
          meta,
          valueFTD,
          valueExcedente,
          dataInicioMeta,
          dataFinalMeta,
          '',
          diario,
          isAdvanceValue,
          advanceValue,
          createPlugChat,
          selectAssessor
        )
        updateLocalStatus(idInfluencer, 8)
      }
    }

    handleConfirmarAcao()
  }

  const handleConfirmarSubmit = () => {
    if(!nome) {
      Toasr("warning", "O campo NOME é Obrigatório.")
      return;
    }

    if(!tipoChavePix) {
      Toasr("warning", "Selecione um tipo de chave Pix.")
      return
    }

    if(!chavePix) {
      Toasr("warning", "Preencha a chave Pix.")
      return
    }

    if(!tipoPagamento) {
      Toasr("warning", "Informe o Tipo de Pagamento.")
      return
    }

    if (tipoPagamento === "Fixo" && (!value || value.trim() === "")) {
      Toasr("warning", "Informe o Valor Semanal.");
      return;
    }

    if (tipoPagamento === "Variavel") {
      if (!meta || meta.trim() === "") {
        Toasr("warning", "Informe a Meta.");
        return;
      }
      if (!valueFTD || valueFTD.trim() === "") {
        Toasr("warning", "Informe o Valor do FTD.");
        return;
      }
      if (!valueExcedente || valueExcedente.trim() === "") {
        Toasr("warning", "Informe o Valor Excedente.");
        return;
      }
      if (!dataInicioMeta) {
        Toasr("warning", "Informe a Data de Início da Meta.");
        return;
      }
      if (!dataFinalMeta) {
        Toasr("warning", "Informe a Data Final da Meta.");
        return;
      }
      if (isAdvanceValue === "true" && (!advanceValue || advanceValue.trim() === "" || advanceValue === "R$ 0,00")) {
        Toasr("warning", "Informe o Valor Antecipado.");
        return;
      }
      if (!email) {
        Toasr("warning", "Informe o Email.");
        return;
      }

      if (!link_plug_chat) {
        Toasr("warning", "Preencha o Link da Conversa.");
        return;
      }
    
      if (!dataFechamento) {
        Toasr("warning", "Informe a Data de Início.");
        return;
      }
    
      if (!horaFechamento) {
        Toasr("warning", "Informe a Hora de Início.");
        return;
      }
    
      if (!campaignOptionsValue) {
        Toasr("warning", "Selecione uma campanha.");
        return;
      }
    }

    toggleConfirmarModal()
  }

  const formatarMoeda = valor => {
    const numeroLimpo = valor.replace(/[^0-9]/g, "")

    const valorNumerico = Number(numeroLimpo) / 100

    const numeroFormatado = valorNumerico.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
    })

    return numeroFormatado
  }


  return (
    <Modal isOpen={open} toggle={toogle}>
      <ModalHeader toggle={toogle} tag="h4">
        Detalhes do Fechamento
      </ModalHeader>
      <ModalBody>
        <h5>Dados Pagamento</h5>
        <Col xs={12}>
          <div className="mb-3">
            <Label className="form-label">Nome Completo</Label>
            <Input
              type="text"
              name="nome"
              placeholder="Digite o nome"
              value={nome}
              onChange={e => setNome(e.target.value)}
              required
            />
          </div>
        </Col>

        <Row>
          <Col md={4}>
            <div className="mb-3">
              <Label className="form-label">Tipo de Chave Pix</Label>
              <Input
                type="select"
                name="type_pix"
                className="form-select"
                onChange={handleTipoChavePixChange}
                value={tipoChavePix}
              >
                <option value="">Selecione</option>
                {type_chave &&
                  type_chave.map(option => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
              </Input>
            </div>
          </Col>
          <Col md={8}>
            <div className="mb-3">
              <Label className="form-label">Chave PIX</Label>
              <Input
                type={tipoChavePix === "E-mail" ? "email" : "text"}
                name="chavePix"
                placeholder="Digite a chave PIX"
                value={chavePix}
                onChange={e => onChangePix(e.target.value)}
              />
            </div>
            {tipoChavePix === "E-mail" && !validEmail && (
              <h6> Email inválido</h6>
            )}
          </Col>
        </Row>

        <Col md={12}>
          <div className="mb-3">
            <Label className="form-label" style={{ marginTop: "10px" }}>
              CPF
            </Label>
            <Input
              name="cpf"
              type="text"
              placeholder="Digite o CPF"
              onChange={e => setCpf(formatCPF(e.target.value))}
              value={cpf}
            />
          </div>
        </Col>
        <Row>
          <Col md={6}>
            <div className="mb-3">
              <Label className="form-label">Tipo de Pagamento</Label>
              <Input
                type="select"
                name="tipoPagamento"
                onChange={handleTipoPagamentoChange}
                value={tipoPagamento}
              >
                <option value="">Selecione</option>
                <option value="Fixo">Fixo</option>
                <option value="Variavel">Variável</option>
                <option value="Diario">Diário</option>
              </Input>
            </div>
          </Col>

          {tipoPagamento === "Fixo" && (
            <Col md={6}>
              <div className="mb-3">
                <Label className="form-label">Valor Semanal</Label>
                <Input
                  type="text"
                  name="value"
                  placeholder="R$ 0,00"
                  value={formatarMoeda(value)}
                  onChange={e => setValue(e.target.value)}
                />
              </div>
            </Col>
          )}

          {tipoPagamento === "Variavel" && (
            <Col md={6}>
              <div className="mb-3">
                <Label className="form-label">Meta</Label>
                <Input
                  type="text"
                  name="meta"
                  placeholder="Digite a meta"
                  value={meta}
                  onChange={e => setMeta(e.target.value)}
                />
              </div>
            </Col>
          )}
        </Row>
        <Row>
          {tipoPagamento === "Variavel" && (
            <>
              <Col md={6}>
                <div className="mb-3">
                  <Label className="form-label">Valor FTD</Label>
                  <Input
                    type="text"
                    name="ftd"
                    placeholder="Digite o valor do FTD"
                    value={valueFTD}
                    onChange={e => setValueFTD(formatarMoeda(e.target.value))}
                  />
                </div>
              </Col>

              <Col md={6}>
                <div className="mb-3">
                  <Label className="form-label">Valor Excedente</Label>
                  <Input
                    type="text"
                    name="excedente"
                    placeholder="Digite o valor Excedente"
                    value={valueExcedente}
                    onChange={e =>
                      setValueExcedente(formatarMoeda(e.target.value))
                    }
                  />
                </div>
              </Col>
            </>
          )}
        </Row>
        <Row>
          {tipoPagamento === "Variavel" && (
            <>
              <Col md={6}>
                <div className="mb-3">
                  <Label className="form-label">Inicio da meta</Label>
                  <Input
                    type="date"
                    name="dataInicioMeta"
                    value={dataInicioMeta}
                    onChange={e => setDataInicioMeta(e.target.value)}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label className="form-label">Final da meta</Label>
                  <Input
                    type="date"
                    name="hora"
                    value={dataFinalMeta}
                    onChange={e => setDataFinalMeta(e.target.value)}
                  />
                </div>
              </Col>
            </>
          )}
        </Row>

        <Row>
          {tipoPagamento === "Variavel" && (
            <>
              <Col md={6}>
                <div className="mb-3">
                  <Label className="form-label">Pagamento Antecipado?</Label>
                  <Input
                    type="select"
                    name="tipoPagamento"
                    onChange={handleIsAdvanceValueChange}
                    value={isAdvanceValue}
                  >
                    <option value={false}>Não</option>
                    <option value={true}>Sim</option>
                  </Input>
                </div>
              </Col>
            </>
          )}

          {isAdvanceValue === "true" && (
            <>
              <Col md={6}>
                <div className="mb-3">
                  <Label className="form-label">Valor Antecipado</Label>
                  <Input
                    type="text"
                    name="ftd"
                    placeholder="Digite o valor antecipado"
                    value={advanceValue}
                    onChange={e =>
                      setAdvanceValue(formatarMoeda(e.target.value))
                    }
                  />
                </div>
              </Col>
            </>
          )}
        </Row>

        <Row>
          {tipoPagamento === "Variavel" && (
            <>
              <Col md={12}>
                <div className="mb-3">
                  <Label className="form-label">Email</Label>
                  <Input
                    type="email"
                    name="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    required
                  />
                </div>
              </Col>
            </>
          )}
        </Row>

        <Row>
          {tipoPagamento === "Diario" && (
            <>
              <Col md={6}>
                <div className="mb-3">
                  <Label className="form-label">Valor FTD</Label>
                  <Input
                    type="text"
                    name="ftd"
                    placeholder="Digite o valor do FTD"
                    value={valueFTD}
                    onChange={e => setValueFTD(formatarMoeda(e.target.value))}
                  />
                </div>
              </Col>

              <Col md={6}>
                <div className="mb-3">
                  <Label className="form-label">Valor Excedente</Label>
                  <Input
                    type="text"
                    name="excedente"
                    placeholder="Digite o valor Excedente"
                    value={valueExcedente}
                    onChange={e =>
                      setValueExcedente(formatarMoeda(e.target.value))
                    }
                  />
                </div>
              </Col>
            </>
          )}
        </Row>

        <Row>
          {tipoPagamento === "Diario" && (
            <>
              <Col md={12}>
                <div className="mb-3">
                  <Label className="form-label">Email</Label>
                  <Input
                    type="email"
                    name="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    required
                  />
                </div>
              </Col>
            </>
          )}
        </Row>

        <hr style={{ backgroundColor: "black" }} />
        <h5>Dados PlugChat</h5>

        <div className="mb-3">
          <Label className="form-label" style={{ marginTop: "10px" }}>
            Link da Conversa
          </Label>
          <Input
            name="link_plug_chat"
            type="text"
            placeholder="Digite o Chat Link"
            onChange={e => setLink_plug_chat(e.target.value)}
            value={link_plug_chat}
          />
        </div>
        <Row>
          {user.userId === "eb8238bc-9974-40bb-a3ed-694074565db7" && (
            <>
              <Col md={6}>
                <div className="mb-3">
                  <Label className="form-label">Criar grupo?</Label>
                  <Input
                    type="select"
                    name="createPlugChat"
                    onChange={handleCreatePlugChat}
                    value={createPlugChat}
                  >
                    <option value={false}>Não</option>
                    <option value={true}>Sim</option>
                  </Input>
                </div>
              </Col>
            </>
          )}
          {user.userId === "eb8238bc-9974-40bb-a3ed-694074565db7" && (
            <>
              <Col md={6}>
                <div className="mb-3">
                  <Label className="form-label">Selecione o Assessor</Label>
                  <Input
                    type="select"
                    name="selectAssessor"
                    onChange={handleSelectAssessor}
                    value={selectAssessor}
                  >
                    <option value="null">Sem assessor</option>
                    {assessores.map((item, i) => (
                      <option key={i} value={item.id}>{item.name}</option>
                    ))}
                  </Input>

                </div>
              </Col>
            </>
          )}

          <Col md={6}>
            <div className="mb-3">
              <Label className="form-label">Data de Inicio</Label>
              <Input
                type="date"
                name="dataFechamento"
                value={dataFechamento}
                onChange={e => setDataFechamento(e.target.value)}
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <Label className="form-label">Hora de Inicio</Label>
              <Input
                type="time"
                name="hora"
                value={horaFechamento}
                onChange={e => setHoraFechamento(e.target.value)}
              />
            </div>
          </Col>
          <div className="mb-3">
            <Label className="form-label">Observações</Label>
            <Input
              type="text"
              name="descricao"
              placeholder="Digite a descrição"
              value={descricao}
              onChange={e => setDescricao(e.target.value)}
            />
          </div>
        </Row>
        <Row>
          <Col md={12}>
            <hr style={{ backgroundColor: "black" }} />
            <div style={{ marginTop: "-20px" }}>
              <h5>Selecione uma campanha</h5>
              <div className="mb-3">
                <Input
                  type="select"
                  name="campaign"
                  className="form-select"
                  value={campaignOptionsValue}
                  onChange={handleCampaignChange}
                >
                  <option value="">Selecione a campanha</option>
                  {campaignOptions.map((option, index) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </Input>
              </div>
            </div>
          </Col>
        </Row>
      </ModalBody>

      <Modal isOpen={confirmarModal} toggle={toggleConfirmarModal}>
        <ModalHeader toggle={toggleConfirmarModal} tag="h4">
          Confirmar Fechamento
        </ModalHeader>
        <ModalBody>
          Você tem certeza de que deseja confirmar esta ação?
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleFechadoModal}>
            Cancelar
          </Button>
          <Button color="success" onClick={() => handleConfirmClosing()}>
            Confirmar
          </Button>
        </ModalFooter>
      </Modal>

      <ModalFooter>
        <Button color="secondary" onClick={toggleFechadoModal}>
          Cancelar
        </Button>
        <Button color="success" onClick={handleConfirmarSubmit}>
          Confirmar
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalFechado
