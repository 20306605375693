import React, { useState } from "react";
import { Input } from "reactstrap";

const CalendarFilter = ({ onFilterChange }) => {


  const getCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const dd = String(today.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  };

  const [selectedDate, setSelectedDate] = useState(getCurrentDate);

  const handleDateChange = (e) => {
    const newDate = e.target.value;
    setSelectedDate(newDate);
    onFilterChange(newDate);
  };

  return (
    <div className="calendar-filter" style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
      <Input
        type="date"
        value={selectedDate}
        onChange={handleDateChange}
        style={{ marginBottom: '10px' }}
      />
    </div>
  );
};

export default CalendarFilter;
