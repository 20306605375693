import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';

//import components
import { JobWidgetCharts } from './JobCharts';

const ChartSection = ({ chartsData }) => {
    return (
        <React.Fragment>
            <Row>
                {(chartsData || []).map((item, key) => (
                    <Col lg={4} key={key}>
                        <Card className="mini-stats-wid">
                            <CardBody>
                                <div className="d-flex flex-column">
                                    <div className="flex-grow-1 mb-2">
                                    <p className="text-muted fw-medium" style={{ fontSize: '14px' }}>{item.title}</p>
                                        <h4 className="mb-0">{item.price}</h4>
                                    </div>


                                    <div className="flex-shrink-0 align-self-center">
                                        <JobWidgetCharts dataColors={item.color} series={item.seriesData} dir="ltr" />
                                    </div>

                                </div>
                            </CardBody>
                            <div className="card-body border-top py-2">
                                <p className="mb-0">
                                    <span className={"badge badge-soft-" + item.bagdeColor + " me-1"}>
                                        <i className="bx bx-trending-up align-bottom me-1"></i>
                                        {item.perstangeValue} %
                                    </span>
                                    <small className="text-muted custom-small text-truncate">Acompanhamento mensal</small>
                                    </p>
                            </div>


                        </Card>
                    </Col>
                ))}
            </Row>
        </React.Fragment>
    );
}

export default ChartSection;