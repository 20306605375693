import React, { useEffect, useState, useContext } from 'react';
import { Row, Col, Card, CardBody } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../../components/Common/ChartsDynamicColor";
import { useParams } from "react-router-dom"
import api from "config/api"

import { AuthContext } from "contexts/auth"

const SalesAnalytics = ({ dataColors, dashboard, contato, negociando, semSucesso, fechados }) => {
  const apexsalesAnalyticsChartColors = getChartColorsArray(dataColors);
  const { user } = useContext(AuthContext)
  const { id } = useParams()
  const [colaborador, setColaborador] = useState('')
  // const [fechamento, setFechamento] = useState('')
  // const [negociando, setNegociando] = useState('')
  // const [semSucesso, setSemSucesso] = useState('')
  // const [contato, setContato] = useState('')

  const options = {
    labels: ["Em Contato", "Negociando", "Sem Sucesso", "Fechados"],
    colors: apexsalesAnalyticsChartColors,
    legend: { show: !1 },
    plotOptions: {
      pie: {
        donut: {
          size: "80%",
        },
      },
    },
  };

  return (
    <React.Fragment>
      {
        dashboard ? (
          <Col>
            <Card>
              <CardBody>
                <h4 className="card-title mb-4">Status total dos Captadores</h4>

                <div>
                  <div id="donut-chart">
                    <ReactApexChart
                      options={options}
                      series={[contato, negociando, semSucesso, fechados]}
                      type="donut"
                      height={260}
                      className="apex-charts"
                    />
                  </div>
                </div>

                <div className="text-center text-muted">
                  <Row>
                    <Col xs="3">
                      <div className="mt-4">
                        <p className="mb-2 text-truncate text-dark">
                          <i className="mdi mdi-circle text-primary me-1" />Em Contato
                        </p>
                        <h5>{contato}</h5>
                      </div>
                    </Col>
                    <Col xs="3">
                      <div className="mt-4">
                        <p className="mb-2 text-truncate text-dark">
                          <i className="mdi mdi-circle text-warning me-1" />Negociando

                        </p>
                        <h5>{negociando}</h5>
                      </div>
                    </Col>
                    <Col xs="3">
                      <div className="mt-4">
                        <p className="mb-2 text-truncate text-dark">
                          <i className="mdi mdi-circle text-danger me-1" />Sem Sucesso
                        </p>
                        <h5>{semSucesso}</h5>
                      </div>
                    </Col>
                    <Col xs="3">
                      <div className="mt-4">
                        <p className="mb-2 text-truncate text-dark">
                          <i className="mdi mdi-circle text-success me-1" />Fechados
                        </p>
                        <h5>{fechados}</h5>
                      </div>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
        ) : (
          <Col xl="7">
          </Col>
        )
      }

    </React.Fragment>
  );
};

export default SalesAnalytics;
