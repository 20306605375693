import api from "config/api"
import React, { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { AuthContext } from "contexts/auth"
import {
  Card,
  CardBody,
  Col,
  Row,
  Badge,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import Toastr from "../../../components/Ui/Notifications"
import ButtonInstagram from "./ButtonInstagram/index"

import { useNavigate } from "react-router-dom"

const List = ({ listData }) => {
  const { user } = useContext(AuthContext)
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false)
  const [idInfluencer, setIdInfluencer] = useState(null)
  const [requestArchiving, setRequestArchiving] = useState([])


  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleArchiveConfirm = async () => {
    try {
      await api.put("/influencers/update/status",{
        id: idInfluencer,
        status: 1
      },{
        headers:{
          Authorization: `Bearer ${user.token}`
        }
      }).then(()=>{
        Toastr("success", "Influencer adicionado aos seus influencers!");
        navigate("/tasks-kanban")
      })
    } catch (err) {
      console.error("Erro" + err)
    }
    setShowModal(false)
  }

  const acceptNewInfluencer = async id => {
    setIdInfluencer(id)
    setShowModal(true)
  }

  return (
    <React.Fragment>
      <Row>
        {(listData || []).map((influencer, key) => (
          <Col xl={3} key={key}>
            {/* influencer?.captacao_responsavel?.fullname */}
            <Card>
              <CardBody>
                <div className="d-flex align-start mb-3">
                  <div className="flex-grow-1">
                    <span className={"badge badge-soft-info"}>
                      {influencer
                        ? influencer?.captacao_responsavel?.fullname
                        : "Nome indisponível"}
                    </span>
                  </div>

                  <button
                    type="button"
                    className="btn btn-light btn-sm like-btn"
                    onClick={e => acceptNewInfluencer(influencer.id)}
                  >
                    <i
                      className="mdi mdi-account-check-outline"
                      style={{ fontSize: "14px" }}
                    ></i>
                  </button>
                </div>

                <div className="text-center mb-3">
                  <img
                    src={influencer.photo}
                    alt=""
                    className="avatar-sm rounded-circle"
                  />
                  <h6 className="font-size-15 mt-3 mb-1 text-truncate">
                    {influencer.fullname}
                  </h6>
                  <p className="mb-0 text-muted font-size-12">@{influencer.username}</p>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <ButtonInstagram
                    followers={influencer?.followers}
                    username={influencer?.username}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>

      <Modal isOpen={showModal} toggle={handleCloseModal}>
        <ModalHeader toggle={handleCloseModal}>
          Confirmar novo influencer
        </ModalHeader>

        <ModalBody>
          Após a confirmação o influencer será adicionado ao kanban com status
          "em contato".
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button color="primary" onClick={handleArchiveConfirm}>
            Sim
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default List
