import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
`

export const Sidebar = styled.div`
  display: flex;
  flex: 1.2;
  justify-content: center;
  margin-top: 75px;
  overflow: auto;
  margin-bottom: 50px;
`
export const SidebarHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`
export const SidebarHeaderText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
export const SidebarHeaderH3 = styled.h3`
  text-align: center;
  margin: 10px 0;
`
export const Body = styled.div`
  display: flex;
  flex: 3;
  justify-content: center;
  align-items: center;
`
export const ImagePlugChat = styled.img`
  width: 80px;
`

export const Buttons = styled.button`
  width: 80px;
  font-size: 20px;
`

export const CardBodyColaborador = styled.div``
export const CardBodyColaboradorInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const divButtonEdit = styled.div`
  background-color: red;
  width: 80%;
  height: 10px;
`

export const ImageColaborador = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin: 10px;
`
export const ButtonEditar = styled.button`
  display: flex; // Use flex to align icon and text (if any)
  justify-content: center; // Center content horizontally
  align-items: center; // Center content vertically
  width: auto; // Width as needed
  padding: 8px; // Padding inside the button
  font-size: 18px;
  background-color: #556ee6; // A green background
  color: white; // White text
  border: none; // No border
  border-radius: 4px; // Slightly rounded corners
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #2980b9; // Darker green on hover
  }

`
export const SelectorGroup = styled.div`
  margin: 10px 0px;
`


export const Select = styled.select`
  width: 100%;
  height: 30px;
  border: 1px solid #ccc; /* Adicione uma borda para separar o seletor */
  border-radius: 4px; /* Arredonde as bordas */
  padding: 5px; /* Adicione um pouco de espaço interno para melhor aparência */
  font-size: 14px; /* Tamanho da fonte */
  color: #333; /* Cor do texto */
  background-color: #fff; /* Cor de fundo */
  outline: none; /* Remova a borda ao focar o seletor */
  cursor: pointer;
  
  /* Estilo quando o seletor está desabilitado */
  &:disabled {
    background-color: #f5f5f5; /* Cor de fundo mais clara */
    cursor: not-allowed; /* Cursor de indisponível */
  }
`;

export const SalvarUpdate = styled.button`
  margin: 10px 0px;
  width: 100%;
  height: 30px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #2980b9;
  }
`

export const ButtonsPayments = styled.button`
  width: 50px;
  font-size: 10px;
`

