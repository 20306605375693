import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'reactstrap';
import { AuthContext } from "contexts/auth";
import { archive_Contributor } from "./functionDesativar";
import api from '../../../config/api';

import {
  CardBody,
  Col,
  Row,
  Badge,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

const List = ({ listData }) => {
  const { user } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  const [selectedInfluencer, setSelectedInfluencer] = useState(null);
  const [colaboradores, setColaboradores] = useState([])

  useEffect(() => {
    getCollaborators()
  }, [user.token])

  const updated = () => {
    getCollaborators()
  }

  async function getCollaborators() {
    await api.get("/users/api/3", {
      headers: {
        Authorization: `Bearer ${user.token}`
      }
    }).then((response) => {
      setColaboradores(response.data);
    }).catch((error) => {
      console.error(error);
    })
  }

  const handleOpenModal = (influencer) => {
    setSelectedInfluencer(influencer);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleArchiveConfirm = () => {
    // archive_Contributor(selectedInfluencer.id, colaboradores, user.token, updated)
    handleCloseModal();
  };

  const activeBtn = (ele) => {
    if (ele.closest("button").classList.contains("active")) {
      ele.closest("button").classList.remove("active");
    } else {
      ele.closest("button").classList.add("active");
    }
  };

  const sectorBadgeClasses = (sectorType) => {
    switch (sectorType) {
      case 'Grupos':
        return 'badge badge-soft-success';
      case 'Captação': 
        return 'badge badge-soft-info';
      case 'TI':
        return 'badge badge-soft-danger';
      default:
        return 'badge badge-soft-warning';
    }
  };
  

  return (
    <React.Fragment>
      <Row>

        {(listData || []).map((influencer, key) => (
          <Col xl={3} key={key}>
            <Card>
              <CardBody>
                <div className="d-flex align-start mb-3">
                  <div className="flex-grow-1">
                    <span className={sectorBadgeClasses((influencer.type_user.type))}>
                      {influencer.type_user.type === 'Captação' ? 'Closer' : influencer.type_user.type}
                    </span>

                  </div>
                  <button
                    type="button"
                    className="btn btn-light btn-sm like-btn"
                    onClick={() => handleOpenModal(influencer)}
                  >
                    <i className="mdi mdi-archive-arrow-down-outline" style={{ fontSize: "14px" }}></i>
                  </button>
                </div>
                <div className="text-center mb-3">
                  <img src={influencer.photo} alt="" className="avatar-sm rounded-circle" />
                  <h6 className="font-size-15 mt-3 mb-1">{influencer.fullname}</h6>
                  <p className="mb-0 text-muted font-size-12">{influencer.email}</p>
                </div>
                <div className="mt-4 pt-1">
                  <Link to={`/colaboradores/${influencer.id}`} className="btn btn-soft-primary d-block">Ver Perfil</Link>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>

      {/* Modal do influenciador */}
      <Modal isOpen={showModal} toggle={handleCloseModal}>
        <ModalHeader toggle={handleCloseModal}>
          Arquivar Colaborador
        </ModalHeader>
        <ModalBody>
          Você deseja desativar este colaborador?
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button color="primary" onClick={handleArchiveConfirm}>
            Sim
          </Button>
        </ModalFooter>
      </Modal>
      {/* Final do modal */}

    </React.Fragment>
  );
};

export default List;
