import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from 'contexts/auth';
import { Card, CardBody, Col, Container, Row, Collapse, Input } from 'reactstrap';
import Select from 'react-select';
import api from 'config/api';
import Spinners from 'components/Common/Spinner';
import Breadcrumbs from 'components/Common/Breadcrumb';
import List from './List';
import { CounterBox, CounterTitle, CounterValue } from './styles'; // Importando estilos dos cards



const CollaboratorsList = () => {
    document.title = 'Influencers | Viraw Influencers';

    const [collaborators, setCollaborators] = useState([]);
    const [originalCollaborators, setOriginalCollaborators] = useState([]); // Estado para manter a lista original
    const [filteredByResponsavel, setFilteredByResponsavel] = useState([]); // Novo estado para colaboradores filtrados por responsável
    const [isLoading, setLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const { user } = useContext(AuthContext);
    const [searchValue, setSearchValue] = useState('');
    const [colaboradoresGrupo, setColaboradoresGrupo] = useState([]);
    const [influencersAll, setInfluencersAll] = useState([]);
    const [selectedSector, setSelectedSector] = useState("");
    const [selectedFilterOption, setSelectedFilterOption] = useState("");
    const [total, setTotal] = useState(0);
    const [counters, setCounters] = useState({
        waiting: 0,
        notPosted: 0,
        posted: 0,
    });


    const calculateCounters = (collaborators) => {
        const activeCollaborators = collaborators.filter(collaborator => collaborator.responsavel_rent.active);
        const counters = {
            total: total, 
            waiting: 0,
            notPosted: 0,
            posted: 0,
        };

        // Calcula os contadores apenas para os colaboradores ativos
        activeCollaborators.forEach(collaborator => {
            if (collaborator.influencer_rent.posts) {
                const today = new Date();
                if(collaborator.influencer_rent?.archiving === false){
                    collaborator.influencer_rent.posts.forEach(post => {
                        const postStartDate = new Date(post.start);
                        if (postStartDate.toDateString() === today.toDateString()) {
                            if (post.waiting) counters.waiting++;
                            if (post.posted) counters.posted++;
                            if (!post.posted && !post.waiting) counters.notPosted++;
                        }
                    });
                }
            }
        });

        setCounters(counters);
    };

    useEffect(() => {
        calculateCounters(collaborators);
    }, [collaborators]);



    const filterOptions = [
        { label: "Todos", value: "all" },
        { label: "Aguardando Hoje", value: "today" },
        { label: "Geral Hoje", value: "todayGeral" },
    ];

    const filterIntervalDate = (collaborators) => {
        const create_at = new Date();
        return collaborators.filter((collaborator) => {
            if (collaborator.influencer_rent.posts) {
                const hasMatchingPost = collaborator.influencer_rent.posts.some((post) => {
                    const postStartDate = new Date(post.start);
                    return (
                        postStartDate.toDateString() === startDate.toDateString() &&
                        post.waiting === true
                    );
                });

                return hasMatchingPost;
            }
            return false;
        });
    };
    const filterPostsByStartDate = (collaborators) => {
        const startDate = new Date();
        return collaborators.filter((collaborator) => {
            if (collaborator.influencer_rent.posts) {
                const hasMatchingPost = collaborator.influencer_rent.posts.some((post) => {
                    const postStartDate = new Date(post.start);
                    return (
                        postStartDate.toDateString() === startDate.toDateString() &&
                        post.waiting === true
                    );
                });

                return hasMatchingPost;
            }
            return false;
        });
    };

    const filterPostsByGeral = (collaborators) => {
        const startDate = new Date();
        return collaborators.filter((collaborator) => {
            if (collaborator.influencer_rent.posts) {
                const hasMatchingPost = collaborator.influencer_rent.posts.some((post) => {
                    const postStartDate = new Date(post.start);
                    return (
                        postStartDate.toDateString() === startDate.toDateString()
                    );
                });
                return hasMatchingPost;
            }
            return false;
        });
    };

    const handleFilterChange = (selectedOption) => {
        setSelectedFilterOption(selectedOption);

        let currentCollaborators = filteredByResponsavel.length > 0 ? filteredByResponsavel : originalCollaborators;

        if (selectedOption) {
            let filteredPosts;
            if (selectedOption.value === "today") {
                filteredPosts = filterPostsByStartDate(currentCollaborators);
            } else if (selectedOption.value === "todayGeral") {
                filteredPosts = filterPostsByGeral(currentCollaborators);
            } else {
                filteredPosts = currentCollaborators;
            }
            setCollaborators(filteredPosts);
        } else {
            setCollaborators(currentCollaborators);
        }
    };


    const getInfluencers = async () => {
        setLoading(true);
        try {
            const response = await api.get('/api/influencers/all', {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            setInfluencersAll(response.data);
        } catch (error) {
            console.error('Error fetching collaborators:', error);
        }
        setLoading(false);
    }

    const getCollaborators = async () => {
        setLoading(true);
        try {
            const response = await api.get('/plugchat', {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });

            setCollaborators(response.data);
            setOriginalCollaborators(response.data);

            let colaboradores = response.data.map(element => element.responsavel_rent.fullname);
            colaboradores = [...new Set(colaboradores)];
            setColaboradoresGrupo(colaboradores);
            
        } catch (error) {
            console.error('Error fetching collaborators:', error);
        }
        setLoading(false);
    };

    useEffect(() => {
        getCollaborators();
        getInfluencers();
    }, [user.token]);


    const handleSectorChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        if (selectedOption) {
            const selectedSectorValue = selectedOption.value;

            // Filtrar colaboradores imediatamente após a seleção
            const filteredBySector = originalCollaborators.filter(collaborator =>
                collaborator.responsavel_rent.fullname === selectedSectorValue);
            setFilteredByResponsavel(filteredBySector);
            setCollaborators(filteredBySector);
        } else {
            setFilteredByResponsavel([]);
            setCollaborators(originalCollaborators);
        }
    };

    const handleSearchCollaborator = (value) => {
        setSearchValue(value);
        const lowerCaseValue = value.toLowerCase();

        const filteredCollaborators = originalCollaborators.filter(collaborator => {
            const fullname = collaborator.influencer_rent?.fullname?.toLowerCase() || '';
            const username = collaborator.influencer_rent?.username?.toLowerCase() || '';
            return fullname.includes(lowerCaseValue) || username.includes(lowerCaseValue);
        });
        setCollaborators(filteredCollaborators);

        if (value.trim() === '') {
            setSelectedOption(null);
            setCollaborators(originalCollaborators);
        }
    };
    useEffect(() => {
        setLoading(true);
        if (user.token && user.userId) {
          api.get(`graphics/admin/${user.userId}`, {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          })
            .then(response => {
              const data = response.data;
              setTotal(data.graphic1.ativos);
              setLoading(false);
            })
            .catch(error => {
              console.error("Erro ao carregar os Dados:", error);
              setLoading(false);
            });
        }
      }, [user.token, user.userId]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <Breadcrumbs title="Influencers" breadcrumbItem="Influencers" />
                    <Row>
                        <Col lg={12}>
                            <Card className="collaborator-filter">
                                <Row className="d-flex justify-content-center">

                                    <Col sm={6} md={3}>
                                        <CounterBox className="bg-muted">
                                            <CounterTitle className="text-muted">Total Ativos</CounterTitle>
                                            <CounterValue className="text-muted">{total}</CounterValue>
                                        </CounterBox>
                                    </Col>
                                    <Col sm={6} md={3}>
                                        <CounterBox className="bg-muted">
                                            <CounterTitle className="text-muted">Aguardando</CounterTitle>
                                            <CounterValue className="text-muted">{counters.waiting}</CounterValue>
                                        </CounterBox>
                                    </Col>
                                    <Col sm={6} md={3}>
                                        <CounterBox className="bg-muted">
                                            <CounterTitle className="text-muted">Não Postou</CounterTitle>
                                            <CounterValue className="text-muted">{counters.notPosted}</CounterValue>
                                        </CounterBox>
                                    </Col>
                                    <Col sm={6} md={3}>
                                        <CounterBox className="bg-muted">
                                            <CounterTitle className="text-muted">Postou</CounterTitle>
                                            <CounterValue className="text-muted">{counters.posted}</CounterValue>
                                        </CounterBox>
                                    </Col>
                                </Row>
                                <CardBody>
                                    <form action="#">
                                        <Row className="g-3">
                                            <Col xxl={4} lg={4}>
                                                <div className="position-relative">
                                                    <Input
                                                        type="text"
                                                        id="searchCollaborator"
                                                        autoComplete="off"
                                                        placeholder="Buscar Influenciador"
                                                        onChange={(e) => handleSearchCollaborator(e.target.value)}
                                                        value={searchValue}
                                                    />
                                                </div>
                                            </Col>
                                            <Col xxl={2} lg={4}>
                                                <div className="position-relative">
                                                    <Select
                                                        className="select2"
                                                        onChange={handleSectorChange}
                                                        options={colaboradoresGrupo.map(colaborador => ({
                                                            value: colaborador,
                                                            label: colaborador
                                                        }))}
                                                        value={selectedOption}
                                                        placeholder="Filtrar por responsável"
                                                        isClearable
                                                    />
                                                </div>
                                            </Col>
                                            <Col xxl={2} lg={4}>
                                                <div className="position-relative">
                                                    <Select
                                                        className="select2"
                                                        onChange={handleFilterChange}
                                                        value={selectedFilterOption}
                                                        options={filterOptions}
                                                        placeholder="Filtrar Posts"
                                                        isClearable
                                                    />
                                                </div>
                                            </Col>

                                            <Collapse isOpen={isOpen} id="collapseExample">
                                            </Collapse>
                                        </Row>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {isLoading ? <Spinners setLoading={setLoading} /> : <List listData={collaborators} />}
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CollaboratorsList;
