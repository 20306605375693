import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import withRouter from "components/Common/withRouter";
import "assets/scss/datatables.scss";

import { AuthContext } from "contexts/auth";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import WalletActivities from "./walletActivities";
import Profile from "./profile";
import WalletOverView from "./walletOverView";
import api from "config/api";
import { FcMoneyTransfer } from "react-icons/fc";
import JobVacancy from "./JobVacancy";




const Tracking = () => {
  const {username} = useParams();
  const {user} = useContext(AuthContext);

  const [isLoading, setLoading] = useState(true);

  const [influencer, setInfluencer] = useState([]);
  const [trackingGeral, setTrackingGeral] = useState([]);
  const [trackingAll, setTrackingAll] = useState([]);
  const [TotalPago, setTotalPago] = useState();
  const [depositos, setDepositos] = useState();
  const [firstDeposits, setFirstDeposits] = useState();
  const [rev, setRev] = useState();
  const [cpa, setCpa] = useState();
  const [commission, setCommission] = useState();
  const [mensal, setMensal] = useState([]);

  useEffect(()=>{
    async function getTrackingData(){
        await api.get(`/tracking/superbet/geral/all`,{
          headers:{
            Authorization: `Bearer ${user.token}`
          }
        }).then((response) =>{

    
          const result = response.data.filter(item => item.username === username);
          
          let influencer = {
            username: result[0]?.username,
            fullname: result[0]?.fullname,
            company: result[0]?.company,
            assessor: result[0]?.assessor,
            captador: result[0]?.captador,
            responsavel: result[0]?.responsavel,
            photo: result[0]?.photo,
            posts: result[0]?.posts,
            plugchat: result[0]?.plugchat,
          }
      
          setMensal(result[0]?.Mensal);
          setInfluencer(influencer);
          setTrackingGeral(result[0]?.Total);
          setTrackingAll(result[0]?.Diario);
          setTotalPago(result[0]?.Total?.ValorInvestido);
        
          let DepositsArray = []
          let FTDs = []
          let Rev = []
          let CPA = []
          let CommisionArray = []

          const Meses = result[0]?.Mensal;
      
          Meses.sort((a, b) => {
            const [anoA, mesA] = a.Period.split('-').map(Number);
            const [anoB, mesB] = b.Period.split('-').map(Number);
            
            if (anoA !== anoB) {
                return anoA - anoB;
            } else {
                return mesA - mesB;
            }
        });

          Meses.forEach(element => {
            DepositsArray.push(element?.Deposits)
            FTDs.push(element?.NewAccPurchases)
            Rev.push(element?.PercentCommission)
            CPA.push(element?.CPACommission)
            CommisionArray.push(element?.TotalCommission)
          });

          setDepositos(DepositsArray)
          setFirstDeposits(FTDs)
          setRev(Rev)
          setCpa(CPA)
          setCommission(CommisionArray)

        })
    }
    getTrackingData()
  },[user.token])




  const wallet = {
  series: [
  
    {
      type: "area",
      name: "Depósitos",
      data: depositos,
    },
    {
      type: "area",
      name: "FTDs",
      data: firstDeposits,
    },
    {
      type: "area",
      name: "Commission Rev",
      data: rev,
    },

    {
      type: "line",
      name: "Commission CPA",
      data: cpa,
    },
    {
      type: "line",
      name: "Commission Total",
      data: commission,
    },
  ],
  options: {
    chart: { toolbar: { show: !1 } },
    dataLabels: { enabled: !1 },
    stroke: { curve: "smooth", width: 2, dashArray: [0, 0, 3] },
    fill: { type: "solid", opacity: [0.15, 0.05, 1] },
    xaxis: {
      categories: [
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
      ],
    },
    colors: ["#f1b44c", "#3452e1", "#187623", "#50a5f1", ],
  }
}


  function formatarParaMoeda(valor) {
    const valorFormatado = Number(valor).toFixed(2);
    return "R$ " + valorFormatado.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Estatísticas" breadcrumbItem="Influencer" />
          {!isEmpty(wallet) && (
            <Row>
              <Col xl="4">
               <Profile
                  data={influencer}
                  geral={trackingGeral}
                /> 
              </Col>
              <Col xl="8">

            
                <Row>
                  <Col sm="6">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="me-3 align-self-center">
                            <i className="bx bxs-dollar-circle h2 text-primary mb-0" />
                          </div>
                          <div className="flex-grow-1">
                            <p className="text-muted mb-2">Total Pago</p>
                            <h5 className="mb-0">
                             {formatarParaMoeda(TotalPago)}
                            </h5>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col sm="6">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="me-3 align-self-center">
                          <i className="mdi mdi-bank-transfer-in text-primary h1" />
                          </div>
                          <div className="flex-grow-1">
                            <p className="text-muted mb-2">Depósitos</p>
                            <h5 className="mb-0">
                              {formatarParaMoeda(trackingGeral?.Deposits)}
                            </h5>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                    
                </Row>

                  <Row>
                    <Col sm="4">            
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="me-3 align-self-center">
                          <i className="mdi mdi-cash-multiple text-primary h1" />
                          </div>
                          <div className="flex-grow-1">
                            <p className="text-muted mb-2">Comissão CPA</p>
                            <h5 className="mb-0">
                            {formatarParaMoeda(trackingGeral?.CPACommission)}                      
                            </h5>
                          </div>
                        </div>                      
                      </CardBody>
                    </Card>  
                  </Col>
                    <Col sm="4">            
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="me-3 align-self-center">
                          <i className="mdi mdi-cash-multiple text-primary h1" />
                          </div>
                          <div className="flex-grow-1">
                            <p className="text-muted mb-2">Comissão REV</p>
                            <h5 className="mb-0">
                            {formatarParaMoeda(trackingGeral?.PercentCommission)}                      
                            </h5>
                          </div>
                        </div>                      
                      </CardBody>
                    </Card>  
                  </Col>
                    <Col sm="4">            
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="me-3 align-self-center">
                          <i className="mdi mdi-cash-multiple text-primary h1" />
                          </div>
                          <div className="flex-grow-1">
                            <p className="text-muted mb-2">Comissão Total</p>
                            <h5 className="mb-0">
                            {formatarParaMoeda(trackingGeral?.TotalCommission)}                      
                            </h5>
                          </div>
                        </div>                      
                      </CardBody>
                    </Card>  
                  </Col>


                </Row>
                  <WalletOverView wallet={wallet} />
              </Col>
            </Row>
          )}
     
          <Row>
            <Col lg="12">
              {!isEmpty(wallet) && (
                <WalletActivities  Trackings={trackingAll} influencer={influencer} isLoading={isLoading} setLoading={setLoading} />
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Tracking);
