import React from "react"
import { Routes, Route } from "react-router-dom"

import Dashboard from "../pages/Comercial/Dashboard/index"
import Influencers from "../pages/Comercial/InfluencerList"
import InfluencersAll from "../pages/Comercial/InfluencerListAll"
import Pendencias from "../pages/Comercial/InfluencerListPendentes"
import Arquivados from "../pages/Comercial/InfluencerListArquivados"
import SemSucesso from "../pages/Comercial/InfluencerListSemSucesso"

import InfluencerProfile from "pages/Grupos/Calendar/index"
import ColaboradoresProfile from "pages/Comercial/ColaboradoresProfile/ContactsProfile/contacts-profile"
import ColaboradoresProfiles from "pages/Comercial/CandidateList"
import Scheduling from "pages/Comercial/Scheduling"
import ColaboradorArquivado from "pages/Comercial/ColaboradorArquivado"

export default function AppRoutes() {
  return (
    <Routes>
      <Route index path="/" element={<Dashboard />} />
      <Route index path="/influencers" element={<Influencers />} />
      <Route index path="/influencers/all" element={<InfluencersAll />} />
      <Route index path="/pendentes" element={<Pendencias />} />
      <Route index path="/arquivados" element={<Arquivados />} />
      <Route index path="/semsucesso" element={<SemSucesso />} />
      <Route index path="/influencer/:id" element={<InfluencerProfile />} />
      <Route index path="/dashboard" element={<Dashboard />} />
      <Route index path="/scheduling" element={<Scheduling />} />
      {/* <Route index path="/colaboradores" element={<Colaboradores />}  /> */}
      <Route
        index
        path="/colaboradores/:id"
        element={<ColaboradoresProfile />}
      />
      <Route index path="/colaboradores" element={<ColaboradoresProfiles />} />
      <Route index path="/desativados" element={<ColaboradorArquivado />} />
      <Route path="*" element={<Dashboard />} />
    </Routes>
  )
}
