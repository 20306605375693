import React from "react"
import { Row, Col, Card, CardBody, CardTitle, Container } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// Charts
import LineBar from "../AllCharts/echart/linebarchart"


const EChart = () => {
  
  //meta title
  document.title = "E Charts | Skote - React Admin & Dashboard Template"

  return (
    <React.Fragment>
            <Col lg="10">
              <Card>
                <CardBody>
                  <CardTitle>Mix Line-Bar</CardTitle>
                  <div id="mix-line-bar" className="e-chart">    
                    <LineBar dataColors='["--bs-success","--bs-primary", "--bs-danger"]'/>
                  </div>
                </CardBody>
              </Card>
            </Col>
    </React.Fragment>
  )
}

export default EChart
