import React from "react"
import { Routes, Route } from "react-router-dom"

import Dashboard from "../pages/Externo/Dashboard"
import Influencers from "../pages/Externo/InfluencerList"
import Arquivados from "../pages/Externo/InfluencerListArquivados"

import Estatisticas from "../pages/Externo/TrackingCode/"
import DashSuper from "../pages/Externo/DashboardSuperBet/"

import InfluencerProfile from "pages/Externo/Calendar/index"
import TrackingPerfil from "../pages/Externo/TrackingPerfil"

export default function AppRoutes() {
  return (
    <Routes>
      <Route index path="/" element={<Dashboard />} />
      <Route index path="/arquivados" element={<Arquivados />} />
      <Route index path="/influencer/:id" element={<InfluencerProfile />} />
      <Route index path="/dashboard" element={<Dashboard />} />
      <Route index path="/estatisticas" element={<Estatisticas />} />
      <Route index path="/dashboard-super" element={<DashSuper />} />
      <Route
        index
        path="/tracking-perfil/:username"
        element={<TrackingPerfil />}
      />
      {/* <Route index path="/colaboradores" element={<Colaboradores />}  /> */}

      <Route path="*" element={<Dashboard />} />
    </Routes>
  )
}
