import React, { useEffect, useContext, useState } from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Col } from "reactstrap"
import "./overview.css"
import {
  CardBodyColaborador,
  ImagePlugChat,
  CardBodyColaboradorInfo,
  ImageColaborador,
  Buttons,
  SidebarHeader,
  SidebarHeaderText,
  SidebarHeaderH3,
  SelectorGroup,
  divButtonEdit,
  Select,
  SalvarUpdate,
  ButtonEditar,
  ButtonsPayments
} from "./styles"

import Toastr from "../../../components/Ui/Notifications"

import PlugChatImg from "../../../assets/images/plugchat.png"
import api from "config/api"
import { AuthContext } from "contexts/auth"
import { StateContext } from "contexts/states"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import AddPaymentModal from "../../../components/Modals/AddPaymentModal"; // Importe o componente AddPaymentModal
import PaymentDetailsModal from "../../../components/Modals/PaymentDetailsModal"; // Importe o componente PaymentDetailsModal
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import EditValuePayment from "../../../components/Modals/EditValuePayment";
import EditObservation from "../../../components/Modals/EditObservation";

//import images
import adobephotoshop from "../../../assets/images/companies/adobe-photoshop.svg"
import { verificarImagem } from "utils/verificationImage"

const Overview = ({ informations, photo }) => {
  const { user } = useContext(AuthContext)
  const { addValue, setValue, addValueSemanal, setAddValueSemanal, detailsValue, setDetailsValue } = useContext(StateContext)
  const [showEditObservation, setShowEditObservation] = useState(false);
  const [observation, setObservation] = useState();
  const { addObservation } = useContext(StateContext)

  const [buttonEdit, setButtonEdit] = useState(false)
  const [selecao, setSelecao] = useState("")
  const [userGroup, setUserGroup] = useState([])
  const [usersCaptacao, setUsersCaptacao] = useState([])



  const [isEditingCaptado, setIsEditingCaptado] = useState(false);
  const [information, setInformations] = useState("")

  const handleEditObservationClick = () => {
    setShowEditObservation(true);
  };

 

  const onChangeValue = (newValue) => {
    setInformations(newValue)
  }
  
  const onChangeValueObservation = (newValue) => {
    setObservation(newValue)
  }
/* 
  useEffect(() => {
    async function getUsers() {
      await api
        .get("users/all", {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        })
        .then(response => {
          let usersGrupo = []
          let usersCaptacao = []
          response.data.forEach(element => {
            if (element.type_user.type === "Grupos") {
              usersGrupo.push(element)
            } else if
              (element.type_user.type === "Captação") {
              usersCaptacao.push(element)
            }
          })
          setUserGroup(usersGrupo)
          setUsersCaptacao(usersCaptacao)
        })
        .catch(error => {
          console.error("Erro " + error)
        })
    }

    getUsers()
  }, [user.token]) */




  async function postUpdatedResponsible() {
    await api
      .patch(
        "/plugchat/update/responsavel",
        {
          id: informations.id,
          responsavel: selecao,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      .then(response => {
        Toastr("success", "Responsável alterado!")
        // Atualize o estado local do responsável para refletir a mudança instantaneamente
        const selectedUser = userGroup.find(user => user?.id === selecao)
        if (selectedUser) {
          setResponsavel(selectedUser)
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  // async function postUpdatedCaptado() {
  //   await api
  //     .patch(
  //       "/influencers/update/captacao",
  //       {
  //         id: informations.influencer_rent.id,
  //         captacao: selectedCaptado,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${user.token}`,
  //         },
  //       }
  //     )
  //     .then(response => {
  //       Toastr("success", "Captador alterado!");
  //       // setSelectedCaptado(selectedCaptado);

  //       const selectedUser = usersCaptacao.find(user => user?.id === selectedCaptado)
  //       if (selectedUser) {
  //         setCaptacao(selectedUser)
  //       }
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  // }

  const handleAddPaymentClick = () => {
    setValue(true);
  };

  const handleEditValueClick = () => {
    setAddValueSemanal(true);
  };

  function redirect(link) {
    window.open(link, "_blank")
  }

  const handleSelecaoChange = event => {
    setSelecao(event.target.value)
  }

  const handleUpdatedChange = event => {
    setIsEditingCaptado(!isEditingCaptado);
  }
  const handleUpdatedChangeReponsavel = event => {
    setButtonEdit(!buttonEdit)
  }

  const handlePaymentDetailsClick = () => {
    setDetailsValue(true);
  };

  const toggleObservation = () =>{
    setShowEditObservation(!showEditObservation);
  }

  const formatar = Intl.NumberFormat('en', {
    notation: 'compact'
})


  return (
    <React.Fragment>
      <Col>
        <Card>
          <CardBody>
            <SidebarHeader>
              <div>

              <div>
              {showEditObservation && <EditObservation id={informations.influencer_rent?.id} observation={observation} controlModal={showEditObservation} toggle={toggleObservation} onChangeValueObservation={onChangeValueObservation}  />}
            </div>


                <img
                  style={{
                    width: '100px',
                    height: '100px',
                    borderRadius: '50%'
                  }}
                  className="photo-sidebar"
                  src={photo}
                  alt="foto de perfil influencer"
                />
              </div>

              <SidebarHeaderText>
                <SidebarHeaderH3>
                  {informations.influencer_rent?.fullname}
                </SidebarHeaderH3>
                <h5>@{informations.influencer_rent?.username}</h5>
              </SidebarHeaderText>
              <div className="sidebar-header-seguidores">
                <h6>Seguidores: {formatar.format(informations.influencer_rent?.followers)}</h6>
                <h6>Seguindo: {formatar.format(informations.influencer_rent?.following)}</h6>
              </div>

              <div className="hstack gap-2">
                <Buttons
                  onClick={() => redirect(`https://instagram.com/${informations.influencer_rent?.username}`)}
                  className="btn btn-soft-danger w-100"
                >
                  <i className="bx bxl-instagram"></i>
                </Buttons>
              </div>
            </SidebarHeader>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default Overview;