//INSERIR TODAS AS ROTAS DO USUÁRIO QUE NÃO PRECISAM ESTAR LOGADO

import React from 'react';
import { Routes, Route } from "react-router-dom";


//Contacts

import ContactList from "../pages/Captacao/Contacts/ContactList/contacts-list";
import Dashboard from "../pages/Captacao/Dashboard-saas";
import Echart from "../pages/Captacao/Charts/EChart";
import Profile from "../pages/Captacao/Contacts/ContactsProfile/contacts-profile";
import Kanban from "../pages/Captacao/Tasks/tasks-kanban";
import KanbanNews from "../pages/Captacao/KanbanNew"
import NovosInfluencers from "../pages/Captacao/InfluencerList/index"
import Ranking from "../pages/Captacao/Ranking/index"
import Arquivados from "../pages/Captacao/InfluencerListArquivados/index"
import InfluFtds from "../pages/Captacao/InfluFds/index"
import Meeting from "../pages/Captacao/Meeting/index"
import Scheduling from "../pages/Captacao/Scheduling"
import Metrics from "../pages/Captacao/Metrics"

import PrimeiraAbordagem from "../pages/Admin/InfluencerListPrimeiraAbordagem"
import ConversaIniciada from "../pages/Admin/InfluencerListConversaIniciada"
import ConviteWhats from "../pages/Admin/InfluencerListConvitesWhats"
import WhatsCaptado from "../pages/Admin/InfluencerListWhatsCaptado";
import WhatsRespondidos from "../pages/Admin/InfluencerListWhatsRespondidos";
import FollowUp from "../pages/Admin/InfluencerListFollowUp";
import SemSucesso from "../pages/Admin/InfluencerListSemSucesso"
import ArquivadosReset from "../pages/Admin/InfluencerListArquivados"

const userId = "816d47ca-774e-4951-b986-ab85c1025e02"; 
const requiredId = "816d47ca-774e-4951-b986-ab85c1025e02"; 
const redirectTo = "/acesso-negado"; 

export default function AppRoutes() {
  return (
    <Routes>
      <Route index path="/" element={<Dashboard />}  />
      <Route index path="/contatos" element={<ContactList />}  />
      <Route index path="/dashboard" element={<Dashboard />}  />
      <Route index path="/contacts-profile" element={<Profile />}  />
      <Route index path="/e-chart" element={<Echart />}  /> 
      <Route index path="/tasks-kanban" element={<Kanban />}  />

      <Route index path="/kanban2" element={<KanbanNews />}  />

      <Route index path="/new-influencers" element={<NovosInfluencers />} />
      <Route index path="/arquivados" element={<Arquivados />} />
      <Route index path="/influencersftds" element={<InfluFtds />} />
      <Route index path="/ranking" element={<Ranking />} />
      <Route index path="/metrics" element={<Metrics />} />
      <Route index path="/scheduling" element={<Scheduling />} />

      <Route index path="/primeira-abordagem" element={<PrimeiraAbordagem />} />
      <Route index path="/arquivados-reset" element={<ArquivadosReset />} />
      <Route index path="/conversa-iniciada" element={<ConversaIniciada />} />
      <Route index path="/convite-whats" element={<ConviteWhats />} />
      <Route index path="/whats-captados" element={<WhatsCaptado />} />
      <Route index path="/whats-respondidos" element={<WhatsRespondidos />} />
      <Route index path="/follow-up" element={<FollowUp />} />
      <Route index path="/semsucesso" element={<SemSucesso />} />


      <Route path="*" element={<Dashboard />} />
    </Routes>
  );
}
