import React, { useContext, useState, useEffect } from "react";
import { Modal, Button, Form, FormGroup, Label, Input, ModalFooter } from "reactstrap";
import { AuthContext } from "contexts/auth";
import Toastr from "../../Ui/Notifications";
import api from "config/api";

const EditBankDataModal = ({ isOpen, toggle, influencerId }) => {

  const [meta, setMeta] = useState('');
  const [valor, setValor] = useState('');
  const [valorExcedente, setValorExcedente] = useState('')
  const [dataInicial, setDataInicial] = useState('');
  const [dataFinal, setDataFinal] = useState('');
  const { user } = useContext(AuthContext);

  const idInfluencer = influencerId 

  const handleDataChangeI = (e) => {
    setDataInicial(e.target.value); 
  };

  const handleChangeMeta = (e) => {
    setMeta(e.target.value)
  }

  const handleDataChangeF = (e) => {
    setDataFinal(e.target.value); 
  };

  const formatarValorEmReal = (valor) => {
    const parteInteira = Math.floor(valor);
    const parteDecimal = Math.round((valor - parteInteira) * 100);

    const parteInteiraFormatada = new Intl.NumberFormat('pt-BR').format(parteInteira);
    return `${parteInteiraFormatada},${parteDecimal.toString().padStart(2, '0')}`;
  };

  const formatarMoeda = (valor) => {
    const numeroLimpo = valor.replace(/[^0-9]/g, '');
    const valorNumerico = parseFloat(numeroLimpo) / 100;
    return formatarValorEmReal(valorNumerico);
  };

  const handleValorChangeI = (e) => {
    const value = e.target.value;
    const formattedValue = formatarMoeda(value); 
    setValor(formattedValue); 
  };

  const handleValorChangeF = (e) => {
    const value = e.target.value;
    const formattedValue = formatarMoeda(value); 
    setValorExcedente(formattedValue); 
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const valueToSendI = `R$ ${valor}`;
    const valueToSendF = `R$ ${valorExcedente}`
    const now = new Date()
    const formattedDateInicial = `${dataInicial || now.toISOString().split('T')[0]} ${now.getHours().toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}:00.000`;
    const formattedDateFinal = `${dataFinal || now.toISOString().split('T')[0]} ${now.getHours().toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}:00.000`;

    try {
        const response = await api.post(`/profile/influencer/meta/`, {
            advanceValue: "R$ 0,00",
            dateFinal: formattedDateFinal,
            dateInit: formattedDateInicial,
            influencer: idInfluencer,
            isAdvanceValue: false,
            isDailyPayment: false,
            meta: meta,
            valueMin: valueToSendI,
            valueBonus: valueToSendF
        }, {
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        })
        toggle()
        Toastr("sucess", "Meta Criada.")
        setTimeout(() => {
          window.location.reload();
        }, 3000);
    } catch (error) {
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <div className="modal-header">
        <h5 className="modal-title">Criar Meta Para o Influenciador</h5>
        <button type="button" className="btn-close" onClick={toggle}></button>
      </div>
      <div className="modal-body">
        <Form>
          <FormGroup>
            <Label for="name">Meta</Label>
            <Input
              type="text"
              id="name"
              placeholder="Informe a Meta"
              value={meta}
              onChange={handleChangeMeta}
            />
          </FormGroup>
          <FormGroup>
            <Label for="cpf">Valor FTD</Label>
            <Input
              type="text"
              id="cpf"
              placeholder="Informe o Valor do FTD"
              value={valor}
              onChange={handleValorChangeI}
            />
          </FormGroup>

          <FormGroup>
            <Label for="pix">Valor Excedente (Bônus)</Label>
            <Input
              type="text"
              id="pix"
              placeholder="Informe o Valor BÔNUS do FTD"
              value={valorExcedente}
              onChange={handleValorChangeF}
            />
          </FormGroup>

          <FormGroup>
            <Label className="form-label">Data Inicial da Meta</Label>
                <Input
                name="data"
                type="date"
                value={dataInicial}
                onChange={handleDataChangeI}
            />
          </FormGroup>

          <FormGroup>
            <Label className="form-label">Data de Encerramento da Meta</Label>
                <Input
                name="data"
                type="date"
                value={dataFinal}
                onChange={handleDataChangeF}
            />
          </FormGroup>

        </Form>
      </div>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Fechar</Button>
        <Button color="primary" onClick={handleSubmit}>Salvar</Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditBankDataModal;