import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";

const SalaList = ({ salas, onTimeSelect, reservas }) => {
  const [localReservas, setLocalReservas] = useState(reservas);

  useEffect(() => {
    setLocalReservas(reservas);
  }, [reservas]);

  if (!Array.isArray(salas) || salas.length === 0) {
    return <div>Não há horários disponíveis para o dia selecionado.</div>;
  }

  const generateHours = () => {
    const hours = [];
    for (let i = 8; i <= 20; i++) {
      const hour = i < 10 ? `0${i}:00` : `${i}:00`;
      hours.push(hour);
    }
    return hours;
  };

  const availableHours = generateHours();

  const reservasPorHora = availableHours.reduce((acc, hour) => {
    acc[hour] = localReservas[hour] || [];
    return acc;
  }, {});

  return (
    <div className="sala-list">
      <div className="sala-item">
        <h3>Agende um horário</h3>
        <div className="times">
          {availableHours.map((hour, idx) => {
            const isReserved = reservasPorHora[hour].length > 0;

            return (
              <Button
                key={idx}
                onClick={() => onTimeSelect(salas[0].date, hour)}
                style={{
                  backgroundColor: isReserved ? "red" : "#28a745",
                  color: "white",
                  margin: "5px",
                }}
              >
                {hour}
              </Button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SalaList;
