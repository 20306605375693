import React, { useState, useEffect, useContext } from "react"
import { AuthContext } from "contexts/auth"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Collapse,
  Input,
} from "reactstrap"
import Select from "react-select"
import api from "config/api"
import Spinners from "components/Common/Spinner"
import Breadcrumbs from "components/Common/Breadcrumb"
import List from "./List"
import { CounterBox, CounterTitle, CounterValue } from "./styles"

const CollaboratorsList = () => {
  document.title = "Influencers | Viraw Influencers"

  const [collaborators, setCollaborators] = useState([])
  const [originalCollaborators, setOriginalCollaborators] = useState([])
  const [filteredByResponsavel, setFilteredByResponsavel] = useState([])
  const [isLoading, setLoading] = useState(true)
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)
  const { user } = useContext(AuthContext)
  const [searchValue, setSearchValue] = useState("")
  const [colaboradoresGrupo, setColaboradoresGrupo] = useState([])
  const [colaboradoresCloser, setColaboradoresCloser] = useState([])
  const [influencersAll, setInfluencersAll] = useState([])
  const [selectedSector, setSelectedSector] = useState("")
  const [selectedFilterOption, setSelectedFilterOption] = useState("")
  const [activeUsers, setActiveUsers] = useState(0)
  const [counters, setCounters] = useState({
    valores: 0,
    naoDivulga: 0,
    naoRespondeu: 0,
    outros: 0,
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("/influencers", {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        })
        const data = response.data

        let temp = []

        data.forEach(element => {
          if (element.status === 2) {
            temp.push(element)
          }
        })
        setCollaborators(temp)
        setOriginalCollaborators(temp)

        const active = data.filter(influencer => influencer.status === 2).length
        setActiveUsers(active)
      } catch (error) {
        console.error("Error fetching influencers:", error)
      }
      setLoading(false)
    }

    fetchData()
  }, [user.token])



  const getCollaborators = async () => {
    setLoading(true)
    try {
      const response = await api.get("/users/api/2", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      const data = response.data
      // setCollaborators(data);
      // setOriginalCollaborators(data);
      let colaboradores = data.map(element => element?.fullname)
      colaboradores = [...new Set(colaboradores)]
      setColaboradoresGrupo(colaboradores)
    } catch (error) {
      console.error("Error fetching collaborators:", error)
    }
    setLoading(false)
  }

  useEffect(() => {
    getCollaborators()
  }, [user.token])

  const handleSectorChange = selectedOption => {
    setSelectedOption(selectedOption)
    if (selectedOption) {
      const selectedSectorValue = selectedOption.value
      const filteredBySector = originalCollaborators.filter(
        collaborator =>
          collaborator?.captacao_influencer?.fullname === selectedSectorValue
      )
      setFilteredByResponsavel(filteredBySector)
      setCollaborators(filteredBySector)
    } else {
      setFilteredByResponsavel([])
      setCollaborators(originalCollaborators)
    }
  }
  const handleSearchCollaborator = value => {
    setSearchValue(value)
    const lowerCaseValue = value.toLowerCase()
    const filteredCollaborators = originalCollaborators.filter(collaborator => {
      const fullname = collaborator?.fullname
        ? collaborator?.fullname.toLowerCase()
        : ""
      const username = collaborator?.username
        ? collaborator?.username.toLowerCase()
        : ""
      return (
        fullname.includes(lowerCaseValue) || username.includes(lowerCaseValue)
      )
    })
    setCollaborators(filteredCollaborators)

    if (value.trim() === "") {
      setSelectedOption(null)
      setCollaborators(originalCollaborators)
    }
  }

  const tema = localStorage.getItem("tema")

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Influencers" breadcrumbItem="Influencers" />
          <Row>
            <Col lg={12}>
              <Card className="collaborator-filter">
              
                <CardBody>
                  <form action="#">
                    <Row className="g-3">
                      <Col xxl={4} lg={4}>
                        <div className="position-relative">
                          <Input
                            type="text"
                            id="searchCollaborator"
                            autoComplete="off"
                            placeholder="Buscar Influenciador"
                            onChange={e =>
                              handleSearchCollaborator(e.target.value)
                            }
                            value={searchValue}
                          />
                        </div>
                      </Col>
 
                      <Col xxl={2} lg={4}>
                        <div className="position-relative">
                          <Select
                            className="select2"
                            onChange={handleSectorChange}
                            options={colaboradoresGrupo.map(colaborador => ({
                              value: colaborador,
                              label: colaborador,
                            }))}
                            value={selectedOption}
                            placeholder="Filtrar por Closer"
                            isClearable
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: base => ({
                                ...base,
                                zIndex: 9999,
                              }),
                              control: base => ({
                                ...base,
                                minHeight: "36px",
                                backgroundColor:
                                  tema === "light" ? "#FFF" : "#2A3042",
                              }),
                              placeholder: base => ({
                                ...base,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                maxWidth: "100%",
                                color: tema === "light" ? "#000" : "#C3CBE4",
                              }),
                              singleValue: base => ({
                                ...base,
                                color: tema === "light" ? "#000" : "#FFF",
                              }),
                              menu: base => ({
                                ...base,
                                backgroundColor:
                                  tema === "light" ? "#FFF" : "#2A3042",
                              }),
                              option: (base, state) => ({
                                ...base,
                                color: tema === "light" ? "#000" : "#FFF",
                                backgroundColor:
                                  tema === "light" ? "#FFF" : "#2A3042",
                              }),
                            }}
                          />
                        </div>
                      </Col>

                      <Collapse isOpen={isOpen} id="collapseExample"></Collapse>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <List listData={collaborators} />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CollaboratorsList
