//INSERIR TODAS AS ROTAS DO USUÁRIO QUE NÃO PRECISAM ESTAR LOGADO

import React from 'react';
import { Routes, Route } from "react-router-dom";


//Contacts

import ContactList from "../pages/Hunter/Contacts/ContactList/contacts-list";
import Dashboard from "../pages/Hunter/Dashboard-saas";
import Echart from "../pages/Hunter/Charts/EChart";
import Profile from "../pages/Hunter/Contacts/ContactsProfile/contacts-profile";
import Colaborador from "../pages/Hunter/InfluencerList/index";


export default function AppRoutes() {
  return (
    <Routes>
      <Route index path="/" element={<Dashboard />} />
      <Route index path="/contatos" element={<ContactList />} />
      <Route index path="/dashboard" element={<Dashboard />} />
      <Route index path="/contacts-profile" element={<Profile />} />
      <Route index path="/e-chart" element={<Echart />} />
      <Route index path="/colaborador" element={<Colaborador />} />

      <Route path="*" element={<Dashboard />} />
    </Routes>
  );
}
