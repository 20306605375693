import React, { useState, useContext } from "react";
import api from "config/api";
import { AuthContext } from "../../../contexts/auth";
import { StateContext } from "contexts/states";
import {
  Label,
  Input,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
} from "reactstrap";
import Toastr from "../../Ui/Notifications"

const ModalCreateInfluencer = ({ updateInfluencersList, id, email, fullname, photo, type }) => {
  const { user } = useContext(AuthContext);
  const { editmodal, setEditModal } = useContext(StateContext);

  const [influencerData, setInfluencerData] = useState({
    id: id,
    email: email,
    fullname: fullname,
    photo: photo,
    type: type, 
  });

  const [isSubmitting, setIsSubmitting] = useState(false); // Controle de envio do formulário

  const toggle = () => {
    setEditModal(!editmodal);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInfluencerData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Ative o controle de envio

    try {
      await api.put("/users/update", influencerData, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      Toastr("success", "Colaborador editado com sucesso!")
    } catch (error) {
      console.error(error);
      Toastr("error", "Ocorreu um erro");
    } finally {
      setIsSubmitting(false); // Desative o controle de envio após a conclusão
      toggle(); 
    }
  };

  return (
    <Modal isOpen={editmodal} toggle={toggle}>
      <ModalHeader toggle={toggle} tag="h4">
        Editar Colaborador
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xxlxs={6}>
              <div className="mb-3">
                <Label className="form-label">Email</Label>
                <Input
                  name="email"
                  type="email"
                  placeholder="Digite o Email"
                  value={influencerData.email}
                  onChange={handleInputChange}
                />
              </div>

              <div className="mb-3">
                <Label className="form-label">Nome Completo</Label>
                <Input
                  name="fullname"
                  type="text"
                  placeholder="Digite o nome completo"
                  value={influencerData.fullname}
                  onChange={handleInputChange}
                />
              </div>

            </Col>
            <Col xs={6}>
  
              <div className="mb-2">
                <Label className="form-label">Foto (Link) </Label>
                <Input
                  name="photo"
                  type="text"
                  placeholder="Cole o link da foto"
                  value={influencerData.photo}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="text-end">
                <button
                  type="submit"
                  className="btn btn-success save-user"
                  style={{ marginTop: "5px" }}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Enviando..." : "Cadastrar"}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ModalCreateInfluencer;
