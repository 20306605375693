import React, { useState, createContext, useEffect } from "react"
import Toastr from "../components/Ui/Notifications/"
import CryptoJS from "crypto-js"

import api from "../config/api"

export const AuthContext = createContext({})

const AuthProvider = ({children }) => {
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)
  const [key, setKey] = useState("viraw")
  const thema = localStorage.getItem('tema');

  useEffect(() => {
    try {
    
      loadStorage()
    } catch (error) {
      console.error(error.message, error)
    }
  }, [])

  async function loadStorage() {
    const encryptedData = localStorage.getItem("encryptedData")

    if (encryptedData) {
      const decryptedJsonData = decryptData(encryptedData, key)

      await api
        .post("/users/token", {
          token: decryptedJsonData.token,
        })
        .then(response => {
          if (response.data.valid) {
            setUser(decryptedJsonData)
            setLoading(false)
          } else {
            logout()
            setLoading(false)
          }
        })
        .catch(error => {
          console.error(error)
          logout()
          setLoading(false)
        })
    } else {
      logout()
      setLoading(false)
    }
  }

  async function login(email, password) {
    setLoading(true)
    try {
      await api
        .post("/users/login", {
          email: email,
          password: password,
        })
        .then(response => {
          setUser(response.data)
          //var responseString = JSON.stringify(response.data)
          storageUser(response.data)
          setLoading(false)
          Toastr("success", "Bem-vindo", "você agora faz parte da Viraw!")
        })
        .catch(error => {
          setLoading(false)
          error && Toastr('error', error.response.data.message)
        })
    } catch (error) {
      console.error(error)
    }
  }

  async function logout() {
    setUser(null)
    localStorage.removeItem("encryptedData")
  }

  function storageUser(data) {
    try {
      const encryptedData = encryptData(data, key)
      localStorage.setItem("encryptedData", encryptedData)
    } catch (error) {
      console.error("Erro ao analisar os dados JSON.")
    }
  }

  const encryptData = (jsonData, key) => {
    const jsonStr = JSON.stringify(jsonData)
    const encrypted = CryptoJS.AES.encrypt(jsonStr, key).toString()
    return encrypted
  }

  const decryptData = (encryptedData, key) => {
    const bytes = CryptoJS.AES.decrypt(encryptedData, key)
    const decryptedJsonStr = bytes.toString(CryptoJS.enc.Utf8)
    return JSON.parse(decryptedJsonStr)
  }

  return (
    <AuthContext.Provider value={{ user, loading, login, logout, thema }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
