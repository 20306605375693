import styled from "styled-components";

export const Main = styled.div`
  width: 100%;
  padding: 20px;
  background-color: ${({ tema }) => (tema === "dark" ? "#262B3C" : "#FFFFFF")};
  color: ${({ tema }) => (tema === "dark" ? "#FFFFFF" : "#000000")};
  min-height: 100vh; 
`;

export const Title = styled.h1`
  text-align: center;
  color: ${({ tema }) => (tema === "dark" ? "#E5E7EB" : "#4a4a4a")};
  margin-bottom: 20px;
`;

export const Card = styled.div`
  background: ${({ tema }) => (tema === "dark" ? "#1F2937" : "#FFFFFF")};
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  transition: background 0.3s ease;
`;

export const DateCarousel = styled.div`
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  padding: 10px;
  gap: 10px;
  justify-content: center;
  height: 140px; 
  align-items: center; 
  
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const DateButton = styled.button`
  background: ${({ selected, today }) => (selected ? '#6366F1' : today ? '#D1FAE5' : '#fff')}; 
  color: ${({ selected }) => (selected ? '#fff' : '#374151')};
  border: ${({ selected }) => (selected ? '2px solid #6366F1' : '2px solid #E5E7EB')};
  border-radius: 10px;
  padding: 10px; 
  flex: 1; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.3s ease;
  scroll-snap-align: start;
  height: 120px; 
  
  .day-name {
    font-size: 0.85rem;
    font-weight: normal;
  }

  .day-number {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .month-name {
    font-size: 0.85rem;
    font-weight: normal;
  }

  &:hover {
    background: #4F46E5;
    color: #fff;
  }
`;

export const TimeGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  margin-top: 20px;
`;

export const TimeButton = styled.button`
  background: ${({ selected }) => (selected ? '#6366F1' : '#fff')};
  color: ${({ selected }) => (selected ? '#fff' : '#374151')};
  border: ${({ selected }) => (selected ? '1px solid #6366F1' : '2px solid #E5E7EB')};
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;

  &:hover {
    background: #4F46E5;
    color: #fff;
  }
`;

export const ConfirmButton = styled.button`
  background: ${({ disabled }) => (disabled ? '#E5E7EB' : '#6366F1')};
  color: ${({ disabled }) => (disabled ? '#fff' : '#fff')};
  border: none;
  padding: 15px;
  border-radius: 5px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 20px;
  width: 100%;

  &:hover {
    background: ${({ disabled }) => (disabled ? '#E5E7EB' : '#4F46E5')};
  }
`;

export const InputContainer = styled.div`
  margin: 20px 0; 
`;

export const InputLabel = styled.label`
  display: block;
  margin-bottom: 8px; 
  font-weight: bold;
  color: ${({ tema }) => (tema === "dark" ? "#E5E7EB" : "#000000")}; 
`;

export const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid ${({ tema }) => (tema === "dark" ? "#4B5563" : "#ccc")};
  background-color: ${({ tema }) => (tema === "dark" ? "#374151" : "#fff")};
  color: ${({ tema }) => (tema === "dark" ? "#FFFFFF" : "#000000")};
  border-radius: 4px;
  font-size: 16px;

  &:focus {
    border-color: #007BFF; 
    outline: none; 
  }
`;
