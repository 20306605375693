import React from "react"
import { Button, Card, CardBody, Col, Row } from "reactstrap"
import { Link } from "react-router-dom"

const List = ({ listData }) => {
  const getCurrentMonthPeriod = () => {
    const now = new Date()
    const month = now.getMonth() + 1
    const year = now.getFullYear()
    return `${year}-${month}`
  }

  const getCurrentMonthFTDs = monthlyData => {
    const currentMonthPeriod = getCurrentMonthPeriod()
    const monthData = monthlyData.find(
      data => data.Period === currentMonthPeriod
    )
    return monthData ? monthData.FirstDepositCount.toLocaleString("pt-BR") : 0
  }

  const sectorBadgeClasses = sectorType => {
    switch (sectorType) {
      case "Francisco":
        return "badge badge-soft-success"
      case "João Victor":
        return "badge badge-soft-info"
      case "Karol":
        return "badge badge-soft-danger"
      default:
        return "badge badge-soft-warning"
    }
  }

  const sectorBadgeCampaigns = option => {
    switch (option) {
      case "SuperBet":
        return "badge badge-soft-info"
      case "Betano e SuperBet":
        return "badge badge-soft-success"
      case "Betano":
        return "badge badge-soft-danger"
      default:
        return "badge badge-soft-warning"
    }
  }

  const renderBadges = influencer => {
    const badges = []
    if (influencer.isAdvanceValue) {
      badges.push(
        <span key="advance" className="badge badge-soft-warning ml-2">
          Antecipado
        </span>
      )
    }
    if (influencer.isDailyPayment) {
      badges.push(
        <span key="daily" className="badge badge-soft-primary ml-2">
          Diário
        </span>
      )
    }
    return badges
  }

  return (
    <React.Fragment>
      <Row>
        {listData.map((influencer, key) => (
          <Col xl={3} key={key}>
            <Card>
              <CardBody>
                <div className="d-flex align-start mb-3">
                  <div className="flex-grow-1">
                    <span
                      className={
                        influencer.responsavel.fullname
                          ? sectorBadgeClasses(influencer.responsavel.fullname)
                          : "badge badge-soft-success"
                      }
                    >
                      {influencer.responsavel.fullname
                        ? influencer.responsavel.fullname
                        : "Nome indisponível"}
                    </span>

                    <span
                      style={{ marginLeft: "10px" }}
                      className={
                        influencer && influencer.company
                          ? sectorBadgeCampaigns(influencer.company)
                          : "badge badge-soft-success"
                      }
                    >
                      {influencer ? influencer.company : "Nome indisponível"}
                    </span>

                    <span style={{ marginLeft: "10px" }}>
                      {renderBadges(influencer)}
                    </span>

                    {influencer.archive && (
                      <span
                        style={{ marginLeft: "5px" }}
                        className="badge badge-soft-primary ml-5"
                      >
                        Arquivado
                      </span>
                    )}
                  </div>
                </div>
                <div className="text-center mb-3">
                  <img
                    src="https://i.ibb.co/y6nkQKW/461085513-1715142105981321-1128849984098454117-n.jpg"
                    alt="Logo da viraw"
                    className="avatar-sm rounded-circle"
                  />
                  <h6 className="font-size-15 mt-3 mb-1 text-truncate">
                    {influencer?.username}
                  </h6>
                  <p className="mb-0 text-muted font-size-12">
                    @{influencer?.username}
                  </p>
                  <p className="mb-0 text-muted font-size-12">
                    <strong>Meta: </strong>
                    {influencer?.meta?.toLocaleString("pt-BR")}
                  </p>
                  <p className="mb-0 text-muted font-size-12">
                    <strong>FTDs no mês: </strong>
                    {getCurrentMonthFTDs(influencer?.Mensal || [])}
                  </p>
                </div>

                {/* <Row>
                  <Col md="12">
                    <Link to={`/influencer/${influencer?.plugchatId}`}>
                      <Button
                        style={{ width: "100%" }}
                        className="btn btn-soft-primary d-block"
                      >
                        Perfil
                      </Button>
                    </Link>
                  </Col>
                </Row> */}
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </React.Fragment>
  )
}

export default List
