import React, { useState, useEffect } from "react";
import { useMemo } from "react";
import { Card, CardBody, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import TableContainerAtendentes from "../../../components/Common/TableContainerAtendentes";
import Spinners from "components/Common/Spinner";

const WalletActivities = ({ Trackings, isLoading, setLoading }) => {
  const [activeTab, setActiveTab] = useState("all");

  Trackings.sort((a, b) => {
    return new Date(a.Period) - new Date(b.Period);
  });

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const columns = useMemo(
    () => [
      {
        Header: "Data",
        accessor: "Period",
        filterable: true,
        isSort: true,
      },
      {
        Header: "TrackingCode",
        accessor: "ACID",
        filterable: true,
        isSort: false,
      },
      {
        Header: "Clicks",
        accessor: "Clicks",
        filterable: true,
        isSort: true,
      },
      {
        Header: "Registrations",
        accessor: "Registrations",
        filterable: true,
        isSort: true,
      },
      
      {
        Header: "FTD",
        accessor: "FirstDepositCount",
        filterable: true,
        isSort: true,
      },  
    ],
    []
  );

  return (
    <Card>
      <CardBody>
        <h4 className="card-title mb-4">Trackings</h4>
        {isLoading ? (
          <Spinners setLoading={setLoading} />
        ) : (
          <>
            <div className="mt-4">
              <TableContainerAtendentes
                tableClass="table-hover dt-responsive nowrap dataTable no-footer dtr-inline"
                columns={columns}
                data={Trackings}
                // data={activeTab !== "all" ? products.filter(product => product.type === activeTab) : products}
                isGlobalFilter={true}
                customPageSize={10}
                isPagination={true}
                isShowingPageLength={true}
                iscustomPageSizeOptions={true}
                paginationDiv="col-sm-12 col-md-7"
                pagination="pagination justify-content-end pagination-rounded"
              />
            </div>
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default WalletActivities;
