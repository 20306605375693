const cardStyles = {
  cardWrapper: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    border: "1px solid #ddd",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    marginBottom: "10px",
    width: "300px",
    minWidth: "300px", 
    maxWidth: "300px",
    boxShadow: "0 2px 3px rgba(255, 255, 255, 0.5)",
  },
  topBar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "5px 5px",
  },
  optionsSquare: {
    marginTop: "5px",
    color: "#000",
    marginRight: "5px",
    width: "30px",
    height: "32px",
    backgroundColor: "#dfdfdf",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px",
    cursor: "pointer",
  },
  optionsSquare2: {
    marginTop: "5px",
    color: "#000",
    marginRight: "5px",
    width: "30px",
    height: "32px",
    backgroundColor: "#dfdfdf",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    cursor: "pointer",
  },
  whatsappButton: {
    marginRight: "10px",
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    borderRadius: "4px",
    padding: "4px 6px",
    cursor: "pointer",
  },
  options: {
    display: "flex",
    alignItems: "center",
  },
  tagBar: {
    width: "85%",
    marginTop: "5px",
    marginLeft: "5px",
    backgroundColor: "#dfdfdf",
    padding: "5px 5px",
    display: "flex",
    gap: "5px",
    justifyContent: "flex-start",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    minHeight: "30px"
  },
  tagBar2: {
    width: "72%",
    marginTop: "5px",
    marginLeft: "5px",
    backgroundColor: "#dfdfdf",
    padding: "5px 5px",
    display: "flex",
    gap: "5px",
    justifyContent: "flex-start",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    minHeight: "30px"
  },
  tag: {
    backgroundColor: "#e0e0e0",
    color: "#000",
    fontSize: "12px",
    padding: "2px 6px",
    borderRadius: "4px",
    fontWeight: "bold",
  },
  card: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
  },
  image: {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    objectFit: "cover",
    marginRight: "10px",
  },
  info: {
    display: "flex",
    flexDirection: "column",
  },
  name: {
    fontSize: "16px",
    fontWeight: "bold",
    margin: 0,
    color: "#333",
  },
  username: {
    fontSize: "14px",
    margin: 0,
    color: "#555",
  },
};

export default cardStyles;
