import React, { useState, useEffect, useContext } from "react";
import { Container } from "reactstrap";
import SalaList from "../../../components/SalaList";
import Toastr from "../../../components/Ui/Notifications";
import CalendarFilter from "./CalendarFilter";
import { AuthContext } from "../../../contexts/auth";
import './Agendar.css';
import api from "config/api";

export default function Agendar() {
  const { user } = useContext(AuthContext);
  const [reservas, setReservas] = useState({});
  const [filter, setFilter] = useState(new Date().toISOString().split('T')[0]);

  const loadReservas = async () => {
    try {
      const response = await api.get("/scheduling");
      setReservas(response.data);
    } catch (error) {
      console.error("Erro ao carregar reservas:", error);
      Toastr("error", "Erro ao carregar reservas.");
    }
  };

  useEffect(() => {
    loadReservas();
  }, [filter]);

  const handleDeleteReserva = async (reservaId) => {

    try {
      await api.delete(`/scheduling/${reservaId}`);
      Toastr("success", "Reserva deletada com sucesso.");

      await loadReservas();
    } catch (error) {
      console.error("Erro ao deletar reserva:", error);
      Toastr("error", "Erro ao deletar reserva.");
    }
  };

  const handleTimeSelect = async (salaName, time) => {

    const reservasDoDia = reservas[filter] || {};

    const userReserva = reservasDoDia[salaName]?.find(r => r.hour === time && r.user.id === user.userId);

    if (userReserva) {
      await handleDeleteReserva(userReserva.id);
      return;
    }

    const userReservationsCount = (Object.values(reservasDoDia).flat() || [])
      .filter(r => r.user.id === user.userId).length;

    if (userReservationsCount >= 2) {
      Toastr("warning", "Você já fez o máximo de 2 reservas para este dia.");
      return;
    }

    if (reservasDoDia[salaName] && reservasDoDia[salaName].some(r => r.hour === time)) {
      Toastr("error", `O horário ${time} para ${salaName} já está reservado.`);
      return;
    }

    const reserva = {
      description: "Reunião de Alinhamento",
      user: user.userId,
      date: filter,
      hour: time
    };

    await api.post("/scheduling", reserva);

    await loadReservas();

    Toastr("success", `Reserva feita com sucesso para ${salaName} às ${time}`);
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  const generateHours = () => {
    const hours = [];
    for (let i = 8; i <= 20; i++) {
      const hour = i < 10 ? `0${i}:00` : `${i}:00`;
      hours.push(hour);
    }
    return hours;
  };

  const availableHours = generateHours();

  const transformedSalas = availableHours.map(hour => ({
    date: filter,
    hour,
    reservations: reservas[filter] && reservas[filter][hour] ? reservas[filter][hour] : []
  }));

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="agendar-container">
          <Container fluid={true}>
            <h1>Agendar Sala de Reunião</h1>
            <CalendarFilter onFilterChange={handleFilterChange} />
            <SalaList
              salas={transformedSalas}
              onTimeSelect={handleTimeSelect}
              reservas={reservas[filter] || {}}
            />
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
}
