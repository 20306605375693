import React, { useState } from "react";
import cardStyles from "./cardStyles";

const Card = ({ photo, fullname, username, status, created_at, updated_at, campaign, plug_chat, ...rest }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  const getTagForStatusAndTime = () => {
    if (created_at) {
      const createdDate = new Date(created_at);
      const today = new Date();
      const diffInMs = today - createdDate;
      const diffInHours = diffInMs / (1000 * 60 * 60);

      if (status === 2) {
        if (diffInHours > 48) {
          return { text: "+48h", color: "#FF4500" };
        } else if (diffInHours > 24) {
          return { text: "+24h", color: "#98c7f1" };
        }
      }

      if (diffInHours >= 24 && status === 1) {
        return { text: "Ausente", color: "#2fa9ec" };
      }

      if (diffInHours <= 24) {
        return { text: "Novo", color: "#47e98b" };
      }
    }

    if (updated_at) {
      const updatedDate = new Date(updated_at);
      const today = new Date();
      const diffInMs = today - updatedDate;
      const diffInHours = diffInMs / (1000 * 60 * 60);

      if (diffInHours > 1 && status != 8 && status != 2 && status != 4 && status != 1) {
        return { text: "Em espera", color: "#808080" };
      }
    }

    return null;
  };

  const redirectWhats = (link) => {
    if (link) {
      window.open(link, "_blank");
    }
  };

  const timeTag = getTagForStatusAndTime();

  const tagBarStyle = status === 4 ? cardStyles.tagBar2 : cardStyles.tagBar;

  return (
    <div style={cardStyles.cardWrapper}>
      <div style={cardStyles.topBar}>
        <div style={tagBarStyle}>
          {!campaign && (
            <span
              style={{
                ...cardStyles.tag,
                backgroundColor: "#FFA500",
                fontWeight: "bold",
              }}
            >
              SuperBet
            </span>
          )}

          {rest.tags?.map((tag, index) => (
            <span key={index} style={cardStyles.tag}>
              {tag}
            </span>
          ))}

          {timeTag && (
            <span
              style={{
                ...cardStyles.tag,
                backgroundColor: timeTag.color,
              }}
            >
              {timeTag.text}
            </span>
          )}

          {rest.last_value && (
            <span style={{ ...cardStyles.tag, backgroundColor: "#FFD700" }}>
              {rest.last_value}
            </span>
          )}

          {status === 8 && plug_chat && (
            <span style={{ ...cardStyles.tag, backgroundColor: "#88b8a9" }}>
              {plug_chat?.responsavel_rent?.fullname}
            </span>
          )}
        </div>

        <div style={cardStyles.options}>
          {status === 4 && (
            <div
              style={cardStyles.optionsSquare2}
              onClick={() => redirectWhats(rest.link)}
            >
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
                alt="WhatsApp"
                style={{ width: "18px", height: "18px" }}
              />
            </div>
          )}

          <div style={cardStyles.optionsSquare} onClick={toggleMenu}>
            ⋮
          </div>
        </div>
      </div>

      <div style={cardStyles.card}>
        <img src={photo} alt={photo} style={cardStyles.image} />
        <div style={cardStyles.info}>
          <h3 style={cardStyles.name}>{fullname}</h3>
          <p style={cardStyles.username}>@{username}</p>
        </div>
      </div>
    </div>
  );
};

export default Card;
