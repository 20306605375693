import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { InputGroup } from "reactstrap";
import { ptBR } from 'date-fns/locale';
import { parseISO, subDays } from 'date-fns';

const LineColumnArea = ({ advisorDetails }) => {
    const [period, setPeriod] = useState("semanal");
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [startDate, setStartDate] = useState(subDays(new Date(), 6));
    const [endDate, setEndDate] = useState(new Date());

    useEffect(() => {
        if (period === "mensal") {
            const firstDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
            setStartDate(firstDayOfMonth);
            setEndDate(new Date());
        } else if (period === "semanal") {
            setStartDate(subDays(new Date(), 6));
            setEndDate(new Date());
        }
    }, [period]);

    const handleMonthChange = (date) => {
        setSelectedDate(date);
    };

    const handleWeekChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const getDataForPeriod = () => {
        const dailyData = advisorDetails || {};

        const filteredData = Object.keys(dailyData).reduce((acc, date) => {
            const currentDate = parseISO(date);
            if (currentDate >= startDate && currentDate <= endDate) {
                acc[date] = dailyData[date];
            }
            return acc;
        }, {});

        const labels = Object.keys(filteredData);
        const visitas = labels.map(date => filteredData[date].totalClicks);
        const registros = labels.map(date => filteredData[date].totalRegistrations);
        const ftd = labels.map(date => filteredData[date].totalFirstDepositCount);

        return {
            visitas,
            registros,
            ftd,
            labels,
        };
    };

    const { visitas, registros, ftd, labels } = getDataForPeriod();

    const series = [
        {
            name: "Clicks",
            type: "column",
            data: visitas,
        },
        {
            name: "Registros",
            type: "area",
            data: registros,
        },
        {
            name: "FTDs",
            type: "line",
            data: ftd,
        },
    ];

    const options = {
        chart: {
            locales: [{
                "name": "pt",
                "options": {
                    "months": [
                        "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
                        "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
                    ],
                    "shortMonths": [
                        "Jan", "Fev", "Mar", "Abr", "Mai", "Jun",
                        "Jul", "Ago", "Set", "Out", "Nov", "Dez"
                    ],
                    "days": [
                        "Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"
                    ],
                    "shortDays": ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
                    "toolbar": {
                        "exportToSVG": "Baixar SVG",
                        "exportToPNG": "Baixar PNG",
                        "menu": "Menu",
                        "selection": "Seleção",
                        "selectionZoom": "Zoom",
                        "zoomIn": "Zoom In",
                        "zoomOut": "Zoom Out",
                        "pan": "Panning",
                        "reset": "Resetar Zoom"
                    }
                }
            }],
            defaultLocale: "pt",
            stacked: false,
            toolbar: {
                show: false,
            },
        },
        stroke: {
            width: [0, 2, 4],
            curve: "smooth",
        },
        plotOptions: {
            bar: {
                columnWidth: "50%",
            },
        },
        fill: {
            opacity: [0.85, 0.25, 1],
            gradient: {
                inverseColors: false,
                shade: "light",
                type: "vertical",
                opacityFrom: 0.85,
                opacityTo: 0.55,
                stops: [0, 100, 100, 100],
            },
        },
        labels: labels,
        markers: {
            size: 0,
        },
        legend: {
            offsetY: 10,
        },
        xaxis: {
            type: "datetime",
        },
        yaxis: {
            title: {
                text: "",
            },
        },
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0) + " ";
                    }
                    return y;
                },
            },
        },
        grid: {
            borderColor: "#f1f1f1",
        },
    };

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'end', gap: '16px'}} className="mb-2">
                <InputGroup style={{ width: '28%' }}>
                    <DatePicker
                        selected={selectedDate}
                        onChange={handleMonthChange}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        disabled={period === "semanal"}
                        className="form-control"
                        locale={ptBR}
                    />
                    <button
                        style={{
                            borderRadius: '3px',
                            width: '100%',
                            border: 'none',
                            cursor: 'pointer',
                            height: '25px',
                            backgroundColor: period === "mensal" ? '#007bff' : '',
                            color: period === "mensal" ? 'white' : ''
                        }}
                        onClick={() => setPeriod("mensal")}
                    >
                        Mensal
                    </button>
                </InputGroup>

                <InputGroup style={{ width: '25%', textAlign: 'center' }}>
                    <DatePicker
                        selected={startDate}
                        onChange={handleWeekChange}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        dateFormat="dd/MM/yyyy"
                        disabled={period === "mensal"}
                        className="form-control"
                        locale={ptBR}
                    />
                    <button
                        style={{
                            width: '100%',
                            borderRadius: '3px',
                            border: 'none',
                            cursor: 'pointer',
                            height: '25px',
                            backgroundColor: period === "semanal" ? '#007bff' : '',
                            color: period === "semanal" ? 'white' : ''
                        }}
                        onClick={() => setPeriod("semanal")}
                    >
                        Semanal
                    </button>
                </InputGroup>
            </div>

            <ReactApexChart options={options} series={series} type="line" height={350} />
        </>
    );
};

export default LineColumnArea;
