import React, { useContext } from "react"
import { Row, Col, Card } from "reactstrap"

//Import Images
import profileImg from "../../../assets/images/profile-img.png"
import { AuthContext } from "contexts/auth"
function CardWelcome(props) {
  const {user} = useContext(AuthContext)
  return (
    <React.Fragment>
    <Col xl="4">
      <Card className="bg-primary-subtle">
        <div>
          <Row>
            <Col xs="7">
              <div className="text-primary p-3">
                <h5 className="text-primary">Bem-vindo {user.fullname}</h5>
                <p>Viraw Plataform ©</p>

                <ul className="ps-3 mb-0">
                  <li className="py-1">Visualize suas Estatísticas</li>
                  <li className="py-1">Gerencie seus Influencers</li>
                </ul>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
      </Card>
    </Col>
  </React.Fragment>
  );
}

export default CardWelcome;