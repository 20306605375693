import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from 'contexts/auth';
import { Card, CardBody, Col, Container, Row, Collapse, Input, Button } from 'reactstrap';
import Select from 'react-select';
import api from 'config/api';
import Spinners from 'components/Common/Spinner';
import Breadcrumbs from 'components/Common/Breadcrumb';
import List from './List';
import CardUser from './card-user';
import CadastrarColaborador from "components/Modals/CadastrarColaborador"
import CadastrarCampanha from "components/Modals/CadastrarCampanha"

import { StateContext } from 'contexts/states';

const fetchCollaborators = async (userToken, selectedSector, setOriginalCollaborators, setLoading) => {
    setLoading(true);
    try {
        const response = await api.get('/users/all', {
            headers: {
                Authorization: `Bearer ${userToken}`,
            },
        });
        const filteredData = response.data.filter(
            (collaborator) =>
                collaborator.type_user.type !== 'Administrador' &&
                collaborator.type_user.type !== 'Externo' &&
                (selectedSector === '' || collaborator.type_user.type === selectedSector || (selectedSector === "Captação" && collaborator.type_user.type === "Closer"))
        );
        setOriginalCollaborators(filteredData);
    } catch (error) {
        console.error('Error fetching collaborators:', error);
    }
    setLoading(false);
};

const CollaboratorsList = () => {
    document.title = 'Colaboradores | Viraw Influencers';

    const [collaborators, setCollaborators] = useState([]);
    const [originalCollaborators, setOriginalCollaborators] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const { user } = useContext(AuthContext);
    const [selectedSector, setSelectedSector] = useState('');
    const { modal, setModal } = useContext(StateContext);
    const [captacao, setCaptacao] = useState();
    const [grupos, setGrupos] = useState();
    const [hunter, setHunter] = useState();
    const [diretoria, setDiretoria] = useState();

    const getCollaborators = () => {
        fetchCollaborators(user.token, selectedSector, setOriginalCollaborators, setLoading);
    };

    function handleUserClick() {
        setModal(true)
    }

    useEffect(() => {
        getCollaborators();
    }, [user.token, selectedSector]);

    const handleSearchCollaborator = (search) => {
        const lowerCaseSearch = search.toLowerCase();
        const filteredCollaborators = originalCollaborators.filter(collaborator => {
            const term = collaborator.fullname; // Ajuste para a propriedade correta
            return term && term.toLowerCase().includes(lowerCaseSearch);
        });
        setCollaborators(filteredCollaborators);
    };

    const handleSectorChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        setSelectedSector(selectedOption ? selectedOption.value : '');
    };

    useEffect(() => {
        const filteredBySector = originalCollaborators.filter(
            (collaborator) =>
                selectedSector === '' || collaborator.type_user.type === selectedSector
        );
        setCollaborators(filteredBySector);
    }, [selectedSector, originalCollaborators]);

    const sectorOptions = [
        { value: '', label: 'Todos' },
        { value: 'Diretoria', label: 'Diretoria' },
        { value: 'Grupos', label: 'Grupos' },
        { value: 'Captação', label: 'Closer' },
    ];

    const [isCampaignModalOpen, setIsCampaignModalOpen] = useState(false);

    function handleOpenCampaignModal() {
        setIsCampaignModalOpen(true);
    }

    function handleOpenCollaboratorModal() {
        setModal(true); 
    }

    useEffect(() => {
        async function getCaptacao() {
            try {
                const response = await api.get("/users/all", {
                    headers: {
                        Authorization: `Bearer ${user.token}`
                    }
                });
                let cap = [];
                let gru = [];
                let hun = [];
                let diret = [];
                response.data.forEach((element) => {
                    if (element.type_user.type === "Closer") { 
                        cap.push(element);
                    } else if (element.type_user.type === "Grupos") {
                        gru.push(element);
                    } else if (element.type_user.type === "Hunter") {
                        hun.push(element);
                    } else if (element.type_user.type === "Diretoria") {
                        diret.push(element);
                    }
                });
                setHunter(hun.length);
                setCaptacao(cap.length);
                setGrupos(gru.length);
                setDiretoria(diret.length);
            } catch (error) {
                console.error("Erro ao buscar dados:", error);
            }
        }
        getCaptacao();
    }, [user.token]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                <CardUser />

                    <Row>
                        <Col lg={12}>
                            <Card className="collaborator-filter">
                                <CardBody>
                                    <form action="#">
                                        <Row className="g-3">
                                            <Col xxl={3} lg={4}>
                                                <div className="position-relative">
                                                    <Input
                                                        type="text"
                                                        id="searchCollaborator"
                                                        autoComplete="off"
                                                        placeholder="Buscar Colaboradores"
                                                        onChange={(e) => handleSearchCollaborator(e.target.value)}
                                                    />
                                                </div>
                                            </Col>
                                            <Col xxl={3} lg={4}>
                                                <div className="position-relative">
                                                    <Select
                                                        className="select2"
                                                        onChange={handleSectorChange}
                                                        options={sectorOptions}
                                                        value={selectedOption}
                                                        placeholder="Selecionar Setor"
                                                    />
                                                </div>
                                            </Col>
                                            <Col xxl={6} lg={4}>
                                                <div className="text-sm-end mb-3">
                                                    <Button
                                                        type="button"
                                                        color="primary"
                                                        className="btn mb-2 me-2"
                                                        onClick={handleOpenCampaignModal} 
                                                    >
                                                        <i className="mdi mdi-plus-circle-outline me-1" />
                                                        Nova Campanha
                                                    </Button>
                                                    <Button
                                                        type="button"
                                                        color="primary"
                                                        className="btn mb-2 me-2"
                                                        onClick={handleOpenCollaboratorModal} 
                                                    >
                                                        <i className="mdi mdi-plus-circle-outline me-1" />
                                                        Cadastrar Colaborador
                                                    </Button>
                                                </div>
                                            </Col>
                                            <Collapse isOpen={isOpen} id="collapseExample">
                                                <div></div>
                                            </Collapse>
                                        </Row>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {isLoading ? <Spinners setLoading={setLoading} /> : <List listData={collaborators} />}
                    <CadastrarCampanha
                        isOpen={isCampaignModalOpen}
                        toggle={() => setIsCampaignModalOpen(!isCampaignModalOpen)}
                        userToken={user.token} 
                    />
                    <CadastrarColaborador />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CollaboratorsList;
