import api from "config/api"
import { AuthContext } from "contexts/auth"
import React, { useState, useEffect, useContext } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Row,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap"
import Toastr from "../../../components/Ui/Notifications"
import UpdateReativarInfluencer from "../../../components/Modals/UpdateReativarInfluencer"

const List = ({ listData }) => {
  const [influencers, setInfluencers] = useState([])
  const { user } = useContext(AuthContext)
  const [showModal, setShowModal] = useState(false)
  const [idInfluencer, setIdInfluencer] = useState()
  const [updatedCaptador, setUpdatedCaptador] = useState(null);

  const activeBtn = (id) => {
    setIdInfluencer(id)
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  // const handleArchiveConfirm = async () => {
  //   await api.put("/influencers/update/archiving/", {
  //     id: idInfluencer,
  //     request_archiving: false,
  //     archiving: false,
  //   },{
  //     headers:{
  //       Authorization: `Bearer ${user.token}`
  //     }
  //   }).then(() => {
  //     Toastr("success", "Influencer reativado!")
  //     setRequestArchiving(true)
  //   }).catch((err) => {
  //     console.error("Erro" + err);
  //   })
  //   setShowModal(false);
  // };

  useEffect(() => {
    const archivedInfluencers = listData.filter(
      (influencer) => influencer.influencer_rent.archiving
    )
    setInfluencers(archivedInfluencers)
  }, [listData])

  const handleChangeCaptador = (newCaptador) => {
    setUpdatedCaptador(newCaptador);
  };

  const sectorBadgeClasses = (sectorType) => {
    switch (sectorType) {
      case "Grupos":
        return "badge badge-soft-success"
      case "Captação":
        return "badge badge-soft-info"
      case "TI":
        return "badge badge-soft-danger"
      default:
        return "badge badge-soft-warning"
    }
  }

  const sectorBadgeCampaigns = (option) => {

    switch (option) {
      case "SuperBet":
        return "badge badge-soft-info"
      case "Betano e SuperBet":
        return "badge badge-soft-success"
      case "Betano":
        return "badge badge-soft-danger"
      default:
        return "badge badge-soft-warning"
    }
  }

  return (
    <React.Fragment>
      <Row>
        {influencers.map((influencer, key) => (
          <Col xl={3} key={key}>
            <Card>
              <CardBody>
                <div className="d-flex align-start mb-3">
                  <div className="flex-grow-1">
                    <span
                      className={
                        influencer.responsavel_rent &&
                        influencer.responsavel_rent.type_user
                          ? sectorBadgeClasses(
                              influencer.responsavel_rent.type_user.type
                            )
                          : "badge badge-soft-success"
                      }
                    >
                      {influencer.responsavel_rent
                        ? influencer.responsavel_rent.fullname
                        : "Nome indisponível"}
                    </span>

                    <span
                      style={{"marginLeft": '10px'}}
                      className={
                        influencer.influencer_rent &&
                        influencer.influencer_rent?.campaigns_influencer
                          ? sectorBadgeCampaigns(
                              influencer.influencer_rent?.campaigns_influencer.name
                            )
                          : "badge badge-soft-success"
                      }
                    >
                      {influencer
                        ? influencer.influencer_rent?.campaigns_influencer?.name
                        : "Nome indisponível"}
                    </span> 
                  </div>
                  <button
                    type="button"
                    className="btn btn-light btn-sm like-btn"
                    onClick={(e) =>
                      activeBtn(influencer.influencer_rent.id)
                    }
                  >
                    <i
                      className="mdi mdi-account-reactivate-outline"
                      style={{ fontSize: "14px" }}
                    ></i>
                  </button>
                </div>
                <div className="text-center mb-3">
                  <img
                    src={influencer.influencer_rent.photo}
                    alt=""
                    className="avatar-sm rounded-circle"
                  />
                  <h6 className="font-size-15 mt-3 mb-1">
                    {influencer.influencer_rent.fullname}
                  </h6>
                  <p className="mb-0 text-muted">
                    @{influencer.influencer_rent.username}
                  </p>
                </div>
                <div className="mt-4 pt-1">
                  <Link
                    to={`/influencer/${influencer.id}`}
                    className="btn btn-soft-primary d-block"
                  >
                    Ver perfil
                  </Link>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
      {showModal && (
        <UpdateReativarInfluencer
          influencerId={idInfluencer} 
          controlModal={showModal}
          toggle={() => setShowModal(!showModal)}
          onChangeCaptador={handleChangeCaptador}
        />
      )}
      {/* <Modal isOpen={showModal} toggle={handleCloseModal}>
        <ModalHeader toggle={handleCloseModal}>
          Reativar Influenciador
        </ModalHeader>
        <ModalBody>Você deseja reativar este influenciador?</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button color="primary" onClick={handleArchiveConfirm}>
            Sim
          </Button>
        </ModalFooter>
      </Modal> */}
    </React.Fragment>
  )
}

export default List
