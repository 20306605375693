import React from "react"
import PropTypes from "prop-types"
import { Card, CardBody, Col, Row } from "reactstrap"
import { Link } from "react-router-dom"

const Profile = ({ data, geral, listData }) => {
  const influencer = data

  return (
    <Card>
      <CardBody>
        <div className="d-flex">
          <div className="me-4">
            <img
              src="https://i.ibb.co/y6nkQKW/461085513-1715142105981321-1128849984098454117-n.jpg"
              alt="Foto do usuário"
              className="img-fluid rounded-circle"
              width="40"
              height="40"
            />
          </div>

          <div className="flex-grow-1">
            <div className="text-muted">
              <h5>{influencer.fullname}</h5>
              <h6 className="mb-1 text-muted">@{influencer.username}</h6>
            </div>
          </div>
          <div className="text-muted">
            <h4 className="mb-1 text-muted">{influencer.company}</h4>
          </div>
        </div>
      </CardBody>
      <CardBody className="border-top">
        <Row>
          <Col sm="5">
            <div className="text-center">
              <p className="text-muted mb-2">Registros</p>
              <h5>{geral.Registrations}</h5>
            </div>
          </Col>
          <Col sm="3">
            <div className="text-center">
              <p className="text-muted mb-2">FTD</p>
              <h5>{geral.FirstDepositCount}</h5>
            </div>
          </Col>
          <Col sm="3">
            <div className="text-center">
              <p className="text-muted mb-2">CPA</p>
              <h5>{geral.CPACount}</h5>
            </div>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md="6" sm="5">
            <div className="text-center">
              <p className="text-muted mb-2">Visitas</p>
              <h5>{geral.Clicks}</h5>
            </div>
          </Col>
        </Row>
      </CardBody>

      <CardBody className="border-top">
        {influencer.captador && (
          <>
            <p className="text-muted mb-3 ">Responsáveis pelo influencer</p>

            <div className="text-center">
              <Row>
                <Col sm="6">
                  <div className="mt-4 mt-sm-0">
                    <div className="font-size-24 text-primary mb-2">
                      <img
                        src={influencer.captador?.photo}
                        alt=""
                        className="img-fluid rounded-circle"
                        width="50"
                        height="50"
                      />
                    </div>

                    <p className="text-muted mb-2">
                      {influencer.captador?.fullname}
                    </p>

                    <div className="mt-3">
                      <Link
                        to={`/colaboradores/${influencer.captador?.id}`}
                        className="btn btn-primary btn-sm w-md"
                      >
                        Ver Perfil
                      </Link>
                    </div>
                  </div>
                </Col>

                <Col sm="6">
                  <div className="mt-4 mt-sm-0">
                    <div className="font-size-24 text-primary mb-2">
                      <img
                        src={influencer.responsavel?.photo}
                        alt=""
                        className="img-fluid rounded-circle"
                        width="50"
                        height="50"
                      />
                    </div>

                    <p className="text-muted mb-2">
                      {influencer.responsavel?.fullname}
                    </p>

                    <div className="mt-3">
                      <Link
                        to={`/colaboradores/${influencer.responsavel?.id}`}
                        className="btn btn-primary btn-sm w-md"
                      >
                        Ver Perfil
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </>
        )}
      </CardBody>
    </Card>
  )
}

Profile.propTypes = {
  data: PropTypes.any,
  isMenu: PropTypes.bool,
  toggleMenu: PropTypes.func,
}

export default Profile
