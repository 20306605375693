import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";

function FechadoModal({ isOpen, toggle, onConfirm }) {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Confirmar Fechamento</ModalHeader>
      <ModalBody>
        Tem certeza de que deseja fechar este influenciador?
        Esta ação moverá o influenciador para a categoria "Fechado".
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Cancelar
        </Button>
        <Button color="primary" onClick={onConfirm}>
          Confirmar
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default FechadoModal;
