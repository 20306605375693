import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from 'contexts/auth';
import { Card, CardBody, Col, Container, Row, Collapse, Input } from 'reactstrap';
import Select from 'react-select';
import api from 'config/api';
import Spinners from 'components/Common/Spinner';
import Breadcrumbs from 'components/Common/Breadcrumb';
import List from './List';
import { CounterBox, CounterTitle, CounterValue } from './styles';

const CollaboratorsList = () => {
    document.title = 'Influencers | Viraw Influencers';

    const [collaborators, setCollaborators] = useState([]);
    const [originalCollaborators, setOriginalCollaborators] = useState([]);
    const [filteredByResponsavel, setFilteredByResponsavel] = useState([]); 
    const [isLoading, setLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const { user } = useContext(AuthContext);
    const [searchValue, setSearchValue] = useState('');
    const [colaboradoresGrupo, setColaboradoresGrupo] = useState([]);
    const [influencersAll, setInfluencersAll] = useState([]);
    const [selectedSector, setSelectedSector] = useState("");
    const [selectedFilterOption, setSelectedFilterOption] = useState("");
    const [activeUsers, setActiveUsers] = useState(0)
    const [counters, setCounters] = useState({
        valores: 0,
        naoDivulga: 0,
        naoRespondeu: 0,
        outros: 0,
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get('/influencers', {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                });
                const data = response.data;
                
                let temp = [];

                data.forEach(element => {
                    if (element.status === 3) {
                        temp.push(element);
                    }
                });

                setCollaborators(temp);
                setOriginalCollaborators(temp);
                const active = data.filter(influencer => influencer.status === 3).length;
                setActiveUsers(active);
            } catch (error) {
                console.error('Error fetching influencers:', error);
            }
            setLoading(false);
        };

        fetchData();
    }, [user.token]);

    const [countedCollaborators, setCountedCollaborators] = useState([]);

    useEffect(() => {
        calculateCounters(countedCollaborators);
    }, [countedCollaborators]);

    useEffect(() => {
        calculateCounters(collaborators);
    }, [collaborators]);

    const calculateCounters = (collaborators) => {
        let valoresCount = 0;
        let naoDivulgaCount = 0;
        let naoRespondeuCount = 0;
        let outrosCount = 0;
    
        collaborators.forEach(collaborator => {
            if (collaborator.observation) {
                const observation = collaborator.observation;
                if (observation === 'Valores.') {
                    valoresCount++;
                } else if (observation === 'não divulga casa de apostas.') {
                    naoDivulgaCount++;
                } else if (observation === 'Não respondeu.') {
                    naoRespondeuCount++;
                } else {
                    outrosCount++;
                }
            }
        });
    
        setCounters({
            valores: valoresCount,
            naoDivulga: naoDivulgaCount,
            naoRespondeu: naoRespondeuCount,
            outros: outrosCount,
        });
    };

    const filterOptions = [
        { label: "Todos", value: "all" },
        { label: "Aguardando Hoje", value: "today" },
        { label: "Geral Hoje", value: "todayGeral" },
    ];

    const handleFilterChange = (selectedOption) => {
        setSelectedFilterOption(selectedOption);
    
        if (selectedOption) {
            let filteredPosts;
            if (selectedOption.value === "today") {
                filteredPosts = filterPostsByStartDate(originalCollaborators);
            } else if (selectedOption.value === "todayGeral") {
                filteredPosts = filterPostsByGeral(originalCollaborators);
            } else if (selectedOption.value === "Outros...") {
                filteredPosts = originalCollaborators.filter(collaborator =>
                    collaborator.observation !== 'Valores.' &&
                    collaborator.observation !== 'Não divulga casa de apostas.' &&
                    collaborator.observation !== 'Não respondeu.'
                );
            } else {
                filteredPosts = originalCollaborators.filter(collaborator =>
                    collaborator.observation === selectedOption.value
                );
            }
            setCollaborators(filteredPosts);
            setCountedCollaborators(originalCollaborators); // Atualiza a cópia para contagem
        } else {
            setCollaborators(originalCollaborators);
            setCountedCollaborators(originalCollaborators); // Atualiza a cópia para contagem
        }
    };

    const filterPostsByStartDate = (collaborators) => {
        const startDate = new Date();
        return collaborators.filter((collaborator) => {
            if (collaborator.influencer_rent && collaborator.influencer_rent.posts) {
                return collaborator.influencer_rent.posts.some((post) => {
                    const postStartDate = new Date(post.start);
                    return postStartDate.toDateString() === startDate.toDateString() && post.waiting === true;
                });
            }
            return false;
        });
    };

    const filterPostsByGeral = (collaborators) => {
        const startDate = new Date();
        return collaborators.filter((collaborator) => {
            if (collaborator.influencer_rent && collaborator.influencer_rent.posts) {
                return collaborator.influencer_rent.posts.some((post) => {
                    const postStartDate = new Date(post.start);
                    return postStartDate.toDateString() === startDate.toDateString();
                });
            }
            return false;
        });
    };

    const getCollaborators = async () => {
        setLoading(true);
        try {
            const response = await api.get('/plugchat', {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            const data = response.data;
            // setCollaborators(data);
            // setOriginalCollaborators(data);

            let colaboradores = data.map(element => element.responsavel_rent.fullname);
            colaboradores = [...new Set(colaboradores)];
            setColaboradoresGrupo(colaboradores);
        } catch (error) {
            console.error('Error fetching collaborators:', error);
        }
        setLoading(false);
    };

    useEffect(() => {
        getCollaborators();
    }, [user.token]);

    const handleSectorChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        if (selectedOption) {
            const selectedSectorValue = selectedOption.value;
            const filteredBySector = originalCollaborators.filter(collaborator =>
                collaborator.responsavel_rent.fullname === selectedSectorValue);
            setFilteredByResponsavel(filteredBySector);
            setCollaborators(filteredBySector);
        } else {
            setFilteredByResponsavel([]);
            setCollaborators(originalCollaborators);
        }
    };

    const handleSearchCollaborator = (value) => {
        setSearchValue(value);
        const lowerCaseValue = value.toLowerCase();
        const filteredCollaborators = originalCollaborators.filter(collaborator => {
            const fullname = (collaborator.influencer_rent && collaborator.influencer_rent.fullname) ? collaborator.influencer_rent.fullname.toLowerCase() : '';
            const username = (collaborator.influencer_rent && collaborator.influencer_rent.username) ? collaborator.influencer_rent.username.toLowerCase() : '';
            return fullname.includes(lowerCaseValue) || username.includes(lowerCaseValue);
        });
        setCollaborators(filteredCollaborators);

        if (value.trim() === '') {
            setSelectedOption(null);
            setCollaborators(originalCollaborators);
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <Breadcrumbs title="Influencers" breadcrumbItem="Influencers" />
                    <Row>
                        <Col lg={12}>
                            <Card className="collaborator-filter">
                                <Row className="d-flex justify-content-center">

                                    <Col sm={6} md={3}>
                                        <CounterBox className="bg-muted">
                                            <CounterTitle className="text-muted">Valores.</CounterTitle>
                                            <CounterValue className="text-muted">{counters.valores}</CounterValue>
                                        </CounterBox>
                                    </Col>
                                    <Col sm={6} md={3}>
                                        <CounterBox className="bg-muted">
                                            <CounterTitle className="text-muted">Não divulga casa de apostas.</CounterTitle>
                                            <CounterValue className="text-muted">{counters.naoDivulga}</CounterValue>
                                        </CounterBox>
                                    </Col>
                                    <Col sm={6} md={3}>
                                        <CounterBox className="bg-muted">
                                            <CounterTitle className="text-muted">Não Respondeu.</CounterTitle>
                                            <CounterValue className="text-muted">{counters.naoRespondeu}</CounterValue>
                                        </CounterBox>
                                    </Col>
                                    <Col sm={6} md={3}>
                                        <CounterBox className="bg-muted">
                                            <CounterTitle className="text-muted">Outros...</CounterTitle>
                                            <CounterValue className="text-muted">{counters.outros}</CounterValue>
                                        </CounterBox>
                                    </Col>
                                </Row>
                                <CardBody>
                                    <form action="#">
                                        <Row className="g-3">
                                            <Col xxl={4} lg={4}>
                                                <div className="position-relative">
                                                    <Input
                                                        type="text"
                                                        id="searchCollaborator"
                                                        autoComplete="off"
                                                        placeholder="Buscar Influenciador"
                                                        onChange={(e) => handleSearchCollaborator(e.target.value)}
                                                        value={searchValue}
                                                    />
                                                </div>
                                            </Col>
                                            <Col xxl={2} lg={4}>
                                                <div className="position-relative">
                                                    <Select
                                                        className="select2"
                                                        onChange={handleFilterChange}
                                                        options={[
                                                            { value: 'Valores.', label: 'Valores.' },
                                                            { value: 'não divulga casa de apostas.', label: 'Não divulga casa de apostas.' },
                                                            { value: 'Não respondeu.', label: 'Não respondeu.' },
                                                            { value: 'Outros...', label: 'Outros...' }
                                                        ]}
                                                        value={selectedFilterOption}
                                                        placeholder="Filtrar observação"
                                                        isClearable
                                                    />
                                                </div>
                                            </Col>

                                            <Collapse isOpen={isOpen} id="collapseExample">
                                            </Collapse>
                                        </Row>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {isLoading ? <Spinners setLoading={setLoading} /> : <List listData={collaborators} />}
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CollaboratorsList;
