import React, { useState, useContext, useEffect } from "react"
import api from "config/api"
import { AuthContext } from "../../../contexts/auth"
import { StateContext } from "contexts/states"
import {
  Label,
  Input,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
} from "reactstrap"
import Toastr from "../../../components/Ui/Notifications"
import Select from "react-select"

const ModalCreateInfluencer = ({ updateInfluencersList }) => {
  const { user } = useContext(AuthContext)
  const { modal, setModal } = useContext(StateContext)

  const [influencerData, setInfluencerData] = useState({
    phone: "",
    email: "",
    fullname: "",
    photo:
      "https://i.ibb.co/y6nkQKW/461085513-1715142105981321-1128849984098454117-n.jpg",
    username: "",
    following: "",
    followers: "",
    method_contact: "",
    status: 1,
    captacao: "",
  })
  const [selectedOption, setSelectedOption] = useState(null)
  const [collaboratorCap, setCollaboratorCap] = useState(null)

  const updateCaptacao = newCaptacao => {
    setInfluencerData(prevState => ({
      ...prevState,
      captacao: newCaptacao,
    }))
  }
  const handleSectorChange = selectedOption => {
    setCollaboratorCap(selectedOption)
    updateCaptacao(selectedOption?.value)
  }
  const [isSubmitting, setIsSubmitting] = useState(false)
  const toggle = () => {
    setModal(!modal)
  }

  const handleInputChange = e => {
    const { name, value } = e.target
    setInfluencerData(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setIsSubmitting(true)

    try {
      await api.post("/influencers", influencerData)
      Toastr("success", "Influenciador cadastrado com sucesso!")
    } catch (error) {
      console.error(error)
      Toastr("error", "Ocorreu um erro")
    } finally {
      setIsSubmitting(false)
      toggle()
    }
  }

  useEffect(() => {
    async function fetchCaptadores() {
      const response = await api.get("/users/api/2", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      setSelectedOption(response.data)
    }

    fetchCaptadores()
  }, [user.token])

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle} tag="h4">
        Adicionar Influenciador
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xxlxs={6}>
              <div className="mb-3">
                <Label className="form-label">@ do influenciador</Label>
                <Input
                  name="username"
                  type="text"
                  placeholder="Digite o @ do influenciador"
                  value={influencerData.username}
                  onChange={handleInputChange}
                />
              </div>

              <div className="mb-3">
                <Label className="form-label">Followers</Label>
                <Input
                  name="followers"
                  type="number"
                  placeholder="Digite o número de seguidores"
                  value={influencerData.followers}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-3">
                <Label className="form-label">Telefone</Label>
                <Input
                  name="phone"
                  type="number"
                  placeholder="Digite o número de telefone (Opcional)"
                  value={influencerData.phone}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-3">
                <Label className="form-label">Email</Label>
                <Input
                  name="email"
                  type="email"
                  placeholder="Digite o Email (Opcional)"
                  value={influencerData.email}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col xs={6}>
              <div className="mb-3">
                <Label className="form-label">Nome Completo</Label>
                <Input
                  name="fullname"
                  type="text"
                  placeholder="Digite o nome completo"
                  value={influencerData.fullname}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-3">
                <Label className="form-label">Following</Label>
                <Input
                  name="following"
                  type="number"
                  placeholder="Digite o número de seguindo"
                  value={influencerData.following}
                  onChange={handleInputChange}
                />
              </div>

              <div className="mb-3">
                <Label className="form-label">Método de contato</Label>
                <Input
                  name="method_contact"
                  type="text"
                  placeholder="Digite o método de contato"
                  value={influencerData.method_contact}
                  onChange={handleInputChange}
                />
              </div>

              <div className="mb-3">
                <Label className="form-label">Captador</Label>
                <Select
                  className="select2"
                  onChange={handleSectorChange}
                  options={selectedOption?.map(colaborador => ({
                    value: colaborador?.id,
                    label: colaborador?.fullname,
                  }))}
                  value={collaboratorCap}
                  placeholder="Selecione um captador"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button
                  type="submit"
                  className="btn btn-info save-user"
                  style={{ marginTop: "5px" }}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Enviando..." : "Cadastrar"}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default ModalCreateInfluencer
