import React from 'react';
import './styles.css'; // Importe o arquivo CSS para o componente Loading
import logo from '../../../assets/images/favicon.png'; // Importe sua imagem de logo

const Loading = () => {
  return (
    <div className="loading-container">
      <img src={logo} id= "loading" alt="Logo" className="loading" />
      <p className='text-muted'>Carregando...</p>
    </div>
  );
};

export default Loading;
