import api from "config/api"
import { AuthContext } from "contexts/auth"
import React, { useState, useEffect, useContext } from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Row, Badge } from "reactstrap"

const List = ({ listData }) => {
  const [influencers, setInfluencers] = useState([])
  const { user } = useContext(AuthContext)

  useEffect(()=>{
    const counters = {
      aguardando: 0,
      postou: 0,
      naoPostou: 0,
      Total: 0,
    }

    listData.forEach(element => {

      const elementDate = new Date(element.influencer_rent?.posts.start);
      const today = new Date();

     

      if (elementDate.toDateString() === today.toDateString()) {
        if(element.influencer_rent.archiving !== true){
          if (element.influencer_rent?.posts.waiting === true) {
            counters.aguardando += 1;
          } else {
            if(element.influencer_rent?.posts.posted === true ){
              counters.postou += 1
            }else{
              counters.naoPostou += 1
            }     
          }
        }

        
      }
    });
  },[listData])

  const activeBtn = ele => {
    if (ele.closest("button").classList.contains("active")) {
      ele.closest("button").classList.remove("active")
    } else {
      ele.closest("button").classList.add("active")
    }
  }

  const sectorBadgeClasses = sectorType => {
    switch (sectorType) {
      case "Grupos":
        return "badge badge-soft-success"
      case "Captação":
        return "badge badge-soft-info"
      case "TI":
        return "badge badge-soft-danger"
      default:
        return "badge badge-soft-warning"
    }
  }
  const sectorBadgeClassesPosts = sectorType => {
    let option = "Não Posta Hoje";

    if (sectorType.length > 0) {
      sectorType.forEach(element => {
        const elementDate = new Date(element.start);
        const today = new Date();
    
        if (elementDate.toDateString() === today.toDateString()) {
          if (element.waiting === true) {
            option = "Aguardando";
          } else {
            option = element.posted === true ? "Postou" : "Não Postou";
          }
        }
      });
    }
 
    switch (option) {
      case "Postou":
        return "badge badge-soft-success"
      case "Aguardando":
        return "badge badge-soft-warning"
      case "Não Postou":
        return "badge badge-soft-danger"
      case "Não Posta Hoje":
        return "badge badge-soft-info"
      default:
        return "badge badge-soft-info"
    }
  }
  const sectorBadgeCampaigns = (option) => {

    switch (option) {
      case "SuperBet":
        return "badge badge-soft-info"
      case "Betano e SuperBet":
        return "badge badge-soft-success"
      case "Betano":
        return "badge badge-soft-danger"
      default:
        return "badge badge-soft-warning"
    }
  }
  

  return (
    <React.Fragment>
      <Row>
        {listData.map((influencer, key) =>
          influencer.influencer_rent?.archiving === false ? (
            <Col xl={3} key={key}>
            <Card>
              <CardBody>
                <div className="d-flex align-start mb-3">
                  <div className="flex-grow-1">
                 
            
                    <span
                      className={
                        influencer.influencer_rent &&
                        influencer.influencer_rent?.posts
                          ? sectorBadgeClassesPosts(
                              influencer.influencer_rent?.posts
                            )
                          : "badge badge-soft-success"
                      }
                    >
                      {influencer
                        ? influencer.responsavel_rent?.fullname
                        : "Nome indisponível"}
                    </span>

                    <span
                      style={{"marginLeft": '10px'}}
                      className={
                        influencer.influencer_rent &&
                        influencer.influencer_rent?.campaigns_influencer
                          ? sectorBadgeCampaigns(
                              influencer.influencer_rent?.campaigns_influencer.name
                            )
                          : "badge badge-soft-success"
                      }
                    >
                      {influencer
                        ? influencer.influencer_rent?.campaigns_influencer?.name
                        : "Nome indisponível"}
                    </span> 

                  </div>
                  <button
                    type="button"
                    className="btn btn-light btn-sm like-btn"
                    onClick={e => activeBtn(e.target)}
                  >
                    <i className="bx bx-heart"></i>
                  </button>
                </div>
                <div className="text-center mb-3">
                  <img
                    src={influencer.influencer_rent?.photo}
                    alt=""
                    className="avatar-sm rounded-circle"
                  />
                  <h6 className="font-size-15 mt-3 mb-1 text-truncate">
                    {influencer.influencer_rent?.fullname}
                  </h6>
                  <p className="font-size-12 mb-0 text-muted">
                    @{influencer.influencer_rent?.username}
                  </p>
                </div>
                <div className="mt-4 pt-1">
                  <Link
                    to={`/influencer/${influencer.id}`}
                    className="btn btn-soft-primary d-block"
                  >
                    Ver perfil
                  </Link>
                </div>
              </CardBody>
            </Card>
          </Col>
          ): null
        )}
      </Row>
    </React.Fragment>
  )
}

export default List