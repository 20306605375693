import styled from 'styled-components';

export const StyledButton = styled.button`
    position: relative;
    width: ${({ followers }) => (followers < 1000 ? '130px' : '130px')};
    height: 35px;
    border-radius: 30px;
    background-color: white;
    border: 1px #FFB700 solid;
    overflow: hidden;
   
    &:hover .icon {
        transform: ${({ followers }) => (followers < 1000 ? 'translateX(96px)' : 'translateX(126px)')};
        transition: transform 0.5s;
    }

    &:hover .text2 {
        transform: ${({ followers }) => (followers < 1000 ? 'translateX(100px)' : 'translateX(130px)')};
        transition: transform 0.6s;
    }

    &:active {
        transform: scale(1.03);
    }
`;

export const Text1 = styled.span`
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    margin-left: 5px;
`;

export const Text2 = styled.span`
justify-content: center;
display: flex;
text-align: center;
position: absolute;
top: 20%;
left: ${({ followers }) => (followers < 1000 ? '-70px' : '-100px')};
font-weight: 700;
font-size: 14px;
color: white;
`;

export const Icon = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.5s;

    &::before {
        position: absolute;
        left: ${({ followers }) => (followers < 1000 ? '-100px' : '-130px')};
        top: 0;
        z-index: -1;
        content: '';
        width: ${({ followers }) => (followers < 1000 ? '130px' : '180px')};
        height: 33px;
        border-radius: 30px;
        background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #ffffff 80%);
    }
`;
