import React, { useState, useEffect, useRef, useMemo, useContext } from "react"
import Toasr from "../../../../components/Ui/Notifications"
import api from "config/api"
import { AuthContext } from "contexts/auth"
import { StateContext } from "contexts/states"
import { formatCPF } from "utils/formatCpf"
import { sortearElemento } from "utils/sortUserGrupos"
import {
  onSubmitInfluencers,
  onSubmitInfluencersEdit,
  fetchInfluencerData,
  FecharInfluencer
} from "./requests"

import io from "socket.io-client";

import ModalCreateInfluencer from "components/Modals/CreateInfluencer"
import ModalUpdatedInfluencer from "components/Modals/UpdatedInfluencer"

import {
  formatAleatoria,
formatCnpj,formatCpf,formatPhoneNumber, validateEmail, formatarParaReal
} from "../../../../utils/masks"

import Table from "../../../../components/Table"

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  Form,
  FormFeedback,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  UncontrolledTooltip,
} from "reactstrap"
import { ToastContainer } from "react-toastify"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import Spinners from "../../../../components/Common/Spinner"
import TableContainer from "../../../../components/Common/TableContainer"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Link } from "react-router-dom"
import Toastr from "../../../../components/Ui/Notifications"
import { forEach } from "lodash"


const ContactsList = props => {
  const { user } = useContext(AuthContext)
  const { modal, setModal, modalEdit, setModalEdit } = useContext(StateContext)
  // const socket = io("https://api-viraw-tm8e.onrender.com");
  //const socket = io("http://localhost:3333");

  document.title = "Lista de Influencers | Viraw Plataform"
  const type_chave = ["CNPJ", "CPF", "Telefone", "E-mail", "Aleatória"]
  const [nome, setNome] = useState("")
  const [cpf, setCpf] = useState("")
  const [value, setValue] = useState("")
  const [tipoChavePix, setTipoChavePix] = useState("")
  const [chavePix, setChavePix] = useState("")
  const [validEmail, setValidEmail] = useState(true)

  const [profileData, setProfileData] = useState({})
  const [profileDataAvatar, setProfileDataAvatar] = useState("")
  const [contact, setContact] = useState()
  const [isLoading, setLoading] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState("")
  const [followingCount, setFollowingCount] = useState("")
  const [followersCount, setFollowersCount] = useState("")
  const [statusList, setStatusList] = useState()
  const [statusClose, setStatusClose] = useState()
  const [influencers, setInfluencers] = useState([])
  const [fechadoModal, setFechadoModal] = useState(false)

  const [link_plug_chat, setLink_plug_chat] = useState("")
  const [dataFechamento, setDataFechamento] = useState("")
  const [horaFechamento, setHoraFechamento] = useState("")

  const [confirmarModal, setConfirmarModal] = useState(false)
  const [influencer, setInfluencer] = useState()
  const [userList, setUserList] = useState([])
  const [confirmarEditModal, setConfirmarEditModal] = useState(false)
  const [influencerDataFullname, setInfluencerDataFullname] = useState("")
  const [userGrupos, setUserGrupos] = useState([])

  // useEffect(() => {
  //    socket.on("new_influencer", (items) => {
  //      setInfluencers(items)
  //      Toastr("success", "Influenciador adicionado com sucesso!")
  //    });
  //  }, []); 

  useEffect(() => {
    async function getUserGrupos() {
      await api
        .get("/users/api/3", {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        })
        .then(response => {
          setUserGrupos(response.data)
        })
        .catch(error => {
          console.error(error)
        })
    }
    getUserGrupos()
  }, [])


  useEffect(() => {
    async function fetchInfluencers() {
      setLoading(true)
      try {
        const response = await api.get(`influencers/captador/${user.userId}`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        })

        if (response.data) {
          setInfluencers(response.data)
        }
      } catch (error) {
        console.error("Erro ao buscar influenciadores:", error)
      } finally {
        setLoading(false)
      }
    }

    fetchInfluencers()
  }, [user.token])

  useEffect(() => {
    async function getStatus() {
      await api
        .get("/status", {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        })
        .then(response => {
          let status = []
          response.data.forEach(element => {
            if (element.status === "Fechado") {
              setStatusClose(element.id)
            } else {
              status.push(element)
            }
          })
          setStatusList(status)
          
        })
        .catch(error => {
          console.error(error)
        })
    }
    getStatus()
  }, [])

  function fetchInfluencer(influencerData) {
    setInfluencerDataFullname(influencerData.fullName)
    setProfileData(influencerData.profileData)
    setProfileDataAvatar(influencerData.profilePicture)
  }

  const handleDeleteUser = async (id, token) => {
    try {
      const response = await api.delete(
        `/influencers/delete/${contact.id}`,{
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      )

      if (response.data) {
        Toastr("success", "Influenciador deletado com sucesso!")
        setInfluencers(prevInfluencers =>
          prevInfluencers.filter(influencer => influencer.id !== contact.id)
        )
        setDeleteModal(false)
      }
    } catch (error) {
      console.error("Erro ao excluir influenciador:", error)
    }
  }

  async function Register(response) {
    try {
      toggle();
    } catch (error) {
      console.error("Erro ao cadastrar influenciador:", error);
    }
  }

  function Updated() {
    Toasr("success", "Influencer Editado com sucesso!")
    setProfileData(null)
    setProfileDataAvatar(null)
    setInfluencerDataFullname(null)
    toggleEdit()
    window.location.reload()
  }


  const toggle = () => {
    setModal(!modal)

    setProfileData(null)
    setProfileDataAvatar(null)
    setInfluencerDataFullname(null)
    setInfluencer(null)
    setSelectedStatus(null)
    
  }

  const toggleEdit = () => {
    setModalEdit(!modalEdit)
  }

  const handleUserClick = arg => {
    const user = arg
    setProfileData({
      following: user.following,
      followers: user.followers,
    })
    setProfileDataAvatar(user.photo)
    setContact({
      id: user.id,
      phone: user.phone,
      email: user.email,
      fullname: user.fullname,
      username: user.username,
      status: user.status,
    })
    toggleEdit()
  }

  const onClickDelete = userData => {
    setContact(userData)
    setDeleteModal(true)
  }

  const handleUserClicks = () => {
    setModalEdit(false)
    toggle()
  }

  const onClickUpdate = data => {
    const userData = data
    handleUserClick(userData)
  }
  const onClickDeleteInfluencer = data => {
    const userData = data
    onClickDelete(userData)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Contatos" breadcrumbItem="Influenciadores" />
          <Row>
            {isLoading ? (
              <Spinners setLoading={setLoading} />
            ) : (
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Table
                      id={user.userId}
                      influencerData={influencers}
                      handleUserClick={handleUserClicks}
                      update={onClickUpdate}
                      deleteInfluencer={onClickDeleteInfluencer}
                    />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>

            <ModalCreateInfluencer  Register={Register} />
            <ModalUpdatedInfluencer profileData={profileData} profileDataAvatar={profileDataAvatar} contact={contact} Updated={Updated} />

          <Modal
            isOpen={deleteModal}
            toggle={() => setDeleteModal(!deleteModal)}
          >
            <ModalHeader tag="h4" toggle={() => setDeleteModal(!deleteModal)}>
              Confirmar Exclusão
            </ModalHeader>
            <ModalBody>Você deseja excluir o influenciador?</ModalBody>
            <ModalFooter>
              <Button
                color="secondary"
                onClick={() => setDeleteModal(!deleteModal)}
              >
                Cancelar
              </Button>
              <Button color="danger" onClick={handleDeleteUser}>
                Excluir
              </Button>
            </ModalFooter>
          </Modal>
          
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}


export default ContactsList
