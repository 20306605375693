import React, { useEffect, useState, useContext } from 'react';
import { Row, Col, Card, CardBody } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../../components/Common/ChartsDynamicColor";
import { useParams } from "react-router-dom"
import api from "config/api"
import Loading from "components/Ui/LoadingCircle"

import { AuthContext } from "contexts/auth"

const SalesAnalytics = ({ dataColors, instagram, whatsapp, youtube, googleforms}) => {
  const apexsalesAnalyticsChartColors = getChartColorsArray(dataColors);
  const {user} = useContext(AuthContext)
  const { id } = useParams()
  // const [instagram, setInstagram] = useState('')
  // const [whatsapp, setWhatsapp] = useState()
  // const [youtube, setYoutube] = useState()
  // const [google, setGoogle] = useState()
  const [loading, setLoading] = useState(false)
  
  const options = {
    labels: ["Instagram", "Whatsapp", "YouTube", "Google Forms"],
    colors: apexsalesAnalyticsChartColors,
    legend: { show: !1 },
    plotOptions: {
      pie: {
        donut: {
          size: "80%",
        },
      },
    },
  };


  return (
    <React.Fragment>
      {
        !loading ? (
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">Plataformas de Captação</h4>
  
              <div>
                <div id="donut-chart">
                  
                  <ReactApexChart
                    options={options}
                    series={[instagram, whatsapp, youtube, googleforms]}
                    type="donut"
                    height={260}
                    className="apex-charts"
                  />
                </div>
              </div>
  
              <div className="text-center text-muted">
                <Row>
                  <Col xs="3">
                    <div className="mt-4">
                      <p className="mb-2 text-truncate text-dark">
                        <i className="mdi mdi-circle text-primary me-1" />Instagram
                      </p>
                      <h5>{instagram}</h5>
                    </div>
                  </Col>
                  <Col xs="3">
                    <div className="mt-4">
                      <p className="mb-2 text-truncate text-dark">
                        <i className="mdi mdi-circle text-success me-1" />WhatsApp
                        
                      </p>
                      <h5>{whatsapp}</h5>
                    </div>
                  </Col>
                  <Col xs="3">
                    <div className="mt-4">
                      <p className="mb-2 text-truncate text-dark">
                        <i className="mdi mdi-circle text-danger me-1" />YouTube
                      </p>
                      <h5>{youtube}</h5>
                    </div>
                  </Col>
                  <Col xs="3">
                    <div className="mt-4">
                      <p className="mb-2 text-truncate text-dark">
                        <i className="mdi mdi-circle text-warning me-1" />Google Forms
                      </p>
                      <h5>{googleforms}</h5>
                    </div>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
      
        ) :  (
          <Loading />
        )
      }
    
    
  
    </React.Fragment>
  );
};

export default SalesAnalytics;
