import React from "react";
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Table } from "reactstrap";

const RankingComponent = (props) => {
  const ordenarDados = (dados) => {
    return dados.sort((a, b) => {
      if (a.mensal === b.mensal) {
        return b.total - a.total;
      }
      return b.mensal - a.mensal;
    });
  };

  const dadosOrdenados = ordenarDados([...props.data]);

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle className="h4">Ranking de Closers GERAL</CardTitle>
              <CardSubtitle className="card-title-desc">
                O Ranking é atualizado de acordo com o número de influenciadores FECHADOS por cada colaborador do setor de <code>CLOSER</code>. Esse é um ranking geral atualizado.
              </CardSubtitle>
              <div className="table-responsive">
                <Table className="table table-sm m-0">
                  <thead>
                    <tr>
                      <th>Nº</th>
                      <th>Colaborador</th>
                      <th style={{ textAlign: "center" }}>Semanal</th>
                      <th style={{ textAlign: "center" }}>Mensal</th>
                      <th style={{ textAlign: "center" }}>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dadosOrdenados.map((user, index) => (
                      <tr key={user.id}>
                        <th scope="row" style={{ textAlign: "center" }}>
                          {index + 1}
                        </th>
                        <td>{user.name}</td>
                        <td style={{ textAlign: "center" }}>{user.semanal}</td>
                        <td style={{ textAlign: "center" }}>{user.mensal}</td>
                        <td style={{ textAlign: "center" }}>{user.total}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default RankingComponent;
