import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "contexts/auth";
import { Card, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import { CounterBox, CounterTitle, BoxGraf, CounterValue } from "./styles";
import PieChart from "./pie";
import LineChart from "./bar";
import TabelaGeral from "./TabelaGeral";
import Tabela from "./financeiro.json";
import { StateContext } from "contexts/states";

import AddButton from "../../../components/Ui/ButtonPage/index";
import ModalCreateInfluencer from "../../../components/Modals/AddFinanceiro/index";

const CollaboratorsList = () => {
  const { user } = useContext(AuthContext);
  const { modal, setModal } = useContext(StateContext);

  const data = Tabela.Data;

  const toggleModal = () => {
    setAbrirModal(!abrirModal);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Financeiro" breadcrumbItem="Financeiro" />
          <Card className="collaborator-filter rounded-xxl p-1">
            <Row>
              <Col lg={12}>
                <Row className="d-flex justify-content-center">
                  <Col sm={6} md={3}>
                    <CounterBox className="bg-muted mt-2 mb-2">
                      <CounterTitle className="text-muted">Entradas</CounterTitle>
                      <CounterValue className="text-muted">
                        R$ {data.entradas.toLocaleString()}
                      </CounterValue>
                    </CounterBox>
                  </Col>

                  <Col sm={6} md={3}>
                    <CounterBox className="bg-muted mt-2 mb-2">
                      <CounterTitle className="text-muted">Saídas</CounterTitle>
                      <CounterValue className="text-muted">
                        R$ {data.saidas.toLocaleString()}
                      </CounterValue>
                    </CounterBox>
                  </Col>

                  <Col sm={6} md={3}>
                    <CounterBox className="bg-muted mt-2 mb-2">
                      <CounterTitle className="text-muted">Custos</CounterTitle>
                      <CounterValue className="text-muted">
                        R$ {data.custos.toLocaleString()}
                      </CounterValue>
                    </CounterBox>
                  </Col>

                  <Col sm={6} md={3}>
                    <CounterBox className="bg-muted mt-2 mb-2">
                      <CounterTitle className="text-muted">Despesas</CounterTitle>
                      <CounterValue className="text-muted">
                        R$ {data.despesas.toLocaleString()}
                      </CounterValue>
                    </CounterBox>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="mt-2">
              <Col sm={6} md={4}>
                <BoxGraf className="bg-muted mt- d-flex justify-content-center align-items-center max-height">
                  <div>
                    <CounterTitle className="text-muted text-center">
                      Distribuição da Saída de Caixa no Período
                    </CounterTitle>
                    <PieChart />
                  </div>
                </BoxGraf>
              </Col>

              <Col sm={6} md={8}>
                <BoxGraf className="bg-muted mt- d-flex flex-column justify-content-center align-items-center max-height">
                  <CounterTitle className="text-muted text-center mb-2">
                    Distribuição Mensal de Entradas e Saídas Totais
                  </CounterTitle>
                  <LineChart />
                </BoxGraf>
              </Col>
            </Row>

            <Row className="mt-2">
              <Col sm={12} md={12}>
                <BoxGraf className="bg-muted mt- d-flex flex-column justify-content-center align-items-center max-height mt-2">
                  <CounterTitle className="text-muted text-center">
                    Detalhamento dos Resultados
                  </CounterTitle>
                  <TabelaGeral />
                </BoxGraf>
              </Col>
            </Row>

            <AddButton />

            <ModalCreateInfluencer modal={modal} toggle={() => setModal(!modal)} />
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CollaboratorsList;
