import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "contexts/auth";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Input,
} from "reactstrap";
import api from "config/api";
import Spinners from "components/Common/Spinner";
import Breadcrumbs from "components/Common/Breadcrumb";
import Toastr from "../../../components/Ui/Notifications/index";
import List from "./List";
import Botao from "./button"
import ModalCreateInfluencer from "components/Modals/CreateInfluencer";
import { StateContext } from "contexts/states";
import BadgeWithTooltip from "./BadgeWithTooltip";

const CollaboratorsList = () => {
  const [collaborators, setCollaborators] = useState([]);
  const [originalCollaborators, setOriginalCollaborators] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState(null);
  const { user } = useContext(AuthContext);
  const [searchValue, setSearchValue] = useState("");
  const [selectedFilterOption, setSelectedFilterOption] = useState(null);
  const [contact, setContact] = useState()
  const { modal, setModal } = useContext(StateContext)

  const Register = async response => {
    try {
      Toastr("success", "Influenciador cadastrado com sucesso!");
      setModal(false);
    } catch (error) {
      console.error("Erro ao cadastrar influenciador:", error)
    }
  }

  const getCollaborators = async () => {
    setLoading(true);
    try {
      const response = await api.get(`influencers/captador/responsavel/${user.userId}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      setOriginalCollaborators(response.data);
      setCollaborators(response.data);
    } catch (error) {
      console.error("Error fetching collaborators:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getCollaborators();
  }, [user.token, selectedFilterOption]);

  const handleSearchCollaborator = (value) => {
    setSearchValue(value);
    const lowerCaseValue = value.toLowerCase();

    const filteredCollaborators = originalCollaborators.filter((collaborator) => {
      const fullname =
        collaborator?.fullname.toLowerCase() || "";
      const username =
        collaborator?.username.toLowerCase() || "";
      return (
        fullname.includes(lowerCaseValue) || username.includes(lowerCaseValue)
      );
    });

    setCollaborators(filteredCollaborators);

    if (value.trim() === "") {
      setSelectedOption(null);
      setCollaborators(originalCollaborators);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Influencers" breadcrumbItem="Influencers" />
          <Row>
            <Col lg={12}>
              <Card className="collaborator-filter">
                <CardBody>
                  <form action="#">
                    <Row className="g-3">
                      <Col xxl={4} lg={4}>
                        <div className="position-relative">
                          <Input
                            type="text"
                            id="searchCollaborator"
                            autoComplete="off"
                            placeholder="Buscar Influenciador"
                            onChange={(e) =>
                              handleSearchCollaborator(e.target.value)
                            }
                            value={searchValue}
                          />
                        </div>
                      </Col>
                      <Col xxl={4} lg={4}>
                        <div className="position-relative">
                          <select
                            className="form-select"
                            onChange={(e) => setSelectedFilterOption(e.target.value)}
                            value={selectedFilterOption}
                          >
                            <option value="">Todos</option>
                            <option value="5">Novos</option>
                            <option value="1">Em contato</option>
                            <option value="2">Negociando</option>
                            <option value="3">Sem sucesso</option>
                            <option value="4">Fechado</option>
                          </select>
                        </div>
                      </Col>
                      <Col xxl={4} lg={4}>
                        <div className="d-flex justify-content-center align-items-center h-100">
                          <div className="me-1">
                            <BadgeWithTooltip id="tooltipNovos" tooltipText="Essa legenda é apenas para os que foram adicionados, e que ainda não foram aceito pelo captador responsável, lembrando que se ele ainda não aceitou, pode ser editado">
                              <div className="badge badge-soft-warning badge-custom">
                                <span>Novos</span>
                              </div>
                            </BadgeWithTooltip>
                          </div>
                          <div className="me-1">
                            <BadgeWithTooltip id="tooltipEmContato" tooltipText="Influenciador iniciou o processo de contato com o responsavel, nessa etapa já não é mais possivel editar.">
                              <div className="badge badge-soft-info badge-custom">
                                <span>Em contato</span>
                              </div>
                            </BadgeWithTooltip>
                          </div>
                          <div className="me-1">
                            <BadgeWithTooltip id="tooltipNegociando" tooltipText="Influenciadores em fase de negociação">
                              <div className="badge badge-soft-primary badge-custom">
                                <span>Negociando</span>
                              </div>
                            </BadgeWithTooltip>
                          </div>
                          <div className="me-1">
                            <BadgeWithTooltip id="tooltipSemSucesso" tooltipText="Influenciadores que mesmo filtrados e não obteve sucesso">
                              <div className="badge badge-soft-danger badge-custom">
                                <span>Sem Sucesso</span>
                              </div>
                            </BadgeWithTooltip>
                          </div>
                          <div>
                            <BadgeWithTooltip id="tooltipFechado" tooltipText="Influenciadores que seguiram todo o fluxo e foi obtido sucesso">
                              <div className="badge badge-soft-success badge-custom">
                                <span>Fechado</span>
                              </div>
                            </BadgeWithTooltip>
                          </div>
                        </div>
                      </Col>


                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <List listData={collaborators} statusFilter={selectedFilterOption} />
          )}
          <Botao />
          <ModalCreateInfluencer Register={Register} contact={contact} />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CollaboratorsList;
