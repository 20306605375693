import React from 'react';
import styled, { keyframes } from 'styled-components';

// Define a keyframe animation for the loading spinner
const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

// Styled components for the loading spinner
const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
`;

const Spinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: ${spin} 1s linear infinite;
  margin-bottom: 20px;
`;

const LoadingText = styled.p`
  margin-left: 10px;
  font-size: 18px;
`;

// Loading component
const Loading = ({ text = 'Carregando...' }) => (
  <LoadingContainer>
    <Spinner />
    <LoadingText className='text-muted'>{text}</LoadingText>
  </LoadingContainer>
);

export default Loading;
