import React, { useContext, useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

import { AuthContext } from "contexts/auth"
import api from "../../../config/api";

function CardUser({ total, ativos, archiveds }) {
  const { user } = useContext(AuthContext)
  const [settingsMenu, setSettingsMenu] = useState(false)
  const getMonthName = (monthIndex) => {
    const months = [
        "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
        "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
    ];
    return months[monthIndex];
};


  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Row>
                <Col lg="4">
                  <div className="d-flex">
                    <div className="me-3">
                      <img
                        src={user.photo}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <p className="mb-2 text-muted">Bem-vindo ao Painel Viraw </p>
                        <h5 className="mb-1 text-muted">{user.fullname}</h5>
                        <p className="mb-0 text-muted">Setor: {user.typeName?.type}</p>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col lg="8" >
                  <div className="mt-4 mt-lg-0">
                    <Row className="align-left">
                      <Col md="12 mb-2">
                      <h5 style={{ marginLeft: "12.5%" }}>Dados obtidos no mês de {getMonthName(new Date().getMonth())}</h5>
                      </Col>
                    </Row>
                    <Row className="align-self-center text-lg-center">
                      <Col xs="4">
                        <div>
                          <p className="text-muted text-truncate mb-2">
                            Total
                          </p>
                          <h5 className="mb-0">{total}</h5>
                        </div>
                      </Col>
                      <Col xs="4">
                        <div>
                          <p className="text-muted text-truncate mb-2">
                            Ativos
                          </p>
                          <h5 className="mb-0">{ativos}</h5>
                        </div>
                      </Col>
                      <Col xs="4">
                        <div>
                          <p className="text-muted text-truncate mb-2">
                            Desativados
                          </p>
                          <h5 className="mb-0">{archiveds}</h5>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>


              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default CardUser
