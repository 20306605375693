import api from "config/api"
import { AuthContext } from "contexts/auth"
import React, { useState, useEffect, useContext } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Row,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap"
import Toastr from "../../../components/Ui/Notifications"

const List = ({ listData }) => {
  const [influencers, setInfluencers] = useState([])
  const { user } = useContext(AuthContext)
  const [showModal, setShowModal] = useState(false)
  const [idInfluencer, setIdInfluencer] = useState(null)

  const activeBtn = (id) => {
    setIdInfluencer(id)
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }


  const handleArchiveConfirm = () => {
    api.put(`/users/archiving/`, {
      id: idInfluencer,
      active: true,
      sort: true
    }, {
      headers: {
        Authorization: `Bearer ${user.token}`
      }
    }).then(response => {
      Toastr("success", "Colaborador reativado com sucesso!");
      setTimeout(() => {
        window.location.reload();  
      }, 3000);       handleCloseModal();
    }).catch(error => {
      console.error("Erro ao reativar colaborador:", error);
    });
  };

  useEffect(() => {
    const archivedInfluencers = listData.filter(
      (influencer) => influencer.active === false
    )
    setInfluencers(archivedInfluencers)
  }, [listData])
  const sectorBadgeClasses = (sectorType) => {
    switch (sectorType) {
      case "Grupos":
        return "badge badge-soft-success"
      case "Captação":
        return "badge badge-soft-info"
      case "TI":
        return "badge badge-soft-danger"
      default:
        return "badge badge-soft-warning"
    }
  }

  return (
    <React.Fragment>
      <Row>
        {influencers.map((influencer, key) => (
          <Col xl={3} key={key}>
            <Card>
              <CardBody>
                <div className="d-flex align-start mb-3">
                  <div className="flex-grow-1">
                    <span
                      className={
                        influencer &&
                          influencer.type_user
                          ? sectorBadgeClasses(
                            influencer.type_user.type
                          )
                          : "badge badge-soft-success"
                      }
                    >
                      {influencer
                        ? influencer.type_user.type
                        : "Nome indisponível"}
                    </span>
                  </div>
                  <button
                    type="button"
                    className="btn btn-light btn-sm like-btn"
                    onClick={() => activeBtn(influencer.id)}
                  >
                    <i
                      className="mdi mdi-account-reactivate-outline"
                      style={{ fontSize: "14px" }}
                    ></i>
                  </button>

                </div>
                <div className="text-center mb-3">
                  <img
                    src={influencer.photo}
                    alt=""
                    className="avatar-sm rounded-circle"
                  />
                  <h6 className="font-size-15 mt-3 mb-1">
                    {influencer.fullname}
                  </h6>
                  <p className="mb-0 text-muted">
                    {influencer.email}
                  </p>
                </div>
                <div className="mt-4 pt-1">
                  <Link
                    to={`/colaboradores/${influencer.id}`}
                    className="btn btn-soft-primary d-block"
                  >
                    Ver perfil
                  </Link>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>

      <Modal isOpen={showModal} toggle={handleCloseModal}>
        <ModalHeader toggle={handleCloseModal}>
          Reativar Colaborador
        </ModalHeader>
        <ModalBody>Você deseja reativar este colaborador?</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button color="primary" onClick={handleArchiveConfirm}>
            Sim
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default List
