import React, { useContext, useState } from "react";
import { Modal, Button, Form, FormGroup, Label, Input, ModalFooter } from "reactstrap";
import { AuthContext } from "contexts/auth";
import { StateContext } from "contexts/states";
import Toastr from "../../../components/Ui/Notifications";
import api from "config/api";

const AddPaymentModal = ({ id, onChangeValue }) => {
  const [paymentValue, setPaymentValue] = useState("");
  const { addValueSemanal, setAddValueSemanal } = useContext(StateContext);
  const { user } = useContext(AuthContext);

  const handleAddPayment = () => {
    postPayment(paymentValue, id);
    toggle();
  };

  const toggle = () => {
    setAddValueSemanal(!addValueSemanal);
  };

  async function postPayment(value, id) {
    await api
      .patch(
        "bankdata/update/value",
        {
          id: id,
          value: value,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      .then((response) => {
        Toastr("success", "Pagamento adicionado com sucesso!");
        onChangeValue(value);
      })
      .catch((error) => {
        console.error(error);
        Toastr("error", "Algo errado aconteceu!");
      });
  }
  

  const formatarMoeda = (valor) => {
    // Remove caracteres não numéricos
    const numeroLimpo = valor.replace(/[^0-9]/g, "");

    // Converte para número
    const valorNumerico = Number(numeroLimpo) / 100;

    // Formata como moeda
    const numeroFormatado = valorNumerico.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
    });

    return numeroFormatado;
  };

  return (
    <Modal isOpen={addValueSemanal} toggle={toggle}>
      <div className="modal-header">
        <h5 className="modal-title">Adicionar Valor</h5>
        <button type="button" className="btn-close" onClick={toggle}></button>
      </div>
      <div className="modal-body">
        <Form>
          <FormGroup>
            <Label for="paymentValue">Valor Semanal</Label>
            <Input
              type="text"
              id="paymentValue"
              placeholder="Digite o valor semanal"
              value={paymentValue}
              onChange={(e) => setPaymentValue(formatarMoeda(e.target.value))}
            />
          </FormGroup>
        </Form>
      </div>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Fechar
        </Button>
        <Button color="primary" onClick={handleAddPayment}>
          Adicionar Valor
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddPaymentModal;
