export const formatCPF = value => {
    const numericValue = value.replace(/\D/g, "")

    const limitedCPF = numericValue.slice(0, 11)
    
    let formattedCPF = limitedCPF

    if (limitedCPF.length > 3) {
      formattedCPF = `${limitedCPF.slice(0, 3)}.${limitedCPF.slice(3)}`
    }
    if (limitedCPF.length > 6) {
      formattedCPF = `${formattedCPF.slice(0, 7)}.${limitedCPF.slice(6)}`
    }
    if (limitedCPF.length > 9) {
      formattedCPF = `${formattedCPF.slice(0, 11)}-${limitedCPF.slice(9)}`
    }

    return formattedCPF
  }