import React, { useState, useEffect, useContext } from "react"
import { format, addDays, startOfWeek, endOfWeek, isSameDay } from "date-fns"
import { ptBR } from "date-fns/locale"
import {
  Main,
  Card,
  DateButton,
  TimeButton,
  DateCarousel,
  TimeGrid,
  ConfirmButton,
  InputContainer,
  InputLabel,
  Input,
} from "./styles"
import Toastr from "../../../components/Ui/Notifications"
import api from "config/api"
import { AuthContext } from "contexts/auth"
import Modal from "../../../components/Modals/modalShedulling"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash } from "@fortawesome/free-solid-svg-icons"

export default function Meeting() {
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [selectedTime, setSelectedTime] = useState(null)
  const [weekDates, setWeekDates] = useState([])
  const [tema, setTema] = useState("")
  const [reservas, setReservas] = useState({})
  const [salas, setSalas] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [selectedRoom, setSelectedRoom] = useState("")
  const [meetingDescription, setMeetingDescription] = useState("")
  const { user } = useContext(AuthContext)
  const [hoveredReservationId, setHoveredReservationId] = useState(null)

  const loadReservas = async () => {
    try {
      const response = await api.get("/scheduling", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      setReservas(response.data)
    } catch (error) {
      console.error("Erro ao carregar reservas:", error)
      Toastr("error", "Erro ao carregar reservas.")
    }
  }

  const loadSalas = async () => {
    try {
      const response = await api.get("/scheduling/room/all", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      setSalas(response.data)
    } catch (error) {
      console.error("Erro ao carregar salas:", error)
      Toastr("error", "Erro ao carregar salas.")
    }
  }
  const getTemaFromStorage = () => {
    return localStorage.getItem("tema") || ""
  }

  useEffect(() => {
    setTema(getTemaFromStorage())

    const intervalId = setInterval(() => {
      const currentTema = getTemaFromStorage()
      if (currentTema !== tema) {
        setTema(currentTema)
      }
    }, 100)

    return () => clearInterval(intervalId)
  }, [tema])

  useEffect(() => {
    loadSalas()
    loadReservas()
  }, [selectedDate])

  const handleTimeSelect = time => {
    const dateKey = format(selectedDate, "yyyy-MM-dd")
    const reservasDoDia = reservas[dateKey] || {}

    const userReservationsCount = Object.values(reservasDoDia)
      .flat()
      .filter(r => r.user.id === user.userId).length

    if (userReservationsCount >= 3) {
      Toastr("warning", "Você já fez o máximo de 3 reservas para este dia.")
      return
    }

    const userHasReservationInTime = reservasDoDia[time]?.some(
      r => r.user.id === user.userId
    )

    if (userHasReservationInTime) {
      Toastr("error", "Você já tem uma reserva nesse horário.")
      return
    }

    setSelectedTime(time)
    setShowModal(true)
  }

  const handleDeleteReserva = async reservaId => {
    try {
      await api.delete(`/scheduling/${reservaId}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      Toastr("success", "Reserva deletada com sucesso.")
      await loadReservas()
    } catch (error) {
      console.error("Erro ao deletar reserva:", error)
      Toastr("error", "Erro ao deletar reserva.")
    }
  }

  const handleConfirmReservation = async () => {
    const dateKey = format(selectedDate, "yyyy-MM-dd")
    const reserva = {
      description: meetingDescription,
      user: user.userId,
      date: dateKey,
      hour: selectedTime,
      room: selectedRoom,
    }

    try {
      const response = await api.post("/scheduling", reserva, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      await loadReservas()
      Toastr(
        "success",
        `Reserva feita com sucesso para ${selectedTime} na sala ${selectedRoom}`
      )
      setShowModal(false)
      setMeetingDescription("")
      setSelectedRoom("")
    } catch (error) {
      console.error(
        "Erro ao fazer reserva:",
        error.response?.data || error.message
      )
      Toastr("error", "Erro ao fazer reserva.")
    }
  }

  useEffect(() => {
    const generateWeekDates = () => {
      const start = startOfWeek(new Date(), { weekStartsOn: 1 })
      const end = endOfWeek(new Date(), { weekStartsOn: 1 })
      const dates = []
      let day = start

      while (day <= end) {
        dates.push(day)
        day = addDays(day, 1)
      }
      return dates
    }

    setWeekDates(generateWeekDates())
  }, [])

  const times = Array.from({ length: 24 }, (_, i) => `${i}:00`)

  return (
    <React.Fragment>
      <div className="page-content">
        <Main tema={tema}>
          <Card tema={tema}>
            <h5>Para quando você gostaria de agendar?</h5>
            <DateCarousel>
              {weekDates.map((date, index) => (
                <DateButton
                  key={index}
                  tema={tema}
                  selected={isSameDay(selectedDate, date)}
                  onClick={() => setSelectedDate(date)}
                >
                  <span
                    className="day-name"
                    style={{ textTransform: "uppercase" }}
                  >
                    {format(date, "eee", { locale: ptBR })}
                  </span>
                  <span className="day-number">
                    {format(date, "dd", { locale: ptBR })}
                  </span>
                  <span className="month-name">
                    {format(date, "MMM", { locale: ptBR })}
                  </span>
                </DateButton>
              ))}
            </DateCarousel>

            <h5>Escolha o horário:</h5>
            <TimeGrid tema={tema}>
              {times.map((time, index) => (
                <TimeButton
                  key={index}
                  tema={tema}
                  selected={selectedTime === time}
                  onClick={e => {
                    e.stopPropagation()
                    handleTimeSelect(time)
                  }}
                >
                  {time}
                  {reservas[format(selectedDate, "yyyy-MM-dd")]?.[time] && (
                    <div
                      style={{
                        display: "flex",
                        position: "relative",
                        gap: "-15px",
                      }}
                    >
                      {reservas[format(selectedDate, "yyyy-MM-dd")][time].map(
                        reserva => (
                          <div
                            key={reserva.id}
                            style={{
                              position: "relative",
                              display: "inline-block",
                              cursor: "pointer",
                            }}
                            className="user-photo-container"
                            onMouseEnter={() =>
                              setHoveredReservationId(reserva.id)
                            }
                            onMouseLeave={() => setHoveredReservationId(null)}
                          >
                            <img
                              src={reserva.user.photo}
                              alt={reserva.user.fullname}
                              style={{
                                width: 40,
                                height: 40,
                                borderRadius: "50%",
                                objectFit: "cover",
                                transition: "transform 0.2s",
                                filter:
                                  hoveredReservationId === reserva.id
                                    ? "brightness(0.5)"
                                    : "none",
                              }}
                              className="user-photo"
                            />

                            {hoveredReservationId === reserva.id && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: "100%",
                                  left: "50%",
                                  transform: "translateX(-50%)",
                                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                                  color: "white",
                                  padding: "5px",
                                  borderRadius: "5px",
                                  fontSize: "12px",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <>
                                  {`${reserva.user.fullname} - Sala: ${reserva.room}`}
                                  <div>Motivo: {reserva.reason}</div>
                                </>
                              </div>
                            )}

                            {hoveredReservationId === reserva.id &&
                              reserva.user.id === user.userId && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    color: "red",
                                  }}
                                  onClick={e => {
                                    e.stopPropagation()
                                    handleDeleteReserva(reserva.id)
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faTrash}
                                    style={{ fontSize: "20px" }}
                                  />
                                </div>
                              )}
                          </div>
                        )
                      )}
                    </div>
                  )}
                </TimeButton>
              ))}
            </TimeGrid>
          </Card>
        </Main>

        {showModal && (
          <Modal onClose={() => setShowModal(false)}>
            <h4>Confirme sua reserva</h4>
            <p>Escolha a sala de reunião e o motivo.</p>

            <InputContainer>
              <InputLabel>Motivo da Reunião</InputLabel>
              <Input
                type="text"
                value={meetingDescription}
                onChange={e => setMeetingDescription(e.target.value)}
                placeholder="Digite o motivo da reunião"
              />
            </InputContainer>

            <InputContainer>
              <InputLabel>Selecione a Sala</InputLabel>
              <select
                value={selectedRoom}
                onChange={e => setSelectedRoom(e.target.value)}
                style={{
                  borderRadius: "10px",
                  border: "none",
                  borderBottom: "2px solid black",
                  padding: "8px",
                }}
              >
                <option value="">Selecione uma sala</option>
                {salas.map(sala => (
                  <option key={sala.id} value={sala.room}>
                    {sala.room}
                  </option>
                ))}
              </select>
            </InputContainer>

            <ConfirmButton
              onClick={handleConfirmReservation}
              disabled={!selectedRoom || !meetingDescription}
            >
              Confirmar Agendamento
            </ConfirmButton>
          </Modal>
        )}
      </div>
    </React.Fragment>
  )
}
