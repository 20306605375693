import React, { useState } from "react"
import { Button, Card, CardBody, Col, Row } from "reactstrap"
import CompararInfluencer from "../../../components/Modals/CompararInfluencer"

const List = ({ listData }) => {

  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedInfluencer, setSelectedInfluencer] = useState(null);

  const toggleModal = () => setModalOpen(!isModalOpen);

  const sectorBadgeClasses = sectorType => {
    switch (sectorType) {
      case "Francisco":
        return "badge badge-soft-success"
      case "João Victor":
        return "badge badge-soft-info"
      case "Karol":
        return "badge badge-soft-danger"
      default:
        return "badge badge-soft-warning"
    }
  }

  const renderBadges = influencer => {
    const badges = []
    if (influencer.isAdvanceValue) {
      badges.push(
        <span key="advance" className="badge badge-soft-warning ml-2">
          Antecipado
        </span>
      )
    }
    if (influencer.isDailyPayment) {
      badges.push(
        <span key="daily" className="badge badge-soft-primary ml-2">
          Diário
        </span>
      )
    }
    return badges
  }

  const handleCompareClick = (influencer) => {
    setSelectedInfluencer(influencer);
    toggleModal();
  };

  return (
    <React.Fragment>
      <Row>
        {listData.map((influencer, key) => (
          <Col xl={3} key={key}>
            <Card>
              <CardBody>
                <div className="d-flex align-start mb-3">
                  <div className="flex-grow-1">
                    <span
                      className={
                        influencer.responsavel.fullname
                          ? sectorBadgeClasses(influencer.responsavel.fullname)
                          : "badge badge-soft-success"
                      }
                    >
                      {influencer.responsavel.fullname
                        ? influencer.responsavel.fullname
                        : "Nome indisponível"}
                    </span>


                    <span style={{ marginLeft: "10px" }}>
                      {renderBadges(influencer)}
                    </span>

                    {influencer.archive && (
                      <span
                        style={{ marginLeft: "5px" }}
                        className="badge badge-soft-primary ml-5"
                      >
                        Arquivado
                      </span>
                    )}
                  </div>
                </div>
                <div className="text-center mb-3">
                  <img
                    src="https://i.ibb.co/y6nkQKW/461085513-1715142105981321-1128849984098454117-n.jpg"
                    alt="Logo da viraw"
                    className="avatar-sm rounded-circle"
                  />
                  <h6 className="font-size-15 mt-3 mb-1 text-truncate">
                    @{influencer?.username}
                  </h6>
                  <p className="mb-0 text-muted font-size-12">
                    Seguidores: {influencer?.followers ? Number(influencer.followers).toLocaleString() : 0}
                  </p>

                  <p></p>
                  <p className="mb-0 text-muted font-size-12">
                    <strong>Valor: </strong>
                    {influencer?.valueMin}
                  </p>
                  <p className="mb-0 text-muted font-size-12">
                    <strong>Cliques: </strong>
                    {Number(influencer?.clicks).toLocaleString()}
                  </p>
                  <p className="mb-0 text-muted font-size-12">
                    <strong>Cadastros: </strong>
                    {Number(influencer?.registrations).toLocaleString()}
                  </p>
                  <p className="mb-0 text-muted font-size-12">
                    <strong>FTD: </strong>
                    {Number(influencer?.firstDepositCount).toLocaleString()}
                  </p>
                  <p></p>
                  <p className="mb-0 text-muted font-size-12">
                    <strong>FATURAMENTO: </strong>
                    R$ {influencer?.faturamento.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                  </p>
                </div>

                <Row>
                  <Col md="12">
                  <Button
                        style={{ width: "100%" }}
                        className="btn btn-soft-primary d-block"
                        onClick={() => handleCompareClick(influencer)}
                      >
                        COMPARAR
                      </Button>
                    <CompararInfluencer
                      isOpen={isModalOpen}
                      toggle={toggleModal}
                      influencer={selectedInfluencer}
                      influencers={listData}
                      selectComparison={() => {}}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </React.Fragment>
  )
}

export default List
