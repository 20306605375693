import styled from 'styled-components';

export const CardContainer = styled.div`
  position: relative;
  width: 190px;
  height: 254px;
  background: #f8f9fa;
  display: flex;
  justify-content: center;
  align-items: start;
  overflow: hidden;
  transition: 0.5s;
  margin: 20px;
  z-index: ${props => props.isTop1 ? 3 : 1}; 
  box-shadow: ${props => props.isTop1 ? '0 15px 25px rgba(0, 0, 0, 0.3)' : '0 10px 15px rgba(0, 0, 0, 0.2)'}; 
  transform: ${props => props.isTop1 ? 'translateY(-60px)' : 'none'}; 
  transition: transform 0.3s ease;

  &:hover img {
    transform: translateY(-70px);
    scale: 0.5;
    opacity: 0.9;
  }

  &:hover .content {
    transform: scale(1);
    bottom: 25px;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(315deg, #03a9f4, #ff0058);
  }

  &::after {
    filter: blur(30px);
  }

  @media (max-width: 1200px) {
    width: 170px;
    height: 230px;
  }

  @media (max-width: 768px) {
    width: 150px;
    height: 200px;
  }

  @media (max-width: 360px) {
    width: 100px;
    height: 150px;
  }
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ tema }) => (tema === "dark" ? "#262B3C" : "#FFFFFF")};
  color: ${({ tema }) => (tema === "dark" ? "#FFFFFF" : "#000000")}; 
  padding: 20px; 
  border-radius: 15px;
  margin: 20px auto; 
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  max-width: 1200px; 
  width: 100%; 
  min-height: 95vh; 
  box-sizing: border-box;

  .card-list {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
  }

  /* Ajuste para telas menores */
  @media (max-width: 360px) {
    .card-list {
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }
  }
`;

export const RankLabel = styled.div`
  position: absolute;
  background: ${props => {
    switch (props.children) {
      case '#1':
        return 'linear-gradient(135deg, gold, #a16e92)'; 
      case '#2':
        return 'linear-gradient(135deg, silver, #9b59b6)'; 
      case '#3':
        return 'linear-gradient(135deg, #cd7f32, #8e44ad)'; 
      default:
        return 'transparent';
    }
  }};
  top: 20px;
  left: 15px;
  color: #fff;
  font-weight: bold;
  font-size: 1.2em;
  padding: 10px;
  border-radius: 2px 10px 0px 20px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8);
  z-index: 4;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${props => {
    switch (props.children) {
      case '#1':
        return 'gold';
      case '#2':
        return 'silver';
      case '#3':
        return '#cd7f32';
      default:
        return 'transparent';
    }
  }};
`;

export const Background = styled.b`
  position: absolute;
  inset: 6px;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
`;

export const Image = styled.img`
  width: 150px;
  position: absolute;
  z-index: 3;
  scale: 0.8;
  opacity: 0.25;
  border-radius: 100%;
  transition: 0.5s;
`;

export const Content = styled.div`
  position: absolute;
  z-index: 3;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: scale(0);
  transition: 0.5s;
`;

export const Title = styled.p`
  position: relative;
  color: #fff;
  font-weight: 500;
  line-height: 1em;
  font-size: 1em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: center;

  span {
    font-weight: 300;
    font-size: 0.7em;
  }
`;

export const SocialIcons = styled.ul`
  display: flex;
  justify-content: center; 
  align-items: center;
  margin-top: 5px;
  padding: 0;
  list-style: none; 

  li {
    list-style: none;

    a {
      text-decoration: none;
      color: rgba(255, 255, 255, 0.5);
      background: rgba(0, 0, 0, 0.2);
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center; 
      border-radius: 4px;
      transition: 0.5s;
      margin: 0 5px; 
    }

    a:hover {
      color: rgba(255, 255, 255, 1);
    }
  }
`;

export const TitleContainer = styled.h1`
  color: #343a40;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  text-transform: uppercase;
  margin-bottom: 80px;
  background: linear-gradient(90deg, #58EDFF, #e52e71);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  font-weight: bold;
  letter-spacing: 2px;
  position: relative;

  @media (max-width: 1200px) {
    font-size: 2.5rem;
  }

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 360px) {
    font-size: 1.5rem;
  }
`;

export const Subtitle = styled.p`
  font-size: 1.2rem;
  color: #6c757d; 
  text-align: center;
  margin: 10px 0; 
  font-weight: 400; 

  @media (max-width: 768px) {
    font-size: 1rem;
  }

  @media (max-width: 360px) {
    font-size: 0.8rem;
  }
`;
