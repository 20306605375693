import api from "config/api"
import { AuthContext } from "contexts/auth"
import React, { useState, useEffect, useContext } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
import Toastr from "../../../components/Ui/Notifications"
import Loading from "components/Ui/LoadingCircle"

const List = ({ listData }) => {
  const [influencers, setInfluencers] = useState([])
  const { user } = useContext(AuthContext)
  const [showModal, setShowModal] = useState(false)
  const [showModalReject, setShowModalReject] = useState(false)
  const [idInfluencer, setIdInfluencer] = useState(null)
  const [idResponsavel, setIdResponsavel] = useState(null)
  const [id, setId] = useState(null)
  const [linkNota, setLinkNota] = useState(null)
  const [requestMeta, setRequestMeta] = useState('');
  const [valor, setValor] = useState(null)
  const [requestArchiving, setRequestArchiving] = useState(false)
  const [cancelingRequest, setCancelingRequest] = useState(false)
  const [rejectingPayment, setRejectingPayment] = useState(false)
  const [reason, setReason] = useState("")
  const [meta, setMeta] = useState("")
  const [valueMin, setValueMin] = useState("")
  const [valueBonus, setValueBonus] = useState("")
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [aceitarNovaMeta, setAceitarNovaMeta] = useState("")
  const [loading, setLoading] = useState(true);
  const [pendingRequests, setPendingRequests] = useState({})

  const getMeta = async (id) => {
    try {
      const response = await api.get(`/payments/request/payment/${id}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      setRequestMeta(response.data);
      setMeta(response.data.result.meta_ref.meta)
      setValueMin(response.data.result.meta_ref.valueMin)
      setValueBonus(response.data.result.meta_ref.valueBonus)
      setLoading(false)
    } catch (error) {
      console.error('Error fetching influencers:', error);
    }
  };

  const activeBtn = (id, influencer, responsavel, link, value) => {
    setShowModal(true)
    setValor(value)
    setLinkNota(link)
    setIdResponsavel(responsavel)
    setIdInfluencer(influencer)
    setId(id)
    getMeta(id)
  }
  const activeBtnReject = (id, influencer, responsavel) => {
    setIdResponsavel(responsavel)
    setIdInfluencer(influencer)
    setId(id)
    setShowModalReject(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }
  const handleCloseModalReject = () => {
    setShowModalReject(false)
  }
  const handleArchiveConfirm = async () => {
    try {
      if (aceitarNovaMeta === true) {
        if (meta === "", valueMin === "", valueBonus === "") {
          Toastr("error", "Preencha todos os campos!");
        } else {
          
          await Promise.all([

            api.put(
              "/payments/request/update/success",
              {
                id: id,
                isPaymentSuccess: true,
              },
              {
                headers: {
                  Authorization: `Bearer ${user.token}`,
                },
              }
            ),


            // Atualização de metadados do influenciador
            api.post(
              "/profile/influencer/meta/",
              {
                influencer: idInfluencer,
                meta: meta,
                valueMin: valueMin,
                valueBonus: valueBonus,
                dateInit: startDate,
                dateFinal: endDate,
              },
              {
                headers: {
                  Authorization: `Bearer ${user.token}`,
                },
              }
            ),

            // Registro do pagamento
            api.post(
              "/payments",
              {
                value: `${valor},00`,
                date: new Date(),
                link: linkNota,
                influencer: idInfluencer,
                responsavel: idResponsavel,
              },
              {
                headers: {
                  Authorization: `Bearer ${user.token}`,
                },
              }
            )

          ]).then(() => {
            Toastr("success", "Todas as operações realizadas com sucesso!");
            window.location.reload()
          })

        }
      } else {
        await Promise.all([
          // Confirmação de pagamento
          api.put(
            "/payments/request/update/success",
            {
              id: id,
              isPaymentSuccess: true,
            },
            {
              headers: {
                Authorization: `Bearer ${user.token}`,
              },
            }
          ),
          // Registro do pagamento
          api.post(
            "/payments",
            {
              value: `${valor},00`,
              date: new Date(),
              link: linkNota,
              influencer: idInfluencer,
              responsavel: idResponsavel,
            },
            {
              headers: {
                Authorization: `Bearer ${user.token}`,
              },
            }
          ),
        ]).then(function (response) {
          Toastr("success", "Todas as operações realizadas com sucesso!");

        });

      }
      setPendingRequests({
        ...pendingRequests,
        [idInfluencer]: true,
      });


    } catch (error) {
      console.error("Erro ao confirmar pagamento:", error);
      Toastr("error", "Erro ao confirmar pagamento. Por favor, tente novamente.");
    }
    setShowModal(false);
  };


  const handleRejectConfirm = async () => {
    try {
      await api.put(
        "/payments/request/update/rejected",
        {
          id: id,
          isRejected: true,
          reasonForRejection: reason,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      window.location.reload()
      handleCloseModal()
      setTimeout(() => {
        Toastr("success", "Todas as operações realizadas com sucesso!");
      }, 3000);

      setPendingRequests({
        ...pendingRequests,
        [id]: false,
      })
    } catch (err) {
      console.error("Erro" + err)
    }
    setRejectingPayment(false)
  }

  const sectorBadgeClasses = sectorType => {
    switch (sectorType) {
      case "Grupos":
        return "badge badge-soft-success"
      case "Captação":
        return "badge badge-soft-info"
      case "TI":
        return "badge badge-soft-danger"
      default:
        return "badge badge-soft-warning"
    }
  }
  const redirectLink = link => {
    window.open(link, "_blank")
  }
  const formatarMoeda = valor => {
    const numeroLimpo = valor.replace(/[^0-9]/g, "")

    const valorNumerico = Number(numeroLimpo) / 100

    const numeroFormatado = valorNumerico.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
    })

    return numeroFormatado
  }

  return (
    <React.Fragment>
      <Row>
        {listData.map((influencer, key) => (
          <Col xl={3} key={key}>
            <Card>
              <CardBody>
                <div className="d-flex align-start mb-3">
                  <div className="flex-grow-1">
                    <span
                      className={
                        influencer.influencer_requestPayment.plug_chat
                          .responsavel_rent.fullname
                          ? sectorBadgeClasses(
                            influencer.influencer_requestPayment.plug_chat
                              .responsavel_rent.fullname
                          )
                          : "badge badge-soft-success"
                      }
                    >
                      {influencer.influencer_requestPayment.plug_chat
                        .responsavel_rent.fullname
                        ? influencer.influencer_requestPayment.plug_chat
                          .responsavel_rent.fullname
                        : "Nome indisponível"}
                    </span>
                  </div>
                  <button
                    type="button"
                    className="btn btn-light btn-sm like-btn"
                    onClick={() => {
                      activeBtn(influencer.id, influencer.influencer, influencer.influencer_requestPayment.plug_chat
                        .responsavel_rent.id, influencer.nota_fiscal, influencer.value)
                    }}
                  >
                    <i
                      className="mdi mdi-account-check-outline"
                      style={{ fontSize: "14px" }}
                    ></i>
                  </button>
                  <button
                    style={{ marginLeft: "10px" }}
                    type="button"
                    className="btn btn-light btn-sm like-btn"
                    onClick={() => {
                      activeBtnReject(influencer.id, influencer.influencer, influencer.influencer_requestPayment.plug_chat
                        .responsavel_rent.id)
                    }}
                  >
                    <i
                      className="mdi mdi-close"
                      style={{ fontSize: "14px" }}
                    ></i>
                  </button>
                </div>
                <div className="text-center mb-3">
                  <img
                    src={influencer.influencer_requestPayment?.photo}
                    alt=""
                    className="avatar-sm rounded-circle"
                  />
                  <h6 className="font-size-15 mt-3 mb-1 text-truncate">
                    {influencer.influencer_requestPayment.fullname}
                  </h6>
                  <p className="mb-0 text-muted font-size-12">
                    @{influencer.influencer_requestPayment.username}
                  </p>
                  <p className="mb-0 text-muted font-size-12">
                    {influencer.value.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                      minimumFractionDigits: 2,
                    })
                    }
                  </p>
                </div>
                <div className="mt-4 pt-1">
                  <Button
                    style={{ width: "100%" }}
                    onClick={() => redirectLink(influencer.nota_fiscal)}
                    className="btn btn-soft-primary d-block"
                  >
                    Nota Fiscal
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
      <Modal isOpen={showModal} toggle={handleCloseModal}>
        <ModalHeader toggle={handleCloseModal}>Confirmar Pagamento</ModalHeader>

        <ModalBody>
          <div className="mb-3">
            <label className="form-label">Selecione o que deseja fazer</label>
            <select
              className="form-select"
              onChange={e => setAceitarNovaMeta(e.target.value === "Nova Meta")}
            >
              <option value="Aceitar Solicitação">
                Aceitar somente Solicitação
              </option>
              <option value="Nova Meta">Aceitar e criar nova meta</option>
            </select>
          </div>
          {aceitarNovaMeta && (
            <>
              {
                loading ? <Loading /> : (
                  <>
                    <div className="mb-3">
                      <label className="form-label">Nova Meta</label>
                      <input
                        type="text"
                        required={true}
                        className="form-control"
                        placeholder="Digite uma meta"
                        value={meta}
                        onChange={e => setMeta(e.target.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Valor por FTD</label>
                      <input
                        type="text"
                        required={true}
                        className="form-control"
                        placeholder="Digite o valor por FTD"
                        value={(valueMin)}
                        onChange={e => setValueMin(formatarMoeda(e.target.value))}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Valor Excedente (Bonus)</label>
                      <input
                        type="text"
                        required={true}
                        className="form-control"
                        placeholder="Digite o valor excedente (Bonus)"
                        value={(valueBonus)}
                        onChange={e => setValueBonus(formatarMoeda(e.target.value))}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Data de Início</label>
                      <input
                        required={true}
                        type="datetime-local"
                        className="form-control"
                        value={startDate}
                        onChange={e => setStartDate(e.target.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Data de Término</label>
                      <input
                        required={true}
                        type="datetime-local"
                        className="form-control"
                        value={endDate}
                        onChange={e => setEndDate(e.target.value)}
                      />
                    </div>
                  </>
                )
              }
            </>
          )}
        </ModalBody>

        <ModalFooter>
          <Button color="primary" onClick={handleArchiveConfirm}>
            Confirmar
          </Button>
          <Button color="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={showModalReject} toggle={handleCloseModalReject}>
        <ModalHeader toggle={handleCloseModalReject}>
          Rejeitar pagamento?
        </ModalHeader>
        <ModalBody>Você deseja rejeitar o pagamento?</ModalBody>
        <ModalFooter>
          <h6>Informe o motivo</h6>
          <Input
            value={reason}
            onChange={e => setReason(e.target.value)}
            placeholder="Motivo da rejeição"
          />
          <Button color="primary" onClick={handleRejectConfirm}>
            Sim
          </Button>
          <Button color="secondary" onClick={handleCloseModalReject}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default List
