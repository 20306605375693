import React, { useState, useEffect, useCallback } from "react";
import { Modal, ModalHeader, ModalBody, Card, CardBody, Row, Col } from "reactstrap";

const CompararInfluencer = ({ isOpen, toggle, influencer, influencers, selectComparison }) => {
  const [selectedInfluencer1, setSelectedInfluencer1] = useState(influencer || null);
  const [selectedInfluencer2, setSelectedInfluencer2] = useState(null);

  useEffect(() => {
    setSelectedInfluencer1(influencer);
  }, [influencer]);

  const handleSelectInfluencer1 = useCallback(e => {
    const selected = influencers.find(item => item.username === e.target.value);
    setSelectedInfluencer1(selected);
  }, [influencers]);

  const handleSelectInfluencer2 = useCallback(e => {
    const selected = influencers.find(item => item.username === e.target.value);
    setSelectedInfluencer2(selected);
    selectComparison(selected);
  }, [influencers, selectComparison]);

  const handleToggle = () => {
    toggle();
    setTimeout(() => {
      setSelectedInfluencer1(null);
      setSelectedInfluencer2(null);
      selectComparison(null);
    }, 300);
  };
  
  function calcularFaturamentoPrevisionado(faturamentoAtual) {
    const today = new Date();
    const dayOfMonth = today.getDate();
    const totalDaysInMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();
  
    const faturamentoPrevisionado = (faturamentoAtual / dayOfMonth) * totalDaysInMonth;
  
    return faturamentoPrevisionado.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  
  return (
    <Modal isOpen={isOpen} toggle={handleToggle} size="lg">
      <ModalHeader toggle={handleToggle}>Comparando Influenciadores</ModalHeader>
      <ModalBody>
        <Row>
          <Col md="6">
            <Card>
              <CardBody>
                <div className="form-group">
                  <label htmlFor="influencerSelect1">Selecione um Influenciador</label>
                  <select
                    id="influencerSelect1"
                    className="form-control"
                    value={selectedInfluencer1?.username || ""}
                    onChange={(e) => {
                      const selected = influencers.find(
                        (item) => item.username === e.target.value
                      );
                      setSelectedInfluencer1(selected);
                    }}
                  >
                    <option value="" disabled>
                      Escolha um influencer
                    </option>
                    {influencers
                      .slice()
                      .sort((a, b) => a.username.localeCompare(b.username))
                      .map((other) => (
                        <option key={other.username} value={other.username}>
                          {other.username}
                        </option>
                      ))}
                  </select>
                </div>
                {selectedInfluencer1 && (
                  <div className="text-center">
                    <img
                      src={selectedInfluencer1?.Photo || "https://i.ibb.co/y6nkQKW/461085513-1715142105981321-1128849984098454117-n.jpg"}
                      alt="Logo da viraw"
                      className="avatar-sm rounded-circle mt-3"
                    />
                    <h6 className="font-size-15 mt-3 mb-1 text-truncate">
                      @{selectedInfluencer1.username}
                    </h6>

                    <p className="mb-0 text-muted font-size-12">
                      Seguidores: {Number(selectedInfluencer1.followers).toLocaleString() || 12020}
                    </p>

                    <p></p>
                    <p className="mb-0 text-muted font-size-12">
                      <strong>Valor: </strong>
                      {selectedInfluencer1.valueMin}
                    </p>

                    <p className="mb-0 text-muted font-size-12">
                      <strong>Cliques: </strong>
                      {Number(selectedInfluencer1.clicks).toLocaleString()}
                    </p>

                    <p className="mb-0 text-muted font-size-12">
                      <strong>Cadastros: </strong>
                      {selectedInfluencer1.registrations}
                    </p>

                    <p className="mb-0 text-muted font-size-12">
                      <strong>FTD: </strong>
                      {selectedInfluencer1.firstDepositCount}
                    </p>

                    <p></p>
                    <p className="mb-0 text-muted font-size-12">
                      <strong>FATURAMENTO</strong>
                    </p>

                    <p className="mb-0 text-muted font-size-12">
                    R$ {selectedInfluencer1.faturamento.toLocaleString("pt-BR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </p>

                    <p></p>
                    <p className="mb-0 text-muted font-size-12">
                      <strong>Taxa de seguidores Convertidos em FTD (%)</strong>
                    </p>
                    <p className="mb-0 text-muted font-size-12">
                      {selectedInfluencer1.followers > 0
                        ? ((selectedInfluencer1.firstDepositCount / selectedInfluencer1.followers) * 100).toFixed(2) + " %"
                        : "N/A"}
                    </p>
                    <p></p>
                    <p className="mb-0 text-muted font-size-12">
                      <strong>Faturamento Previsionado (Mensal)</strong>
                    </p>
                    <p className="mb-0 text-muted font-size-12">
                      R$ {calcularFaturamentoPrevisionado(selectedInfluencer1.faturamento)}
                    </p>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>

          <Col md="6">
            <Card>
              <CardBody>
                <div className="form-group">
                  <label htmlFor="influencerSelect2">Selecione um Influenciador</label>
                  <select
                    id="influencerSelect2"
                    className="form-control"
                    value={selectedInfluencer2?.username || ""}
                    onChange={(e) => {
                      const selected = influencers.find(
                        (item) => item.username === e.target.value
                      );
                      setSelectedInfluencer2(selected);
                      selectComparison(selected);
                    }}
                  >
                    <option value="" disabled>
                      Escolha um influencer
                    </option>
                    {influencers
                      .slice()
                      .sort((a, b) => a.username.localeCompare(b.username))
                      .map((other) => (
                        <option key={other.username} value={other.username}>
                          {other.username}
                        </option>
                      ))}
                  </select>
                </div>
                {selectedInfluencer2 && (
                  <div className="text-center">
                    <img
                      src={selectedInfluencer2?.Photo || "https://i.ibb.co/y6nkQKW/461085513-1715142105981321-1128849984098454117-n.jpg"}
                      alt="Logo da viraw"
                      className="avatar-sm rounded-circle mt-3"
                    />
                    <h6 className="font-size-15 mt-3 mb-1 text-truncate">
                      @{selectedInfluencer2.username}
                    </h6>
                    <p className="mb-0 text-muted font-size-12">
                      Seguidores: {Number(selectedInfluencer2.followers).toLocaleString() || 12020}
                    </p>
                    <p></p>
                    <p className="mb-0 text-muted font-size-12">
                      <strong>Valor: </strong>
                      {selectedInfluencer2.valueMin}
                    </p>
                    <p className="mb-0 text-muted font-size-12">
                      <strong>Cliques: </strong>
                      {Number(selectedInfluencer2.clicks).toLocaleString()}
                    </p>
                    <p className="mb-0 text-muted font-size-12">
                      <strong>Cadastros: </strong>
                      {selectedInfluencer2.registrations}
                    </p>
                    <p className="mb-0 text-muted font-size-12">
                      <strong>FTD: </strong>
                      {selectedInfluencer2.firstDepositCount}
                    </p>
                    <p></p>
                    <p className="mb-0 text-muted font-size-12">
                      <strong>FATURAMENTO</strong>
                    </p>
                    <p className="mb-0 text-muted font-size-12">
                    R$ {selectedInfluencer2.faturamento.toLocaleString("pt-BR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </p>
                    <p></p>
                    <p className="mb-0 text-muted font-size-12">
                      <strong>Taxa de seguidores Convertidos em FTD (%)</strong>
                    </p>
                    <p className="mb-0 text-muted font-size-12">
                      {selectedInfluencer2.followers > 0
                        ? ((selectedInfluencer2.firstDepositCount / selectedInfluencer2.followers) * 100).toFixed(2) + " %"
                        : "N/A"}
                    </p>
                    <p></p>
                    <p className="mb-0 text-muted font-size-12">
                      <strong>Faturamento Previsionado (Mensal)</strong>
                    </p>
                    <p className="mb-0 text-muted font-size-12">
                      R$ {calcularFaturamentoPrevisionado(selectedInfluencer2.faturamento)}
                    </p>
                    
                    
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default CompararInfluencer;
