import React, { useContext, useState, useEffect } from "react";
import {
  Modal,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  ModalFooter,
} from "reactstrap";
import { AuthContext } from "contexts/auth";
import Toastr from "../../Ui/Notifications";
import api from "config/api";

const EditMetaodal = ({
  id,
  meta,
  editMeta,
  valueMin,
  valueBonus,
  dateInit,
  dateFinal,
  controlModal,
  toggle,
}) => {
  const [newMeta, setNewMeta] = useState(meta || "");
  const [newValueMin, setNewValueMin] = useState(valueMin || "");
  const [newValueBonus, setNewValueBonus] = useState(valueBonus || "");
  const [newDateInit, setNewDateInit] = useState(dateInit || "");
  const [newDateFinal, setNewDateFinal] = useState(dateFinal || "");
  const [paymentDiario, setPaymentDiario] = useState();
  const [paymenteAntecipado, setPaymenteAntecipado] = useState();
  const [filteredArchive, setFilteredArchive] = useState(null);
  const [advanceValue, setAdvanceValue] = useState("R$ 0,00");
  const { user } = useContext(AuthContext);

  const handleFilterArchive = event => {
    const selectedValue = event.target.value;

    let newPaymentDiario = false;
    let newPaymenteAntecipado = false;

    if (selectedValue === "Diário") {
      newPaymentDiario = true;
      newPaymenteAntecipado = false;
    } else if (selectedValue === "Antecipado") {
      newPaymentDiario = false;
      newPaymenteAntecipado = true;
    } else if (selectedValue === "Variável" || selectedValue === "") {
      newPaymentDiario = false;
      newPaymenteAntecipado = false;
    }

    setPaymentDiario(newPaymentDiario);
    setPaymenteAntecipado(newPaymenteAntecipado);
    setFilteredArchive(selectedValue);
  };

  const handleSaveChanges = () => {
    api
      .put(
        `/profile/influencer/meta/update`,
        {
          id: editMeta.id,
          meta: parseInt(newMeta),
          valueMin: newValueMin,
          valueBonus: newValueBonus,
          dateInit: newDateInit,
          dateFinal: newDateFinal,
          isDailyPayment: paymentDiario || false,
          isAdvanceValue: paymenteAntecipado || false,
          advanceValue: formatCurrencyPut(advanceValue) || 'R$ 0,00',
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      .then(() => {
        Toastr("success", "Meta atualizada com sucesso!");
         setTimeout(() => {
          window.location.reload();
        }, 3000);
        toggle();
      })
      .catch(error => {
        console.error("Erro ao editar meta:", error);
        Toastr(
          "error",
          "Erro ao editar meta. Verifique o console para mais detalhes."
        );
      });
  };

  useEffect(() => {
    setNewMeta(meta || "");
    setNewValueMin(valueMin || "");
    setNewValueBonus(valueBonus || "");
    setNewDateInit(formatDateToInput(dateInit || ""));
    setNewDateFinal(formatDateToInput(dateFinal || ""));
  }, [meta, valueMin, valueBonus, dateInit, dateFinal]);

  function formatDateToInput(dateString) {
    let date = new Date(dateString);
    let day = String(date.getUTCDate()).padStart(2, '0');
    let month = String(date.getUTCMonth() + 1).padStart(2, '0');
    let year = date.getUTCFullYear();

    return `${year}-${month}-${day}`;
  }

  function converterFormato(data) {
    const partes = data.split("-");
    const ano = partes[0];
    const mes = partes[1];
    const dia = partes[2];

    return `${dia}-${mes}-${ano}`;
  }

  const formatCurrency = (value) => {
    const onlyNumbers = value.replace(/[^\d]/g, "");
    const formattedValue = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
    }).format(onlyNumbers / 100);

    return formattedValue;
  };

  const formatCurrencyPut = (value) => {
    const onlyNumbers = value.replace(/[^\d]/g, "");

    const numericValue = parseFloat(onlyNumbers) / 100;

    let formattedValue = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
    }).format(numericValue);

    formattedValue = formattedValue.replace(/\.(?=\d{3},)/g, "").trim();

    return formattedValue;
  };

  return (
    <Modal isOpen={controlModal} toggle={toggle}>
      <div className="modal-header">
        <h5 className="modal-title">Editar Dados da Meta</h5>
        <button type="button" className="btn-close" onClick={toggle}></button>
      </div>
      <div className="modal-body">
        <Form>
          <FormGroup>
            <Label for="newMeta">Meta</Label>
            <Input
              type="text"
              id="newMeta"
              value={newMeta}
              onChange={e => setNewMeta(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="newValueMin">Valor Mínimo</Label>
            <Input
              type="text"
              id="newValueMin"
              value={newValueMin}
              onChange={e => setNewValueMin(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="newValueBonus">Valor Bônus</Label>
            <Input
              type="text"
              id="newValueBonus"
              value={newValueBonus}
              onChange={e => setNewValueBonus(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="newDateInit">Data de Início da Meta</Label>
            <Input
              type="date"
              id="newDateInit"
              value={newDateInit}
              onChange={e => setNewDateInit(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="newDateFinal">Data Final da Meta</Label>
            <Input
              type="date"
              id="newDateFinal"
              value={newDateFinal}
              onChange={e => setNewDateFinal(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="paymentType">Tipo de pagamento</Label>
            <Input
              value={filteredArchive}
              onChange={handleFilterArchive}
              type="select"
              id="paymentType"
            >
              <option value="Variável">Variável</option>
              <option value="Diário">Diário</option>
              <option value="Antecipado">Antecipado</option>
            </Input>
          </FormGroup>

          {filteredArchive === "Antecipado" && (
            <FormGroup>
              <Label for="advanceValue">Valor Antecipado</Label>
              <Input
                type="text"
                id="advanceValue"
                value={formatCurrency(advanceValue)}
                onChange={e => setAdvanceValue(e.target.value)}
              />
            </FormGroup>
          )}
        </Form>
      </div>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Fechar
        </Button>
        <Button color="primary" onClick={handleSaveChanges}>
          Salvar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditMetaodal;
