import React, { useState } from "react";
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Table, Button } from "reactstrap";

const RankingComponent = ({ week, month, year, monthLast }) => {
  const [filtroRanking, setFiltroRanking] = useState('month');

  const filtrarDados = (filtro) => {
    setFiltroRanking(filtro);
  };

  const dadosOrdenados = () => {
    let data;
    switch (filtroRanking) {
      case 'semana':
        data = week;
        break;
      case 'mes':
        data = month;
        break;
      case 'ano':
        data = year;
        break;
      case 'passado':
        data = monthLast;
        break;

      default:
        data = month;
    }

    return Object.entries(data).sort((a, b) => b[1].FTDs - a[1].FTDs);
  };

  const formatCurrency = (value) => {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  };

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle className="h4">Ranking de FTDs GERAL Atendimento</CardTitle>
              <CardSubtitle className="card-title-desc">
                O Ranking é atualizado de acordo com o número de FTDS atribuídos aos influenciadores por cada colaborador do setor de <code>Atendimento</code>. Esse é um ranking geral atualizado.
              </CardSubtitle>
              <div className="mb-3">
                <Button color="primary" onClick={() => filtrarDados('semana')} className={filtroRanking === 'semana' ? 'active' : ''}>Semana</Button>{' '}
                <Button color="primary" onClick={() => filtrarDados('mes')} className={filtroRanking === 'mes' ? 'active' : ''}>Mês</Button>{' '}
                <Button color="primary" onClick={() => filtrarDados('ano')} className={filtroRanking === 'ano' ? 'active' : ''}>Ano</Button>{' '}
                <Button color="primary" onClick={() => filtrarDados('passado')} className={filtroRanking === 'passado' ? 'active' : ''}>Mês Anterior</Button>
              </div>
              <div className="table-responsive">
                <Table className="table table-sm m-0">
                  <thead>
                    <tr>
                      <th>Nº</th>
                      <th>Colaborador</th>
                      <th style={{ textAlign: "center" }}>Cadastros</th>
                      <th style={{ textAlign: "center" }}>FTDs</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dadosOrdenados().map(([name, data], index) => (
                      <tr key={name}>
                        <th scope="row" style={{ textAlign: "center" }}>
                          {index + 1}
                        </th>
                        <td>{name}</td>
                        <td style={{ textAlign: "center" }}>{data.Cadastros}</td>
                        <td style={{ textAlign: "center" }}>{data.FTDs}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default RankingComponent;
