import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import { AuthContext } from "contexts/auth";
import { IoChatbox } from "react-icons/io5";


const List = ({ listData }) => {

  const { user } = useContext(AuthContext);
  const [influencers, setInfluencers] = useState([]);

  function redirect(link) {
    window.open(link, "_blank")
  }


  return (
    <React.Fragment>
      <Row>
        {listData.map((influencer, key) => (
          <Col xl={3} key={key}>
            <Card>
              <CardBody>
                <div className="d-flex align-start mb-3">
                  <div className="flex-grow-1">
                    <span
                      className={
                        influencer.captacao_influencer &&
                        influencer.captacao_influencer.type_user
                          ? sectorBadgeClasses(
                              influencer.plug_chat.responsavel_rent.fullname
                            )
                          : "badge badge-soft-success"
                      }
                    >
                      {influencer
                        ? influencer.plug_chat?.responsavel_rent.fullname
                        : "Nome indisponível"}
                    </span>
                  </div>


                  <button
                    type="button"
                    className="btn btn-light btn-sm like-btn"
                    onClick={(e) =>   redirect(influencer.plug_chat?.linkPlug)}>
                    <i
                      className="mdi mdi-chat"
                      style={{ fontSize: "14px" }}
                    ></i>
                  </button>

                </div>
                <div className="text-center mb-3">
                  <img
                    src={influencer.photo}
                    alt=""
                    className="avatar-sm rounded-circle"
                  />
                  <h6 className="font-size-15 mt-3 mb-1 text-truncate">
                    {influencer.fullname}
                  </h6>
                  <p className="mb-0 text-muted font-size-12">
                    @{influencer.username}
                  </p>
                </div>
             
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </React.Fragment>
  );
};

export default List;
