import React, { useState, useEffect } from "react";
import { format, addDays, startOfWeek, endOfWeek, isSameDay } from "date-fns";
import { ptBR } from "date-fns/locale";
import * as Yup from "yup";
import {
  Main,
  Card,
  DateButton,
  TimeButton,
  DateCarousel,
  TimeGrid,
  ConfirmButton,
  InputContainer,
  InputLabel,
  Input
} from './styles';

const emailSchema = Yup.object().shape({
  email: Yup.string().email('Email inválido').required('O email é obrigatório'),
});

export default function Meeting() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState(null);
  const [weekDates, setWeekDates] = useState([]);
  const [tema, setTema] = useState(""); 
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const getTemaFromStorage = () => {
    return localStorage.getItem("tema") || "";
  };
  
  useEffect(() => {
    setTema(getTemaFromStorage());

    const intervalId = setInterval(() => {
      const currentTema = getTemaFromStorage();
      if (currentTema !== tema) {
        setTema(currentTema);
      }
    }, 100); 

    return () => clearInterval(intervalId);
  }, [tema]); 

  useEffect(() => {
    const generateWeekDates = () => {
      const start = startOfWeek(new Date(), { weekStartsOn: 1 });
      const end = endOfWeek(new Date(), { weekStartsOn: 1 });
      const dates = [];
      let day = start;

      while (day <= end) {
        dates.push(day);
        day = addDays(day, 1);
      }
      return dates;
    };

    setWeekDates(generateWeekDates());
  }, []);

  const times = Array.from({ length: 24 }, (_, i) => `${i}:00`);

  const handleSubmit = async () => {
    try {
      await emailSchema.validate({ email });
      const eventDetails = {
        date: format(selectedDate, 'dd MMMM yyyy', { locale: ptBR }),
        time: selectedTime,
        email: email,
      };
      alert(`Reunião agendada para ${eventDetails.date} às ${eventDetails.time} para ${eventDetails.email}`);
      setEmailError(''); 
    } catch (error) {
      setEmailError(error.message); 
    }
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    emailSchema
      .validate({ email: value })
      .then(() => {
        setEmailError('');
      })
      .catch((error) => {
        setEmailError(error.message);
      });
  };

  return (
    <React.Fragment >
      <div className="page-content">
        <Main tema={tema}>
          <Card tema={tema}>
            <h5>Para quando você gostaria de agendar?</h5>
            <DateCarousel>
              {weekDates.map((date, index) => (
                <DateButton
                  key={index}
                  tema={tema}
                  selected={isSameDay(selectedDate, date)}
                  onClick={() => setSelectedDate(date)}
                >
                  <span className="day-name" style={{ textTransform: 'uppercase' }}>
                    {format(date, 'eee', { locale: ptBR })}
                  </span>
                  <span className="day-number">{format(date, 'dd', { locale: ptBR })}</span>
                  <span className="month-name">{format(date, 'MMM', { locale: ptBR })}</span>
                </DateButton>
              ))}
            </DateCarousel>

            <h5>Escolha o horário:</h5>
            <TimeGrid tema={tema}>
              {times.map((time, index) => (
                <TimeButton
                  key={index}
                  tema={tema}
                  selected={selectedTime === time}
                  onClick={() => setSelectedTime(time)}
                >
                  {time}
                </TimeButton>
              ))}
            </TimeGrid>

            <InputContainer tema={tema}>
              <InputLabel htmlFor="email" tema={tema}>Convite para:</InputLabel>
              <Input
                tema={tema}
                type="email"
                id="email"
                placeholder="Email do convidado"
                value={email}
                onChange={handleEmailChange} 
              />
              {emailError && <span style={{ color: 'red' }}>{emailError}</span>}
            </InputContainer>

            <ConfirmButton
              tema={tema}
              disabled={!selectedTime || !!emailError}
              onClick={handleSubmit}
            >
              Confirmar Agendamento
            </ConfirmButton>
          </Card>
        </Main>
      </div>
    </React.Fragment>
  );
}
