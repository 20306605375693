import React, { useState, createContext, useEffect } from "react"

export const StateContext = createContext({})

const StateProvider = ({ children }) => {
  const [modal, setModal] = useState(false)
  const [modalEdit, setModalEdit] = useState(false)
  const [modalDel, setModalDel] = useState(false)
  const [editmodal, setEditModal] = useState(false)
  const [addValue, setValue] = useState(false)
  const [detailsValue, setDetailsValue] = useState(false)
  const [addValueSemanal, setAddValueSemanal ] = useState();

  return (
    <StateContext.Provider value={{ modal, setModal, modalEdit, setModalEdit, setModalDel, modalDel, editmodal, setEditModal, addValue, setValue,
      detailsValue, setDetailsValue, addValueSemanal, setAddValueSemanal 
    }}>
      {children}
    </StateContext.Provider>
  )
}

export default StateProvider
