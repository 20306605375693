import React, { useState } from "react";
import { Tooltip } from "reactstrap";

const BadgeWithTooltip = ({ id, children, tooltipText }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  const tooltipStyle = {
    textAlign: "justify" 
  };

  return (
    <>
      <span id={id} onMouseOver={toggleTooltip} onMouseOut={toggleTooltip}>
        {children}
      </span>
      <Tooltip placement="top" isOpen={tooltipOpen} target={id} toggle={toggleTooltip} style={tooltipStyle}>
        {tooltipText}
      </Tooltip>
    </>
  );
};

export default BadgeWithTooltip;
