import styled from "styled-components";

export const Buttons = styled.div`
display: flex;
justify-content: space-between;
align-items:center;
padding: 10px 20px;
margin: 10px 0;
`;
export const ButtonsTxt = styled.span`
    font-size: 16px;
    transition: font-size 0.3s ease;

    &:hover{
        font-size: 17px;

        cursor: pointer;
    }
`;



