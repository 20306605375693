import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Label,
  FormGroup,
} from "reactstrap";

const ModalJustification = ({ isOpen, toggle, onSubmit }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [customJustification, setCustomJustification] = useState("");

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleConfirm = () => {
    let finalJustification = selectedOption;

    if (selectedOption === "Outro") {
      finalJustification = customJustification;
    }

    if (!finalJustification) {
      return;
    }
    onSubmit(finalJustification);
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        Informe o motivo do remarketing.
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="reasonSelect">Selecione um motivo:</Label>
          <Input
            type="select"
            id="reasonSelect"
            onChange={handleOptionChange}
            defaultValue=""
          >
            <option value="" disabled hidden>
              Selecione um motivo...
            </option>
            <option value="Nova Abordagem (Copy).">Nova Abordagem (Copy).</option>
            <option value="Ofertar um novo valor.">Ofertar um novo valor.</option>
            <option value="Outro">Outro...</option>
          </Input>
        </FormGroup>

        {selectedOption === "Outro" && (
          <FormGroup>
            <Label for="customJustification">Motivo personalizado:</Label>
            <Input
              type="textarea"
              id="customJustification"
              placeholder="Escreva o motivo aqui..."
              value={customJustification}
              onChange={(e) => setCustomJustification(e.target.value)}
            />
          </FormGroup>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Cancelar
        </Button>
        <Button color="primary" onClick={handleConfirm}>
          Confirmar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalJustification;

