import React, { useContext, useEffect, useState } from "react"
import "./styles.css"
import "react-big-calendar/lib/css/react-big-calendar.css"
import { Link, useParams, useNavigate } from "react-router-dom"
import api from "config/api"
import { AuthContext } from "contexts/auth"
import { Calendar, momentLocalizer } from "react-big-calendar"
import moment from "moment"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "moment/locale/pt-br"
import Modal from "react-modal"
import { Button } from "reactstrap"
import Overview from "./Overview"
import Loading from "components/Ui/LoadingCircle";

import { Container, Sidebar, Body } from "./styles"

moment.locale("pt-br")
const localizer = momentLocalizer(moment)

export default function InfluencerProfile() {
  const { user } = useContext(AuthContext)
  const { id } = useParams()
  const navigate = useNavigate()
  const [influencer, setInfluencer] = useState([])
  const [loading, setLoading] = useState(true)
  const [startDate, setStartDate] = useState(new Date())
  const [events, setEvents] = useState([])
  const [selectedPosted, setSelectedPosted] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState(null)
  const [eventTitle, setEventTitle] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [plugchatLink, setPlugchatLink] = useState("")

  useEffect(() => {
    async function getInfluencer() {
      try {
        const response = await api.get(`/plugchat/plugchat/${id}`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        })

        const influencerData = response.data
        const plugchatLink = influencerData.link

        if (plugchatLink) {
          setPlugchatLink(plugchatLink)
        } else {
          console.error(
            "Nenhum link do PlugChat cadastrado para este influenciador."
          )
        }

        const formattedEvents = influencerData.influencer_rent.posts.map(
          event => ({
            id: event.id,
            title: event.title,
            start: new Date(event.start),
            end: new Date(event.start),
            color: event.color,
            influencer: influencerData.influencer_rent.id,
          })
        )

        setInfluencer(influencerData)
        setEvents(formattedEvents)
        setLoading(false)
      } catch (error) {
        console.error("Erro ao carregar o influenciador:", error)
        setErrorMessage("Erro ao carregar o influenciador.")
      }
    }

    getInfluencer()
  }, [user, id])

  const handleDateChange = date => {
    setStartDate(date)
  }

  async function postCreatePosts(data) {
    try {
      const response = await api.post(
        "/posts",
        {
          posted: data.posted,
          title: data.title,
          start: data.start,
          end: data.end,
          color: data.color,
          influencer: data.influencer,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      )

      setEvents([...events, response.data])
      setEventTitle("")
      setIsModalOpen(false)
      setErrorMessage("")
    } catch (error) {
      console.error("Erro ao criar o evento:", error)
      setErrorMessage("Erro ao criar o evento.")
    }
  }

  const handlePlugChatRedirect = () => {
    if (plugchatLink) {
      navigate(plugchatLink)
    } else {
      setErrorMessage(
        "Nenhum link do PlugChat cadastrado para este influenciador."
      )
    }
  }

  const handleAddEvent = () => {
    if (!eventTitle) {
      setErrorMessage("O título do evento é obrigatório.")
      return
    }

    const newEvent = {
      posted: selectedPosted,
      title: eventTitle,
      start: startDate,
      end: startDate,
      color: selectedPosted ? "green" : "red",
      influencer: influencer.influencer_rent.id,
    }

    postCreatePosts(newEvent)

    setEvents([...events, newEvent])
    setEventTitle("")
    setIsModalOpen(false)
  }

  const handleEventClick = (event, e) => {
    setSelectedEvent(event)
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setSelectedEvent(null)
    setEventTitle("")
    setErrorMessage("")
  }

  const handleEditEvent = () => {
    if (!eventTitle) {
      setErrorMessage("O título do evento é obrigatório.")
      return
    }

    setSelectedEvent({ ...selectedEvent, title: eventTitle })
    closeModal()
  }

  const handleRemoveEvent = () => {
    const updatedEvents = events.filter(event => event !== selectedEvent)
    setEvents(updatedEvents)
    closeModal()
  }

  return (
    <Container>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Sidebar>
            <Overview informations={influencer} />
          </Sidebar>

          <Body>
            <div className="event-form">
              <select
                value={selectedPosted}
                onChange={e => setSelectedPosted(e.target.value)}
              >
                <option value={true}>Postou</option>
                <option value={false}>Não Postou</option>
              </select>
              <DatePicker
                selected={startDate}
                onChange={handleDateChange}
                dateFormat="dd/MM/yyyy"
                className="date-picker"
              />
              <input
                type="text"
                placeholder="Título do evento"
                value={eventTitle}
                onChange={e => setEventTitle(e.target.value)}
                className="event-title-input"
              />
              <button onClick={handleAddEvent}>Registrar Evento</button>
              <p className="error-message">{errorMessage}</p>
            </div>
            <Calendar
              localizer={localizer}
              events={events}
              eventPropGetter={event => ({
                style: {
                  backgroundColor: event.color,
                  color: "white",
                },
              })}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 500 }}
              onSelectEvent={handleEventClick}
            />





          </Body>
          <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            contentLabel="Editar/Remover Evento"
          >
            {selectedEvent ? (
              <>
                <h2>Editar/Remover Evento</h2>
                <input
                  type="text"
                  placeholder="Novo título do evento"
                  value={eventTitle}
                  onChange={e => setEventTitle(e.target.value)}
                />
                <button onClick={handleEditEvent}>Editar</button>
                <button onClick={handleRemoveEvent}>Remover</button>
                <button onClick={closeModal}>Fechar</button>
                <p className="error-message">{errorMessage}</p>
              </>
            ) : (
              <p>Nenhum evento selecionado.</p>
            )}
          </Modal>
        </>
      )}
    </Container>
  )
}
