import React, { useState, useEffect, useContext } from "react";
import ReactApexChart from "react-apexcharts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { InputGroup } from "reactstrap";
import { ptBR } from 'date-fns/locale';
import api from "../../../config/api.js";
import { AuthContext } from "../../../contexts/auth";
import { parseISO, subDays, startOfMonth, endOfMonth } from 'date-fns';

const LineColumnArea = () => {
    const { user } = useContext(AuthContext);
    const [period, setPeriod] = useState("semanal");
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [startDate, setStartDate] = useState(subDays(new Date(), 6));
    const [endDate, setEndDate] = useState(new Date());
    const [trackingData, setTrackingData] = useState()

    useEffect(() => {
        if (period === "mensal") {
            const firstDayOfMonth = startOfMonth(new Date(selectedDate));
            const lastDayOfMonth = endOfMonth(new Date(selectedDate));
            setStartDate(firstDayOfMonth);
            setEndDate(lastDayOfMonth);
        } else if (period === "semanal") {
            const today = new Date();
            setStartDate(subDays(today, 6)); 
            setEndDate(today); 
        }
    }, [period, selectedDate]);

    useEffect(() => {
    const fetchTrackingsSuperbet = async () => {
        try {
            const response = await api.get(
                "/tracking/superbet/fixed/orlandinho",
                {
                    headers: { Authorization: `Bearer ${user.token}` },
                }
            );
            const data = response.data
            const Trackings = data.Trackings
            setTrackingData(Trackings)

        } catch (error) {
            console.error("Erro ao buscar dados:", error);
        }
    };

    fetchTrackingsSuperbet();
    }, [])

    const handleMonthChange = (date) => {
        setSelectedDate(date);
        setPeriod("mensal"); 
    };

    const handleWeekChange = (dates) => {
        const [start, end] = dates;
        
        setStartDate(start);
        setEndDate(end);
    };
    

    const getDataForPeriod = () => {
        const dailyData = trackingData || [];
    
        const normalizeDate = (date) => {
            const normalizedDate = new Date(date);
            normalizedDate.setHours(0, 0, 0, 0);
            return normalizedDate;
        };
    
        const normalizedStartDate = normalizeDate(startDate);
        const normalizedEndDate = normalizeDate(endDate);
    
        const aggregatedData = dailyData.reduce((acc, record) => {
            const currentDate = normalizeDate(parseISO(record.Period));
    
            if (currentDate >= normalizedStartDate && currentDate <= normalizedEndDate) {
                const dateKey = currentDate.toISOString().split("T")[0];
                if (!acc[dateKey]) {
                    acc[dateKey] = {
                        Clicks: 0,
                        Registrations: 0,
                        FirstDepositCount: 0,
                    };
                }
                acc[dateKey].Clicks += record.Clicks;
                acc[dateKey].Registrations += record.Registrations;
                acc[dateKey].FirstDepositCount += record.FirstDepositCount;
            }
            return acc;
        }, {});
    
        const labels = [];
        let currentDate = new Date(normalizedStartDate);
        const lastDate = new Date(normalizedEndDate);

        while (currentDate <= lastDate) {
            labels.push(currentDate.toISOString().split("T")[0]);
            currentDate.setUTCDate(currentDate.getUTCDate() + 1);
        }
    
        const visitas = labels.map(date => aggregatedData[date]?.Clicks || 0);
        const registros = labels.map(date => aggregatedData[date]?.Registrations || 0);
        const ftd = labels.map(date => aggregatedData[date]?.FirstDepositCount || 0);
    
        return {
            visitas,
            registros,
            ftd,
            labels,
        };
    };

    const { visitas, registros, ftd, labels } = getDataForPeriod();

    const series = [
        {
            name: "Clicks",
            type: "column",
            data: visitas,
        },
        {
            name: "Registros",
            type: "area",
            data: registros,
        },
        {
            name: "FTDs",
            type: "line",
            data: ftd,
        },
    ];

    const options = {
        chart: {
            locales: [{
                "name": "pt",
                "options": {
                    "months": [
                        "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
                        "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
                    ],
                    "shortMonths": [
                        "Jan", "Fev", "Mar", "Abr", "Mai", "Jun",
                        "Jul", "Ago", "Set", "Out", "Nov", "Dez"
                    ],
                    "days": [
                        "Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"
                    ],
                    "shortDays": ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
                    "toolbar": {
                        "exportToSVG": "Baixar SVG",
                        "exportToPNG": "Baixar PNG",
                        "menu": "Menu",
                        "selection": "Seleção",
                        "selectionZoom": "Zoom",
                        "zoomIn": "Zoom In",
                        "zoomOut": "Zoom Out",
                        "pan": "Panning",
                        "reset": "Resetar Zoom"
                    }
                }
            }],
            defaultLocale: "pt",
            stacked: false,
            toolbar: {
                show: false,
            },
        },
        stroke: {
            width: [0, 2, 4],
            curve: "smooth",
        },
        plotOptions: {
            bar: {
                columnWidth: "50%",
            },
        },
        fill: {
            opacity: [0.85, 0.25, 1],
            gradient: {
                inverseColors: false,
                shade: "light",
                type: "vertical",
                opacityFrom: 0.85,
                opacityTo: 0.55,
                stops: [0, 100, 100, 100],
            },
        },
        labels: labels,
        markers: {
            size: 0,
        },
        legend: {
            offsetY: 10,
        },
        xaxis: {
            type: "datetime",
            categories: labels,
        },
        yaxis: {
            title: {
                text: "",
            },
        },
        tooltip: {
            shared: true,
            intersect: false,
            x: {
                format: 'dd/MM/yyyy',
            },
            y: {
                formatter: function (value, { seriesIndex }) {
                    return `${value} ${seriesIndex === 0 ? "" : seriesIndex === 1 ? "" : ""}`;
                },
            },
        },
        grid: {
            borderColor: "#f1f1f1",
        },
    };

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'end', gap: '16px'}} className="mb-2">
                <InputGroup style={{ width: '28%' }}>
                    <DatePicker
                        selected={selectedDate}
                        onChange={handleMonthChange}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        disabled={period === "semanal"}
                        className="form-control"
                        locale={ptBR}
                    />
                    <button
                        style={{
                            borderRadius: '3px',
                            width: '100%',
                            border: 'none',
                            cursor: 'pointer',
                            height: '25px',
                            backgroundColor: period === "mensal" ? '#007bff' : '',
                            color: period === "mensal" ? 'white' : ''
                        }}
                        onClick={() => setPeriod("mensal")}
                    >
                        Mensal
                    </button>
                </InputGroup>

                <InputGroup style={{ width: '25%', textAlign: 'center' }}>
                    <DatePicker
                        selected={startDate}
                        onChange={handleWeekChange}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        dateFormat="dd/MM/yyyy"
                        disabled={period === "mensal"}
                        className="form-control"
                        locale={ptBR}
                    />
                    <button
                        style={{
                            width: '100%',
                            borderRadius: '3px',
                            border: 'none',
                            cursor: 'pointer',
                            height: '25px',
                            backgroundColor: period === "semanal" ? '#007bff' : '',
                            color: period === "semanal" ? 'white' : ''
                        }}
                        onClick={() => setPeriod("semanal")}
                    >
                        Semanal
                    </button>
                </InputGroup>
            </div>

            <ReactApexChart options={options} series={series} type="line" height={350} />
        </>
    );
};

export default LineColumnArea;
