import React, { useEffect, useState, useContext } from "react"
import { AuthContext } from "contexts/auth"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Input,
  Button,
} from "reactstrap"
import Spinners from "components/Common/Spinner"
import Breadcrumbs from "components/Common/Breadcrumb"
import List from "./List"
import {
  CounterBox,
  CounterTitle,
  CounterValue,
} from "./styles"
import { StateContext } from "contexts/states"
import CadastrarInfluencerManual from "components/Modals/CadastrarInfluencerManual"
import api from "config/api"

const CollaboratorsList = () => {
  const { user } = useContext(AuthContext)
  const [collaborators, setCollaborators] = useState([])
  const [originalCollaborators, setOriginalCollaborators] = useState([])
  const [isLoading, setLoading] = useState(true)
  const [searchValue, setSearchValue] = useState("")
  const { modal, setModal } = useContext(StateContext)

  useEffect(() => {
    loadCollaboratorsFromAPI()
  }, [])

  const loadCollaboratorsFromAPI = async () => {
    setLoading(true);
    try {
        const response = await api.get('/assessor', {
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        });
        const assessoresData = response.data.results;
        const filteredCollaborators = assessoresData.filter(collaborator => collaborator.isBetano === true);
        setCollaborators(filteredCollaborators);
        setOriginalCollaborators(filteredCollaborators);
    } catch (error) {
        console.error('Erro ao carregar assessores da API: ', error);
    }
    setLoading(false);
};

  const handleSearchCollaborator = value => {
    setSearchValue(value)
    const lowerCaseValue = value.toLowerCase()

    const filteredCollaborators = originalCollaborators.filter(collaborator => {
      const fullname = collaborator.name?.toLowerCase() || ""
      const username = collaborator.email?.toLowerCase() || ""
      return fullname.includes(lowerCaseValue) || username.includes(lowerCaseValue)
    })
    setCollaborators(filteredCollaborators)

    if (value.trim() === "") {
      setCollaborators(originalCollaborators)
    }
  }

  function handleOpenCollaboratorModal() {
    setModal(true)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Assessores" breadcrumbItem="Assessores" />
          <Row>
            <Col lg={12}>
              <Card className="collaborator-filter">
                <Row className="d-flex justify-content-center">
                  <Col sm={6} md={3}>
                    <CounterBox className="bg-muted">
                      <CounterValue className="text-muted">
                        {collaborators.length}
                      </CounterValue>
                    </CounterBox>
                  </Col>

                  <Col sm={6} md={9}>
                  </Col>
                </Row>
                <CardBody>
                  
                  <form action="#">
                    <Row className="g-3">
                      <Col xxl={4} lg={4}>
                        <div className="position-relative">
                          <Input
                            type="text"
                            id="searchCollaborator"
                            autoComplete="off"
                            placeholder="Buscar Assessor"
                            onChange={e => handleSearchCollaborator(e.target.value)}
                            value={searchValue}
                          />
                        </div>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <List listData={collaborators} />
          )}
          <CadastrarInfluencerManual />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CollaboratorsList
