
const boardStyles = {
  container: {
    width: '100%',
    height: 'calc(100vh - 80px)', 
    marginTop: '80px',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    overflow: 'hidden', 
  },
  bntRedirect: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: '5px',
    border: 'none',
    background: 'none',
    fontWeight: 'bold',
    marginLeft: '-5px',
  },
  board: {
    display: 'flex', 
    gap: '1rem',
    overflowX: 'auto', 
    overflowY: 'hidden',
    padding: '1rem', 
    height: 'calc(100vh - 180px)',
    overflowX: 'auto', 
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    borderRadius: '6px',


    '@media screen and (max-width: 1200px)': {
      paddingLeft: '2rem',
      paddingRight: '2rem',
    },
    '@media screen and (max-width: 992px)': {
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
      gap: '0.5rem', 
    },
    '@media screen and (max-width: 768px)': {
      flexDirection: 'column',
      overflowX: 'auto', 
      height: 'auto',
    },
  },
  column: {
    flex: '0 0 25%', 
    minWidth: '230px', 
    borderRadius: '6px',
    flexDirection: 'column',
    overflowY: 'auto',
    maxHeight: '75vh',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    scrollSnapAlign: 'start', 

    '@media screen and (max-width: 1200px)': {
      flex: '0 0 33%', 
    },
    '@media screen and (max-width: 992px)': {
      flex: '0 0 50%', 
    },
    '@media screen and (max-width: 768px)': {
      flex: '1 0 100%', 
    },
  },
  columnTitle: {
    padding: '10px',
    fontWeight: 'bold',
    textAlign: 'center',
    paddingLeft: '1rem',
    fontSize: '14.976px',
    fontFamily: 'Nunito, sans-serif',
    textTransform: 'uppercase',
    position: 'sticky',
    top: '0',
    zIndex: '999',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  },
  card: {
    fontFamily: 'Nunito, sans-serif',
    fontSize: '0.9rem',
    fontWeight: '400',
    lineHeight: '1.5',
    padding: '1rem',
    borderRadius: '6px',
    boxShadow: '0px 0px 20px 0px rgba(154, 161, 171, .30)',
    marginBottom: '0.5rem',
    transition: 'box-shadow 0.1s ease-in-out',
    scrollbarWidth: 'thin',
    cursor: 'pointer',
    margin: '10px 15px',
  },
  cardHeader: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '0.5rem',
  },
  cardPriority: {
    display: 'inline-block',
    fontSize: '0.6rem',
    fontWeight: '700',
    lineHeight: '1',
    color: '#FFFFFF',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'baseline',
    borderRadius: '0.25rem',
    padding: '0.2em 0.6em 0.3em',
    textTransform: 'uppercase',
  },
  cardDate: {
    fontSize: '0.75rem',
  },
  cardTitle: {
    fontWeight: '600',
    fontSize: '1rem',
    marginBottom: '0.5rem',
  },
  cardFooter: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: '0.85rem',
  },
  cardAvatar: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    objectFit: 'cover',
    marginRight: '10px',
  },
  cardUsername: {
    fontWeight: 'bold',
  },
  cardMenuButton: {
    cursor: 'pointer',
    border: 'none',
    background: 'transparent',
    padding: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dropdownMenuItemHover: {
    color: "#333",
    cursor: "pointer",
  },
  dropdownMenu: {
    position: 'absolute',
    top: '100%',
    right: '0',
    borderRadius: '6px',
    padding: '0.5rem',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    zIndex: 1000,
  },
  dropdownMenuItem: {
    padding: '10px 15px',
    cursor: 'pointer',
    borderBottom: '1px solid #f0f0f0',
  },
  addNewButton: {
    position: 'fixed',
    bottom: '50px',
    right: '20px',
    backgroundColor: '#6495ED',
    color: 'white',
    border: 'none',
    padding: '10px 15px',
    borderRadius: '50%',
    cursor: 'pointer',
    zIndex: '999',
  },
  Btn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '45px',
    height: '45px',
    border: 'none',
    borderRadius: '50%',
    cursor: 'pointer',
    position: 'relative',
    overflow: 'hidden',
    transitionDuration: '.3s',
    boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.199)',
    backgroundColor: '#6495ED',
  },
  sign: {
    width: '100%',
    fontSize: '2em',
    color: 'white',
    transitionDuration: '.3s',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    position: 'absolute',
    right: '0%',
    width: '0%',
    opacity: '0',
    color: 'white',
    fontSize: '1.2em',
    fontWeight: '500',
    transitionDuration: '.3s',
  },
  hoverBtn: {
    width: '125px',
    borderRadius: '10px',
    transitionDuration: '.3s',
  },
  hoverSign: {
    width: '30%',
    transitionDuration: '.3s',
    paddingLeft: '20px',
  },
  hoverText: {
    opacity: '1',
    width: '70%',
    transitionDuration: '.3s',
    paddingRight: '20px',
  },
};

export default boardStyles;
