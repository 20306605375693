import React, { useContext, useState } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  CardBody,
  CardSubtitle,
  Container,
  Progress,
} from "reactstrap";
import Dropzone from "react-dropzone";

// Breadcrumb
import Breadcrumbs from "../../../common/Breadcrumb";
import Toastr from "../../../components/Ui/Notifications";

import { Link } from "react-router-dom";
import { AuthContext } from "contexts/auth";
import api from "config/api";

const FormUpload = () => {

  const [selectedFiles, setselectedFiles] = useState([]);
  const { user } = useContext(AuthContext);
  const [influencersAll, setInfluencersAll] = useState();
  const [loading, setLoading] = useState(false);
  const [upLoading, setUpLoading] = useState(false);
  const [progress, setProgress] = useState(0); 

  function handleAcceptedFiles(files) {
    const csvFiles = files.filter((file) => file.type === "text/csv");
    if (files.length > csvFiles.length) {
      Toastr("warning", "Só é permitido arquivos csv");
    }
    csvFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(csvFiles);
  }

  const postUpload = async (fileData) => {
    setLoading(true);
    setUpLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", fileData[0]);
  
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percentCompleted = Math.round((loaded * 100) / total);
          setProgress(percentCompleted);
        },
      };
  
      await api.post("/tracking/superbet", formData, config)
      .then((response) => {
        setInfluencersAll(response.data);
        setUpLoading(false);
        Toastr("success", "Dados atualizados com sucesso!")

      })   
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setLoading(false);
    }
  };
  

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const simulateProgress = () => {
    const totalSteps = 100;
    const delay = 30000;
    setProgress(0);

    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress === totalSteps) {
          clearInterval(interval);
          return prevProgress;
        } else {
          return prevProgress + 1;
        }
      });
    }, delay / totalSteps);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <h6 className="card-title">SuperBet </h6>
                  <CardSubtitle className="mb-3">
                    {" "}
                    Selecione os arquivo ou arraste o arquivo desejado.
                  </CardSubtitle>
                  <Form>
                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        handleAcceptedFiles(acceptedFiles);
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick mt-2"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="mb-3">
                              <i className="display-4 text-muted bx bxs-cloud-upload" />
                            </div>
                            <h4>Fazer Upload de arquivos</h4>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div
                      className="dropzone-previews mt-3"
                      id="file-previews"
                    >
                      {selectedFiles.map((f, i) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name}
                                    src={f.preview}
                                  />
                                </Col>
                                <Col>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        );
                      })}
                    </div>
                    {progress > 0 && progress < 100 && (
                      <div className="mt-3">
                        <Progress value={progress} />
                      </div>
                    )}
                  </Form>

                  <div className="text-center mt-4">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        postUpload(selectedFiles);
                        simulateProgress(); 
                      }}
                      disabled={upLoading?true:false}>
                    
                      {upLoading ? "Carregando..." : "Enviar"}
                    </button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default FormUpload;