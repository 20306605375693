import React from 'react';
import * as S from "./styles";

function formatarSeguidores(followers) {
    let seguidores = parseInt(followers);
    if (typeof seguidores !== 'number') {
        return "Erro: O valor de followers deve ser um número.";
    }

    if (seguidores < 1000) {
        return seguidores.toString();
    }

    const casasDecimais = 0;

    return seguidores.toLocaleString(undefined, {maximumFractionDigits: casasDecimais});
}

const formatar = Intl.NumberFormat('en', {
    notation: 'compact'
})

const FollowButton = ({ followers, username }) => {
    const redirectToInstagram = () => {
        window.open(`https://www.instagram.com/${username}`, '_blank');
    };

    return (
        <S.StyledButton onClick={redirectToInstagram} className='bg-light'>
            <S.Icon className="icon">
                <svg height="33" viewBox="0 0 128 128" width="33" xmlns="http://www.w3.org/2000/svg">
                    <linearGradient id="a" gradientTransform="matrix(1 0 0 -1 594 633)" gradientUnits="userSpaceOnUse" x1="-566.711" x2="-493.288" y1="516.569" y2="621.43">
                        <stop offset="0" stopColor="#ffb900"></stop>
                        <stop offset="1" stopColor="#9100eb"></stop>
                    </linearGradient>
                    <circle cx="64" cy="64" fill="url(#a)" r="64"></circle>
                    <g fill="#fff">
                        <path d="m82.333 104h-36.666c-11.947 0-21.667-9.719-21.667-21.667v-36.666c0-11.948 9.72-21.667 21.667-21.667h36.666c11.948 0 21.667 9.719 21.667 21.667v36.667c0 11.947-9.719 21.666-21.667 21.666zm-36.666-73.333c-8.271 0-15 6.729-15 15v36.667c0 8.271 6.729 15 15 15h36.666c8.271 0 15-6.729 15-15v-36.667c0-8.271-6.729-15-15-15z"></path>
                        <path d="m64 84c-11.028 0-20-8.973-20-20 0-11.029 8.972-20 20-20s20 8.971 20 20c0 11.027-8.972 20-20 20zm0-33.333c-7.352 0-13.333 5.981-13.333 13.333 0 7.353 5.981 13.333 13.333 13.333s13.333-5.98 13.333-13.333c0-7.352-5.98-13.333-13.333-13.333z"></path>
                        <circle cx="85.25" cy="42.75" r="4.583"></circle>
                    </g>
                </svg>
                <S.Text1>Instagram</S.Text1>
                <S.Text2>{formatar.format(followers)}</S.Text2>
            </S.Icon>
        </S.StyledButton>
    );
};

export default FollowButton;
