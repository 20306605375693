import React, { useState } from "react";
import { Table, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import Tabela from "./financeiro.json"

const FinancialTable = () => {
  const data = Tabela.Financeiro

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const tableStyle = {
    minHeight: "200px",
  };

  return (
    <div className="">
      <Table bordered style={tableStyle}>
        <thead>
          <tr>
            <th>Nº</th>
            <th>Data</th>
            <th>Classificação</th>
            <th>INFO</th>
            <th>Tipo</th>
            <th>Valor</th>
            <th>Detalhes</th>
            <th>Setor</th>
            <th>Responsável</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item, index) => (
            <tr key={item.id} style={{ backgroundColor: index % 2 === 0 ? "#f8f9fa" : "#e9ecef" }}>
              <td>{item.id}</td>
              <td>{item.data}</td>
              <td>{item.classification}</td>
              <td>{item.info}</td>
              <td>{item.type}</td>
              <td>R$ {item.value.toFixed(2).replace('.', ',')}</td>
              <td>{item.details}</td>
              <td>{item.sector}</td>
              <td>{item.responsible}</td>
            </tr>
          ))}
          {currentItems.length < itemsPerPage && (
            Array.from({ length: itemsPerPage - currentItems.length }, (_, index) => (
              <tr key={`empty-${index}`} style={{ backgroundColor: index % 2 === 0 ? "#f8f9fa" : "#e9ecef" }}>
                <td colSpan="5">&nbsp;</td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
      <Pagination>
        {Array.from({ length: totalPages }, (_, index) => (
          <PaginationItem key={index + 1} active={index + 1 === currentPage}>
            <PaginationLink onClick={() => handlePageChange(index + 1)}>
              {index + 1}
            </PaginationLink>
          </PaginationItem>
        ))}
      </Pagination>
    </div>
  );
};

export default FinancialTable;
