export const formatCnpj = value => {
  const onlyNums = value.replace(/[^\d]/g, "")
  const formattedCnpj = onlyNums.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/,
    "$1.$2.$3/$4-$5"
  )
  return formattedCnpj
}

export const formatCpf = value => {
  const onlyNums = value.replace(/[^\d]/g, "")
  const formattedCpf = onlyNums.replace(
    /^(\d{3})(\d{3})(\d{3})(\d{2}).*/,
    "$1.$2.$3-$4"
  )

  return formattedCpf
}

export const formatPhoneNumber = value => {
  // Remove non-numeric characters
  const onlyNums = value.replace(/[^\d]/g, "");

  // Limit to 10 digits and format
  const formattedPhoneNumber = onlyNums.replace(
    /^(\d{2})(\d{5})(\d{4}).*/,
    (_, p1, p2, p3) => `(${p1}) ${p2}-${p3}`
  );

  return formattedPhoneNumber;
};
export const formatAleatoria = (value) => {
  // Remover todos os caracteres não hexadecimais
  const onlyHexChars = value.replace(/[^0-9a-zA-Z]/g, "");

  // Limitar a 32 caracteres (8 grupos de 4)
  const limitedChars = onlyHexChars.substring(0, 32);

  // Adicionar hífens nos locais corretos para corresponder ao formato
  const formattedValue = limitedChars.replace(
    /^([0-9a-zA-Z]{8})([0-9a-zA-Z]{4})([0-9a-zA-Z]{4})([0-9a-zA-Z]{4})([0-9a-zA-Z]{12})$/,
    "$1-$2-$3-$4-$5"
  );

  return formattedValue;
};

export function formatarParaReal(numero) {
  if (typeof numero === 'number') {
    // Converte o número para o formato de moeda
    const numeroFormatado = numero.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    });
    return numeroFormatado;
  } else {
    // Caso não seja um número válido, retorna null ou uma mensagem de erro
    return null; // Ou lança uma exceção, dependendo do contexto
  }
}





export const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };

  