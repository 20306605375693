import api from "config/api";
import { AuthContext } from "contexts/auth";
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Row,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import Toastr from "../../../components/Ui/Notifications";
import Loading from "components/Ui/LoadingCircle";

const List = ({ listData }) => {
  const [influencers, setInfluencers] = useState([]);
  const { user } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  const [idInfluencer, setIdInfluencer] = useState(null);
  const [requestArchiving, setRequestArchiving] = useState(false);
  const [cancelingRequest, setCancelingRequest] = useState(false);
  const [newCampaign, setNewCampaign] = useState({});
  const [loading, setLoading] = useState(true);

  const [pendingRequests, setPendingRequests] = useState({});

  const activeBtn = (id, archiving,campaign) => {
    setIdInfluencer(id);
    setCancelingRequest(false);
    setShowModal(true);
    RequestCampaign(campaign)
  };

  const RequestCampaign = async (campaign) => {
    const response = await api.get(`/campaigns/${campaign}`,{
      headers:{
        Authorization: `Bearer ${user.token}`
      }
    })
    setLoading(false)
    setNewCampaign(response.data);
  }

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleArchiveConfirm = async () => {
    if (cancelingRequest) {
      try {
        await api.put("/influencers/update/archiving/", {
          id: idInfluencer,
          request_archiving: false,
          archiving: true, 
        },{
          headers:{
            Authorization: `Bearer ${user.token}`
          }
        });
        Toastr("success", "Solicitação de desarquivamento cancelada!");
        setPendingRequests({
          ...pendingRequests,
          [idInfluencer]: false,
        });
      } catch (err) {
        console.error("Erro" + err);
      }
    } else {
      try {
        await api.put("/influencers/update/reactive/", {
          id: idInfluencer,
          request_archiving: false,
          archiving: false, 
          new_campaign: newCampaign?.id
        },{
          headers:{
            Authorization: `Bearer ${user.token}`
          }
        });
        Toastr("success", "Influenciador reativado!");

        setPendingRequests({
          ...pendingRequests,
          [idInfluencer]: true,
        });
      } catch (err) {
        console.error("Erro" + err);
      }
    }

    setShowModal(false);
  };

  const sectorBadgeClasses = (sectorType) => {
    switch (sectorType) {
      case "Grupos":
        return "badge badge-soft-success";
      case "Captação":
        return "badge badge-soft-info";
      case "TI":
        return "badge badge-soft-danger";
      default:
        return "badge badge-soft-warning";
    }
  };


  return (
    <React.Fragment>
      <Row>
        {listData.map((influencer, key) =>
          influencer.influencer_rent.request_archiving === true && influencer.influencer_rent.archiving === true ? (
            <Col xl={3} key={key}>
              <Card>
                <CardBody>
                  <div className="d-flex align-start mb-3">
                    <div className="flex-grow-1">
                      <span
                        className={
                          influencer.responsavel_rent &&
                          influencer.responsavel_rent.type_user
                            ? sectorBadgeClasses(
                                influencer.responsavel_rent.type_user.type
                              )
                            : "badge badge-soft-success"
                        }
                      >
                        {influencer.responsavel_rent
                          ? influencer.responsavel_rent.fullname
                          : "Nome indisponível"}
                      </span>
                    </div>
                    <button
                      type="button"
                      className="btn btn-light btn-sm like-btn"
                      onClick={(e) =>
                        activeBtn(
                          influencer.influencer_rent.id,
                          influencer.influencer_rent.archiving,
                          influencer.influencer_rent.new_campaign
                        )
                      }
                    >
                      <i
                        className="mdi mdi-account-check-outline"
                        style={{ fontSize: "14px" }}
                      ></i>
                    </button>
                    <button
                      style={{ marginLeft: "10px" }}
                      type="button"
                      className="btn btn-light btn-sm like-btn"
                      onClick={(e) => {
                        setIdInfluencer(influencer.influencer_rent.id);
                        setCancelingRequest(true); // Configurar para cancelar
                        setShowModal(true);
                      }}
                    >
                      <i
                        className="mdi mdi-close"
                        style={{ fontSize: "14px" }}
                      ></i>
                    </button>
                  </div>
                  <div className="text-center mb-3">
                    <img
                      src={influencer.influencer_rent.photo}
                      alt=""
                      className="avatar-sm rounded-circle"
                    />
                    <h6 className="font-size-15 mt-3 mb-1 text-truncate">
                      {influencer.influencer_rent.fullname}
                    </h6>
                    <p className="mb-0 text-muted font-size-12">
                      @{influencer.influencer_rent.username}
                    </p>
                  </div>
                  <div className="mt-4 pt-1">
                    <Link
                      to={`/influencer/${influencer.id}`}
                      className="btn btn-soft-primary d-block"
                    >
                      Ver perfil
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </Col>
          ) : null
        )}
      </Row>
      {/* Modal do influenciador */}
      <Modal isOpen={showModal} toggle={handleCloseModal}>
        <ModalHeader toggle={handleCloseModal}>
          {cancelingRequest ? "Cancelar Solicitação" : "Desativar Influenciador"}
        </ModalHeader>
        <ModalBody>
          {loading ? <Loading /> : (
            cancelingRequest
              ? "Você deseja cancelar a solicitação de desarquivamento deste influenciador?"
              : `Você deseja reativar este influenciador para a ${newCampaign.name}?`
          )}
          
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button color="primary" onClick={handleArchiveConfirm}>
            Sim
          </Button>
        </ModalFooter>
      </Modal>
      {/* Final do modal */}
    </React.Fragment>
  );
};

export default List;
