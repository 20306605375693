import React, { useState, useEffect, useContext } from "react"
import {
  Table,
  UncontrolledTooltip,
  Button,
  Col,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import "./styles.css"
import { Link } from "react-router-dom"
import { AuthContext } from "contexts/auth"
import api from "config/api"
import ModalObservation from "../../components/Modals/VerObservacao/index";
import ModalRemarketing from "../../components/Modals/VerRemarketing/index";

const Tables = props => {
  const { id, influencerData, handleUserClick, update, deleteInfluencer } =
    props
  const { user } = useContext(AuthContext)
  const [modalRemarketingOpen, setModalRemarketingOpen] = useState(false);

  const [searchUsername, setSearchUsername] = useState("")
  const [statusFilter, setStatusFilter] = useState("Todos")
  const [campaignFilter, setCampaignFilter] = useState("Todos")
  const [observationFilter, setObservationFilter] = useState("Todos")
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [dropdownOpenCampaign, setDropdownOpenCampaign] = useState(false)
  const [dropdownOpenObservation, setDropdownOpenObservation] = useState(false)
  const [listCampaign, setListCampaign] = useState([])
  const [modalRemarketingReasonOpen, setModalRemarketingReasonOpen] = useState(false);

  const [modalObservationOpen, setModalObservationOpen] = useState(false);
  const [selectedInfluencer, setSelectedInfluencer] = useState(null);

  const predefinedObservations = ["Valores.", "Não divulga casas de aposta.", "Não respondeu."];

  useEffect(() => {
    async function getCampaign() {
      await api
        .get("/campaigns", {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        })
        .then(response => {
          setListCampaign(response.data)
        })
    }

    getCampaign()
  }, [user.token])


  const openObservationModal = (influencer) => {
    if (!influencer.observation || influencer.observation.trim() === "") {
      influencer.observation = "Motivo não informado";
    }
    setSelectedInfluencer(influencer);
    setModalObservationOpen(true);
  };

  const openRemarketingModal = (influencer) => {
    setSelectedInfluencer(influencer);
    setModalRemarketingOpen(true);
  };

// const fetchInfluencerDetails = async (influencerId) => {
//   try {
//     const response = await api.get(`/influencers/${influencerId}`, {
//       headers: {
//         Authorization: `Bearer ${user.token}`,
//       },
//     });
    
//     const { observation, fullname } = response.data;
//     setSelectedInfluencer({ observation, fullname });
//     setModalOpen(true);
//   } catch (error) {
//     console.error("Erro ao buscar os detalhes do influenciador", error);
//   }
// };

  const toggle = () => setDropdownOpen(prevState => !prevState)
  const toggleCampaign = () => setDropdownOpenCampaign(prevState => !prevState)
  const toggleObservation = () => setDropdownOpenObservation(prevState => !prevState)

  const filteredInfluencers = influencerData.filter(influencer => {
    const usernameMatches = influencer.username.toLowerCase().includes(searchUsername.toLowerCase());
    const statusMatches = statusFilter === "Todos" || influencer.status_influencer.status === statusFilter;
    const campaignMatches = campaignFilter === "Todos" || influencer.campaigns_influencer?.name === campaignFilter;
  
    let observationMatches = true;
  
    if (observationFilter === "Outros...") {
      if (influencer.observation) {
        const observationLower = influencer.observation.toLowerCase();
        if (observationLower.includes("valores.") || 
            observationLower.includes("não divulga casa de apostas.") ||
            observationLower.includes("não respondeu.")) {
          observationMatches = false;
        }
      }
    } else if (observationFilter && observationFilter !== "Todos") {
      observationMatches = influencer.observation &&
        influencer.observation.toLowerCase().includes(observationFilter.toLowerCase());
    }
  
    return usernameMatches && statusMatches && campaignMatches && observationMatches;
  });
  
  

  const handleSearchChange = e => {
    setSearchUsername(e.target.value)
  }

  const handleStatusFilter = status => {
    setStatusFilter(status)
  }

  const handleCampaignFilter = campaign => {
    setCampaignFilter(campaign)
  }

  const handleObservationFilter = observation => {
    setObservationFilter(observation)
  }

  return (
    <>
      <ModalObservation
        isOpen={modalObservationOpen}
        toggle={() => setModalObservationOpen(!modalObservationOpen)}
        influencer={selectedInfluencer}
      />

      <ModalRemarketing
        isOpen={modalRemarketingOpen}
        toggle={() => setModalRemarketingOpen(!modalRemarketingOpen)}
        influencer={selectedInfluencer}
      />


      <Row className=" align-items-center">
        <Col md={3}>
          <div className="text-sm-end mb-3">
            <Input
              type="text"
              placeholder="Pesquisar por @"
              value={searchUsername}
              onChange={handleSearchChange}
            />
          </div>
        </Col>

        <Col md={2}>
          <div className="text-sm-end mb-3">
            <Dropdown
              isOpen={dropdownOpen}
              toggle={toggle}
              className="mb-2 me-2"
            >
              <DropdownToggle caret>{statusFilter}</DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => handleStatusFilter("Todos")}>
                  Todos
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem header>Filtrar Status</DropdownItem>
                <DropdownItem onClick={() => handleStatusFilter("Primeira Abordagem")}>
                  Primeira Abordagem
                </DropdownItem>
                <DropdownItem onClick={() => handleStatusFilter("Conversa Iniciada")}>
                  Conversa Iniciada
                </DropdownItem>
                <DropdownItem onClick={() => handleStatusFilter("Convites de Whatsapp")}>
                  Convites de Whatsapp
                </DropdownItem>
                <DropdownItem onClick={() => handleStatusFilter("Whatsapp Captado")}>
                  Whatsapp Captado
                </DropdownItem>
                <DropdownItem onClick={() => handleStatusFilter("Whatsapp Respondidos")}>
                  Whatsapp Respondidos
                </DropdownItem>
                <DropdownItem onClick={() => handleStatusFilter("Follow Up")}>
                  Follow Up
                </DropdownItem>
                <DropdownItem onClick={() => handleStatusFilter("Sem sucesso")}>
                  Sem sucesso
                </DropdownItem>
                <DropdownItem onClick={() => handleStatusFilter("Fechado")}>
                  Fechado
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </Col>

        <Col md={1}>
          <div className="text-sm-end mb-3">
            <Dropdown
              isOpen={dropdownOpenCampaign}
              toggle={toggleCampaign}
              className="mb-2 me-2"
            >
              <DropdownToggle caret>{campaignFilter}</DropdownToggle>

              <DropdownMenu>
                <>
                  <DropdownItem onClick={() => handleCampaignFilter("Todos")}>
                    Todos
                  </DropdownItem>
                  {listCampaign.map((element, index) => (
                    <DropdownItem
                      key={element.id}
                      onClick={() => handleCampaignFilter(element.name)}
                    >
                      {element.name}
                    </DropdownItem>
                  ))}
                </>
                {/* Add other campaign filter options here */}
              </DropdownMenu>
            </Dropdown>
          </div>
        </Col>

        {/* TERCEIRO FILTRO */}
        <Col md={1}>
          <div className="text-sm-end mb-3">
            <Dropdown
              isOpen={dropdownOpenObservation}
              toggle={toggleObservation}
              className="mb-2 me-2"
            >
              <DropdownToggle caret>{observationFilter}</DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => handleObservationFilter("Todos")}>
                  Todos
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem header>Filtrar Detalhes</DropdownItem>
                <DropdownItem onClick={() => handleObservationFilter("Valores.")}>
                  Valores.
                </DropdownItem>
                <DropdownItem onClick={() => handleObservationFilter("Não divulga casa de apostas.")}>
                  Não divulga casa de apostas.
                </DropdownItem>
                <DropdownItem onClick={() => handleObservationFilter("não respondeu.")}>
                  Não respondeu.
                </DropdownItem>
                <DropdownItem onClick={() => handleObservationFilter("Outros...")}>
                  Outros...
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </Col>

        <Col md={5}>
          <div className="text-sm-end mb-3">
            {/* <Button
              type="button"
              color="primary"
              className="btn mb-2 me-2"
              onClick={handleUserClick}
            >
              <i className="mdi mdi-plus-circle-outline me-1" />
              Cadastrar Influencer
            </Button> */}
          </div>
        </Col>
      </Row>

      <Table striped responsive className="table-sm">
        <thead className="table-header">
          <tr>
            <th className="align-middle">Foto</th>
            <th className="align-middle">@ Influencer</th>
            <th className="align-middle">Telefone</th>
            <th className="align-middle">Remarketing</th>
            <th className="align-middle">Campanha</th>
            <th className="align-middle">Status</th>
            <th className="align-middle">Ação</th>
          </tr>
        </thead>
        <tbody>
          {filteredInfluencers.map((influencer, index) =>
            id === influencer.captacao && influencer.status_influencer.status !== "Novo" ? (
              <tr key={index}>
                <th scope="row">
                  <img
                    className="photoInfluencer"
                    src={influencer.photo}
                    alt=""
                  />
                </th>
                <td>{influencer.username}</td>
                <td>{influencer.phone}</td>
                <td>

                {influencer.status_influencer.status === "Em contato" && influencer.remarketing ? (
                  <Link
                    to="#"
                    className="text-info"
                    onClick={() => openRemarketingModal(influencer)}
                  >
                    <i className="mdi mdi-magnify font-size-18" id={`remarketingtooltip-${index}`} />
                    <UncontrolledTooltip placement="top" target={`remarketingtooltip-${index}`}>
                      Visualizar Remarketing
                    </UncontrolledTooltip>
                  </Link>
                ) : null}

                </td>
                <td>{influencer.campaigns_influencer?.name}</td>
                <td>{influencer.status_influencer.status}</td>
                <td>
                  {influencer.status_influencer.status !== "Fechado" && influencer.status_influencer.status !== "Sem sucesso"  ? (
                    <>
                      <Link
                        to="#"
                        className="text-success"
                        onClick={() => {
                          update(influencer)
                        }}
                      >
                        <i
                          className="mdi mdi-pencil font-size-18"
                          id={`edittooltip-${index}`}
                        />
                        <UncontrolledTooltip
                          placement="top"
                          target={`edittooltip-${index}`}
                        >
                          Editar
                        </UncontrolledTooltip>
                      </Link>
                      <Link
                        to="#"
                        className="text-danger"
                        onClick={() => {
                          deleteInfluencer(influencer)
                        }}
                      >
                        <i
                          className="mdi mdi-delete font-size-18"
                          id={`deletetooltip-${index}`}
                        />
                        <UncontrolledTooltip
                          placement="top"
                          target={`deletetooltip-${index}`}
                        >
                          Delete
                        </UncontrolledTooltip>
                      </Link>
                    </>
                  ) : null}
                  {influencer.status_influencer.status === "Sem sucesso" ? (
                      <>
                          <Link
                            to="#"
                            className="text-info"
                            onClick={() => openObservationModal(influencer)}
                          >
                            <i className="mdi mdi-magnify font-size-18" id={`visualizetooltip-${index}`} />
                            <UncontrolledTooltip placement="top" target={`visualizetooltip-${index}`}>
                              Visualizar
                            </UncontrolledTooltip>
                          </Link>
                      </>
                  ) : null}

                </td>
              </tr>
            ) : null
          )}
        </tbody>
      </Table>
    </>
  )
}

export default Tables