import React from 'react';
import styled from 'styled-components';

// Estilos do Toggle
const ToggleContainer = styled.div`
  width: 51px;
  height: 31px;
  position: relative;
`;

const ToggleCheckbox = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
`;

const ToggleSwitch = styled.label`
  width: 100%;
  height: 100%;
  display: block;
  background-color: #e9e9eb;
  border-radius: 16px;
  cursor: pointer;
  transition: background-color 0.2s ease-out;

  &::after {
    content: '';
    width: 27px;
    height: 27px;
    position: absolute;
    left: calc(50% - 27px / 2 - 10px);
    top: calc(50% - 27px / 2);
    border-radius: 50%;
    background: #ffffff;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15),
                0px 3px 1px rgba(0, 0, 0, 0.06);
    transition: left 0.2s ease-out;
  }

  ${ToggleCheckbox}:checked + & {
    background-color: #34C759;

    &::after {
      left: calc(50% - 27px / 2 + 10px);
    }
  }
`;

const Toggle = ({ checked, onClick, id }) => {
    return (
      <ToggleContainer>
        <ToggleCheckbox
          type="checkbox"
          id={id}
          checked={checked}
          onClick={onClick}
        />
        <ToggleSwitch htmlFor={id} />
      </ToggleContainer>
    );
  };
  
  export default Toggle;