import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../../components/Common/ChartsDynamicColor";
const SalesAnalytics = ({ dataColors, dashboard, values }) => {
  const apexsalesAnalyticsChartColors = getChartColorsArray(dataColors);
  
  const options = {
    labels: ["Adicionados", "Sem sucesso", "Fechado"],
    colors: apexsalesAnalyticsChartColors,
    legend: { show: !1 },
    plotOptions: {
      pie: {
        donut: {
          size: "80%",
        },
      },
    },
  };

  return (
        <Col md={6}>
        <Card>
          <CardBody>
            <h4 className="card-title mb-4">Filtro de Fechados</h4>

            <div>
              <div id="donut-chart">
                <ReactApexChart
                  options={options}
                  series={values}
                  type="donut"
                  height={260}
                  className="apex-charts"
                />
              </div>
            </div>

            <div className="text-center text-muted">
              <Row>
                <Col xs="4">
                  <div className="mt-4">
                    <p className="mb-2 text-truncate text-muted">
                      <i className="mdi mdi-circle text-warning me-1" /> Adicionados
                    </p>
                    <h5>{values[0]}</h5>
                  </div>
                </Col>
         
                <Col xs="4">
                  <div className="mt-4">
                    <p className="mb-2 text-truncate text-muted">
                      <i className="mdi mdi-circle text-danger me-1" />Sem sucesso
                    </p>
                    <h5>{values[1]}</h5>
                  </div>
                </Col>
                <Col xs="4">
                  <div className="mt-4">
                    <p className="mb-2 text-truncate text-muted">
                      <i className="mdi mdi-circle text-success me-1" />Fechado
                    </p>
                    <h5>{values[2]}</h5>
                  </div>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </Col>
  );
};

export default SalesAnalytics;
