import React, { useEffect, useState, useMemo, useContext } from "react"

import Loading from "../../../components/Ui/LoadingCircle"

import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap"

import ApexRevenue from "./ApexRevenue"

// Importar Breadcrumbs (Trilha de navegação)
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// Importar Componentes
import CardUser from "./card-user"
import CardWelcome from "./card-welcome"
import MiniWidget from "./mini-widget"
import Earning from "./earning"
import SalesAnalytics from "./sales-analytics"
import TotalSellingProduct from "./total-selling-product"
import Tasks from "./tasks"
import ChatBox from "./chat-box"
import MiniCards from "./mini-card"

import { AuthContext } from "contexts/auth"
import api from "config/api"

import { Fechados, FechadosCards, Title } from "./styles"

const DashboardSaas = props => {
  const { user } = useContext(AuthContext)
  const [loading, setLoading] = useState(true)
  const [grafico1, setGrafico1] = useState({});
  const [grafico2, setGrafico2] = useState({adicionadosHoje: 0, adicionadosMes: 0, adicionadosSemana: 0});
  const [grafico3, setGrafico3] = useState({});
  const [grafico4, setGrafico4] = useState({});
  const [errorMessage, setErrorMessage] = useState(null)


  useEffect(() => {
    setLoading(true);
    if (user.token && user.userId) {
      api.get(`graphics/hunter/${user.userId}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
        .then(response => {
          const data = response.data;
          setGrafico1(data.graphic1);
          setGrafico2(data.graphic2);
          setGrafico3(data.graphic3);
          setGrafico4(data.graphic4);
          setLoading(false);
        })
        .catch(error => {
          console.error("Erro ao carregar os Dados:", error);
          setErrorMessage("Erro ao carregar os Dados.");
          setLoading(false);
        });
    }
  }, [user.token, user.userId]);

  // Título da página
  document.title = "Painel | Plataforma Viraw ©"
  return (
    <React.Fragment>
      {!loading ? (
        <div className="page-content">
          <Container fluid>
            {/* Renderizar Trilha de navegação */}
            <Breadcrumbs title="Dashboard" breadcrumbItem="Painel" />

            {/* Cartão do Usuário */}
            <CardUser total={grafico1?.total} fechadosTotal ={grafico1?.fechadosTotal} fechadosMes={grafico1?.fechadosMes}/>

            <Row>
              <CardWelcome />

              <Col xl="8">
                <Row>
                  <Fechados>
                    <Title>Influencers Adicionados</Title>
                    <FechadosCards>
                      <MiniCards
                        title={"Hoje"}
                        text={`${grafico1?.adicionadosHoje}`}
                        iconClass={"bx-check-circle"}
                        key={"_card_" + 1}
                      />
                      <MiniCards
                        title={"Na semana"}
                        text={`${grafico1?.adicionadosSemana}`}
                        iconClass={"bx-check-circle"}
                        key={"_card_" + 2}
                      />
                      <MiniCards
                        title={"No mês"}
                        text={`${grafico1?.adicionadosMes}`}
                        iconClass={"bx-check-circle"}
                        key={"_card_" + 3}
                      />
                    </FechadosCards>
                  </Fechados>
                </Row>
              </Col>
            </Row>

            <Row>
               <Col md="6">
                <Card>
                  <CardBody>
                    <CardTitle className="card-title">Evolução Mensal</CardTitle>
                    <div id="revenue-chart">
                      <ApexRevenue
                        dataColors='["--bs-primary"]'
                        data={[
                          grafico2?.jan || 0,
                          grafico2?.fev || 0,
                          grafico2?.mar || 0,
                          grafico2?.abr || 0,
                          grafico2?.mai || 0,
                          grafico2?.jun || 0,
                          grafico2?.jul || 0,
                          grafico2?.ago || 0,
                          grafico2?.set || 0,
                          grafico2?.out || 0,
                          grafico2?.nov || 0,
                          grafico2?.dez || 0,
                        ]}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col> 

              <SalesAnalytics
                dataColors='["--bs-warning", "--bs-danger","--bs-success"]'
                dashboard={true}
                values={[
                  grafico1 && grafico1.total,
                  grafico1 && grafico1.semSucessoTotal,
                  grafico1 && grafico1.fechadosTotal,
                ]}
              /> 
            </Row>
          </Container>
        </div>
      ) : (
        <Loading />
      )}
    </React.Fragment>
  )
}

export default DashboardSaas
