import styled from "styled-components";


export const Fechados = styled.div`

`;

export const FechadosCards = styled.div`
display: flex;
flex-direction: row;
gap: 5px;
`;

export const Title = styled.h4`
text-align: left;
font-style: 12px;
padding: 5px;
`;