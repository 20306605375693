import React, { useState } from "react";
import { Button, Card, CardBody, Col, Row, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import PropTypes from "prop-types";

const List = ({ listData }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedInfluencer, setSelectedInfluencer] = useState(null);

    const toggle = () => setIsOpen(!isOpen);

    const handleShowDetails = (influencer) => {
        setSelectedInfluencer(influencer);
        toggle();
    };

    const redirect = (link) => {
        window.open(link, "_blank");
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const offset = +3; // Ajuste de horário
        const localDate = new Date(date.getTime() + offset * 60 * 60 * 1000);

        return localDate.toLocaleString("pt-BR", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            timeZone: "America/Sao_Paulo",
        });
    };

    return (
        <>
            <Row>
                {listData.map((item, key) => (
                    <Col xl={3} key={key}>
                        <Card>
                            <CardBody>
                                <div className="d-flex align-start mb-3">
                                    <div className="flex-grow-1">
                                        <span
                                            className={
                                                item.campaign === "Superbet"
                                                    ? "badge badge-soft-success"
                                                    : item.campaign === "Freelance"
                                                    ? "badge badge-soft-info"
                                                    : item.campaign === "Part Time"
                                                    ? "badge badge-soft-danger"
                                                    : "badge badge-soft-danger"
                                            }
                                        >
                                            {item.campaign}
                                        </span>
                                    </div>
                                </div>
                                <div className="text-center mb-3">
                                    <img
                                        src={item.photo}
                                        alt=""
                                        className="avatar-sm rounded-circle"
                                    />
                                    <h6 className="font-size-15 mt-3 mb-1">
                                        {item.fullname}
                                    </h6>
                                    <a onClick={() => redirect(`https://instagram.com/${item?.username}`)}>
                                        <p className="mb-0 text-muted">
                                            @{item.username}
                                        </p>
                                    </a>
                                </div>
                                <div className="d-flex mb-3 justify-content-center gap-2 text-muted">
                                    <p className="mb-0 text-center text-muted">
                                        Meta: {item.meta.meta}
                                    </p>
                                </div>
                                <div className="d-flex mb-3 justify-content-center gap-2 text-muted">
                                    <p className="mb-0 text-center text-muted">
                                        Depositantes: {item.meta.FTD}
                                    </p>
                                </div>
                                <div className="mt-4 pt-1">
                                    <Button
                                        className="btn btn-soft-primary d-block"
                                        style={{ width: "100%" }}
                                        onClick={() => handleShowDetails(item)}
                                    >
                                        Ver detalhes
                                    </Button>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                ))}
            </Row>
            {selectedInfluencer && (
                <Modal isOpen={isOpen} toggle={toggle}>
                    <ModalHeader toggle={toggle}>Detalhes</ModalHeader>
                    <ModalBody>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <h2>{selectedInfluencer.fullname}</h2>
                            <h5>
                                {`${formatDate(selectedInfluencer.meta.dateInit)} a ${formatDate(selectedInfluencer.meta.dateFinal)}`}
                            </h5>
                        </div>
                        <hr />
                        <h5>
                            <strong>Casa:</strong> {selectedInfluencer.campaign || ""}
                        </h5>
                        <h5>
                            <strong>Meta:</strong> {selectedInfluencer.meta.meta || 0}
                        </h5>
                        <h5>
                            <strong>Depositante:</strong> {selectedInfluencer.meta.FTD || 0}
                        </h5>
                        <h5>
                            <strong>Registros:</strong> {selectedInfluencer.meta.Registros || 0}
                        </h5>
                        <h5>
                            <strong>Clicks:</strong> {selectedInfluencer.meta.Clicks || 0}
                        </h5>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggle}>Fechar</Button>
                    </ModalFooter>
                </Modal>
            )}
        </>
    );
};

List.propTypes = {
    listData: PropTypes.array.isRequired,
};

export default List;
