import React, { useState, useContext, useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"

import { AuthContext } from "contexts/auth";

// import io from "socket.io-client";

import som from "../../../assets/sons/som.mp3"

//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg"
import avatar4 from "../../../assets/images/users/avatar-4.jpg"

//i18n
import { withTranslation } from "react-i18next"
import api from "config/api"

const NotificationDropdown = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const { user } = useContext(AuthContext)
  const [notifications, setNotifications] = useState()
  const [notiIcon, setNotiIcon] = useState()
  // const socket = io("https://api-viraw-tm8e.onrender.com");

  const audio = useMemo(() => new Audio(som), [som]);

 /*  useEffect(() => {
    socket.on("new_notifications", (items) => {
      update(items)
    });
  }, []); */


  useEffect(() => {
    getNotifications()
  }, [user.userId, user.token])

  async function getNotifications() {
    await api
      .get("/notifications", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then(response => {
        let notificationsTemp = []
        let countNotifications = 0


        response.data.forEach(element => {
          if (element.user === user.userId) {
            notificationsTemp.push(element)
            if (element.read === false) {
              countNotifications += 1
            }
          }
        })
        setNotiIcon(countNotifications)
        setNotifications(notificationsTemp)
      })
  }

  const update = (new_item) => {
    setNotifications(notifications => {
      if (new_item.user === user.userId) {
        const updatedNotifications = [...notifications, new_item];

        let notificationsTemp = [];
        let countNotifications = 0;
        updatedNotifications.forEach(element => {
          if (element.user === user.userId) {

            notificationsTemp.push(element);
            if (!element.read) {
              countNotifications += 1;
              audio.play().catch((error) => console.error("Erro ao reproduzir o áudio:", error));
            }

          }
        });
        setNotiIcon(countNotifications);
        return notificationsTemp;
      }
    });
  }

  const confirmRead = async (id) => {
    await api.put("/notifications/update/read", {
      id: id,
      read: true
    }, {
      headers: {
        Authorization: `Bearer ${user.token}`
      }
    }).then(response => {
      getNotifications()
    }).catch(error => {
      console.error(error)
    })
  }


  return (
    <React.Fragment>
      {user.type === 3 || user.type === 2  ? (
        <Dropdown
          isOpen={menu}
          toggle={() => setMenu(!menu)}
          className="dropdown d-inline-block"
          tag="li"
        >
          <DropdownToggle
            className="btn header-item noti-icon position-relative"
            tag="button"
            id="page-header-notifications-dropdown"
          >
            <i className="bx bx-bell bx-tada" />
            <span className="badge bg-danger rounded-pill">{notiIcon}</span>
          </DropdownToggle>

          <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
            <div className="p-3">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0"> {props.t("Notifications")} </h6>
                </Col>
              </Row>
            </div>

            <SimpleBar style={{ height: "230px" }}>
              {
                notifications && notifications.map((element, index) => (
                  element.read === false ? (
                    <Link key={index} className="text-reset notification-item" onClick={() => confirmRead(element.id)}>

                      <div className="d-flex">

                        <div className="avatar-xs me-3">
                          <span className="avatar-title bg-primary rounded-circle font-size-16">
                            <i className="bx bxs-chat" />
                          </span>
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="mt-0 mb-1">
                            Atenção
                          </h6>
                          <div className="font-size-12 text-muted">
                            <p className="mb-1 text-muted">
                              {element.message}
                            </p>
                            <p className="mb-0 text-muted">
                              @{element.influencer}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ) : null
                ))
              }
            </SimpleBar>
            <div className="p-2 border-top d-grid">
              <Link
                className="btn btn-sm btn-link font-size-14 text-center"
                to="/influencers"
              >
                <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
                <span key="t-view-more">{props.t("Ver influencers")}</span>
              </Link>
            </div>
          </DropdownMenu>
        </Dropdown>
      ) : null}
    </React.Fragment>
  )
}

export default withTranslation()(NotificationDropdown)

NotificationDropdown.propTypes = {
  t: PropTypes.any,
}
