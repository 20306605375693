import React, { useContext, useEffect, useState } from 'react';
import { Container, Row } from 'reactstrap';
import ChartSection from './ChartSection';
import JobVacancy from './JobVacancy';
import ReceivedTime from './ReceivedTime';
import SalesAnalytics2 from "./sales-analytics";
import EGames from "./egames";
import Section from './Section';
import StatisticsApplications from './StatisticsApplications';
import { AuthContext } from 'contexts/auth';
import api from 'config/api';
import CandidateSection from './CandidateSection';
import Loading from "components/Ui/LoadingCircle"

const DashboardJob = () => {
    const { user } = useContext(AuthContext);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function getGraphics() {
            await api.get(`/graphics/super/admin/${user.userId}`, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            }).then(response => {
                setData(response.data);
                setLoading(false);
            });
        }
        getGraphics();
    }, [user.token]);

    const calculatePercentageChange = (current, previous) => {
        if (!previous) return 0; 
        return ((current - previous) / previous) * 100;
    };

    const getChartData = () => {
        const depositsChange = data?.GraphicDeposit?.length > 1 ? calculatePercentageChange(
            data.GraphicDeposit[data.GraphicDeposit.length - 1],
            data.GraphicDeposit[data.GraphicDeposit.length - 2]
        ) : 0;

        const registrationsChange = data?.GraphicsRegistrations?.length > 1 ? calculatePercentageChange(
            data.GraphicsRegistrations[data.GraphicsRegistrations.length - 1],
            data.GraphicsRegistrations[data.GraphicsRegistrations.length - 2]
        ) : 0;

        const withdrawalsChange = data?.GraphicsWithdrawals?.length > 1 ? calculatePercentageChange(
            data.GraphicsWithdrawals[data.GraphicsWithdrawals.length - 1],
            data.GraphicsWithdrawals[data.GraphicsWithdrawals.length - 2]
        ) : 0;

        const commissionChange = data?.GraphicsCommission?.length > 1 ? calculatePercentageChange(
            data.GraphicsCommission[data.GraphicsCommission.length - 1],
            data.GraphicsCommission[data.GraphicsCommission.length - 2]
        ) : 0;

        return [
            {
                id: 1,
                title: "Depósitos Total",
                price: data?.Total?.Deposits.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
                perstangeValue: depositsChange.toFixed(2),
                bagdeColor: depositsChange >= 0 ? "success" : "danger",
                seriesData: [{
                    name: "Depósitos",
                    data: data?.GraphicDeposit,
                    number: false,
                }],
                color: depositsChange >= 0 ? '["--bs-success", "--bs-transparent"]' : '["--bs-danger", "--bs-transparent"]'
            },
            {
                id: 2,
                title: "Registros Total",
                price: data?.Total?.Registrations,
                perstangeValue: registrationsChange.toFixed(2),
                bagdeColor: registrationsChange >= 0 ? "success" : "danger",
                seriesData: [{
                    name: "New Application",
                    data: data?.GraphicsRegistrations,
                    number: true,
                }],
                color: registrationsChange >= 0 ? '["--bs-success", "--bs-transparent"]' : '["--bs-danger", "--bs-transparent"]'
            },
            {
                id: 3,
                title: "Comissão Total",
                price: data?.Total?.Commission.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
                perstangeValue: commissionChange.toFixed(2),
                bagdeColor: commissionChange >= 0 ? "success" : "danger",
                seriesData: [{
                    name: "Total? Approved",
                    data: data?.GraphicsCommission,
                    number: false,
                }],
                color: commissionChange >= 0 ? '["--bs-success", "--bs-transparent"]' : '["--bs-danger", "--bs-transparent"]'
            },
            {
                id: 4,
                title: "Total retirado",
                price: data?.Total?.Withdrawals.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
                perstangeValue: withdrawalsChange.toFixed(2),
                bagdeColor: withdrawalsChange >= 0 ? "success" : "danger",
                istrendingArrow: true,
                seriesData: [{
                    name: "Total? Rejected",
                    data: data?.GraphicsWithdrawals,
                    number: false,
                }],
                color: withdrawalsChange >= 0 ? '["--bs-success", "--bs-transparent"]' : '["--bs-danger", "--bs-transparent"]'
            },
        ];
    };

    const chartsData = getChartData();

    return (
        <React.Fragment>
                {loading ? (
                       <Loading />
                ) : (
            <div className="page-content">
                    <Container fluid style={{ marginTop: '-30px' }}>
                        <Section />
                        <ChartSection chartsData={chartsData} />
                        <Row>
                            <StatisticsApplications data={data}/>
                            
                             <SalesAnalytics2 data={data.Total} dataMeses={data.Meses}dataColors='["--bs-primary","--bs-success","--bs-danger", "--bs-warning"]' dashboard={true} />
                        </Row>
        
                        <Row>
                        <JobVacancy data={data} /> 
                        <CandidateSection />
                         {/*    <ReceivedTime /> */}
                            {/* <ActivityFeed /> */}
                           
                        </Row>
                   
                    </Container>
            </div>
                )}
        </React.Fragment>
    );
};

export default DashboardJob;